import { Box, CircularProgress, Divider, Paper } from '@mui/material'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import React from 'react'
import { RootState } from '../../configureStore'
import ActionBar from './ActionBar'
import { style } from '../Report/SheetStyles'
import { Tab, Tabs } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { FamilyDetailsHeading, StyledTableCell } from '../Common/CommonStyles'
import AnalyticsPieChart from './AnalyticsPieChart'
import FreeResourcesTable from './FreeResourcesTable'
import DomainChart from './DomainChart'
import {
  fetchCertiBySplitUp,
  fetchCertiPercentage,
  fetchCountryDistribution,
  fetchGradeLable,
  fetchUserStatistics,
  fetchUserTechnology,
} from '../../actions'
import { fetchUserDetailsEntities, fetchUserDetailsUI } from '../../reducers'
import CertifiedUserChart from './CertifiedUserChart'
import CertificateWiseSplitUp from './CertificateWiseSplitUp'
import UserStatisticsChart from './UserStatisticsChart'
import CountryContributionChart from './CountryContributionChart'
//   import AnalyticsPieChart from '../Report/'

const styles = {
  styledCells: {
    padding: '6px 6px',
  },
  typoIntablehead: {
    margin: '0',
    padding: '6px 2px',
    width: '160px',
  },
  border: {
    borderLeft: '1px solid #E9E9E9',
    borderRight: '1px solid #E9E9E9',
  },
}

const ProjectAnalytics = (props: any) => {
  const [workingEmployee, setWorkingEmployee] = useState<number | null>(null)
  const {
    fetchUserTechnology,
    getUserTechnology,
    isUserTechnology,
    fetchGradeLable,
    getGradeLabelData,
    fetchCertiBySplitUp,
    getCertiBySplitUpData,
    getCertiPercentage,
    fetchCertiPercentage,
    fetchUserStatistics,
    getUserStatistics,
    getCountryDistribution,
    fetchCountryDistribution,
    DrsData,
    isGetUserTechnologyLoader,
    isCertiPercentageLoader,
    isCountryDistributionLoader,
    isUserStatisticsLoader,
    isGradeLabelLoader,
    isCertiBySplitLoader,
  } = props

  const [startDate, setStartDate] = useState(dayjs())
  const [endDate, setEndDate] = useState(dayjs())
  const savedTab = parseInt(sessionStorage.getItem('activeTab') || '0', 10)
  const [selectedTab, setSelectedTab] = useState(savedTab || 1)

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }

  const handleSelectedTab = (data: any) => {
    sessionStorage.setItem('activeTab', data.toString()) // Store the selected tab in sessionStorage
    setSelectedTab(data)
    setStartDate(dayjs())
    setEndDate(dayjs())
  }

  useEffect(() => {
    fetchUserStatistics()
    fetchGradeLable()
    fetchCertiPercentage({ date: startDate.format('YYYY-MM-DD').toString() })
    fetchUserTechnology({ date: startDate.format('YYYY-MM-DD').toString() })
    fetchCountryDistribution({ date: startDate.format('YYYY-MM-DD').toString() })
    fetchCertiBySplitUp({ date: startDate.format('YYYY-MM-DD').toString() })
  }, [])


  return (
    <Paper sx={style.container}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          marginTop: 0,
        }}
      >
        <Tabs
          value={selectedTab - 1}
          onChange={handleTabChange}
          aria-label='Tabs for different tables'
        >
          <Tab label='Analytics Graph' onClick={() => handleSelectedTab(1)} />
          {/* <Tab label='Free Resources' onClick={() => handleSelectedTab(2)} />
          <Tab label='Domain' onClick={() => handleSelectedTab(3)} /> */}
        </Tabs>
      </Box>
      <Box sx={style.pageHead}></Box>
      {selectedTab === 1 && (
        <>
          <Box sx={{ border: '2px solid #E0E0E0', borderRadius: '5px' }}>
            <Box
              style={{
                display: 'flex',
                alignItems: 'flex-start', // Aligns items vertically in the center
                marginTop: '30px',
                height: '450px',
                marginBottom: '30px',
              }}
            >
              <Box
                sx={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center', // Centers content inside this box vertically
                  alignItems: 'center',
                }}
              >
                <FamilyDetailsHeading>Certificate Wise Split Up</FamilyDetailsHeading>
                {isCertiBySplitLoader ? (
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '380px', // Adjust to match the table/chart height
                      width: '70%',
                    }}
                  >
                    <CircularProgress disableShrink />
                  </Box>
                ) : getCertiBySplitUpData && getCertiBySplitUpData.length > 0 ? (
                  <CertificateWiseSplitUp myChartData={getCertiBySplitUpData} />
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '430px',
                      width: '70%',
                    }}
                  >
                    <p>No Data Found</p>
                  </div>
                )}
              </Box>
              <Divider orientation='vertical' flexItem sx={{ mx: 2, border: '1.9px solid #E0E0E0' }} />
              <Box
                style={{
                  marginLeft: '20px',
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center', // Centers content inside this box vertically
                  alignItems: 'center',
                }}
              >
                <FamilyDetailsHeading>Certified Employees</FamilyDetailsHeading>
                {isCertiBySplitLoader ? (
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '380px', // Adjust to match the table/chart height
                      width: '70%',
                    }}
                  >
                    <CircularProgress disableShrink />
                  </Box>
                ) : getUserTechnology?.technologies &&
                  getUserTechnology?.technologies.length > 0 ? (
                  <CertifiedUserChart myChartData={getCertiPercentage} />
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '430px',
                      width: '70%',
                    }}
                  >
                    <p>No Data Found</p>
                  </div>
                )}
              </Box>
            </Box>

            <Box
              style={{
                display: 'flex',
                alignItems: 'flex-start', // Aligns items vertically in the center
                marginTop: '30px',
                marginBottom: '30px',
              }}
            >
              <Box
                sx={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center', // Centers content inside this box vertically
                  alignItems: 'center',
                }}
              >
                <FamilyDetailsHeading>Country-wise Business Distribution</FamilyDetailsHeading>
                {isCountryDistributionLoader ? (
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '380px', // Adjust to match the table/chart height
                      width: '70%',
                    }}
                  >
                    <CircularProgress disableShrink />
                  </Box>
                ) : getCountryDistribution && getCountryDistribution.length > 0 ? (
                  <CountryContributionChart myChartData={getCountryDistribution} />
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '430px',
                      width: '70%',
                    }}
                  >
                    <p>No Data Found</p>
                  </div>
                )}
              </Box>
              <Divider orientation='vertical' flexItem sx={{ mx: 2, border: '1.9px solid #E0E0E0' }} />
              <Box
                style={{
                  marginLeft: '20px',
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center', // Centers content inside this box vertically
                  alignItems: 'center',
                }}
              >
                <FamilyDetailsHeading>Hierarchy-based Workforce Distribution</FamilyDetailsHeading>
                {isGradeLabelLoader ? (
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '380px', // Adjust to match the table/chart height
                      width: '70%',
                    }}
                  >
                    <CircularProgress disableShrink />
                  </Box>
                ) : Object.keys(getGradeLabelData).length > 0 ? (
                  <DomainChart myChartData={getGradeLabelData} flag={'free-resource'} />
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '430px',
                      width: '70%',
                    }}
                  >
                    <p>No Data Found</p>
                  </div>
                )}
              </Box>
            </Box>

            <Box
              style={{
                marginLeft: '-15px',
                display: 'flex',
                alignItems: 'flex-start', // Aligns items vertically in the center
                marginTop: '30px',
                marginBottom: '30px',
              }}
            >
              <Box
                sx={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center', // Centers content inside this box vertically
                  alignItems: 'center',
                }}
              >
                <FamilyDetailsHeading>Technology-wise Segmentation</FamilyDetailsHeading>
                {isCertiBySplitLoader ? (
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '380px', // Adjust to match the table/chart height
                      width: '70%',
                    }}
                  >
                    <CircularProgress disableShrink />
                  </Box>
                ) : getUserTechnology?.technologies &&
                  getUserTechnology?.technologies.length > 0 ? (
                  <AnalyticsPieChart myChartData={getUserTechnology} flag={'free-resource'} />
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '430px',
                      width: '70%',
                    }}
                  >
                    <p>No Data Found</p>
                  </div>
                )}
              </Box>
              <Divider orientation='vertical' flexItem sx={{ mx: 2, border: '1.9px solid #E0E0E0' }} />
              <Box
                style={{
                  marginLeft: '20px',
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center', // Centers content inside this box vertically
                  alignItems: 'center',
                }}
              >
                <FamilyDetailsHeading>Resource Utilization and Analytics</FamilyDetailsHeading>
                {isUserStatisticsLoader ? (
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '380px', // Adjust to match the table/chart height
                      width: '70%',
                    }}
                  >
                    <CircularProgress disableShrink />
                  </Box>
                ) : getUserStatistics && Object.keys(getUserStatistics).length > 0 ? (
                  <UserStatisticsChart myChartData={getUserStatistics} />
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '430px',
                      width: '70%',
                    }}
                  >
                    <p>No Data Found</p>
                  </div>
                )}
              </Box>
            </Box>
          </Box>
        </>
      )}

      {selectedTab === 2 && <FreeResourcesTable />}
    </Paper>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    getCountryDistribution: fetchUserDetailsEntities.fetchUserData(state).getContryDistribution,
    getCertiPercentage: fetchUserDetailsEntities.fetchUserData(state).getCertiPercentage,
    getUserStatistics: fetchUserDetailsEntities.fetchUserData(state).getUserStatistics,
    getCertiBySplitUpData: fetchUserDetailsEntities.fetchUserData(state).getCertiBySplitUpData,
    getGradeLabelData: fetchUserDetailsEntities.fetchUserData(state).getGradeLabelData,
    getUserTechnology: fetchUserDetailsEntities.fetchUserData(state).getUserTechnology,
    isGetUserTechnologyLoader: fetchUserDetailsUI.fetchUserData(state).isUserTechnologyFetched,
    isCertiBySplitLoader: fetchUserDetailsUI.fetchUserData(state).isCertiBySplit,
    isGradeLabelLoader: fetchUserDetailsUI.fetchUserData(state).isGradeLabel,
    isUserStatisticsLoader: fetchUserDetailsUI.fetchUserData(state).isUserStatistics,
    isCertiPercentageLoader: fetchUserDetailsUI.fetchUserData(state).isCertiPercentage,
    isCountryDistributionLoader: fetchUserDetailsUI.fetchUserData(state).isCountryDistribution,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchCountryDistribution: (data: any) => dispatch(fetchCountryDistribution.request(data)),

    fetchUserStatistics: () => dispatch(fetchUserStatistics.request()),
    fetchCertiPercentage: (data: any) => dispatch(fetchCertiPercentage.request(data)),

    fetchCertiBySplitUp: (data: any) => dispatch(fetchCertiBySplitUp.request(data)),

    fetchGradeLable: () => dispatch(fetchGradeLable.request()),
    fetchUserTechnology: (data: any) => dispatch(fetchUserTechnology.request(data)),
    resetUserTechnology: () => dispatch(fetchUserTechnology.reset()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectAnalytics)
