import { useEffect, useState } from 'react'
import { loaderProps, StyledTableCell, StyledTableCellForMyTeam, StyledTableRow } from '../Common/CommonStyles'
import CommentIcon from '@mui/icons-material/Comment';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Tooltip,
  Typography,
  styled,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Heading } from '../Pages/Styles'
import { RootState } from '../../configureStore'
import { attendanceEntity, attendanceUI, dashboardEntity } from '../../reducers'
import { Dispatch } from 'redux'
import { getAttendanceDetail, getAttendanceTimesheet, getCommentForTimesheet, managerViewData } from '../../actions'
import { connect } from 'react-redux'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { ReactComponent as EditIcon } from "../../assets/images/editIconTimesheet.svg";
import { toast } from 'react-toastify';
import './IDSRs.scss'
import dayjs from 'dayjs';
import style from '../../utils/styles.json'

const StyledPaper = styled(Paper)(() => ({
  width: '93%',
  padding: '1px  25px 1px 25px',
  background: '#FFFFFF',
  opacity: '1',
  marginTop: '10px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '70px',
  border: '1px solid #DDDDDD',
}))

const ActionButton = styled(Button)(() => ({
  fontSize: '13px',
  height: '42px',
  borderRadius: '20px',
  padding: '5px 20px',
  fontFamily: style.FONT_MEDIUM,
}))

const CancelButton = styled(Button)(({ theme }) => ({
  background: '#E2E2E2',
  color: '#000000',
  fontSize: '13px',
  height: '42px',
  fontFamily: style.FONT_BOLD,
  width: '20%',
  borderRadius: '20px',
  '&:hover': {
    background: '#E2E2E2',
    color: '#000000',
  },
}))

const DrsIDSRsTable = (props: any) => {
  const { ManagerViewData, mandateTypeData } = props
  let statusType = new Map<number, string>()
  for (const key in mandateTypeData?.data) {
    statusType.set(mandateTypeData.data[key]?.id, mandateTypeData.data[key]?.mandate_name)
  }

  const convertMinutesToHours = (minutes: number) => {
    const hours = (minutes / 60).toFixed(1);
    return `${hours} hrs`;
  };

  function htmlToText(html: string) {
    const temporaryElement = document.createElement('div');
    temporaryElement.innerHTML = html;
    return temporaryElement.textContent || temporaryElement.innerText || '';
  }

  const leadTooltipContent = (data: any) => {
    return (
      <div style={{ padding: "8px", width: '150px' }}>
        <Typography fontWeight={500} fontSize={'13px'} >Comment: </Typography>
        <Typography marginTop={'5px'} fontSize={'12px'}>{data}</Typography>
      </div>
    );
  };

  return (
    <StyledPaper>
      <TableContainer sx={{ margin: '36px 0' }} component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label='customized table'>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>DATE (DAY)</StyledTableCell>
              <StyledTableCell>SUBJECT</StyledTableCell>
              <StyledTableCell>PROJECT NAME</StyledTableCell>
              <StyledTableCell>TYPE</StyledTableCell>
              <StyledTableCell>TIME SPENT</StyledTableCell>
              <StyledTableCell>SUBMITTED AT</StyledTableCell>
              <StyledTableCell>COMMENT</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          {ManagerViewData?.IDSR?.length ? (
            <TableBody>
              {ManagerViewData?.IDSR?.map((data: any) => (
                <StyledTableRow
                  key={`${data?.subject}${data.time_spent}`}
                  onClick={() => props.onRowClick(data)}
                  sx={{ cursor: 'pointer' }}
                >
                  <StyledTableCellForMyTeam component='th' scope='row'>
                    {`${new Date(data.IDSR_date).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    })}`}{' '}
                    (
                    {`${new Date(data.IDSR_date).toLocaleDateString('en-GB', {
                      weekday: 'long',
                    })}`}
                    )
                  </StyledTableCellForMyTeam>
                  <StyledTableCellForMyTeam align='left'>{data.subject}</StyledTableCellForMyTeam>
                  <StyledTableCellForMyTeam align='left'>
                    {data.project_name === 'Other' ? data.other_project_name : data.project_name}
                  </StyledTableCellForMyTeam>
                  <StyledTableCellForMyTeam align='left'>
                    {data.mandate_type_id ? statusType.get(data.mandate_type_id) : '-'}</StyledTableCellForMyTeam>{' '}
                  <StyledTableCellForMyTeam align='left'>
                    {`${data.time_spent} mins (${convertMinutesToHours(data.time_spent)})`}
                  </StyledTableCellForMyTeam>

                  <StyledTableCellForMyTeam align='left'>
                    {dayjs(data.submission_time).format('hh:mm A')}
                  </StyledTableCellForMyTeam>
                  <StyledTableCellForMyTeam align='left'><Tooltip placement="left"
                    classes={{
                      tooltip: 'custom-tooltip',
                      arrow: "custom-tooltip-arrow",
                    }} title={data.comment ? leadTooltipContent(htmlToText(data.comment)) : ''} arrow>
                    <IconButton sx={{
                      width: '40px',
                      opacity: data?.comment ? 1 : 0.5,
                      color: '#483F3F',
                      cursor: data?.comment ? 'pointer' : 'not-allowed',
                    }}
                      disableRipple={data?.comment}
                    > <CommentIcon />
                    </IconButton>
                  </Tooltip></StyledTableCellForMyTeam>
                </StyledTableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align='center' colSpan={10}>
                  <Typography variant='subtitle1' sx={{ color: '#483f3f', marginLeft: 50 }}>
                    No matching records found.
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </StyledPaper>
  )
}

const DRsIDSRsList = (props: any) => {
  const { ManagerViewData, mandateTypeData, resetCommentStat } = props

  const [selectedIDSR, setSelectedIDSR] = useState<any | null>(null)


  const [formValues, setFormValues] = useState('')

  const handleTaskDescriptionChange = (content: string) => {
    setFormValues(content);
  }
  const [timesheetID, setTimesheetID] = useState();
  const [isNewPlanDialogOpen, setNewPlanDialog] = useState(false)

  const handleNewPlanDialog = () => {
    setNewPlanDialog(true)
  }

  const handleCloseNewPlanDialog = () => {
    setNewPlanDialog(false)
  }


  const handlePostComment = () => {
    if (formValues) {
      let finalData = JSON.stringify({ comment: formValues });
      props.createCommentTimesheet({ id: timesheetID, data: finalData })
      handleCloseNewPlanDialog();
      setFormValues('')
    }
  }

  const handleRowClickData = (data: any) => {
    setSelectedIDSR(data)
  }
  const handleCloseModal = () => {
    setSelectedIDSR(null)
  }

  function convertTextToHTML(text: string) {
    let htmlText = text.replace(/\\n/g, '<br>')

    htmlText = htmlText.replace(/\*([^*]+)\*/g, '<strong>$1</strong>')

    return <div dangerouslySetInnerHTML={{ __html: htmlText }} />
  }


  interface DialogTitleProps {
    id: string
    children?: React.ReactNode
    onClose: () => void
  }


  useEffect(() => {
    if (!props.getLoaderState && props.getCommentSuccess) {
      toast.success('Comment added successfully')
      props.resetTimesheetComment()
    }
  }, [props.getCommentSuccess])


  function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label='close'
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    )
  }

  return (
    <>
      <DrsIDSRsTable
        ManagerViewData={ManagerViewData}
        onRowClick={handleRowClickData}
        mandateTypeData={mandateTypeData}
      />
      <Modal open={!!selectedIDSR} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70vw',
            maxWidth: '75%',
            bgcolor: 'background.paper',
            p: 4,
            maxHeight: '80vh',
            overflowY: 'auto',
            borderRadius: '10px',
          }}
        >

          <IconButton
            aria-label='close'
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[600],
              marginTop: '15px',
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box display={'flex'} justifyContent={'center'} width='100%' height={'70px'}>
            <Box width='55%' justifyContent='flex-end' display='flex'>
              <Heading
                style={{
                  fontSize: '18px',
                  // textAlign: 'center',
                  // fontFamily: 'sans-serif',
                  // letterSpacing: '0px',
                  // position: 'relative',
                }}
                dangerouslySetInnerHTML={{
                  __html: selectedIDSR?.subject || '',
                }}

              />
            </Box>
            <Box width={'35%'} display={'flex'} justifyContent={'flex-end'} >
              <IconButton
                aria-label='close'
                // onClick={handleCloseModal}
                sx={{
                  position: 'absolute',
                  left: '60%',
                  top: 8,
                  color: 'black',
                  marginTop: '11px',
                }}
              >
                <Tooltip title='Add Comment' arrow>
                  <EditIcon onClick={() => { setTimesheetID(selectedIDSR.id); setSelectedIDSR(null); return handleNewPlanDialog() }} />
                </Tooltip>
              </IconButton>
            </Box>
          </Box>
          <Typography variant='body1' style={{ fontSize: '12px', marginTop: '-25px' }}>
            <div>{convertTextToHTML(selectedIDSR?.body || '')}</div>
          </Typography>
          {
            selectedIDSR?.comment && <Box width={'98%'} paddingTop={'15px'} >
              {/* <Typography fontFamily={'Montserrat-Semibold'} fontSize={'15px'} >Comment </Typography> */}
              <Box width={'100%'} height={'30px'} display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                <Typography fontFamily={style.FONT_BOLD} fontSize={'15px'} >Comment </Typography>
                <Typography marginLeft={'3px'} fontFamily={style.FONT_BOLD} fontSize={'13px'}> {`( ${dayjs(selectedIDSR?.comment_submission_time).format("MM/DD/YYYY hh:mm A")})`}:</Typography>
              </Box>

              <Typography border={'1px solid #D3D3D3'} padding={'0 10px'} width={'100%'} sx={{
                wordWrap: 'break-word',
                fontSize: '12px',
                borderBottomColor: 'coral',
              }}>{convertTextToHTML(selectedIDSR?.comment || '')}</Typography>
            </Box>
          }
        </Box>
      </Modal>

      <Dialog open={isNewPlanDialogOpen} >
        <Box className="PFTD_Wrapper">
          <BootstrapDialogTitle id='customized-dialog-title' onClose={handleCloseNewPlanDialog} >
            <Typography padding={'5px 0'} variant='h5' sx={{ textAlign: 'center', marginTop: '-10px' }} fontSize={'22px'} fontFamily={style.FONT_MEDIUM}>
              Add Comment
            </Typography>
          </BootstrapDialogTitle>

          <Box sx={{ padding: '0 16px', marginTop: '-8px' }} >
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} >
              <Grid item xs={12} sm={12} className='PFTD_Editor'>
                <CKEditor
                  editor={ClassicEditor}
                  data={''}
                  onReady={() => { }}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    handleTaskDescriptionChange(data)
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <DialogActions className='button_wrapper' sx={{ padding: '15px 10px' }}>
            <Button sx={{
              background: '#E2E2E2',
              color: '#000000',
              fontSize: `13px`,
              height: `42px`,
              fontFamily: style.FONT_BOLD,
              width: '100px',
              borderRadius: '20px',
              '&:hover': {
                background: '#E2E2E2',
                color: '#000000',
              }
            }} onClick={handleCloseNewPlanDialog}>CANCEL</Button>
            <Button
              sx={{
                borderRadius: '20px',
                fontSize: '13px',
                height: '42px',
                fontFamily: style.FONT_BOLD,
                width: '100px',

                '&.Mui-disabled': {
                  opacity: 0.5,
                  color: '#ffffff',
                  cursor: 'not-allowed',
                },
              }}
              onClick={handlePostComment}
              disabled={!formValues}
            >
              Submit
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      <Dialog open={props.getLoaderState} PaperProps={{ style: loaderProps }}>
        <CircularProgress color='secondary' />
      </Dialog>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    ManagerViewData: dashboardEntity.getDashboard(state).getManagerViewData,
    AttendanceTimesheet: attendanceEntity.getAllAttendance(state).getAttendanceTimesheet,
    getUserById: dashboardEntity.getDashboard(state).getUserDetails,
    getComment: attendanceEntity.getAllAttendance(state).getCommentTimesheetReducer,
    getLoaderState: attendanceUI.getEmpAttendanceList(state).isCreateCommentForTimesheetLoader,
    getCommentSuccess: attendanceUI.getEmpAttendanceList(state).isCreateCommentForTimesheetSuccess,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchManagerViewData: (data: any) => dispatch(managerViewData.request(data)),
    fetchAttendanceTimesheet: (userId: any) => dispatch(getAttendanceTimesheet.request({ userId })),
    fetchAttendanceData: (data: any) => dispatch(getAttendanceDetail.request(data)),
    getCommentTimesheet: (data: any) => dispatch(getCommentForTimesheet.request(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DRsIDSRsList)
