import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { loaderProps } from '../Common/CommonStyles'
import { Box, Button, CircularProgress, Dialog, Grid, TextField, Typography } from '@mui/material'
import {
  CommonButton,
  InputField,
  SelectField,
  StyledMenuItem,
  useStyles,
} from './EmpoloyeementInfoStyle'
import { FormValues } from './IFormValue'
import { RootState } from '../../configureStore'
import {
  employeePortalEntity,
  fetchUserDetailsEntities,
  fetchUserDetailsUI,
  financeEntity,
  financeUI,
  projectManagementEntity,
} from '../../reducers'
import {
  fetchAllStates,
  fetchAllCountries,
  addBankInfo,
  fetchBackInfo,
  fetchBankInfo,
} from '../../actions'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ArrowBack } from '@mui/icons-material'
import styles from '../../utils/styles.json'

const style = {
  selectStyle: {
    '& .MuiSelect-select': {
      height: '15px',
    },
  },
  gridStyle: {
    '& .MuiFormControl-root': {
      margin: '0 0 15px',
    },
  },
}

const BankInformation = (props: any) => {
  const { isGettingBankInfo, addBankInfo, allStates, fetchBankInfoData, BankInfoData, isAddingBankInfo } = props

  const location = useLocation()
  const userId = new URLSearchParams(location.search).get('userId')

  const classes = useStyles()
  const navigate = useNavigate()
  // const userData = location?.state?.data?.rowData || {}
  const [states, setStates] = useState([])

  useEffect(() => {
    props.fetchAllState()
    props.fetchAllCountry()
    fetchBankInfoData({ userId: userId })
  }, [])

  useEffect(() => {
    if (allStates?.length > 0) {
      setStates(allStates)
    }
  }, [allStates])

  useEffect(() => {
    if (props.isBankInfoAdded) {
      toast.success('Added bank info successfully')
      props.resetAddBankInfo()
      fetchBankInfoData({ userId: userId })
    }
  }, [props.isBankInfoAdded])

  const validationSchema = Yup.object({
    bank_name: Yup.string().required('Required'),
    ifsc_code: Yup.string().required('Required'),
    account_number: Yup.string().required('Required'),
    account_type: Yup.string().required('Required'),
    branch_name: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
  })

  const formik = useFormik({
    initialValues: {
      bank_name: BankInfoData?.bank_name || '',
      ifsc_code: BankInfoData?.id_bank || '',
      account_number: BankInfoData?.account_no || '',
      account_type: BankInfoData?.account_type || 0,
      branch_name: BankInfoData?.branch_name || '',
      city: BankInfoData?.city || '',
      country: BankInfoData?.id_country || '',
      state: Number(BankInfoData?.id_state) || '',
      address: BankInfoData?.bank_branch_address_no1 || '',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => { },
  })

  const handleCountry = (event: any) => {
    const countryID = event.target.value
    formik.setFieldValue('country', countryID)

    const selectedCountry = props.allCountries.find(
      (country: any) => country.id === countryID,
    )?.id_country

    const filtertedStates = allStates?.filter((state: any) => state?.id_country === selectedCountry)

    setStates(() => {
      return filtertedStates.length > 0 ? filtertedStates : allStates
    })
  }

  const handleSubmit = async () => {
    try {
      await validationSchema.validate(formik.values, { abortEarly: false })
      const entity = {
        id_user: Number(userId),
        // employee_id: String(formik.values.employee_id),
        bank_name: formik.values.bank_name,
        id_bank: formik.values.ifsc_code,
        account_no: formik.values.account_number,
        account_type: formik.values.account_type,
        branch_name: formik.values.branch_name,
        city: formik.values.city,
        id_country: formik.values.country,
        id_state: String(formik.values.state),
        bank_branch_address_no1: formik.values.address,
      }
      addBankInfo(entity)
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const stepErrors = error.inner.reduce((acc, curr) => {
          if (curr.path) {
            acc[curr.path as keyof FormValues] = curr.message
          }
          return acc
        }, {} as { [K in keyof FormValues]?: string })

        formik.setTouched(
          Object.keys(stepErrors).reduce((acc, key) => {
            acc[key as keyof FormValues] = true
            return acc
          }, {} as { [K in keyof FormValues]: any }),
        )

        formik.setErrors(stepErrors)
      } else {
        console.error('Unexpected error:', error)
      }
    }
  }

  return (
    <>
      {isGettingBankInfo || isAddingBankInfo && (
        <Dialog open={isGettingBankInfo || isAddingBankInfo} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      )}
      <div className={classes.root}>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ mx: 4, my: 1, mt: 3, backgroundColor: '' }}>
            <div>
              <Grid item container xs={12}>
                <Grid
                  item
                  xs={12}
                  sx={{ backgroundColor: 'white', borderRadius: '8px', paddingBottom: '15px' }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center', // Center items vertically
                      justifyContent: 'space-between', // Space items apart (button left, heading center)
                      width: '84.6vw', // Set width as per your requirement
                      height: '70px', // Set height as per your requirement
                      borderRadius: '8px', // Round corners of the box
                      padding: '0 20px', // Optional: Add horizontal padding
                      opacity: 1, // Adjust opacity as needed
                      // backgroundColor: 'white',
                      marginTop: '20px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: styles.FONT_BOLD,
                        fontSize: '32px', // Set font size
                        fontWeight: 700, // Set font weight
                        lineHeight: '38.73px', // Set line height
                        textAlign: 'center', // Center align the text
                        flexGrow: 1, // Allow the heading to grow and take available space
                        margin: '0', // Optional: Remove default margin
                        color: '#193C6C',
                      }}
                    >
                      Add/Update Bank Info
                    </Typography>
                    <Box onClick={() => navigate(-1)} sx={{ float: 'right', mt: '0px', cursor: 'pointer' }}>
                      <ArrowBack />
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Grid item xs={4}>
                      <InputField
                        size='small'
                        fullWidth
                        id='bank_name'
                        name='bank_name'
                        label='Employee Bank Name'
                        onChange={formik.handleChange}
                        value={formik.values.bank_name}
                        required={true}
                        error={formik.touched.bank_name && Boolean(formik.errors.bank_name)}
                        sx={{
                          '& .MuiFormLabel-asterisk': {
                            color: 'red',
                          },
                        }}
                      />
                      <SelectField
                        size='small'
                        id='account_type'
                        name='account_type'
                        label='Account Type'
                        select
                        value={formik.values.account_type}
                        onChange={formik.handleChange}
                        error={formik.touched.account_type && Boolean(formik.errors.account_type)}
                        sx={{
                          ...style.selectStyle,
                          '& .MuiFormLabel-asterisk': {
                            color: 'red',
                          }
                        }}
                        required={true}
                      >
                        <StyledMenuItem value={0}> Saving </StyledMenuItem>
                        <StyledMenuItem value={1}> Current </StyledMenuItem>
                      </SelectField>
                      <TextField
                        label='Address'
                        multiline
                        fullWidth
                        variant='outlined'
                        name='address'
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        error={formik.touched.address && Boolean(formik.errors.address)}
                        sx={{
                          marginLeft: '14px',
                          '& .MuiInputBase-inputMultiline': {
                            position: 'relative',
                            left: '12px',
                            top: '6px',
                          },
                          '& .MuiOutlinedInput-root': {
                            width: '92%',
                            fontSize: '12px',
                            fontFamily: 'Montserrat-Medium',
                            borderRadius: '30px',
                            minHeight: '30px',
                            padding: '6px 8px',
                            alignItems: 'center',
                          },
                          '& .MuiOutlinedInput-input': {
                            fontSize: '12px',
                            fontFamily: 'Montserrat-Medium',
                            minHeight: '30px',
                            padding: '0',
                            boxSizing: 'border-box',
                            lineHeight: '1.2',
                            '& .MuiFormLabel-asterisk': {
                              color: 'red',
                            },
                          },
                          '& .MuiInputLabel-root': {
                            marginTop: '-4px',
                            fontSize: '13px !important',
                            fontFamily: 'Montserrat-Medium !important',
                            '& .MuiFormLabel-asterisk': {
                              color: 'red',
                            },
                          },
                          '& textarea': {
                            fontSize: '12px',
                            fontFamily: 'Montserrat-Medium',
                            height: 'auto',
                            width: '95%',
                            minHeight: '35px',
                            overflowY: 'auto',
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <InputField
                        size='small'
                        fullWidth
                        id='ifsc_code'
                        name='ifsc_code'
                        label='IFSC code'
                        onChange={formik.handleChange}
                        value={formik.values.ifsc_code}
                        required={true}
                        error={formik.touched.ifsc_code && Boolean(formik.errors.ifsc_code)}
                        sx={{
                          '& .MuiFormLabel-asterisk': {
                            color: 'red',
                          }
                        }}
                      />
                      <InputField
                        size='small'
                        fullWidth
                        id='branch_name'
                        name='branch_name'
                        label='Branch Name'
                        onChange={formik.handleChange}
                        value={formik.values.branch_name}
                        required={true}
                        error={formik.touched.branch_name && Boolean(formik.errors.branch_name)}
                        sx={{
                          '& .MuiFormLabel-asterisk': {
                            color: 'red',
                          }
                        }}
                      />
                      {/* {props.allCountries && props.allCountries.length > 0 && ( */}
                      <SelectField
                        select
                        size='small'
                        fullWidth
                        id='country'
                        name='country'
                        label='Country'
                        value={formik.values.country}
                        onChange={(event) => handleCountry(event)}
                        error={formik.touched.country && Boolean(formik.errors.country)}
                      >
                        {props.allCountries.map((country: any) => (
                          <StyledMenuItem key={country.id} value={country.id}>
                            {country.country_name}
                          </StyledMenuItem>
                        ))}
                      </SelectField>
                    </Grid>
                    <Grid item xs={4}>
                      <InputField
                        size='small'
                        fullWidth
                        id='account_number'
                        name='account_number'
                        label='Employee Account Number'
                        onChange={(e: any) => {
                          let onlyNums = e.target.value.replace(/[^0-9]/g, '')
                          if (onlyNums.length > 20) {
                            onlyNums = onlyNums.slice(0, 20)
                          }
                          formik.setFieldValue('account_number', onlyNums)
                        }}
                        value={formik.values.account_number}
                        required={true}
                        error={formik.touched.country && Boolean(formik.errors.country)}
                        sx={{
                          '& .MuiFormLabel-asterisk': {
                            color: 'red',
                          }
                        }}
                      />
                      <InputField
                        size='small'
                        fullWidth
                        id='city'
                        name='city'
                        label='City'
                        onChange={formik.handleChange}
                        value={formik.values.city}
                        required={true}
                        error={formik.touched.city && Boolean(formik.errors.city)}
                        sx={{
                          '& .MuiFormLabel-asterisk': {
                            color: 'red',
                          }
                        }}
                      />
                      <SelectField
                        select
                        size='small'
                        fullWidth
                        id='state'
                        name='state'
                        label='State'
                        value={formik.values.state}
                        onChange={formik.handleChange}
                        error={formik.touched.state && Boolean(formik.errors.state)}
                      >
                        {states?.map((state: any) => (
                          <StyledMenuItem key={state.id} value={state.id}>
                            {state.state_name}
                          </StyledMenuItem>
                        ))}
                      </SelectField>
                    </Grid>
                  </Box>
                  <div
                    style={{
                      marginTop: '24px',
                      marginRight: '15px',
                      display: 'flex',
                      justifyContent: 'end',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <Button
                      sx={{
                        fontSize: '16px !important',
                        fontFamily: `${styles.FONT_BOLD}!important`,
                        width: '15%',
                        borderRadius: '20px !important',
                        cursor: 'pointer',
                        height: '40px', // Set height as per your requirement
                        padding: '14px 28px',
                        opacity: 1, // Adjust opacity as needed
                        background: '#E2E2E2',
                        color: '#193C6C',
                        '&:hover': {
                          background: '#E2E2E2',
                          color: '#000000',
                        },
                      }}
                      variant='contained'
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </Button>
                    <CommonButton sx={{ width: '15%!important', }} variant='contained' color='primary' onClick={handleSubmit}>
                      SAVE
                    </CommonButton>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Box>
        </form>
      </div>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    employeementInfoData: fetchUserDetailsEntities.fetchUserData(state).getEmployeementInfo,
    getUserDetails: fetchUserDetailsEntities.fetchUserData(state).fetchUser,
    BankInfoData: financeEntity.getFinance(state).getBankInfoData,
    allStates: fetchUserDetailsEntities.fetchUserData(state).fetchAllState,
    allCountries: fetchUserDetailsEntities.fetchUserData(state).fetchAllCountry,
    isBankInfoAdded: fetchUserDetailsUI.fetchUserData(state).isBankInfoAdded,
    isGettingBankInfo: financeUI.getFinance(state).isGettingBankInfo,
    isAddingBankInfo: fetchUserDetailsUI.fetchUserData(state).isAddingBankInfo,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchAllState: () => dispatch(fetchAllStates.request()),
    fetchAllCountry: () => dispatch(fetchAllCountries.request()),
    addBankInfo: (data: any) => dispatch(addBankInfo.request(data)),
    resetAddBankInfo: () => {
      dispatch(addBankInfo.reset())
    },
    fetchBankInfoData: (data: any) => dispatch(fetchBankInfo.request(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(BankInformation)
