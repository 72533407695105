import GetTudipLogo from '../assets/images/favicon.ico'
import GetKuberLogo from '../assets/images/Kuber_Logo.png'
import GetLoginProfile from '../assets/images/profile.jpg'
import GetHomeIcon from '../assets/images/home.svg'
import GetOpenTabIcon from '../assets/images/open_New_Tab.svg'
import GetDashboardIcon from '../assets/images/dashboard-icon.svg'
import TudipCompensatiopnLogo from '../assets/images/logo.png'
import loginLeftImage from '../assets/images/login_image.png'

export const OpenTabLogo = GetOpenTabIcon
export const KuberLogo = GetKuberLogo
export const TudipLogo = GetTudipLogo
export const CurrentLoginUserProfile = GetLoginProfile
export const HomeIcon = GetHomeIcon
export const DashboardIcon = GetDashboardIcon
export const GetCompensationTudipLogo = TudipCompensatiopnLogo
export const GetLoginLeftImage = loginLeftImage

export const SideDrawerMenu: { [key: string]: string[] } = {
  dashboard: [],
  admin:['employees', 'Form16Info', 'expected-joiners', 'product-analytics'],
  myTeam: [],
  employeeportal: [ 'basicinfo', 'professionalinfo', 'backgroundinfo'],
  financeinfo: ['BankInfo', 'Compensation', 'taxReport', 'Form16', 'Loan', 'PaySlips'],
  employeelist: [],
  // finance: ['PaySlips', 'Compensation', 'InvestmentForm', 'BankInfo', 'Loan'],
  // statusSummary: [],
  // idsr:[],
  leaveinfo: [],
  workinfo:[],
  projectmanagement:[],
  organizationalchart:[],
  benefits: [],
}
