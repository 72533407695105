import { Box, Button, CircularProgress, Dialog, Typography } from '@mui/material'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { loaderProps } from '../../Common/CommonStyles'
import { useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { connect } from 'react-redux'
import { RootState } from '../../../configureStore'
import { dashboardEntity, projectManagementEntity, projectManagementUI } from '../../../reducers'
import { Dispatch } from 'redux'
import * as echarts from 'echarts'
import { projectGraphData } from '../../../actions'
import styles from '../../../utils/styles.json'

const style = {
  graphContainer: {
    border: '1px solid #ebebeb',
    paddingTop: '10px',
    paddingBottom: '20px',
    paddingRight: '10px',
    borderRadius: '10px',
    boxShadow: ' rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
  },
  resultBtnStyle: {
    fontSize: '13px',
    letterSpacing: '0px',
    borderRadius: '20px',
    padding: '5px 20px',
    height: '40px',
    whiteSpace: 'nowrap',
    fontFamily: styles.FONT_MEDIUM,
    marginTop: '-10px',
  },
  datePickerStyle: {
    marginTop: '0',
    '.MuiFormLabel-asterisk': {
      color: 'red',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '20px',
      height: '40px',
    },
    '& .MuiInputLabel-root': {
      fontSize: '14px',
    },
    '& .MuiPickersDay-dayDisabled': {
      cursor: 'not-allowed',
    },
    width: '150px!important',
  },
}

const EmployeeCountGraph = (props: any) => {
  const { selectedTab, fetchProjectGraphData, getUserById, isGettingGraphData, projectGraphData } =
    props
  const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf('month'))
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs().endOf('month'))

  const userId = getUserById?.id

  const startDateString = startDate?.format('YYYY-MM-DD') || ''
  const endDateString = endDate?.format('YYYY-MM-DD') || ''

  const filterDataByDate = {
    start_date: startDateString,
    end_date: endDateString,
  }

  useEffect(() => {
    setStartDate(dayjs().startOf('month'))
    setEndDate(dayjs().endOf('month'))
  }, [selectedTab])

  useEffect(() => {
    if (selectedTab === 4) {
      fetchProjectGraphData({ userId: userId, filterDataByDate })
    }
  }, [selectedTab])

  useEffect(() => {
    if (selectedTab === 4) {
      var chartDom = document.getElementById('project-graph')
      var myChart = echarts.init(chartDom)

      const dates = projectGraphData.map((entry: { date: string | number | Date }) =>
        new Date(entry.date).toLocaleDateString(),
      )
      const billableData = projectGraphData.map((entry: { billable: any }) => entry.billable)
      const nonBillableData = projectGraphData.map(
        (entry: { nonBillable: any }) => entry.nonBillable,
      )
      const freeData = projectGraphData.map((entry: { free: any }) => entry.free)
      const additionalData = projectGraphData.map((entry: { additional: any }) => entry.additional)

      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999',
            },
          },
        },
        legend: {
          data: ['Billable', 'Non-Billable', 'Free', 'Additional'],
        },
        xAxis: [
          {
            type: 'category',
            data: dates,
            axisPointer: {
              type: 'shadow',
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: "Employee's Count",
            min: 0,
            nameRotate: 90,
            nameLocation: 'middle',
            nameGap: 50,
            color: 'green',
            axisLabel: {
              formatter: '{value}',
            },
            nameTextStyle: {
              fontWeight: '500',
              fontSize: '16px',
              color: styles.PRIMARY_COLOR,
            },
          },
        ],
        series: [
          {
            name: 'Billable',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value: any, params: number) {
                const percentage = billableData[params]?.percentage
                return value + ' employees' + ' (' + percentage + '%)'
              },
            },
            data: billableData?.map((data: { count: number }) => data?.count),
          },
          {
            name: 'Non-Billable',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value: any, params: number) {
                const percentage = nonBillableData[params]?.percentage
                return value + ' employees' + ' (' + percentage + '%)'
              },
            },
            data: nonBillableData?.map((data: { count: number }) => data?.count),
          },
          {
            name: 'Free',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value: any, params: number) {
                const percentage = freeData[params]?.percentage
                return value + ' employees' + ' (' + percentage + '%)'
              },
            },
            data: freeData?.map((data: { count: number }) => data?.count),
          },
          {
            name: 'Additional',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value: any, params: number) {
                const percentage = additionalData[params]?.percentage
                return value + ' employees' + ' (' + percentage + '%)'
              },
            },
            data: additionalData?.map((data: { count: number }) => data?.count),
          },
        ],
      }

      myChart.setOption(option)

      return () => {
        myChart.dispose()
      }
    }
  }, [selectedTab, projectGraphData])

  const handleStartDateChange = (date: Dayjs | null) => {
    if (date) {
      setStartDate(date)
    }
  }
  const handleEndDateChange = (date: any | null) => {
    if (date) {
      setEndDate(date)
    }
  }

  const handleShowResult = () => {
    return fetchProjectGraphData({ userId: userId, filterDataByDate })
  }

  return (
    <Box sx={style.graphContainer}>
      <Dialog open={isGettingGraphData} PaperProps={{ style: loaderProps }}>
        <CircularProgress color='secondary' />
      </Dialog>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box marginLeft='40px' marginTop='-10px'>
          <Typography fontSize='20px' fontFamily={styles.FONT_BOLD} color={styles.PRIMARY_COLOR}></Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <Box>
                <DatePicker
                  sx={style.datePickerStyle}
                  label='Start Date'
                  value={startDate}
                  onChange={handleStartDateChange}
                  shouldDisableDate={(date) => (endDate ? date.isAfter(endDate, 'day') : false)}
                />
              </Box>
            </DemoContainer>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <Box>
                <DatePicker
                  sx={style.datePickerStyle}
                  label='End Date'
                  value={endDate}
                  onChange={handleEndDateChange}
                  shouldDisableDate={(date) =>
                    startDate ? date.isBefore(startDate, 'day') : false
                  }
                />
              </Box>
            </DemoContainer>
          </LocalizationProvider>
          <Button sx={style.resultBtnStyle} onClick={handleShowResult} color='primary'>
            SHOW RESULT
          </Button>
        </Box>
      </Box>{' '}
      <Box id='project-graph' style={{ width: 'auto', height: '400px', marginLeft: '0px' }}></Box>{' '}
      <Box textAlign='center' marginTop='-30px'>
        <Typography fontSize='20px' fontFamily={styles.FONT_BOLD} color={styles.PRIMARY_COLOR}>
          Employee's Count
        </Typography>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    projectGraphData: projectManagementEntity.getAllProjectManagement(state).fetchProjectGraphData,
    getUserById: dashboardEntity.getDashboard(state).getUserDetails,
    isGettingGraphData: projectManagementUI.getProjectManagemet(state).isGettingGraphData,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchProjectGraphData: (data: any) => dispatch(projectGraphData.request({ data })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeCountGraph)
