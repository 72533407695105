import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import {
  Box,
  CircularProgress,
  Dialog,
  Typography,
  Divider,
} from '@mui/material'
import { useEffect } from 'react'
import { CurrentLoginUserProfile } from '../../utils/StaticData'
import { useLocation, useNavigate } from 'react-router-dom'
import { Dispatch } from 'redux'
import { RootState } from '../../configureStore'
import { connect } from 'react-redux'
import { fetchBasicInfo, fetchCountry, fetchMaritialStatus, fetchState } from '../../actions'
import { dashboardEntity, dashboardUI, financeEntity } from '../../reducers'
import { ArrowBack } from '@mui/icons-material'
import moment from 'moment'
import { loaderProps } from '../Common/CommonStyles'
import style from '../../utils/styles.json'

const Maincontainer = {
  backgroundColor: 'rgb(231, 235, 240)',
  width: '100%',
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: ' 85%',
  padding: '25px 25px',
  background: '#FFFFFF',
  opacity: '1',
  marginTop: '25px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '20px',
  border: '1px solid #DDDDDD',
}))

const EmployeeProfile = (props: any) => {
  const data = props.data
  const UserFullName = data.first_name + ' ' + data.last_name
  const UserFirstName = data.first_name
  const emp = UserFirstName + "'s DRs"

  const navigate = useNavigate()
  const handleEmployOpen = (userID: number) => {
    navigate(`/home/basic-info-on-search?id=${userID}`)
  }

  return (
    <>
      <Box sx={{ textAlign: 'left' }}>
        <Box
          onClick={() => navigate(-1)}
          sx={{
            float: 'right',
            cursor: 'pointer',
            position: 'relative',
            right: '110px',
            top: '30px',
          }}
        >
          <ArrowBack />
        </Box>
      </Box>
      <StyledPaper elevation={0}>
        <Grid
          container
          sm={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
            padding: '20px',
            paddingTop: '0',
          }}
        >
          <Grid sm={12} sx={{ display: 'flex', flexDirection: 'row' }}>
            <Grid sm={1.5}>
              <img
                src={data.image_path ? data.image_path : CurrentLoginUserProfile}
                style={{
                  width: '100px',
                  borderRadius: '50%',
                  height: '100px',
                  objectFit: 'cover',
                }}
                alt=''
              />
            </Grid>
            <Grid
              sm={10.5}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant='h6'
                component='h6'
                sx={{
                  fontSize: '20px',
                  textAlign: 'left',
                  marginTop: '-14px',
                  fontFamily: style.FONT_BOLD,
                  letterSpacing: '0px',
                  color: style.PRIMARY_COLOR,
                }}
              >
                {UserFullName}
              </Typography>
              <Typography
                variant='h6'
                component='h6'
                sx={{
                  fontSize: '17px',
                  textAlign: 'left',
                  marginTop: '8px',
                  fontFamily: style.FONT_MEDIUM,
                  fontWeight: '100',
                  letterSpacing: '0px',
                  color: style.PRIMARY_COLOR,
                }}
              >
                {' '}
                Emp ID: {data.employee_id ?? 'NA'}
              </Typography>
            </Grid>
          </Grid>
          <Divider orientation='horizontal' sx={{ marginTop: '10px' }} flexItem></Divider>
          <Grid sm={12} sx={{ padding: '20px', paddingTop: '30px' }}>
            <Grid sm={12} sx={{ display: 'flex', flexDirection: 'row' }}>
              <Grid
                sm={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'left',
                  justifyContent: 'center',
                  height: '60px',
                }}
              >
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    fontSize: '12px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontFamily: style.FONT_MEDIUM,
                    letterSpacing: '0px',
                    color: '#5e646f',
                  }}
                >
                  Email
                </Typography>
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    fontSize: '15px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontFamily: style.FONT_BOLD,
                    letterSpacing: '0px',
                    color: '#262e3c',
                  }}
                >
                  {data.username ?? 'NA'}
                </Typography>
              </Grid>
              <Grid
                sm={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'left',
                  justifyContent: 'center',
                  height: '60px',
                }}
              >
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    fontSize: '12px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontFamily: style.FONT_MEDIUM,
                    letterSpacing: '0px',
                    color: '#5e646f',
                  }}
                >
                  Mobile Number
                </Typography>
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    fontSize: '15px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontFamily: style.FONT_BOLD,
                    letterSpacing: '0px',
                    color: '#262e3c',
                  }}
                >
                  {data.mobile_no ?? 'NA'}
                </Typography>
              </Grid>
              <Grid
                sm={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'left',
                  justifyContent: 'center',
                  marginTop: '18px',
                  maxHeight: '210px',
                  margin: '10px 0px 0px 0px',
                }}
              >
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    fontSize: '12px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontFamily: style.FONT_MEDIUM,
                    letterSpacing: '0px',
                    color: '#5e646f',
                  }}
                >
                  Manager
                </Typography>
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    fontSize: '12px',
                    position: 'relative',
                    right: '54px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontFamily: style.FONT_BOLD,
                    letterSpacing: '0px',
                    overflow: 'auto',
                    width: '300px',
                    color: '#262e3c',
                    '&::-webkit-scrollbar': {
                      width: '4px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: style.PRIMARY_COLOR,
                      borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                      backgroundColor: '#555',
                    },
                  }}
                >
                  <>
                    <Grid
                      sx={{ display: 'flex', alignItems: 'left' }}
                      onClick={(e) => handleEmployOpen(data.managerId)}
                    >
                      <Grid
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                      >
                        <Grid>
                          <img
                            src={
                                  data?.managerImageURL
                                ? data?.managerImageURL
                                : CurrentLoginUserProfile
                            }
                            style={{
                              width: '35px',
                              borderRadius: '50%',
                              height: '35px',
                              position: 'relative',
                              bottom: '-5px',
                            }}
                            alt=''
                          />
                        </Grid>
                        <Grid style={{ marginLeft: '20px', marginTop: '-7px' }}>
                          <p style={{ color: style.PRIMARY_COLOR, height: '15px', marginBottom: '5px' }}>
                            {data?.managerName}
                          </p>
                          {/* <p style={{ fontSize: '9px', marginTop: '6px' }}>
                                {data?.managerName}
                              </p> */}
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                </Typography>
              </Grid>
            </Grid>

            <Grid sm={12} sx={{ display: 'flex', flexDirection: 'row' }}>
              <Grid
                sm={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'left',
                  justifyContent: 'center',
                  height: '60px',
                }}
              >
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    fontSize: '12px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontFamily: style.FONT_MEDIUM,
                    letterSpacing: '0px',
                    color: '#5e646f',
                  }}
                >
                  Office Timing
                </Typography>
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    fontSize: '15px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontFamily: style.FONT_BOLD,
                    letterSpacing: '0px',
                    color: '#262e3c',
                  }}
                >
                  {data?.officeTime ? moment(data?.officeTime, ['HH.mm']).format('hh:mm A') : 'NA'}
                </Typography>
              </Grid>
              <Grid
                sm={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'left',
                  justifyContent: 'center',
                  height: '60px',
                }}
              >
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    fontSize: '12px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontFamily: style.FONT_MEDIUM,
                    letterSpacing: '0px',
                    color: '#5e646f',
                  }}
                >
                  Office Location
                </Typography>
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    fontSize: '15px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontFamily: style.FONT_BOLD,
                    letterSpacing: '0px',
                    color: '#262e3c',
                  }}
                >
                  {data.companyLocationName ?? 'NA'}
                </Typography>
              </Grid>

              <Grid
                sm={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'left',
                  justifyContent: 'center',
                  height: '60px',
                }}
              >
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    fontSize: '12px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontFamily: style.FONT_MEDIUM,
                    letterSpacing: '0px',
                    color: '#5e646f',
                  }}
                >
                  Workstation
                </Typography>
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    fontSize: '15px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontFamily: style.FONT_BOLD,
                    letterSpacing: '0px',
                    color: '#262e3c',
                  }}
                >
                  {data.workStation ?? 'NA'}
                </Typography>
              </Grid>
            </Grid>

            <Grid sm={12} sx={{ display: 'flex', flexDirection: 'row' }}>
              {data?.DRS?.length > 0 ? (
                <Grid
                  sm={4}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                    justifyContent: 'center',
                    marginTop: '18px',
                    maxHeight: '210px',
                  }}
                >
                  <Typography
                    variant='h6'
                    component='h6'
                    sx={{
                      fontSize: '12px',
                      textAlign: 'left',
                      marginLeft: '10px',
                      fontFamily: style.FONT_MEDIUM,
                      letterSpacing: '0px',
                      color: '#5e646f',
                    }}
                  >
                    {emp}
                  </Typography>
                  <Typography
                    variant='h6'
                    component='h6'
                    sx={{
                      fontSize: '12px',
                      textAlign: 'left',
                      marginLeft: '10px',
                      fontFamily: style.FONT_BOLD,
                      letterSpacing: '0px',
                      overflow: 'auto',
                      width: '300px',
                      color: '#262e3c',
                      '&::-webkit-scrollbar': {
                        width: '4px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: style.PRIMARY_COLOR,
                        borderRadius: '4px',
                      },
                      '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: '#555',
                      },
                    }}
                  >
                    {data.DRS.length ? (
                      data.DRS.map((item: any, index: any) => (
                        <>
                          <Grid
                            sx={{ display: 'flex', alignItems: 'left' }}
                            onClick={(e) => handleEmployOpen(item.userId)}
                          >
                            <Grid
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                cursor: 'pointer',
                              }}
                            >
                              <Grid>
                                <img
                                  src={item.imagePath ? item.imagePath : CurrentLoginUserProfile}
                                  style={{
                                    width: '35px',
                                    borderRadius: '50%',
                                    height: '35px',
                                  }}
                                  alt=''
                                />
                              </Grid>
                              <Grid style={{ marginLeft: '20px', marginTop: '-7px' }}>
                                <p style={{ height: '15px', marginBottom: '7px' }}>
                                  {item.employeeName}
                                </p>
                                <p style={{ fontSize: '9px', marginTop: '6px' }}>
                                  {item.designation}
                                </p>
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      ))
                    ) : (
                      <p style={{ fontSize: '15px', marginTop: '1px' }}>NA</p>
                    )}
                  </Typography>
                </Grid>
              ) : null}

              <Grid
                sm={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'left',
                  justifyContent: 'center',
                  height: '60px',
                }}
              >
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    fontSize: '12px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontFamily: style.FONT_MEDIUM,
                    letterSpacing: '0px',
                    color: '#5e646f',
                  }}
                >
                  Official Birth Date
                </Typography>
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    fontSize: '15px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontFamily: style.FONT_BOLD,
                    letterSpacing: '0px',
                    color: '#262e3c',
                  }}
                >
                  {new Date(data.official_birth_date).toLocaleDateString('en-GB') ?? 'NA'}
                </Typography>
              </Grid>

              <Grid
                sm={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'left',
                  justifyContent: 'center',
                  height: '60px',
                }}
              >
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    fontSize: '12px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontFamily: style.FONT_MEDIUM,
                    letterSpacing: '0px',
                    color: '#5e646f',
                  }}
                >
                  Actual Birth Date
                </Typography>
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    fontSize: '15px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontFamily: style.FONT_BOLD,
                    letterSpacing: '0px',
                    color: '#262e3c',
                  }}
                >
                  {new Date(data.birth_date).toLocaleDateString('en-GB') ?? 'NA'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </StyledPaper>
    </>
  )
}

const BasicInfo = (props: any) => {
  const {
    BasicInfoData,
    fetchBasicInfoData,
    fetchCountryData,
    fetchMaritalStatusData,
    fetchStateData,
    loaderState,
  } = props

  const location = useLocation()
  const userId = new URLSearchParams(location.search).get('id')

  useEffect(() => {
    if (userId) {
      fetchBasicInfoData({userId, isGlobal: true})
    }
    fetchCountryData()
    fetchMaritalStatusData()
    fetchStateData()
  }, [userId])
  return (
    <>
      {loaderState && (
        <Dialog open={loaderState} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      )}
      {Object.keys(BasicInfoData)?.length ? (
        <div style={Maincontainer}>
          <Grid>
            <Grid item xs={12}>
              <EmployeeProfile data={BasicInfoData} />
            </Grid>
          </Grid>
        </div>
      ) : null}
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    BasicInfoData: dashboardEntity.getDashboard(state).getBasicInfoData,
    StateData: financeEntity.getFinance(state).getStateData,
    CountryData: financeEntity.getFinance(state).getCountryData,
    MaritalStatusData: dashboardEntity.getDashboard(state).getMaritalStatusData,
    loaderState: dashboardUI.getDashboard(state).isBasicInfoData,
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchBasicInfoData: (data: any) => dispatch(fetchBasicInfo.request(data)),
    fetchStateData: () => dispatch(fetchState.request()),
    fetchCountryData: () => dispatch(fetchCountry.request()),
    fetchMaritalStatusData: () => dispatch(fetchMaritialStatus.request()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BasicInfo)
