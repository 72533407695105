import React, { useEffect, useState } from 'react'
import ApexCharts from 'apexcharts'

// Predefined color palette for the first 10 colors
const predefinedColors = [
  '#FF5733',
  '#33FF57',
  '#3357FF',
  '#FF33A8',
  '#FF8F33',
  '#33FFF3',
  '#33A8FF',
  '#D433FF',
  '#FF5733',
  '#57FF33',
]

// Function to generate distinct colors using RGB
const generateColors = (count: number) => {
  const colors = [...predefinedColors]

  if (count > predefinedColors.length) {
    const step = Math.floor(255 / (count - predefinedColors.length))
    for (let i = predefinedColors.length; i < count; i++) {
      const r = (i * step) % 256
      const g = (128 + i * step) % 256
      const b = (64 + i * step) % 256
      colors.push(`rgb(${r}, ${g}, ${b})`)
    }
  }

  return colors.slice(0, count)
}

const DomainChart = (props: any) => {
  const { myChartData } = props

  useEffect(() => {
    if (myChartData) {
      const chartData = [
        {
          name: 'Lower Management',
          percentage: myChartData.lowerManagementPercentage || '0%',
          count: myChartData?.lowerManagementCount,
        },
        {
          name: 'Mid-Level Management',
          percentage: myChartData.midLevelManagementPercentage || '0%',
          count: myChartData.midLevelManagementCount,
        },
        {
          name: 'Higher Management',
          percentage: myChartData.higherManagementPercentage || '0%',
          count: myChartData?.higherManagementCount,
        },
      ]

      const names = chartData.map((item) => item.name)
      const percentages = chartData.map((item) => parseFloat(item.percentage))
      const counts = chartData.map((item) => item.count)
      const colors = generateColors(chartData.length)

      const options = {
        series: percentages,
        chart: {
          width: 460,
          height: 360,
          type: 'donut',
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        fill: {
          type: 'gradient',
        },
        labels: names,
        colors: colors,
        tooltip: {
          custom: function ({ seriesIndex }: any) {
            return `
              <div style="padding: 12px 16px;
                background-color: #2c3e50;  /* Darker blue-gray background */
                color: #ecf0f1;  /* Light font color */
                border-radius: 10px;
                box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
                font-family: 'Roboto', sans-serif;
                max-width: 220px;
                font-size: 14px;">
                <strong>${names[seriesIndex]}</strong><br/>
                <strong>Count: ${chartData[seriesIndex].count}</strong><br/>
                <strong>Percentage: ${chartData[seriesIndex].percentage}</strong>
              </div>
            `
          },
        },
        legend: {
          position: 'bottom',
        },
      }

      const chart = new ApexCharts(document.querySelector('#piechartDomain'), options)
      chart.render()

      return () => {
        chart.destroy()
      }
    }
  }, [myChartData])

  return (
    <div
      id='piechartDomain'
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '300px',
        marginTop: '25px',
      }}
    />
  )
}

export default DomainChart
