import {
  Box,
  Typography,
  CircularProgress,
  Dialog,
  InputLabel,
  Tabs,
  Tab,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TableRow,
  Button,
  TableCell,
  tableCellClasses,
} from '@mui/material'
import styled from '@emotion/styled'
import { makeStyles } from '@mui/styles'
import {
  HeaderHeading,
  StyledMenuItem,
  // StyledTableCell,
  StyledTableRow,
  loaderProps,
} from '../Common/CommonStyles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useNavigate } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import { connect } from 'react-redux'
import { RootState } from '../../configureStore'
import { fetchLeaveDetails, getAttendanceDetail, getAttendanceTimesheet } from '../../actions'
import { Dispatch } from 'redux'
import { attendanceEntity, attendanceUI, dashboardEntity, dashboardUI } from '../../reducers'
import { useState, useEffect, useCallback } from 'react'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import Table from '@mui/material/Table'
import moment from 'moment'
import { SelectField } from '../Common/ComponentCommonStyles'
import FormControl from '@mui/material/FormControl'
import { ILeaveDetails } from '../../models/leave-detail.model'
import NavigationIcon from '@mui/icons-material/Navigation'
import React from 'react'
import style from './../../utils/styles.json'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: style.PRIMARY_COLOR,
    color: 'White',
    fontFamily: style.FONT_MEDIUM,
    textAlign: 'center',
    border: '1px solid #d1cbcbf2',
    fontSize: '11px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    fontFamily: style.FONT_MEDIUM,
    textAlign: 'center',
    border: '1px solid #d1cbcbf2',
    color: '#483f3f',
  },
}))

const AttendanceList = (props: any) => {
  const {
    fetchAttendanceData,
    AttendanceTimesheet,
    AttendanceData,
    loaderState,
    getUserById,
    fetchAttendanceTimesheet,
    fetchLeaveDetails,
    leaveDetails,
    loaderStateLeave,
  } = props

  const [selectedMonth, setSelectedMonth] = useState('')

  const [selectedTab, setSelectedTab] = useState(0)

  useEffect(() => {
    if (getUserById.id) {
      fetchAttendanceTimesheet(getUserById.id)
      fetchLeaveDetails({ userId: getUserById.id })
    }
  }, [getUserById.id])

  useEffect(() => {
    if (AttendanceTimesheet.length) {
      setSelectedMonth(AttendanceTimesheet[0].id)
      fetchAttendanceData({ userId: getUserById.id, tId: AttendanceTimesheet[0].id })
    }
  }, [AttendanceTimesheet])

  const handleSelectMonth = useCallback(
    (event: any) => {
      const selectedMonthId = event.target.value
      setSelectedMonth(selectedMonthId)
      fetchAttendanceData({
        userId: getUserById.id,
        tId: selectedMonthId,
      })
      setSelectedTab(1)
    },
    [getUserById.id, fetchAttendanceData],
  )

  const ActionButton = styled(Button)(({ theme }) => ({
    fontSize: '13px',
    height: '42px',
    float: 'right',
    width: '180px',
    right: '10px',
    marginTop: '15px',
    borderRadius: '20px',
    padding: '5px 20px',
    fontFamily: style.FONT_MEDIUM,
  }))

  const StyledPaper = styled(Paper)(({ theme }) => ({
    width: '93%',
    padding: '25px 25px',
    background: '#FFFFFF',
    opacity: '1',
    marginTop: '25px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '25px',
    border: '1px solid #DDDDDD',
  }))

  const StyledPaperAccrualReport = styled(Paper)(({ theme }) => ({
    width: '93%',
    padding: '25px 25px',
    background: '#FFFFFF',
    opacity: '1',
    marginTop: '25px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '25px',
    border: '1px solid #DDDDDD',
  }))

  const MainContainer = {
    backgroundColor: 'rgb(231, 235, 240)',
    width: '100%',
  }

  const StyledFormControl = styled(FormControl)(({ theme }) => ({
    marginLeft: '-38px',
  }))

  const SubHeading = styled(Typography)(({ theme }) => ({
    fontSize: '20px',
    fontFamily: style.FONT_MEDIUM,
    textAlign: 'center',
    letterSpacing: '0px',
  }))

  const LeavesDetailsHeading = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    textAlign: 'center',
    fontFamily: style.FONT_BOLD,
    color: 'black',
    padding: '20px',
  }))

  const LastCellStyle = { color: '#555', fontFamily: style.FONT_MEDIUM }

  const AllocatedLeaves = (props: {
    totalLeaveCount: number
    allocatedLeaves: ILeaveDetails[]
  }) => {
    const { totalLeaveCount, allocatedLeaves } = props

    return (
      <>
        <LeavesDetailsHeading>ALLOCATED LEAVES ({totalLeaveCount})</LeavesDetailsHeading>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label='customized table'>
            <TableHead>
              <TableRow>
                <StyledTableCell>LEAVE TYPE</StyledTableCell>
                <StyledTableCell>LEAVE START DATE</StyledTableCell>
                <StyledTableCell>LEAVE END DATE</StyledTableCell>
                <StyledTableCell>COUNT</StyledTableCell>
                <StyledTableCell style={{ width: '32%' }}>DESC</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allocatedLeaves?.map((leave, i) => (
                <>
                  <StyledTableRow key={`${leave?.leaveStartDate}${leave?.count}`}>
                    <StyledTableCell component='th' scope='row'>
                      {leave?.leaveType}
                    </StyledTableCell>
                    <StyledTableCell align='right'>
                      {new Date(leave?.leaveStartDate).toLocaleDateString('en-GB')}
                    </StyledTableCell>
                    <StyledTableCell align='right'>
                      {new Date(leave?.leaveEndDate).toLocaleDateString('en-GB')}
                    </StyledTableCell>
                    <StyledTableCell align='right'>{leave?.count}</StyledTableCell>
                    <StyledTableCell align='right'>{leave?.desc}</StyledTableCell>
                  </StyledTableRow>
                </>
              ))}
              <StyledTableRow key={888}>
                <StyledTableCell component='th' scope='row' colSpan={3} style={LastCellStyle}>
                  Total Leaves Allocated:
                </StyledTableCell>
                <StyledTableCell align='right' style={LastCellStyle}>
                  {totalLeaveCount}
                </StyledTableCell>
                <StyledTableCell align='right'></StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }

  const DeductibleLeavesAvailed = (props: {
    totalDeductibleLeaves: number
    deductibleLeavesAvailed: ILeaveDetails[]
  }) => {
    const { totalDeductibleLeaves, deductibleLeavesAvailed } = props
    return (
      <>
        <LeavesDetailsHeading>
          DEDUCTIBLE LEAVES AVAILED ({totalDeductibleLeaves})
        </LeavesDetailsHeading>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label='customized table'>
            <TableHead>
              <TableRow>
                <StyledTableCell>LEAVE TYPE</StyledTableCell>
                <StyledTableCell>LEAVE START DATE</StyledTableCell>
                <StyledTableCell>LEAVE END DATE</StyledTableCell>
                <StyledTableCell>COUNT</StyledTableCell>
                <StyledTableCell style={{ width: '32%' }}>DESC</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {deductibleLeavesAvailed?.map((leave, i) => (
                <>
                  <StyledTableRow key={`${leave?.leaveStartDate}${leave?.count}`}>
                    <StyledTableCell component='th' scope='row'>
                      {leave?.leaveType}
                    </StyledTableCell>
                    <StyledTableCell align='right'>
                      {new Date(leave?.leaveStartDate).toLocaleDateString('en-GB')}
                    </StyledTableCell>
                    <StyledTableCell align='right'>
                      {new Date(leave?.leaveEndDate).toLocaleDateString('en-GB')}
                    </StyledTableCell>
                    <StyledTableCell align='right'>{leave?.count}</StyledTableCell>
                    <StyledTableCell align='right'>{leave?.desc}</StyledTableCell>
                  </StyledTableRow>
                </>
              ))}
              <StyledTableRow>
                <StyledTableCell component='th' scope='row' colSpan={3} style={LastCellStyle}>
                  Total Deductible Leaves:
                </StyledTableCell>
                <StyledTableCell align='right' style={LastCellStyle}>
                  {totalDeductibleLeaves}
                </StyledTableCell>
                <StyledTableCell align='right'></StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }

  const NonDeductibleLeavesAvailed = (props: {
    totalNonDeductibleLeavesAvailed: number
    nonDeductibleLeavesAvailed: ILeaveDetails[]
  }) => {
    const { totalNonDeductibleLeavesAvailed, nonDeductibleLeavesAvailed } = props
    return (
      <>
        <LeavesDetailsHeading>
          NON DEDUCTIBLE LEAVES AVAILED ({totalNonDeductibleLeavesAvailed})
        </LeavesDetailsHeading>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label='customized table'>
            <TableHead>
              <TableRow>
                <StyledTableCell>LEAVE TYPE</StyledTableCell>
                <StyledTableCell>LEAVE START DATE</StyledTableCell>
                <StyledTableCell>LEAVE END DATE</StyledTableCell>
                <StyledTableCell>COUNT</StyledTableCell>
                <StyledTableCell style={{ width: '32%' }}>DESC</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nonDeductibleLeavesAvailed?.map((leave, i) => (
                <>
                  <StyledTableRow key={`${leave?.leaveType}${leave?.leaveStartDate}`}>
                    <StyledTableCell component='th' scope='row'>
                      {leave?.leaveType}
                    </StyledTableCell>
                    <StyledTableCell align='right'>
                      {new Date(leave?.leaveStartDate).toLocaleDateString('en-GB')}
                    </StyledTableCell>
                    <StyledTableCell align='right'>
                      {new Date(leave?.leaveEndDate).toLocaleDateString('en-GB')}
                    </StyledTableCell>
                    <StyledTableCell align='right'>{leave?.count}</StyledTableCell>
                    <StyledTableCell align='right'>{leave?.desc}</StyledTableCell>
                  </StyledTableRow>
                </>
              ))}
              <StyledTableRow>
                <StyledTableCell component='th' scope='row' colSpan={3} style={LastCellStyle}>
                  Total Non Deductible Leaves:
                </StyledTableCell>
                <StyledTableCell align='right' style={LastCellStyle}>
                  {totalNonDeductibleLeavesAvailed}
                </StyledTableCell>
                <StyledTableCell align='right'></StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }

  const AttendanceListTable = (props: any) => {
    const [expanded, setExpanded] = React.useState<string | false>(false)
    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
      props.setSelectedTab(newValue)
    }

    const useStyles = makeStyles({
      header: {},
      subHeadingContainer: {
        position: 'relative',
        top: 14,
        left: 18,
        margin: '20px 0px !important',
      },
      SubHeading: {
        color: style.PRIMARY_COLOR,
        right: '20px',
        padding: '6px',
        margin: '10px',
        position: 'relative',
        bottom: '8px',
      },
      LateAttendanceSubHeadingContainer: {
        position: 'relative',
        top: '48px',
        left: '18px',
        margin: '20px 0px !important',
      },
      LateAttendanceSubHeading: {
        color: style.PRIMARY_COLOR,
        bottom: '38px',
        position: 'relative',
      },
      list: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        listStyle: 'none',
      },
    })
    const classes = useStyles(props)
    const navigate = useNavigate()

    const convertTime = (date: any) => {
      const originalDate = new Date(date)

      const modifiedDate = new Date(originalDate)
      modifiedDate.setHours(originalDate.getHours() - 5)
      modifiedDate.setMinutes(originalDate.getMinutes() - 30)
      return modifiedDate.toISOString()
    }

    const formatTime = (timeString: any) => {
      const [hourString, minute] = timeString.split(':')
      const hour = +hourString % 24
      return (hour % 12 || 12) + ':' + minute + (hour < 12 ? 'AM' : 'PM')
    }


    const AccordingHeading = {
      flexShrink: 0,
      fontFamily: style.FONT_MEDIUM,
      letterSpacing: '0',
      fontSize: '20px',
    }

    const PaperBlock = styled(Paper)(({ theme }) => ({
      width: '30%',
      padding: '6px',
      fontFamily: style.FONT_MEDIUM,
      fontSize: '13px',
    }))

    const PaperMiniBlock = styled(Paper)(({ theme }) => ({
      width: '5%',
      padding: '6px',
      fontFamily: style.FONT_MEDIUM,
      fontSize: '13px',
      textAlign: 'center',
    }))

    const PaperSepBlock = styled(Paper)(({ theme }) => ({
      width: '68%',
      padding: '6px',
      fontFamily: style.FONT_MEDIUM,
      fontSize: '13px',
      textAlign: 'center',
    }))

    const SplitDiv: React.CSSProperties = {
      display: 'flex',
    }

    const Heading: React.CSSProperties = {
      margin: 0,
      marginBottom: '12px',
      fontSize: '25px',
      textAlign: 'center',
      fontFamily: style.FONT_MEDIUM,
    }

    const handleClickOpen = () => {
      return navigate('/home/service-request', {
        state: { tabName: 'Leave Details', deptId: 3, issueId: 80, providedFeedback: false },
      })
    }

    return (
      <>
        <StyledPaper>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: '50px',
              justifyContent: 'space-between',
              marginTop: '-25px',
            }}
          >
            <Tabs
              value={props.selectedTab}
              onChange={handleTabChange}
              aria-label='Tabs for different tables'
            >
              <Tab label='Leave Info' onClick={() => props.setSelectedTab(0)} />
              <Tab label='Attendance Info' onClick={() => props.setSelectedTab(1)} />
            </Tabs>
            {props.selectedTab === 0 && (
              <Box
                sx={{
                  textAlign: 'left',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                }}
              >
                <ActionButton
                  variant='outlined'
                  startIcon={<NavigationIcon sx={{ width: 24, height: 24 }} />}
                  onClick={handleClickOpen}
                >
                  Leave Request
                </ActionButton>
              </Box>
            )}
          </Box>
          {props.selectedTab === 0 && (
            <StyledPaper>
              {loaderStateLeave && (
                <Dialog open={loaderStateLeave} PaperProps={{ style: loaderProps }}>
                  <CircularProgress color='secondary' />
                </Dialog>
              )}
              {leaveDetails && Object.values(leaveDetails) && (
                <>
                  <StyledPaperAccrualReport elevation={3}>
                    <p style={Heading}>Leave Accrual Report </p>
                    <div style={SplitDiv}>
                      <PaperBlock variant='outlined' square>
                        Employee Id:
                      </PaperBlock>
                      <PaperBlock variant='outlined' square>
                        {leaveDetails?.employeeId}
                      </PaperBlock>
                      <PaperBlock variant='outlined' square>
                        Total Leaves Allocated:
                      </PaperBlock>
                      <PaperMiniBlock variant='outlined' square>
                        {leaveDetails?.totalLeaves}
                      </PaperMiniBlock>
                    </div>
                    <div style={SplitDiv}>
                      <PaperBlock variant='outlined' square>
                        Employee Name:
                      </PaperBlock>

                      <PaperBlock variant='outlined' square>
                        {leaveDetails?.employeeName}
                      </PaperBlock>
                      <PaperBlock variant='outlined' square>
                        Leaves Availed:
                      </PaperBlock>
                      <PaperMiniBlock variant='outlined' square>
                        {leaveDetails?.availedLeaves}
                      </PaperMiniBlock>
                    </div>
                    <div style={SplitDiv}>
                      <PaperBlock variant='outlined' square>
                        Designation:
                      </PaperBlock>

                      <PaperBlock variant='outlined' square>
                        {leaveDetails?.designation}
                      </PaperBlock>
                      <PaperBlock variant='outlined' square>
                        Leave Balance:
                      </PaperBlock>
                      <PaperMiniBlock variant='outlined' square>
                        {leaveDetails?.balanceLeaves}
                      </PaperMiniBlock>
                    </div>
                    <div style={SplitDiv}>
                      <PaperBlock variant='outlined' square>
                        Accrual Type:
                      </PaperBlock>

                      <PaperBlock variant='outlined' square>
                        {leaveDetails?.accrualType}
                      </PaperBlock>
                      <PaperBlock variant='outlined' square>
                        Leaves Allocated in previous FY:
                      </PaperBlock>
                      <PaperMiniBlock variant='outlined' square>
                        {leaveDetails?.previousYearLeaves}
                      </PaperMiniBlock>
                    </div>
                    {leaveDetails && leaveDetails?.encashedLeaves >= 0 ? (
                      <div style={SplitDiv}>
                        <PaperBlock variant='outlined' square>
                          Previous FY Leave encashment:
                        </PaperBlock>
                        <PaperSepBlock variant='outlined' square>
                          {leaveDetails?.encashedLeaves}
                        </PaperSepBlock>
                      </div>
                    ) : (
                      <div style={SplitDiv}>
                        <PaperBlock variant='outlined' square>
                          Extra Leaves availed in previous FY year:
                        </PaperBlock>

                        <PaperSepBlock variant='outlined' square>
                          -{leaveDetails?.encashedLeaves}
                        </PaperSepBlock>
                      </div>
                    )}
                  </StyledPaperAccrualReport>

                  <Box padding='0 10px'>
                    {leaveDetails &&
                      leaveDetails?.leaveAccrualList &&
                      leaveDetails?.leaveAccrualList?.length > 0 &&
                      leaveDetails?.leaveAccrualList?.map((leaveAccrual: any, i: any) => (
                        <Accordion
                          expanded={expanded === `panel${i}`}
                          onChange={handleChange(`panel${i}`)}
                          sx={{ marginBottom: '15px' }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1bh-content'
                            id='panel1bh-header'
                            sx={{ backgroundColor: '#F2F2F2' }}
                          >
                            <Typography sx={AccordingHeading}>
                              {`Leave Details for the Financial Year ${leaveAccrual?.financialYear}`}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <AllocatedLeaves
                              totalLeaveCount={leaveAccrual?.totalLeaveCount}
                              allocatedLeaves={leaveAccrual?.allocatedLeaves}
                            />
                            <DeductibleLeavesAvailed
                              totalDeductibleLeaves={leaveAccrual?.totalDeductibleLeaves}
                              deductibleLeavesAvailed={leaveAccrual?.deductibleLeavesAvailed}
                            />
                            <NonDeductibleLeavesAvailed
                              totalNonDeductibleLeavesAvailed={
                                leaveAccrual?.totalNonDeductibleLeavesAvailed
                              }
                              nonDeductibleLeavesAvailed={leaveAccrual?.nonDeductibleLeavesAvailed}
                            />
                          </AccordionDetails>
                        </Accordion>
                      ))}
                  </Box>
                </>
              )}
            </StyledPaper>
          )}
          {props.selectedTab === 1 && (
            <StyledPaper sx={{ padding: '1rem' }}>
              <Box sx={{ justifyContent: 'flex-end', display: 'flex', alignItems: 'flex-start' }}>
                <Box sx={{ width: '160px' }}>
                  <StyledFormControl>
                    <InputLabel id='demo-simple-select-readonly-label'>Select Month</InputLabel>
                    <SelectField
                      sx={{ borderRadius: '22px' }}
                      variant='outlined'
                      label='Select Month:'
                      value={selectedMonth}
                      onChange={handleSelectMonth}
                    >
                      {AttendanceTimesheet.map((option: any) => (
                        <StyledMenuItem key={option?.id} value={option?.id}>
                          {moment(option?.start_date).format('MMMM YYYY')}
                        </StyledMenuItem>
                      ))}
                    </SelectField>
                  </StyledFormControl>
                </Box>
                <Box
                  onClick={() => navigate(-1)}
                  sx={{
                    float: 'left',
                    marginRight: '-25px',
                    pr: '30px',
                    mt: '0px',
                    marginTop: '10px',
                    cursor: 'pointer',
                  }}
                >
                  {/* <ArrowBack /> */}
                </Box>
              </Box>
              <div>
                <HeaderHeading sx={{ marginTop: '-42px' }}>Attendance Details</HeaderHeading>
                <Typography
                  align='center'
                  sx={{ position: 'relative', top: '15px', fontFamily: style.FONT_MEDIUM }}
                >
                  Your current reporting time is at: {formatTime(props?.data?.officeTiming || '')}
                </Typography>
              </div>
              {/* tables */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  alignItems: 'baseline',
                }}
              >
                {/* first table */}
                <Box sx={{ flex: '1', marginRight: '20px' }}>
                  <Typography className={classes.LateAttendanceSubHeadingContainer}>
                    <SubHeading className={classes.LateAttendanceSubHeading}>
                      Late Attendance
                    </SubHeading>
                  </Typography>
                  <TableContainer
                    sx={{
                      margin: '20px 0',
                      position:
                        props.data.lateAttendanceDetails?.length > 0 ? 'relative' : 'static',
                      maxHeight: '300px',
                    }}
                    component={Paper}
                  >
                    <Table
                      sx={{
                        minWidth: 670,
                        minHeight: props.data.lateAttendanceDetails?.length > 0 ? 115 : 50,
                      }}
                      aria-label='customized table'
                    >
                      <TableHead>
                        <StyledTableRow>
                          <StyledTableCell style={{ textAlign: 'left', marginLeft: '8px' }}>
                            REPORTING TIME
                          </StyledTableCell>
                          <StyledTableCell style={{ textAlign: 'left' }}>
                            ACTUAL REPORTING TIME
                          </StyledTableCell>
                          <StyledTableCell style={{ textAlign: 'left' }}>LATE BY</StyledTableCell>
                          <StyledTableCell style={{ textAlign: 'left' }}>DAY</StyledTableCell>
                          <StyledTableCell style={{ textAlign: 'left' }}>DATE</StyledTableCell>
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>
                        {props.data.lateAttendanceDetails?.length > 0 ? (
                          props?.data?.lateAttendanceDetails.map((data: any) => (
                            <StyledTableRow key={`${data.date}${data.actual_scan_time}`}>
                              <StyledTableCell
                                component='th'
                                scope='row'
                                style={{ verticalAlign: 'top', margin: '25px' }}
                              >
                                {moment(
                                  new Date(convertTime(data.expected_scan_time)),
                                  'HH:mm',
                                ).format('h:mm A')}
                              </StyledTableCell>
                              <StyledTableCell
                                component='th'
                                scope='row'
                                style={{ verticalAlign: 'top' }}
                              >
                                {moment(
                                  new Date(convertTime(data.actual_scan_time)),
                                  'HH:mm',
                                ).format('h:mm A')}
                              </StyledTableCell>
                              <StyledTableCell align='left' style={{ verticalAlign: 'top' }}>
                                {data.late_by}
                              </StyledTableCell>
                              <StyledTableCell align='left' style={{ verticalAlign: 'top' }}>
                                {moment(new Date(data.expected_scan_time), 'YYYY-MM-DD').format(
                                  'dddd',
                                )}
                              </StyledTableCell>
                              <StyledTableCell align='left' style={{ verticalAlign: 'top' }}>
                                {data.date}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                        ) : (
                          <StyledTableRow>
                            <StyledTableCell align='center' colSpan={10}>
                              <Typography
                                variant='subtitle1'
                                align='center'
                                sx={{ color: '#483f3f' }}
                              >
                                No data found
                              </Typography>
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                {/* second table */}
                <Box sx={{ flex: '1' }}>
                  <Typography className={classes.subHeadingContainer}>
                    <SubHeading className={classes.SubHeading}>No Attendance</SubHeading>
                  </Typography>

                  <TableContainer
                    sx={{
                      margin: '20px 0',
                      position: 'relative',
                      bottom: '6px',
                      maxHeight: '300px',
                    }}
                    component={Paper}
                  >
                    <Table
                      sx={{
                        // minWidth: 420,
                        minHeight: 105,
                      }}
                      aria-label='customized table'
                    >
                      <TableHead>
                        <StyledTableRow>
                          <StyledTableCell style={{ textAlign: 'left', marginLeft: '8px' }}>
                            REPORTING TIME
                          </StyledTableCell>
                          <StyledTableCell style={{ textAlign: 'left' }}>DAY</StyledTableCell>{' '}
                          <StyledTableCell style={{ textAlign: 'left' }}>DATE</StyledTableCell>
                        </StyledTableRow>
                      </TableHead>
                      {props.data.noAttendanceDetails?.length > 0 ? (
                        <TableBody>
                          {props?.data?.noAttendanceDetails.map((data: any) => (
                            <StyledTableRow key={`${data.date}`}>
                              <StyledTableCell component='th' scope='row'>
                                {moment(
                                  new Date(convertTime(data.expected_scan_time)),
                                  'HH:mm',
                                ).format('h:mm A')}
                              </StyledTableCell>
                              <StyledTableCell align='left'>
                                {moment(
                                  new Date(convertTime(data.expected_scan_time)),
                                  'YYYY-MM-DD',
                                ).format('dddd')}
                              </StyledTableCell>
                              <StyledTableCell align='left'>{data.date}</StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      ) : (
                        <TableBody>
                          <StyledTableRow>
                            <StyledTableCell align='center' colSpan={10}>
                              <Typography
                                variant='subtitle1'
                                align='center'
                                sx={{ color: '#483f3f' }}
                              >
                                No data found
                              </Typography>
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </StyledPaper>
          )}
        </StyledPaper>
      </>
    )
  }

  return (
    <>
      {loaderState ? (
        <Dialog open={loaderState} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      ) : (
        <div style={MainContainer}>
          {!AttendanceTimesheet.length ? (
            <div></div>
          ) : (
            <AttendanceListTable
              data={AttendanceData}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          )}
        </div>
      )}
    </>
  )
}

//used to map relavant part of redux state to its props
const mapStateToProps = (state: RootState) => {
  return {
    AttendanceData: attendanceEntity.getAllAttendance(state).getEmployeeAttendance,
    AttendanceTimesheet: attendanceEntity.getAllAttendance(state).getAttendanceTimesheet,
    loaderState: attendanceUI.getEmpAttendanceList(state).isGetEmployeeAttendanceList,
    getUserById: dashboardEntity.getDashboard(state).getUserDetails,

    leaveDetails: dashboardEntity.getDashboard(state).getLeaveDetails,
    loaderStateLeave: dashboardUI.getDashboard(state).isLeaveData,
  }
}

//api calls here to display data
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchAttendanceTimesheet: (userId: any) => dispatch(getAttendanceTimesheet.request({ userId })),
    fetchAttendanceData: (data: any) => dispatch(getAttendanceDetail.request(data)),
    fetchLeaveDetails: (data: any) => dispatch(fetchLeaveDetails.request(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceList)
//connect to redux store through connect
