import { Box } from '@mui/material' 
import DateRangePicker from './DateRangePicker'
import SearchBox from './SearchBox'
import { ActionBarPropsType } from './ProjectSheetTypes'
import { style } from './SheetStyles'
import ExportButton from './ExportButton'


const ActionBar = (props: ActionBarPropsType) => {

  const {
    searchQuery,
    selectedTab,
    setSearchQuery,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setShowResults,
    subTableData,
  } = props

  
  return (
    <Box component="div" id='actionBar'>
      <Box sx={style.actionBarConatiner} paddingBottom={ '0'}>
        <Box sx={style.actionBar}>
        <SearchBox 
        searchQuery={searchQuery} 
        setSearchQuery={setSearchQuery}       
        />
          <Box display='flex' alignItems='center' justifyContent='center' padding='0' sx={style.datePickerContainer}>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setShowResults={setShowResults}
              searchQuery={searchQuery}
              selectedTab={selectedTab}
            />
          </Box>
          <Box sx={style.actionBarDownloadbtn}>
          <ExportButton 
              subTableData={subTableData} 
              selectedTab={selectedTab} 
              startDate={startDate}
              endDate={endDate}
              searchQuery={searchQuery}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ActionBar
