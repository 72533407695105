import * as React from 'react'
import MenuItem from '@mui/material/MenuItem'
import { Box, FormControl, InputLabel, Select, SelectChangeEvent } from '@mui/material'
import { useState } from 'react'
import styles from '../../../utils/styles.json'

type PropType = {
  setTabView: React.Dispatch<React.SetStateAction<string>>
  setPage: React.Dispatch<React.SetStateAction<number>>
}

const style = {
  selectEntriesBtn: {
    width: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '5px',
    fontFamily: styles.FONT_MEDIUM,
    height: '40px',
    fontSize: '0.9rem',
    display: 'flex',
    flexWrap: 'nowrap',
    backgroundColor: '#FFFFFF',
    color: '#353665',
    borderRadius: '50px',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: '#353665',
    },
  },
}

const SelectResources = (props: PropType) => {
  const [value, setValue] = useState('billable')

  const handleClose = (e: SelectChangeEvent) => {
    setValue((prev) => e.target.value ?? prev)
    props.setTabView((prev) => e.target.value ?? prev)
    props.setPage(1)
  }

  return (
    <Box display='flex' alignItems='center' justifyContent='center'>
      <FormControl fullWidth>
        <InputLabel id='demo-simple-select-label'>Select Resources</InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          displayEmpty
          value={value}
          label='Select Resources'
          onChange={handleClose}
          sx={style.selectEntriesBtn}
        >
          <MenuItem value={'billable'}>Billable Resources</MenuItem>
          <MenuItem value={'additional'}>Additional Resources</MenuItem>
          <MenuItem value={'nonbillable'}>Non-Billable Resources</MenuItem>
          <MenuItem value={'free'}>Free Resources</MenuItem>
        </Select>
      </FormControl>
    </Box>
  )
}

export default SelectResources
