// import './IDSR.scss'
import {
  Box,
  IconButton,
  Typography,
  Modal,
  Tooltip,
  CircularProgress,
  Dialog,
  Button,
  DialogActions,
  Grid,
  DialogTitle,
} from '@mui/material'

import { Heading } from '../Pages/Styles'
import CloseIcon from '@mui/icons-material/Close'
import 'react-quill/dist/quill.snow.css'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { ReactComponent as ApproveIcon } from '../../assets/images/approveIconTimesheet.svg'
import { ReactComponent as CheckedApprovedIcon } from '../../assets/images/checkApprovedIcon.svg'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import { loaderProps } from '../Common/CommonStyles'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import style from '../../utils/styles.json'

const SheetOverview = (props: any) => {
  const { selectedPlanForTheDay, approvePlanForTheDayDispatchCall } = props

  const [isNewPlanDialogOpen, setNewPlanDialog] = useState(false)
  const handleNewPlanDialog = () => {
    setNewPlanDialog(true)
  }

  const hasApprovedStatus = selectedPlanForTheDay?.hasOwnProperty('approve_status')

  const handleCloseNewPlanDialog = () => {
    setNewPlanDialog(false)
  }

  interface DialogTitleProps {
    id: string
    children?: React.ReactNode
    onClose: () => void
  }

  function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label='close'
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    )
  }
  const [formValues, setFormValues] = useState('')

  const handleTaskDescriptionChange = (content: string) => {
    setFormValues(content)
  }

  const handlePostComment = () => {
    if (hasApprovedStatus) {
      if (formValues) {
        let finalData = JSON.stringify({ comment: formValues })
        props.createCommentPlanForTheDay({ id: selectedPlanForTheDay.id, data: finalData })
        handleCloseNewPlanDialog()
        setFormValues('')
        props.handleClosePlanModal()
      }
    } else {
      if (formValues) {
        let finalData = JSON.stringify({ comment: formValues })
        props.createCommentTimesheet({ id: selectedPlanForTheDay.id, data: finalData })
        handleCloseNewPlanDialog()
        setFormValues('')
        props.handleClosePlanModal()
      }
    }
  }

  const handleCopyContent = () => {
    const stripHTML = (html: any) => {
      let temp = html.replace(/<p>/g, '\n')
      temp = temp.replace(/<\/p>/g, '\n')
      temp = temp.replace(/<br\s*\/?>/gi, '\n')
      temp = temp.replace(/<strong>/g, '*')
      temp = temp.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
      temp = temp.replace(/<\/strong>/g, '*')
      temp = temp.replace(/<em>/g, '*')
      temp = temp.replace(/<\/em>/g, '*')
      temp = temp.replace(/<i>/g, '*').replace(/<\/i>/g, '*')
      temp = temp.replace(/<li>/g, '\n ').replace(/<\/li>/g, '')

      temp = temp.replace(/<ol>/g, '\n').replace(/<\/ol>/g, '\n')
      temp = temp.replace(/<ul>/g, '\n').replace(/<\/ul>/g, '\n')
      temp = temp.replace(/<u>/g, '__')
      temp = temp.replace(/<\/u>/g, '__')
      temp = temp.replace(/&nbsp;/g, ' ')
      temp = temp.replace(/<[^>]+>/g, '')
      temp = temp.replace(/&amp;/g, '&')
      temp = temp.replace(/&\$/g, '$')

      return temp
    }
    const subject = props.selectedPlanForTheDay?.subject || ''
    const body = stripHTML(props.selectedPlanForTheDay?.body || '')
    const content = `${subject}\n${body}`

    navigator.clipboard.writeText(content).then(() => {
      props.setCopyTooltipOpen(true)
      setTimeout(() => {
        props.setCopyTooltipOpen(false)
      }, 2000)
    })
  }

  function convertTextToHTML(text: string) {
    let htmlText = text.replace(/\\n/g, '<br>')

    htmlText = htmlText.replace(/\*([^*]+)\*/g, '<strong>$1</strong>')

    return <div dangerouslySetInnerHTML={{ __html: htmlText }} />
  }

  useEffect(() => {
    if (!props.getApproveLoader && props.getApproveSuccessStatus) {
      toast.success('Plan for the day approved successfully')
    }
    props.resetPlanForTheDayApprove()
    props.handleClosePlanModal()
  }, [props.getApproveSuccessStatus])

  useEffect(() => {
    if (!props.getLoaderState && props.getCommentSuccess) {
      toast.success('Comment added successfully')
    }
    props.resetTimesheetComment()
    props.handleClosePlanModal()
  }, [props.getCommentSuccess])

  useEffect(() => {
    if (!props.getPlanForTheDayLoaderState && props.getPlanForTheDayCommentSuccess) {
      toast.success('Comment added successfully')
    }
    props.resetPlanForTheDayComment()
    props.handleClosePlanModal()
  }, [props.getPlanForTheDayCommentSuccess])

  return (
    <>
      <Dialog
        open={props.getLoaderState || props.getPlanForTheDayLoaderState}
        PaperProps={{ style: loaderProps }}
      >
        <CircularProgress color='secondary' />
      </Dialog>
      <Dialog open={props.getApproveLoader} PaperProps={{ style: loaderProps }}>
        <CircularProgress color='secondary' />
      </Dialog>
      <Modal
        open={!!props.selectedPlanForTheDay}
        onClose={props.handleClosePlanModal}
        sx={{ outline: 'none' }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70vw',
            maxWidth: '75%',
            bgcolor: 'background.paper',
            p: 4,
            maxHeight: '80vh',
            overflowY: 'auto',
            borderRadius: '10px',
            outline: 'none',
          }}
        >
          <IconButton
            aria-label='close'
            onClick={props.handleClosePlanModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[600],
              marginTop: '15px',
              outline: 'none',
            }}
          >
            <CloseIcon />
          </IconButton>
          <Tooltip
            title={props.copyTooltipOpen ? 'Copied!' : 'Copy to Clipboard'}
            open={props.hoverTooltipOpen || props.copyTooltipOpen}
          >
            <IconButton
              aria-label='copy'
              onClick={handleCopyContent}
              onMouseEnter={() => props.setHoverTooltipOpen(true)}
              onMouseLeave={() => props.setHoverTooltipOpen(false)}
              sx={{
                position: 'absolute',
                right: 50,
                top: 22,
                zIndex: 999,
                outline: 'none',
              }}
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
          <Box display={'flex'} justifyContent={'center'} width='100%' height={'70px'}>
            <Box width='65%' justifyContent='flex-end' display='flex'>
              <Heading
                style={{
                  fontSize: '18px',
                  textAlign: 'center',
                  fontFamily: 'sans-serif',
                  letterSpacing: '0px',
                  position: 'relative',
                }}
                dangerouslySetInnerHTML={{
                  __html: selectedPlanForTheDay?.subject || '',
                }}
              />
            </Box>
            <Box
              width={'40%'}
              display={'flex'}
              justifyContent={'flex-end'}
              sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center', gap: '5px' }}
            >
              <Tooltip title={selectedPlanForTheDay?.approve_status === 1 ? '' : 'Approve'}>
                <IconButton
                  sx={{
                    position: 'absolute',
                    left: '63%',
                    top: 8,
                    marginTop: '11px',
                    width: '50px',
                    // opacity: selectedPlanForTheDay?.approve_status === 1 ? 0.5 : 1,
                    cursor: selectedPlanForTheDay?.approve_status === 1 ? 'not-allowed' : 'pointer',
                  }}
                  disableRipple={selectedPlanForTheDay?.approve_status === 1}
                >
                  {hasApprovedStatus ? (
                    selectedPlanForTheDay?.approve_status === 1 ? (
                      <CheckedApprovedIcon />
                    ) : (
                      <ApproveIcon
                        onClick={() => {
                          if (selectedPlanForTheDay?.approve_status !== 1) {
                            approvePlanForTheDayDispatchCall({ id: selectedPlanForTheDay?.id })
                          }
                        }}
                      />
                    )
                  ) : (
                    ''
                  )}
                </IconButton>
              </Tooltip>

              <Tooltip title={selectedPlanForTheDay?.approve_status === 1 ? '' : 'Add Comment'}>
                <IconButton
                  sx={{
                    position: 'absolute',
                    left: '68%',
                    top: 8,
                    color: 'black',
                    marginTop: '11px',
                  }}
                >
                  {hasApprovedStatus ? (
                    selectedPlanForTheDay?.approve_status === 1 ? (
                      ''
                    ) : (
                      <AddCircleOutlineIcon
                        onClick={() => {
                          if (selectedPlanForTheDay?.approve_status !== 1) {
                            // setPlanForTheDayData(selectedPlanForTheDay.id);
                            // setSelectedPlanForTheDay(null);
                            return handleNewPlanDialog()
                          }
                        }}
                        sx={{
                          cursor: 'pointer',
                          color: 'green',
                          marginTop: '-2px',
                          fontSize: '40px',
                          marginLeft: '-5px',
                        }}
                      />
                    )
                  ) : selectedPlanForTheDay?.approve_status === 1 ? (
                    ''
                  ) : (
                    <AddCircleOutlineIcon
                      onClick={() => {
                        if (selectedPlanForTheDay?.approve_status !== 1) {
                          // setPlanForTheDayData(selectedPlanForTheDay.id);
                          // setSelectedPlanForTheDay(null);
                          return handleNewPlanDialog()
                        }
                      }}
                      sx={{
                        cursor: 'pointer',
                        color: 'green',
                        marginTop: '-2px',
                        fontSize: '40px',
                        marginLeft: '-5px',
                      }}
                    />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Typography
            variant='body1'
            style={{
              fontSize: '12px',
              wordBreak: 'break-word',
              outline: 'none',
              marginTop: '-25px',
            }}
          >
            <div>{props.convertTextToHTML(props.selectedPlanForTheDay?.body || '')}</div>
            {props.selectedPlanForTheDay?.hasOwnProperty('approve_status') ? (
              props.selectedPlanForTheDay?.approve_status === 0 ? (
                props.selectedPlanForTheDay?.comment && (
                  <Box
                    width={'98%'}
                    padding={'15px 0 0 0'}
                    display={'flex'}
                    flexDirection={'column'}
                    flexWrap={'wrap'}
                  >
                    <Typography
                      fontFamily={style.FONT_BOLD}
                      fontSize={'15px'}
                      marginBottom={'5px'}
                    >
                      Comment:{' '}
                    </Typography>
                    <Typography
                      width={'100%'}
                      border={'1px solid #D3D3D3'}
                      padding={'0 10px'}
                      sx={{
                        wordWrap: 'break-word',
                        fontSize: '12px',
                        borderBottomColor: 'coral',
                      }}
                    >
                      {convertTextToHTML(props.selectedPlanForTheDay?.comment || '')}
                    </Typography>
                  </Box>
                )
              ) : (
                ''
              )
            ) : props.selectedPlanForTheDay?.comment ? (
              <Box
                width={'98%'}
                padding={'15px 0 0 0'}
                display={'flex'}
                flexDirection={'column'}
                flexWrap={'wrap'}
              >
                {' '}
                <Typography
                  fontFamily={style.FONT_BOLD}
                  fontSize={'15px'}
                  marginBottom={'5px'}
                >
                  Comment:{' '}
                </Typography>
                <Typography
                  width={'100%'}
                  border={'1px solid #D3D3D3'}
                  padding={'0 10px'}
                  sx={{
                    wordWrap: 'break-word',
                    fontSize: '12px',
                    borderBottomColor: 'coral',
                  }}
                >
                  {convertTextToHTML(props.selectedPlanForTheDay?.comment || '')}
                </Typography>{' '}
              </Box>
            ) : (
              ''
            )}
          </Typography>
        </Box>
      </Modal>
      <Dialog open={isNewPlanDialogOpen}>
        <Box className='PFTD_Wrapper'>
          <BootstrapDialogTitle id='customized-dialog-title' onClose={handleCloseNewPlanDialog}>
            <Typography
              padding={'5px 0'}
              variant='h5'
              sx={{ textAlign: 'center', marginTop: '-10px' }}
              fontSize={'22px'}
              fontFamily={style.FONT_MEDIUM}
            >
              Add Comment
            </Typography>
          </BootstrapDialogTitle>

          <Box sx={{ padding: '0 16px', marginTop: '-8px' }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
              <Grid item xs={12} sm={12} className='PFTD_Editor'>
                <CKEditor
                  editor={ClassicEditor}
                  data={''}
                  onReady={() => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    handleTaskDescriptionChange(data)
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <DialogActions className='button_wrapper' sx={{ padding: '15px 10px' }}>
            <Button
              sx={{
                background: '#E2E2E2',
                color: '#000000',
                fontSize: `13px`,
                height: `42px`,
                fontFamily: style.FONT_BOLD,
                width: '100px',
                borderRadius: '20px',
                '&:hover': {
                  background: '#E2E2E2',
                  color: '#000000',
                },
              }}
              onClick={handleCloseNewPlanDialog}
            >
              CANCEL
            </Button>
            <Button
              sx={{
                borderRadius: '20px',
                fontSize: '13px',
                height: '42px',
                fontFamily: style.FONT_BOLD,
                width: '100px',

                '&.Mui-disabled': {
                  opacity: 0.5,
                  color: '#ffffff',
                  cursor: 'not-allowed',
                },
              }}
              onClick={handlePostComment}
              disabled={!formValues}
            >
              Submit
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  )
}

export default SheetOverview
