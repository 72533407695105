import {
  Box,
  Button,
  Paper,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { RootState } from '../../configureStore'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import {
  downloadForm16,
  getFinancialYear,
  getPayrollForm16,
} from '../../actions'
import { dashboardEntity, financeEntity, financeUI } from '../../reducers'
import { toast } from 'react-toastify'
import { SelectField, StyledMenuItem } from '../UserDetails/UserDetailsStyle'
import formImg from '../../assets/images/form16Form.png'
import form16DownloadIcon from '../../assets/images/form16DownloadIcon.png'
import { ReactComponent as FormLogo } from '../../assets/images/formnewimage.svg'
import styles from '../../utils/styles.json'


const Form16 = (props: any) => {
  const { userDetails, downloadForm16LinkData } = props

  const [yearData, setYearData] = useState(props?.financialYearData[0] ?? '')
  let [empStatus, setEmpStatus] = useState(1)
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const [userId, setUserId] = useState(userDetails?.id ?? 0)

  useEffect(() => {
    if (userDetails) {
      setUserId(userDetails?.id)
    }
  }, [userDetails])

  useEffect(() => {
    props?.fetchFinancialYear()
  }, [])

  useEffect(() => {
    if (props?.financialYearData) {
      setYearData(props?.financialYearData[0])
    }
  }, [props?.financialYearData])


  useEffect(() => {
    if (yearData)
      
      props?.fetchForm16Data({
        currentYear: yearData,
        status: empStatus,
        limit: rowsPerPage,
        page: page,
        user: 1,
      })
  }, [yearData, props?.isUploadedForm16Loader])

  useEffect(() => {
    if (props?.downloadForm16Success) {
      const link = document.getElementById('download')
      link?.click()
      toast.success('Form 16 downloaded successfully')
      props?.downloadForm16Reset()
    }
  }, [props?.downloadForm16Success])

  const setDownloadForm16Data = () => {
    props?.downloadForm16({ id: userId, currentYear: yearData })
  }

  return (
    <Paper
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '40px 0',
        height: '100%',
        backgroundColor: '#3FA9F5',
        margin: '15px auto 0',
        width: '90%',
        gap: '50px'

      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginLeft: '100px',
          width: '400px',
          borderRadius: '11px',
          height: '420px',
          backgroundColor: '#FFFFFF',
        }}
      >
        <Typography
          sx={{
            margin: '20px 0 0 0',
            height: '33px',
            lineHeight: '32.78px',
            fontSize: '24px',
            fontFamily: styles.FONT_BOLD,
          }}
        >
          Form 16
        </Typography>
        <img src={formImg} style={{ width: '250px', height: '200px' }} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          width={'80%'}
        >
          <Typography
            sx={{
              fontWeight: '500',
              fontFamily: styles.FONT_MEDIUM,
              fontSize: '16px',
              textAlign: 'center',
            }}
          >
            Choose the financial year from below
          </Typography>

          <SelectField
            size='small'
            id='financialYear'
            name='financialYear'
            label='Financial Year'
            select
            required={false}
            value={yearData}
            onChange={(e: any) => {
              setYearData(e.target.value)
            }}
          >
            {props?.getAllForm16Data?.data?.map((data: any, index: number) => {
              return (
                <StyledMenuItem key={index} value={data.year}>
                  {data.year}
                </StyledMenuItem>
              )
            })}
          </SelectField>

          <a id='download' href={downloadForm16LinkData} download></a>

          <Button
            onClick={() => {
              if (
                props?.getAllForm16Data?.data?.find((value: any) => value.year === yearData)
                  ?.file === 'Uploaded'
              )
                setDownloadForm16Data()
            }}
            // disabled={props?.getAllForm16Data?.data?.find((value:any)=> value.year === yearData)?.file!=='Uploaded'}
            disableRipple={
              props?.getAllForm16Data?.data?.find((value: any) => value.year === yearData)?.file !==
              'Uploaded'
            }
            sx={{
              borderRadius: '23px',
              width: '100%',
              height: '40px',
              backgroundColor: '#193C6C',
              color: 'white',
              opacity:
                props?.getAllForm16Data?.data?.find((value: any) => value.year === yearData)
                  ?.file !== 'Uploaded'
                  ? 0.5
                  : 1,
              '&:hover': {
                cursor:
                  props?.getAllForm16Data?.data?.find((value: any) => value.year === yearData)
                    ?.file !== 'Uploaded'
                    ? 'not-allowed'
                    : 'pointer',
              },
            }}
          >
            <Typography
              sx={{
                fontWeight: '500',
                fontFamily: styles.FONT_MEDIUM,
                fontSize: '16px',
                textAlign: 'center',
              }}
            >
              Download
            </Typography>
            <img
              src={form16DownloadIcon}
              style={{ width: '22px', height: '22px', marginLeft: '5px' }}
            />
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          width: '45%',
          height: '350px',
          backgroundColor: '#3FA9F5',
        }}
      >
        <FormLogo style={{ width: '100%', height: '100%', backgroundColor: '#3FA9F5' }} />
      </Box>
    </Paper>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    userDetails: dashboardEntity.getDashboard(state).getUserDetails,
    getAllForm16Data: financeEntity.getFinance(state).getForm16Data,
    isForm16DataLoader: financeUI.getFinance(state).isForm16DataLoader,
    financialYearData: financeEntity.getFinance(state).getFinancialYearData,
    downloadForm16LinkData: financeEntity.getFinance(state).getDownloadForm16Link,
    downloadForm16Success: financeUI.getFinance(state).isDownloadedForm16,
    isDownloadedFormSucesss: financeUI.getFinance(state).isDownloadedFormSucesss,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchForm16Data: (data: any) => {
      dispatch(getPayrollForm16.request(data))
    },

    fetchFinancialYear: () => {
      dispatch(getFinancialYear.request())
    },
    downloadForm16: (data: any) => {
      dispatch(downloadForm16.request(data))
    },
    downloadForm16Reset: () => {
      dispatch(downloadForm16.reset())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form16)
