import { Button } from '@mui/material'
import styled from 'styled-components'
import styles from '../../../utils/styles.json'

export const ResultActionButton = styled(Button)(({ theme }) => ({
  fontSize: '18px',
  height: '43px',
  padding: '20px 25px',
  fontFamily: 'Poppins-Light',
  marginLeft: '10px',
  borderRadius: '10px',
  '@media only screen and (max-width:900px)': {
    fontSize: '14px',
    height: '35px',
    padding: '14px 17px',
    marginTop: '10px',
  },
}))

export const style = {
  container: {
    backgroundColor: '#FFFFFF',
    margin: '20px',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '90vw',
    '@media print': {
      '@page': {
        size: 'landscape',
      }
    }
  },
  pageHead: {
    padding: '10px 32px 0',
    display: 'flex',
    width: '93%',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media only screen and (max-width:780px)': {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },

  primaryHeading: {
    fontSize: '1.3rem',
    fontFamily: 'Montserrat-Bold',
    color: styles.PRIMARY_COLOR,
    '@media only screen and (max-width:480px)': {
      textAlign: 'center',
    },
  },
  headerHeading: {
    fontSize: '0.9rem',
    fontFamily: styles.FONT_MEDIUM,
    color: styles.PRIMARY_COLOR,
    '@media only screen and (max-width:780px)': {
      fontSize: '0.7rem',
      textAlign: 'center',
    },
    width: 'fit-content',
    marginBottom: '1rem',
    className: 'heading',
  },
  tableContainer: {
    '*::-webkit-scrollbar ': { display: 'none' },
  },
  tableHeadCell: {
    color: 'white',
  },
  rowBorder: {
    borderTop: '1px solid #EEEEEE',
    borderBottom: '1px solid #EEEEEE',
    minHeight: '60px',
    margin: '16px 0 0',
  },
  rowInnerItem: {
    fontFamily: 'Poppins-Regular',
    fontSize: '0.8rem',
    padding: '0',
  },
  actionBarConatiner: {
    margin: '0 18px',
    position: 'relative',
    border: '2px solid #E0E0E0',
    borderRadius: '5px',
    width: '97%',
    '@media only screen and (max-width:1050px)': {
      width: "95%",
      marginLeft: "20px"
    },
    '@media only screen and (max-width:830px)': {
      marginLeft: "25px"
    },
    '@media only screen and (max-width:770px)': {
      width: "90%",
      marginLeft: '40px'
    },
  },
  actionBar: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingLeft: '22px',
    width: '93%',
    '@media only screen and (max-width:1200px)': {
      width: '94%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  entriesAndDownloadbtn: {
    width: 'auto',
    padding: '10px 0',
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: '5px',
    marginBottom: '2px',
    '@media only screen and (max-width:1080px)': {
      flexDirection: 'column',
      width: 'auto',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '@media only screen and (max-width:780px)': {
      flexDirection: 'column',
      width: '50%',
      alignItems: 'center',
      justifyContent: 'center',
      // gap: '16px'
    },
  },
  entriesBox: {
   
  },

  selectTypeStyle: {
    
  },

  FormControlStyle: {
    // m: 1,
    minWidth: '180px',
  },
  actionBarDownloadbtn: {
    backgroundColor: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    '@media only screen and (max-width:1200px)': {
      marginBottom: "1rem"
    },
    '@media only screen and (max-width:1178px)': {
      marginLeft: '15px'
    },
    '@media only screen and (max-width:700px)': {
      marginLeft: 0,
    },
  },
  expandButton: {
    backgroundColor: 'transparent',
    position: 'absolute',
    right: '0',
    top: '0.5rem',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  expandBox: {
    // border:' 1px solid red',
    display: 'flex',
    height: '40px',
    padding: '5px 0', 
    justifyContent: 'space-between',
    width: '100%',
    gap: '0',
    '@media only screen and (max-width:1200px)': {
      padding: '0',
      justifyContent: 'center',
      height: '110px',
      gap: '20px'
    },

  },

  collapseBoxLeft: {
    width: '45%',
    // border: '1px solid green',
    height: '100%',
    '@media only screen and (max-width:1200px)': {
      flexDirection: 'column',
      width: 'auto',
    },
  },

  collapseBoxRight: {
    width: '50%',
    height: '100%',
    '@media only screen and (max-width:1250px)': {
      width: '53%'
    },
    '@media only screen and (max-width:1200px)': {
      flexDirection: 'column',
      width: 'auto'
    },
  },

  innerCollapseBox: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    gap: '0px',
    '@media only screen and (max-width:1200px)': {
      height: '50%',
      flexDirection: 'column',
      gap: '20px'
    },
  },

  innerCollapseBoxRight: {

  },

  col1: {
    width: '50%',
    height: '100%',
    '@media only screen and (max-width:1200px)': {
      width: '100%',
    },
  },
  col2: {
    width: '50%',
    height: '100%',
    '@media only screen and (max-width:1200px)': {
      width: '100%',

    },
  },

  cardSection: {
    width: '22rem',
    height: '45px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '1rem 2.8rem',
    color: 'primary',
    backgroundColor: '#F2F2F2',
    textTransform: 'uppercase',
  },
  cardText: {
    color: '#353665',
    fontFamily: 'Poppins-Medium',
  },
  loader: {
    backgroundColor: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '30vh',
  },
  cardContainer: {
    display: 'flex',
    width: '97%',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    marginLeft: "15px"
  },
  tableHead: {
    '& th:first-of-type': {
      borderRadius: '50px 0 0 50px',
      border: 'none',
    },
    '& th:last-of-type': {
      borderRadius: '0 50px 50px 0',
      border: 'none',
    },
    '& th': {
      fontFamily: 'Poppins-Medium',
      fontSize: '1rem',
      padding: '5px 0 5px 0',
      border: 'none',
    },
    backgroundColor: styles.PRIMARY_COLOR,
  },
}
