import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import { RootState } from '../../configureStore'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Avatar,
  Typography,
  CircularProgress,
  Dialog,
  Box,
} from '@mui/material'
import {
  fetchUserDetailsEntities,
  fetchUserDetailsUI,
  financeEntity,
  financeUI,
} from '../../reducers'
import PdfIcon from '../../assets/images/pdf_image.png'
import React, { useEffect, useState } from 'react'
import { GetCompensationTudipLogo } from '../../utils/StaticData'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee'
import html2pdf from 'html2pdf.js'
import { fetchCompensation, getCompensationDetails } from '../../actions'
import moment from 'moment'
import { loaderProps } from '../Common/CommonStyles'
import { numberFormat } from '../../utils/currency-format'
import style from '../../utils/styles.json'
import { useLocation, useNavigate } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '80%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: '#FFFFFF',
  opacity: '1',
  marginTop: '25px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '20px',
  border: '1px solid #DDDDDD',
}))

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontFamily: style.FONT_MEDIUM,
  fontSize: '13px',
  margin: '0px 5px',
  letterSpacing: '0px',
}))

const SelectField = styled(Select)(({ theme }) => ({
  width: '240px',
  borderRadius: '20px',
  '& .MuiSelect-select': {
    padding: '11px 14px',
    fontSize: '13px',
    fontFamily: style.FONT_MEDIUM,
    borderRadius: '20px',
  },
}))

const Heading: React.CSSProperties = {
  margin: '0px 0px 10px 0px',
  fontSize: '22px',
  textAlign: 'center',
  fontFamily: style.FONT_MEDIUM,
}

const SideHeadings = styled(Typography)(({ theme }) => ({
  margin: '35px 0px',
  fontSize: '16px',
  textAlign: 'center',
  fontFamily: style.FONT_BOLD,
  letterSpacing: '0px',
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    padding: '3px 10px',
    backgroundColor: style.PRIMARY_COLOR,
    fontSize: '15px',
    border: '0.1px solid grey',
    color: theme.palette.common.white,
    fontFamily: style.FONT_MEDIUM,
    letterSpacing: '0px',
  },
  [`&.${tableCellClasses.body}`]: {
    padding: '3px 10px',
    fontSize: '15px',
    border: '0.1px solid grey',
    fontFamily: style.FONT_MEDIUM,
    letterSpacing: '0px',
  },
}))

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  // marginTop: '4.5px',
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    borderBottom: 'none',
    fontFamily: style.FONT_BOLD,
  },
}))

const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}

const handleExportPDF = () => {
  const element = document.getElementById('pdf-export')

  if (element) {
    const options = {
      margin: 10,
      filename: 'Compensation.pdf',
      image: { type: 'png', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    }
    const imgElement = element.querySelector('img')

    if (imgElement) {
      // Show the image only in the exported PDF
      imgElement.style.display = 'block'

      html2pdf().set(options).from(element).save()

      // Reset the image display after exporting
      imgElement.style.display = 'none'
    }
  }
}

const CompensationDetails = (props: any) => {
  const { compensationData, fetchCompensationTypeData, userId } = props
  const [compensationPeriod, setCompensationPeriod] = useState(
    `${compensationData?.compensationId + ',' + compensationData?.apoId}`,
  )

  //   const userId: any = useLocation()?.state

  const handleCompensationPeriod = (event: any) => {
    setCompensationPeriod(event.target.value)
    if (event.target.value.trim()) {
      fetchCompensationTypeData({ userId: userId, compensationString: event.target.value })
    }
  }

  return (
    <Box padding='30px 45px' bgcolor='#FFFFFF'>
      {Object.keys(compensationData).length ? (
        <Grid container spacing={3}>
            {/* Align Compensation Period and Export Button */}
      <Grid item xs={12} sm={12}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <StyledFormControl>
            <InputLabel id="demo-simple-select-readonly-label">Compensation period:</InputLabel>
            <SelectField
              value={compensationPeriod}
              label="Compensation Period:"
              onChange={handleCompensationPeriod}
            >
              {compensationData?.employeeCompensations?.map((empCompensation: any) => (
                <StyledMenuItem
                  key={`${empCompensation.id_compensation}`}
                  value={`${empCompensation?.id_compensation},${empCompensation?.id_appraisal_period_opened}`}
                >
                  Compensation ({moment(empCompensation.created_at).format('DD/MM/YYYY')})
                </StyledMenuItem>
              ))}
            </SelectField>
          </StyledFormControl>
          <Button
            variant="outlined"
            sx={{
              fontSize: '14px',
              height: '42px',
              width: '220px',
              letterSpacing: '0px',
              borderRadius: '20px',
              padding: '5px 20px',
            }}
            startIcon={
              <Avatar sx={{ width: 24, height: 24 }}>
                <img src={PdfIcon} alt="PDF" />
              </Avatar>
            }
            onClick={handleExportPDF}
          >
            EXPORT AS PDF
          </Button>
        </div>
      </Grid>

      {/* Remaining Grid Items */}
      <Grid item xs={12} sm={12}>
        <div style={{ width: '100%' }} id="pdf-export">
          <Grid item xs={12} sm={12}>
            <img src={GetCompensationTudipLogo} alt="Tudip Logo" style={{ display: 'none' }} />
          </Grid>
              <Grid item xs={12} sm={12}>
                <p style={Heading}>
                  {compensationData?.companyName
                    ? compensationData?.companyName?.toUpperCase()
                    : ''}
                </p>
                <p style={Heading}>{compensationData?.compensationName}</p>
              </Grid>

              <Grid item xs={12} sm={12} >
                <Typography
                  sx={{
                    fontSize: '1rem',
                    minHeight: '20px',
                    textAlign: 'left',
                    fontFamily: style.FONT_MEDIUM,
                  }}
                >
                  Employee Id: <span style={{color: "grey",fontFamily: style.FONT_MEDIUM,fontSize:'14px'}}>{compensationData?.employeeId}</span>
                </Typography>
                <Typography
                  sx={{
                    fontSize: '1rem',
                    minHeight: '20px',
                    textAlign: 'left',
                    fontFamily: style.FONT_MEDIUM,
                  }}
                >
                  Employee Name: {' '}
                  <span style={{color: "grey",fontFamily: style.FONT_MEDIUM,fontSize:'14px'}}>{compensationData?.employeeName}</span>
                </Typography>
                <Typography
                  sx={{
                    fontSize: '1rem',
                    minHeight: '20px',
                    textAlign: 'left',
                    fontFamily: style.FONT_MEDIUM,
                  }}
                >
                  Compensation Name: <span style={{color: "grey",fontFamily: style.FONT_MEDIUM,fontSize:'14px'}}>{compensationData?.compensationName || 'NA'}</span>
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12}>
                <SideHeadings>ALLOWANCES</SideHeadings>
              </Grid>

              <Grid item xs={12} sm={12}>
                <TableContainer
                  component={Paper}
                  style={{ overflowX: 'auto', display: 'flex', border: '0.1px solid grey' }}
                >
                  <Table sx={{ width: '100%' }} aria-label='customized table'>
                    <TableHead>
                      <StyledTableCell rowSpan={2} align='center'>
                        Component
                      </StyledTableCell>
                      <StyledTableCell align='right'>
                        <div style={divStyle}>
                          Monthly (<CurrencyRupeeIcon sx={{ fontSize: '15px' }} />)
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align='right'>
                        <div style={divStyle}>
                          Annual (<CurrencyRupeeIcon sx={{ fontSize: '15px' }} />)
                        </div>
                      </StyledTableCell>
                    </TableHead>
                    <TableBody>
                      {compensationData?.Allowances?.CompensationDetails?.map(
                        (compensation: any, index: number) => (
                          <StyledTableRow key={`${compensation.component}`}>
                            <StyledTableCell component='th' scope='row' align='left'>
                              {compensation.component}
                            </StyledTableCell>
                            <StyledTableCell align='right'>
                              {compensation?.monthlyPayPeriodAmount
                                ? numberFormat(compensation?.monthlyPayPeriodAmount)
                                : 0}
                            </StyledTableCell>
                            <StyledTableCell align='right'>
                              {compensation?.yearlyPayPeriodAmount
                                ? numberFormat(compensation?.yearlyPayPeriodAmount)
                                : 0}
                            </StyledTableCell>
                          </StyledTableRow>
                        ),
                      )}
                      {
                        <StyledTableRow>
                          <StyledTableCell component='th' scope='row' align='left'>
                            {'Total Gross Salary'}
                          </StyledTableCell>
                          <StyledTableCell align='right'>
                            {compensationData?.Allowances?.totalMonthlyGrossSalary
                              ? numberFormat(compensationData?.Allowances?.totalMonthlyGrossSalary)
                              : 0}
                          </StyledTableCell>
                          <StyledTableCell align='right'>
                            {compensationData?.Allowances?.totalYearlyGrossSalary
                              ? numberFormat(compensationData?.Allowances?.totalYearlyGrossSalary)
                              : 0}
                          </StyledTableCell>
                        </StyledTableRow>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid item xs={12} sm={12}>
                <SideHeadings>COMPANY CONTRIBUTIONS</SideHeadings>
              </Grid>

              <Grid item xs={12} sm={12}>
                <TableContainer
                  component={Paper}
                  style={{ overflowX: 'auto', display: 'flex', border: '0.1px solid grey' }}
                >
                  <Table sx={{ width: '100%' }} aria-label='customized table'>
                    <TableHead>
                      <StyledTableCell rowSpan={2} align='center'>
                        Retiral and Other Benefits
                      </StyledTableCell>
                      <StyledTableCell align='right'>
                        <div style={divStyle}>
                          Yearly (<CurrencyRupeeIcon sx={{ fontSize: '15px' }} />)
                        </div>
                      </StyledTableCell>
                    </TableHead>
                    <TableBody>
                      {compensationData?.CompanyContributions?.contributionDetails?.map(
                        (compensation: any, index: number) => (
                          <StyledTableRow key={`${compensation.contributionName}`}>
                            <StyledTableCell component='th' scope='row' align='left'>
                              {compensation?.contributionName}
                            </StyledTableCell>
                            <StyledTableCell align='right'>
                              {compensation?.yearlyPayPeriodAmount
                                ? numberFormat(compensation?.yearlyPayPeriodAmount)
                                : 0}
                            </StyledTableCell>
                          </StyledTableRow>
                        ),
                      )}
                      <StyledTableRow>
                        <StyledTableCell component='th' scope='row' align='left'>
                          {'Total Salary'}
                        </StyledTableCell>
                        <StyledTableCell align='right'>
                          {compensationData?.totalSalary
                            ? numberFormat(compensationData?.totalSalary)
                            : 0}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </div>
          </Grid>
        </Grid>
      ) : (
        <Typography variant='h4'>No record found</Typography>
      )}
    </Box>
  )
}

const AdminCompensation = (props: any) => {
  const { compensatiosalarySlipnData, fetchCompensationTypeData, loaderState, compensationData } =
    props
  const userId: any = useLocation()?.state

  const navigate = useNavigate()

  useEffect(() => {
    fetchCompensationTypeData({ userId: userId })
  }, [])

  return (
    <StyledPaper elevation={0}>
      {loaderState && (
        <Dialog open={loaderState} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      )}
      <Box sx={{ textAlign: 'left', width: '100%' }}>
        <Box
          onClick={() => navigate(-1)}
          sx={{ float: 'right', mt: '-20px', mr: '-50px', cursor: 'pointer' }}
        >
          <ArrowBack />
        </Box>
      </Box>

      {!loaderState && !Object.keys(compensationData).length ? (
        <div
          style={{
            backgroundColor: '#ffffff',
            width: '100%',
            height: '18em',
            overflow: 'auto',
            paddingTop: '10em',
          }}
        >
          <Typography variant='h4' style={{ textAlign: 'center' }}>
            No compensation found
          </Typography>
          <Typography variant='h4' style={{ textAlign: 'center' }}>
            Please contact HR Team for more information
          </Typography>
        </div>
      ) : null}
      {/* To check data is present before rendering the compensation */}
      {Object.keys(compensationData).length ? (
        <div
          style={{
            backgroundColor: 'rgb(231, 235, 240)',
            width: '100%',
            overflow: 'auto',
          }}
        >
          <Grid container spacing={3}>
            <Box sx={{ textAlign: 'left' }}></Box>
            <Grid item xs={12}>
              <CompensationDetails
                userId={userId}
                compensationData={compensationData}
                fetchCompensationTypeData={fetchCompensationTypeData}
              />
            </Grid>
          </Grid>
        </div>
      ) : null}
    </StyledPaper>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    compensationData: fetchUserDetailsEntities.fetchUserData(state).getCompensationInfo,
    loaderState: fetchUserDetailsUI.fetchUserData(state).isEmpCompensationLoader,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchCompensationTypeData: (data: any) => dispatch(fetchCompensation.request(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminCompensation)
