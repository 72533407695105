import React, { useEffect } from 'react'
import ApexCharts from 'apexcharts'

// Predefined color palette for the first 2 colors
const predefinedColors = [
  '#FF5733', // Certified Users
  '#33FF57', // Uncertified Users
]

const CertifiedUserChart = (props: any) => {
  const { myChartData } = props

  useEffect(() => {
    if (myChartData) {
      const certifiedUsers = myChartData.certifiedUsers || 0
      const totalUsers = myChartData.totalUsers || 0
      const unCertifiedUsers = totalUsers - certifiedUsers

      // Calculate percentage for certified and uncertified users
      const certifiedPercentage = ((certifiedUsers / totalUsers) * 100).toFixed(2)
      const unCertifiedPercentage = ((unCertifiedUsers / totalUsers) * 100).toFixed(2)

      const chartData = [
        { name: 'Certified Employees', percentage: certifiedPercentage, count: certifiedUsers },
        {
          name: 'Uncertified Employees',
          percentage: unCertifiedPercentage,
          count: totalUsers - certifiedUsers,
        },
      ]

      const names = chartData.map((item) => item.name)
      const percentages = chartData.map((item) => parseFloat(item.percentage))
      const counts = chartData.map((item) => item.count)
      const colors = predefinedColors

      const options = {
        series: percentages,
        chart: {
          width: 355,
          height: 355,
          type: 'donut',
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        fill: {
          type: 'gradient',
        },
        labels: names,
        colors: colors,
        tooltip: {
          custom: function ({ seriesIndex }: any) {
            return `
              <div style="
                padding: 12px 16px;
                background-color: #2c3e50;  /* Darker blue-gray background */
                color: #ecf0f1;  /* Light font color */
                border-radius: 10px;
                box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
                font-family: 'Roboto', sans-serif;
                max-width: 220px;
                font-size: 14px;
              ">
                <strong>${names[seriesIndex]}</strong><br/>
                <strong>Count: ${chartData[seriesIndex].count}</strong><br/>
                <strong>Percentage: ${chartData[seriesIndex].percentage}%</strong>
             
              </div>
            `
          },
        },
        legend: {
          position: 'bottom',
        },
      }

      const chart = new ApexCharts(document.querySelector('#piechartDomainCerti'), options)
      chart.render()

      return () => {
        chart.destroy()
      }
    }
  }, [myChartData])

  return (
    <div
      id='piechartDomainCerti'
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '300px',
        marginTop: '25px',
        marginLeft: '-30px',
      }}
    />
  )
}

export default CertifiedUserChart
