import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormLabel,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import { HeaderHeading, loaderProps } from '../Common/CommonStyles'
import { Dispatch } from 'redux'
import { RootState } from '../../configureStore'
import { connect, useSelector } from 'react-redux'
import {
  downloadCertificate,
  fetchBackInfo,
  fetchCertificateInfo,
  fetchQualification,
  fetchQualificationSkill,
  uploadCertificate,
} from '../../actions'
import {
  dashboardEntity,
  employeePortalEntity,
  dashboardUI,
  employeePortalUI,
} from '../../reducers'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import CircularProgress from '@mui/material/CircularProgress'
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone'
import moment from 'moment'
import CloseIcon from '@mui/icons-material/Close'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import style from '../../utils/styles.json'

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '92%',
  padding: '30px 25px 25px 25px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: '#FFFFFF',
  opacity: '1',
  margin: 'auto',
  border: '1px solid #DDDDDD',
  marginTop: '20px',
}))

const HeadingPaper = styled(Paper)(({ theme }) => ({
  width: '92%',
  padding: '15px 25px 25px 25px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: style.PRIMARY_COLOR,
  color: '#FFFFFF',
  opacity: '1',
  margin: '0px auto 15px auto',
}))

const SelectField = styled(Select)(({ theme }) => ({
  width: '171px',
  height: '38px',
  borderRadius: '20px',
  '& .MuiSelect-select': {
    fontSize: '13px',
    fontFamily: style.FONT_MEDIUM,
    cursor: 'not-allowed',
  },
}))

const SelectFieldBool = styled(Select)(({ theme }) => ({
  width: '124px',
  height: '38px',
  borderRadius: '20px',
  '& .MuiSelect-select': {
    fontSize: '13px',
    fontFamily: style.FONT_MEDIUM,
    cursor: 'not-allowed',
  },
}))

const InputField = styled(TextField)({
  margin: '0',
  height: 'auto',
  borderRadius: '20px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
  '& .MuiOutlinedInput-input': {
    fontSize: '13px',
    fontFamily: style.FONT_MEDIUM,
    borderRadius: '20px',
    cursor: 'not-allowed',
  },
  '& .MuiFormLabel-asterisk': {
    display: 'none',
  },
})

const Label = styled(FormLabel)({
  fontFamily: style.FONT_BOLD,
  fontSize: '14px',
  letterSpacing: '0px',
  opacity: '1',
})

const HeadingLabel = styled(FormLabel)({
  fontFamily: style.FONT_BOLD,
  fontSize: '13px',
  letterSpacing: '0px',
  color: '#000000',
  opacity: '1',
})
const ActionButton = styled(Button)(() => ({
  fontSize: '13px',
  height: '42px',
  float: 'right',
  marginTop: '15px',
  borderRadius: '20px',
  padding: '5px 20px',
  fontFamily: style.FONT_MEDIUM,
}))

const Heading = () => {
  return (
    <HeadingPaper elevation={3}>
      <Grid container spacing={2}>
        <Grid item xs={2} sm={2}>
          <HeadingLabel>Qualification Or Skillset</HeadingLabel>
        </Grid>
        <Grid item xs={1.5} sm={1.5}>
          <HeadingLabel>Graduated</HeadingLabel>
        </Grid>
        <Grid item xs={2} sm={2}>
          <HeadingLabel>Passing Year</HeadingLabel>
        </Grid>
        <Grid item xs={3} sm={3}>
          <HeadingLabel>College/University</HeadingLabel>
        </Grid>
        <Grid item xs={2} sm={2}>
          <HeadingLabel>Branch/Subject</HeadingLabel>
        </Grid>
        <Grid item xs={1.5} sm={1.5}>
          <HeadingLabel>Percentage/CGPA</HeadingLabel>
        </Grid>
      </Grid>
    </HeadingPaper>
  )
}

const Graduate = (props: any) => {
  const [open, setOpen] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const validationSchema = Yup.object({
    title: Yup.string(),
    description: Yup.string(),
  })

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
    },
    validationSchema,
    onSubmit: (values) => {},
  })

  const handleFileChange = (event: any) => {
    const file = event.target.files[0]

    if (file) {
      if (
        file.type === 'video/mp4' ||
        file.type === 'video/mpeg' ||
        file.type === 'video/quicktime' ||
        file.type === 'video/x-matroska'
      ) {
        event.target.value = ''
        setSelectedFile(null)
      } else {
        setSelectedFile(file)
      }
    }
  }

  let name: any = []
  let data =
    Object.keys(props.userData).length &&
    props.userData.educationalInfo.filter((item: any) => item.id_qualification_set === 1)
  data &&
    data.map((item: any) => {
      let skill_name = Object.keys(item).length > 0 ? item.skill_name : ''
      let qualification_data =
        props.qualificationSkill?.length &&
        props.qualificationSkill.find((e: any) => e.id === skill_name)
      let qualification_skill_name =
        Object.keys(qualification_data).length > 0
          ? qualification_data.qualification_skill_name
          : ''
      name.push(qualification_skill_name)
      return null
    })

  let name1: any = []
  let data1 =
    Object.keys(props.userData).length &&
    props.userData.educationalInfo.filter((item: any) => item.id_qualification_set === 2)
  data1 &&
    data1.map((item: any) => {
      let skill_name = Object.keys(item).length > 0 ? item.skill_name : ''
      let qualification_data =
        props.qualificationSkill?.length &&
        props.qualificationSkill.find((e: any) => e.id === skill_name)
      let qualification_skill_name =
        Object.keys(qualification_data).length > 0
          ? qualification_data.qualification_skill_name
          : ''
      name1.push(qualification_skill_name)
      return null
    })

  let name2: any = []
  let data2 =
    Object.keys(props.userData).length &&
    props.userData.educationalInfo.filter((item: any) => item.id_qualification_set === 3)
  data2 &&
    data2.map((item: any) => {
      let skill_name = Object.keys(item).length > 0 ? item.skill_name : ''
      let qualification_data =
        props.qualificationSkill?.length &&
        props.qualificationSkill.find((e: any) => e.id === skill_name)
      let qualification_skill_name =
        Object.keys(qualification_data).length > 0
          ? qualification_data.qualification_skill_name
          : ''
      name2.push(qualification_skill_name)
      return null
    })

  let name3: any = []
  let data3 =
    Object.keys(props.userData).length &&
    props.userData.educationalInfo.filter((item: any) => item.id_qualification_set === 4)
  data3 &&
    data3.map((item: any) => {
      let skill_name = Object.keys(item).length > 0 ? item.skill_name : ''
      let qualification_data =
        props.qualificationSkill?.length &&
        props.qualificationSkill.find((e: any) => e.id === skill_name)
      let qualification_skill_name =
        Object.keys(qualification_data).length > 0
          ? qualification_data.qualification_skill_name
          : ''
      name3.push(qualification_skill_name)
      return null
    })

  let name4: any = []
  let data4 =
    Object.keys(props.userData).length &&
    props.userData.educationalInfo.filter((item: any) => item.id_qualification_set === 5)
  data4 &&
    data4.map((item: any) => {
      let skill_name = Object.keys(item).length > 0 ? item.skill_name : ''

      let qualification_data =
        props.qualificationSkill?.length &&
        props.qualificationSkill.find((e: any) => e.id === skill_name)
      let qualification_skill_name =
        Object.keys(qualification_data).length > 0
          ? qualification_data.qualification_skill_name
          : ''
      name4.push(qualification_skill_name)
      return null
    })

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setSelectedFile(null)
    formik.resetForm()
    props.uploadCertificateReset()
    setOpen(false)
  }

  const handleUploadCertificate = () => {
    if (selectedFile) {
      const formData = {
        file: selectedFile,
        description: formik.values.description,
        title: formik.values.title,
        id_user: props.userInfo.id,
      }
      props.uploadCertificate(formData)
    }
  }

  const handleDownload = (id: number, filename: string) => {
    props.downloadCertificate({ id: id, filename: filename })
  }

  useEffect(() => {
    if (props.downloadCertificateData?.url) {
      const link = document.createElement('a')
      link.href = props.downloadCertificateData?.url
      link?.setAttribute('download', props.downloadCertificateData?.url)
      document.body.appendChild(link)
      link?.click()
      link?.parentNode?.removeChild(link)
      toast.success('File downloaded successfully.')
      props.downloadCertificateReset()
    }
  }, [props.downloadCertificateData])

  useEffect(() => {
    if (props?.uploadCertificateSuccess?.id) {
      toast.success('Document uploaded successfully')
      handleClose()
    }
  }, [props.uploadCertificateSuccess])

  return (
    <>
      <StyledPaper elevation={3}>
        {/* <HeaderHeading sx={{ marginBottom: '30px' }}>Background Information</HeaderHeading> */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            position: 'relative',
            marginBottom: '30px',
          }}
        >
          {/* Left-aligned Search Bar */}
          <Box></Box>

          {/* Centered Typography */}
          <HeaderHeading
            sx={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              marginBottom: '30px',
            }}
          >
            Background Information
          </HeaderHeading>

          {/* Right-aligned Dropdown */}
          <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}>
            <ActionButton
              variant='outlined'
              startIcon={<AddTwoToneIcon sx={{ width: 24, height: 24 }} />}
              onClick={handleClickOpen}
            >
              UPLOAD CERTIFICATE
            </ActionButton>
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={2} sm={2}>
            <HeadingLabel>Qualification Or Skillset</HeadingLabel>
          </Grid>
          <Grid item xs={1.5} sm={1.5}>
            <HeadingLabel>Graduated</HeadingLabel>
          </Grid>
          <Grid item xs={2} sm={2}>
            <HeadingLabel>Passing Year</HeadingLabel>
          </Grid>
          <Grid item xs={3} sm={3}>
            <HeadingLabel>College/University</HeadingLabel>
          </Grid>
          <Grid item xs={2} sm={2}>
            <HeadingLabel>Branch/Subject</HeadingLabel>
          </Grid>
          <Grid item xs={1.5} sm={1.5}>
            <HeadingLabel>Percentage/CGPA</HeadingLabel>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Label>Graduate school level</Label>
          </Grid>
          {Object.keys(props.userData).length > 0 &&
            props.userData.educationalInfo
              .filter((item: any) => item.id_qualification_set === 1)
              .map((item: any, index: any) => (
                <>
                  <Grid item xs={2} sm={2}>
                    <SelectField
                      id='demo-simple-select-readonly'
                      value={name[index]}
                      size='small'
                      inputProps={{ readOnly: true }}
                    >
                      {props.qualificationSkill
                        .filter(
                          (value: any) => value.id_qualification_set === item.id_qualification_set,
                        )
                        .map((filteredValue: any) => (
                          <MenuItem
                            key={filteredValue.id}
                            selected={item.skill_name === filteredValue.id}
                            value={filteredValue.qualification_skill_name}
                          >
                            {item.skill_name === filteredValue.id
                              ? filteredValue.qualification_skill_name
                              : null}
                          </MenuItem>
                        ))}
                    </SelectField>
                  </Grid>
                  <Grid item xs={1.5} sm={1.5}>
                    <SelectFieldBool
                      id='demo-simple-select-readonly'
                      value={item.graduated}
                      size='small'
                      inputProps={{ readOnly: true }}
                    >
                      <MenuItem value={item.graduated}>
                        <em>{item.graduated}</em>
                      </MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                    </SelectFieldBool>
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <InputField
                      defaultValue={item.year}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <InputField
                      defaultValue={item.degree}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <InputField
                      defaultValue={item.course_of_study}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={1.5} sm={1.5}>
                    <InputField
                      defaultValue={item.percentage}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </>
              ))}

          <Grid item xs={12} sm={12}>
            <Divider sx={{ marginTop: '8px' }} />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Label>Undergraduate school level</Label>
          </Grid>
          {Object.keys(props.userData).length > 0 &&
            props.userData.educationalInfo
              .filter((item: any) => item.id_qualification_set === 2)
              .map((item: any, index: any) => (
                <>
                  <Grid item xs={2} sm={2}>
                    <SelectField
                      id='demo-simple-select-readonly'
                      value={name1.length && name1[index]}
                      size='small'
                      inputProps={{ readOnly: true }}
                    >
                      <MenuItem value={item.skill_name}>
                        <em>{item.skill_name}</em>
                      </MenuItem>
                      <MenuItem value={name1[index]}>{name1[index]}</MenuItem>
                      <MenuItem value={2}>Value 2</MenuItem>
                      <MenuItem value={3}>Value 3</MenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item xs={1.5} sm={1.5}>
                    <SelectFieldBool
                      id='demo-simple-select-readonly'
                      value={item.graduated}
                      size='small'
                      inputProps={{ readOnly: true }}
                    >
                      <MenuItem value={item.graduated}>
                        <em>{item.graduated}</em>
                      </MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                    </SelectFieldBool>
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <InputField
                      defaultValue={item.year}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <InputField
                      defaultValue={item.degree}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <InputField
                      defaultValue={item.course_of_study}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={1.5} sm={1.5}>
                    <InputField
                      defaultValue={item.percentage}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </>
              ))}
          <Grid item xs={12} sm={12}>
            <Divider sx={{ marginTop: '8px' }} />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Label>High school level</Label>
          </Grid>
          {Object.keys(props.userData).length > 0 &&
            props.userData.educationalInfo
              .filter((item: any) => item.id_qualification_set === 3)
              .map((item: any, index: any) => (
                <>
                  <Grid item xs={2} sm={2}>
                    <SelectField
                      id='demo-simple-select-readonly'
                      value={name2[index]}
                      size='small'
                      inputProps={{ readOnly: true }}
                    >
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={name2[index]}>{name2[index]}</MenuItem>
                      <MenuItem value={2}>Value 2</MenuItem>
                      <MenuItem value={3}>Value 3</MenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item xs={1.5} sm={1.5}>
                    <SelectFieldBool
                      id='demo-simple-select-readonly'
                      value={item.graduated}
                      size='small'
                      inputProps={{ readOnly: true }}
                    >
                      <MenuItem value={item.graduated}>
                        <em>{item.graduated}</em>
                      </MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                    </SelectFieldBool>
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <InputField
                      defaultValue={item.year}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <InputField
                      defaultValue={item.degree}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <InputField
                      defaultValue={item.course_of_study}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={1.5} sm={1.5}>
                    <InputField
                      defaultValue={item.percentage}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </>
              ))}
          <Grid item xs={12} sm={12}>
            <Divider sx={{ marginTop: '8px' }} />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Label>Other</Label>
          </Grid>
          {Object.keys(props.userData).length > 0 &&
            props.userData.educationalInfo
              .filter((item: any) => item.id_qualification_set === 4)
              .map((item: any, index: any) => (
                <>
                  <Grid item xs={2} sm={2}>
                    <SelectField
                      id='demo-simple-select-readonly'
                      value={name3.length && name3[index]}
                      size='small'
                      inputProps={{ readOnly: true }}
                    >
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={name3[index]}>{name3[index]}</MenuItem>
                      <MenuItem value={2}>Value 2</MenuItem>
                      <MenuItem value={3}>Value 3</MenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item xs={1.5} sm={1.5}>
                    <SelectFieldBool
                      id='demo-simple-select-readonly'
                      value={item.graduated}
                      size='small'
                      inputProps={{ readOnly: true }}
                    >
                      <MenuItem value={item.graduated}>
                        <em>{item.graduated}</em>
                      </MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                    </SelectFieldBool>
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <InputField
                      defaultValue={item.year}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <InputField
                      defaultValue={item.degree}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={2} sm={2}>
                    <InputField
                      defaultValue={item.course_of_study}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={1.5} sm={1.5}>
                    <InputField
                      defaultValue={item.percentage}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </>
              ))}
          <Grid item xs={12} sm={12}>
            <Divider sx={{ marginTop: '8px' }} />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Label>License</Label>
          </Grid>
          {Object.keys(props.userData).length > 0 &&
            props.userData.educationalInfo
              .filter((item: any) => item.id_qualification_set === 5)
              .map((item: any, index: any) => (
                <>
                  <Grid item xs={2} sm={2}>
                    <SelectField
                      id='demo-simple-select-readonly'
                      value={name4.length && name4[index]}
                      size='small'
                      inputProps={{ readOnly: true }}
                    >
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={name4[index]}>{name4[index]}</MenuItem>
                      <MenuItem value={2}>Value 2</MenuItem>
                      <MenuItem value={3}>Value 3</MenuItem>
                    </SelectField>
                  </Grid>

                  <Grid item xs={1.5} sm={1.5}>
                    <SelectFieldBool
                      id='demo-simple-select-readonly'
                      value={item.graduated}
                      size='small'
                      inputProps={{ readOnly: true }}
                    >
                      <MenuItem value={item.graduated}>
                        <em>{item.graduated}</em>
                      </MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                    </SelectFieldBool>
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <InputField
                      defaultValue={item.year}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <InputField
                      defaultValue={item.degree}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <InputField
                      defaultValue={item.course_of_study}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={1.5} sm={1.5}>
                    <InputField
                      defaultValue={item.percentage}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </>
              ))}

          <Grid item xs={12} sm={12}>
            <Divider sx={{ marginTop: '8px' }} />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Label>Certificates</Label>
            <TableContainer component={Paper} sx={{ marginTop: '15px' }} elevation={2}>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: '30%', fontFamily: style.FONT_BOLD }}>
                      Title
                    </TableCell>
                    <TableCell sx={{ width: '60%', fontFamily: style.FONT_BOLD }}>
                      Description
                    </TableCell>
                    <TableCell align='center' sx={{ fontFamily: style.FONT_BOLD }}>
                      Download
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.certificatInfo?.length > 0 ? (
                    props.certificatInfo.map(
                      (info: {
                        title: string
                        id: number
                        description: string
                        fileName: string
                      }) => (
                        <TableRow
                          key={info.title}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell
                            component='th'
                            scope='row'
                            sx={{ fontFamily: 'Montserrat-Regular', fontSize: '13px' }}
                          >
                            {info.title}
                          </TableCell>
                          <TableCell sx={{ fontFamily: 'Montserrat-Regular', fontSize: '13px' }}>
                            {info.description}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center', fontFamily: 'Montserrat-Regular' }}>
                            <FileDownloadOutlinedIcon
                              sx={{
                                color: '#193c6c',
                                cursor: 'pointer',
                                '&:hover': {
                                  transform: 'scale(1.1)',
                                },
                              }}
                              onClick={() => handleDownload(info.id, info.fileName)}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} sx={{ textAlign: 'center', fontFamily: 'Montserrat-Regular', fontSize: '13px' }}>
                        No data available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </StyledPaper>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={false}
        PaperProps={{
          style: {
            width: '30%',
            maxWidth: '30%',
          },
        }}
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>
          Upload Certificate
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: 'absolute !important',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider className='divider' />
        <DialogContent sx={{ padding: '10px 15px !important' }}>
          <div style={{ width: '100%', marginTop: '6px' }}>
            <Grid item alignItems='center'>
              <InputField
                size='small'
                fullWidth
                id='title'
                name='title'
                label='Title'
                required={false}
                value={formik.values.title}
                onChange={formik.handleChange}
                sx={{ '& .MuiOutlinedInput-input': { cursor: 'pointer' } }}
              />
            </Grid>
            <Grid item alignItems='center'>
              <InputField
                size='small'
                fullWidth
                multiline={true}
                maxRows={4}
                id='description'
                name='description'
                label='Description'
                sx={{ marginTop: '10px', '& .MuiOutlinedInput-input': { cursor: 'pointer' } }}
                required={false}
                value={formik.values.description}
                onChange={formik.handleChange}
              />
            </Grid>
            <input
              type='file'
              id={'id'}
              style={{ display: 'none' }}
              // disabled={isRequestId}
              onChange={handleFileChange}
              accept='image/pjpeg,
                  image/jpeg,
                  image/png,
                  application/pdf,
                  application/msword,
                  application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                  application/vnd.oasis.opendocument.text,
                  application/vnd.ms-excel,
                  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            />
            <label htmlFor={'id'}>
              <Button
                // disabled={isRequestId}
                sx={{
                  fontSize: '15px',
                  padding: '6px 22px',
                  borderRadius: '24px',
                  fontFamily: style.FONT_MEDIUM,
                  marginTop: '10px',
                }}
                variant='contained'
                component='span'
              >
                Upload File
              </Button>
            </label>
            <Typography
              sx={{
                fontSize: '13px',
                marginLeft: '10px',
                marginTop: '10px',
                fontFamily: style.FONT_MEDIUM,
                maxWidth: '200px',
              }}
              variant='body1'
              color='textSecondary'
            >
              {selectedFile ? selectedFile.name : 'No file selected'}
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'right',
              marginTop: '10px',
            }}
          >
            <Button
              // className={classes.cancelbtn}
              // variant='contained'
              color='primary'
              sx={{
                borderRadius: '20px!important',
                backgroundColor: '#e0e0e0!important',
                color: '#000000!important',
                marginRight: '10px!important',
                fontFamily: `${style.FONT_BOLD}!important`,
                fontSize: '14px!important',
                width: '100px!important',
              }}
              onClick={() => handleClose()}
            >
              Cancel
            </Button>
            <Button
              // className={classes.updateButton}
              variant='contained'
              sx={{
                borderRadius: '20px',
                fontFamily: style.FONT_BOLD,
                fontSize: '14px',
                minWidth: '50px',
              }}
              color='primary'
              onClick={() => handleUploadCertificate()}
            // disabled={isUpdateDisabled}
            >
              Upload
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

const Undergraduate = () => {
  const [year, setYear] = React.useState(2)
  const handleChange = (event: any) => {
    setYear(event.target.value)
  }

  return (
    <StyledPaper elevation={3}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Label>UNDERGRADUATE SCHOOL LEVEL</Label>
        </Grid>
        <Grid item xs={2} sm={2}>
          <SelectField
            id='demo-simple-select-readonly'
            value={year}
            size='small'
            onChange={handleChange}
            inputProps={{ readOnly: true }}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            <MenuItem value={1}>Value 1</MenuItem>
            <MenuItem value={2}>Value 2</MenuItem>
            <MenuItem value={3}>Value 3</MenuItem>
          </SelectField>
        </Grid>
        <Grid item xs={1.5} sm={1.5}>
          <SelectFieldBool
            id='demo-simple-select-readonly'
            value={year}
            size='small'
            onChange={handleChange}
            inputProps={{ readOnly: true }}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            <MenuItem value={1}>Yes</MenuItem>
            <MenuItem value={2}>No</MenuItem>
          </SelectFieldBool>
        </Grid>
        <Grid item xs={2} sm={2}>
          <InputField
            defaultValue='2018'
            size='small'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={3} sm={3}>
          <InputField
            defaultValue='College Name'
            size='small'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={2} sm={2}>
          <InputField
            defaultValue='Science'
            size='small'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={1.5} sm={1.5}>
          <InputField
            defaultValue='80'
            size='small'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      </Grid>
    </StyledPaper>
  )
}

const Highschool = () => {
  const [year, setYear] = React.useState(1)
  const handleChange = (event: any) => {
    setYear(event.target.value)
  }

  return (
    <StyledPaper elevation={3}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Label>HIGH SCHOOL LEVEL</Label>
        </Grid>
        <Grid item xs={2} sm={2}>
          <SelectField
            id='demo-simple-select-readonly'
            value={year}
            size='small'
            onChange={handleChange}
            inputProps={{ readOnly: true }}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            <MenuItem value={1}>Value 1</MenuItem>
            <MenuItem value={2}>Value 2</MenuItem>
            <MenuItem value={3}>Value 3</MenuItem>
          </SelectField>
        </Grid>
        <Grid item xs={1.5} sm={1.5}>
          <SelectFieldBool
            id='demo-simple-select-readonly'
            value={year}
            size='small'
            onChange={handleChange}
            inputProps={{ readOnly: true }}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            <MenuItem value={1}>Yes</MenuItem>
            <MenuItem value={2}>No</MenuItem>
          </SelectFieldBool>
        </Grid>
        <Grid item xs={2} sm={2}>
          <InputField
            defaultValue='2016'
            size='small'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={3} sm={3}>
          <InputField
            defaultValue='College Name'
            size='small'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={2} sm={2}>
          <InputField
            defaultValue='Math'
            size='small'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={1.5} sm={1.5}>
          <InputField
            defaultValue='85'
            size='small'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      </Grid>
    </StyledPaper>
  )
}

const Other = () => {
  return (
    <StyledPaper elevation={3}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Label>OTHER</Label>
        </Grid>
        <Grid item xs={2} sm={2}>
          <Select
            labelId='demo-simple-select-helper-label'
            id='demo-simple-select-helper'
            size='small'
            style={{ width: '162px' }}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>value</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={1.5} sm={1.5}>
          <Select
            labelId='demo-simple-select-helper-label'
            id='demo-simple-select-helper'
            size='small'
            style={{ width: '116px' }}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>value</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={2} sm={2}>
          <InputField size='small' />
        </Grid>
        <Grid item xs={3} sm={3}>
          <InputField size='small' />
        </Grid>
        <Grid item xs={2} sm={2}>
          <InputField size='small' />
        </Grid>
        <Grid item xs={1.5} sm={1.5}>
          <InputField size='small' />
        </Grid>
      </Grid>
    </StyledPaper>
  )
}

const ProfessionalCert = () => {
  return (
    <StyledPaper elevation={3}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Label>LICENSE/ PROFESSIONAL CERT</Label>
        </Grid>
        <Grid item xs={2} sm={2}>
          <Select
            labelId='demo-simple-select-helper-label'
            id='demo-simple-select-helper'
            size='small'
            style={{ width: '162px' }}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>value</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={1.5} sm={1.5}>
          <Select
            labelId='demo-simple-select-helper-label'
            id='demo-simple-select-helper'
            size='small'
            style={{ width: '116px' }}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>value</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={2} sm={2}>
          <InputField size='small' />
        </Grid>
        <Grid item xs={3} sm={3}>
          <InputField size='small' />
        </Grid>
        <Grid item xs={2} sm={2}>
          <InputField size='small' />
        </Grid>
        <Grid item xs={1.5} sm={1.5}>
          <InputField size='small' />
        </Grid>
      </Grid>
    </StyledPaper>
  )
}

const PrevPosition = (props: any) => {
  return (
    <>
      {props.userData.previousPositionsHistory?.map((item: any, index: any) => (
        <StyledPaper elevation={3} key={item?.telephone}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Label>PREVIOUS POSITIONS HELD</Label>
            </Grid>
            <Grid item xs={2} sm={2}>
              <InputField
                size='small'
                inputProps={{ readOnly: true }}
                defaultValue={item?.position ?? 'NA'}
                label='Position'
              />
            </Grid>
            <Grid item xs={2} sm={2}>
              <InputField
                size='small'
                inputProps={{ readOnly: true }}
                defaultValue={item?.company ?? 'NA'}
                label='Company'
              />
            </Grid>
            <Grid item xs={2} sm={2}>
              <InputField
                size='small'
                inputProps={{ readOnly: true }}
                defaultValue={moment(item?.from_year).format('DD-MM-YYYY') ?? 'NA'}
                label='From'
              />
            </Grid>
            <Grid item xs={2} sm={2}>
              <InputField
                size='small'
                inputProps={{ readOnly: true }}
                defaultValue={moment(item?.to_year).format('DD-MM-YYYY') ?? 'NA'}
                label='To'
              />
            </Grid>
            <Grid item xs={2} sm={2}>
              <InputField
                size='small'
                inputProps={{ readOnly: true }}
                defaultValue={item?.supervisor ?? 'NA'}
                label='Salary per month'
              />
            </Grid>
            <Grid item xs={2} sm={2}>
              <InputField
                size='small'
                inputProps={{ readOnly: true }}
                defaultValue={item?.telephone ?? 'NA'}
                label='Annual Salary'
              />
            </Grid>
          </Grid>
        </StyledPaper>
      ))}
    </>
  )
}

const BackgroundInfo = (props: any) => {
  const {
    fetchQualification,
    fetchQualificationSkill,
    fetchUserData,
    isGetBackInfoData,
    qualificationData,
    qualificationSkill,
    userData,
    userInfo,
    uploadCertificate,
    fetchCertificateInfo,
    certificatInfo,
    uploadCertificateReset,
    downloadCertificate,
    downloadCertificateData,
    downloadCertificateReset,
    isDownloadingCertificate,
    isUploadingCertificate,
    uploadCertificateSuccess,
  } = props

  useEffect(() => {
    fetchUserData()
    fetchQualification()
    fetchQualificationSkill()
  }, [])

  useEffect(() => {
    if (userInfo?.id && !isUploadingCertificate) {
      fetchCertificateInfo({ id: userInfo?.id })
    }
  }, [userInfo, isUploadingCertificate])

  if (isGetBackInfoData) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '20%',
        }}
      >
        <CircularProgress disableShrink />
      </div>
    )
  }
  return (
    <div
      style={{
        backgroundColor: 'rgb(231, 235, 240)',
        width: '100%',
        paddingBottom: '20px',
      }}
    >
      <Dialog
        open={isDownloadingCertificate || isUploadingCertificate}
        PaperProps={{ style: loaderProps }}
      >
        <CircularProgress color='secondary' />
      </Dialog>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Graduate
            userData={userData}
            userInfo={userInfo}
            qualificationData={qualificationData}
            qualificationSkill={qualificationSkill}
            uploadCertificate={uploadCertificate}
            uploadCertificateReset={uploadCertificateReset}
            certificatInfo={certificatInfo}
            downloadCertificate={downloadCertificate}
            downloadCertificateData={downloadCertificateData}
            downloadCertificateReset={downloadCertificateReset}
            uploadCertificateSuccess={uploadCertificateSuccess}
          />
          <PrevPosition userData={userData} />
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    userData: dashboardEntity.getDashboard(state).getBackInfoData,
    userInfo: dashboardEntity.getDashboard(state).getUserDetails,
    qualificationData: employeePortalEntity.getEmployeePortal(state).getQualification,
    qualificationSkill: employeePortalEntity.getEmployeePortal(state).getQualificationSkill,
    isGetBackInfoData: dashboardUI.getDashboard(state).isGetBackInfoData,
    certificatInfo: employeePortalEntity.getEmployeePortal(state).getCertificateInfo,
    downloadCertificateData: employeePortalEntity.getEmployeePortal(state).downloadCertificate,
    isDownloadingCertificate: employeePortalUI.getAllEmpPortalUI(state).isDownloadingCertificate,
    isUploadingCertificate: employeePortalUI.getAllEmpPortalUI(state).isUploadingCertificate,
    uploadCertificateSuccess:
      employeePortalEntity.getEmployeePortal(state).uploadCertificateSuccess,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchUserData: () => dispatch(fetchBackInfo.request()),
    fetchQualification: () => dispatch(fetchQualification.request()),
    fetchQualificationSkill: () => dispatch(fetchQualificationSkill.request()),
    uploadCertificate: (data: any) => dispatch(uploadCertificate.request({ data })),
    uploadCertificateReset: (data: any) => dispatch(uploadCertificate.reset()),
    fetchCertificateInfo: (data: any) => dispatch(fetchCertificateInfo.request({ data })),
    downloadCertificate: (data: any) => dispatch(downloadCertificate.request({ data })),
    downloadCertificateReset: () => dispatch(downloadCertificate.reset()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BackgroundInfo)
