import { combineReducers } from 'redux'
import { Actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import {DELETE_MANDATE_TYPE, ADD_EMP_BASED_ON_ROLE, ADD_NEW_PROJECT, CREATE_PROJECT_CUSTOMERS, FETCH_MANDATE_TYPE,  CREATE_MANDATE_TYPE,  DELETE_EMP_BASED_ON_ROLE, DELETE_PROJECT, DELETE_PROJECT_CUSTOMER, DESIGNATION_GRAPH_DATA, EDIT_PROJECT, FAILURE, FETCH_ALL_PROJECT_RESOURCE_REPORT, FETCH_NON_BILLABLE_RESOURCES, FETCH_PROJECT_CUSTOMERS_LIST, FETCH_PROJECT_DETAILS, FETCH_PROJECT_MANAGEMENT_REPORT, FETCH_PROJECT_QA_REPORT, FETCH_PROJECT_REPORTS, FETCH_PROJECT_TYPES, FETCH_WORKING_EMP, ORGANISATION_DESIGNATION_DATA, ORGANIZATION_DESIGNATION, PROJECT_GRAPH_DATA, REQUEST, RESET, RESTART_PROJECT, SUCCESS, UPDATE_NON_BILLABLE_RESOURCES, UPDATE_PROJECT, UPDATE_PROJECT_CUSTOMER, UPDATE_MANDATE_TYPE, FETCH_REPORTS, CREATE_DOMAIN_TYPE, UPDATE_DOMAIN_TYPE } from '../../actions/actiontypes'
import { toast } from 'react-toastify'


const ui = () => {


  const isGettingProjectsData = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_PROJECT_TYPES[SUCCESS]:
        return false
        case FETCH_PROJECT_TYPES[FAILURE]:
      case FETCH_PROJECT_TYPES[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isGettingDesignationGraphData = (state = false, action: Actions) => {
    switch (action.type) {
      case DESIGNATION_GRAPH_DATA[SUCCESS]:
        return false
        case DESIGNATION_GRAPH_DATA[FAILURE]:
      case DESIGNATION_GRAPH_DATA[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isGettingProjectTypes = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_PROJECT_TYPES[SUCCESS]:
        return false
      case FETCH_PROJECT_TYPES[FAILURE]:
      case FETCH_PROJECT_TYPES[REQUEST]:
      case FETCH_PROJECT_TYPES[RESET]:
        return true
      default:
        return state
    }
  }

  const isDeletingProject = (state = false, action: Actions) => {
    switch (action.type) {
      case DELETE_PROJECT[SUCCESS]:
        return true
      case DELETE_PROJECT[FAILURE]:
      case DELETE_PROJECT[REQUEST]:
      case DELETE_PROJECT[RESET]:
        return false
      default:
        return state
    }
  }

  const isRestartingProject = (state = false, action: Actions) => {
    switch (action.type) {
      case RESTART_PROJECT[SUCCESS]:
        return true
      case RESTART_PROJECT[FAILURE]:
      case RESTART_PROJECT[REQUEST]:
      case RESTART_PROJECT[RESET]:
        return false
      default:
        return state
    }
  }

  const isCreatingproject = (state = false, action: Actions) => {
    switch (action.type) {
      case ADD_NEW_PROJECT[SUCCESS]:
        return true
      case ADD_NEW_PROJECT[FAILURE]:
      case ADD_NEW_PROJECT[REQUEST]:
        return false
      case ADD_NEW_PROJECT[RESET]:
        return false
      default:
        return state
    }
  }

  const isProjectCreating = (state = false, action: Actions) => {
    switch (action.type) {
      case ADD_NEW_PROJECT[SUCCESS]:
        return false
      case ADD_NEW_PROJECT[FAILURE]:
      case ADD_NEW_PROJECT[REQUEST]:
        return true
      case ADD_NEW_PROJECT[RESET]:
        return false
      default:
        return state
    }
  }
  
  const isAddingEmp = (state = false, action: Actions) => {
    switch (action.type) {
      case ADD_EMP_BASED_ON_ROLE[SUCCESS]:
        return true
      case ADD_EMP_BASED_ON_ROLE[FAILURE]:
      case ADD_EMP_BASED_ON_ROLE[REQUEST]:
      case ADD_EMP_BASED_ON_ROLE[RESET]:
        return false
      default:
        return state
    }
  }

  const resetAddDeveloper = (state = false, action: Actions) => {
    switch (action.type) {
      case ADD_EMP_BASED_ON_ROLE[RESET]:
        return true
      case ADD_EMP_BASED_ON_ROLE[SUCCESS]:
      case ADD_EMP_BASED_ON_ROLE[FAILURE]:
      case ADD_EMP_BASED_ON_ROLE[REQUEST]:
        return false
      default:
        return state
    }
  }

  const resetCreateproject = (state = false, action: Actions) => {
    switch (action.type) {
      case ADD_NEW_PROJECT[RESET]:
        return true
      case ADD_NEW_PROJECT[SUCCESS]:
      case ADD_NEW_PROJECT[FAILURE]:
      case ADD_NEW_PROJECT[REQUEST]:
        return false
      default:
        return state
    }
  }


  const isProjectUpdated = (state = false, action: Actions) => {
    switch (action.type) {
      case UPDATE_PROJECT[SUCCESS]:
        return true
      case UPDATE_PROJECT[FAILURE]:
      case UPDATE_PROJECT[REQUEST]:
        return false
      case UPDATE_PROJECT[RESET]:
        return false
      default:
        return state
    }
  }

  const isProjectUpdating = (state = false, action: Actions) => {
    switch (action.type) {
      case UPDATE_PROJECT[SUCCESS]:
        return false
      case UPDATE_PROJECT[FAILURE]:
      case UPDATE_PROJECT[REQUEST]:
        return true
      case UPDATE_PROJECT[RESET]:
        return false
      default:
        return state
    }
  }

  const isRecordsdeleted = (state = false, action: Actions) => {
    switch (action.type) {
      case DELETE_EMP_BASED_ON_ROLE[SUCCESS]:
        return true
      case DELETE_EMP_BASED_ON_ROLE[FAILURE]:
      case DELETE_EMP_BASED_ON_ROLE[REQUEST]:
        return false
      case DELETE_EMP_BASED_ON_ROLE[RESET]:
        return false
      default:
        return state
    }
  }

  const isEditingProject = (state = false, action: Actions) => {
    switch (action.type) {
      case EDIT_PROJECT[SUCCESS]:
        return true
      case EDIT_PROJECT[FAILURE]:
      case EDIT_PROJECT[REQUEST]:
        return false
      case EDIT_PROJECT[RESET]:
        return false
      default:
        return state
    }
  }

  const isProjectEditing = (state = false, action: Actions) => {
    switch (action.type) {
      case EDIT_PROJECT[SUCCESS]:
        return false
      case EDIT_PROJECT[FAILURE]:
      case EDIT_PROJECT[REQUEST]:
        return true
      case EDIT_PROJECT[RESET]:
        return false
      default:
        return state
    }
  }

  const isGettingProjectReportsList = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_PROJECT_REPORTS[SUCCESS]:
        return false
      case FETCH_PROJECT_REPORTS[FAILURE]:
        return false
      case FETCH_PROJECT_REPORTS[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isGetProjectReportsListFailed = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_PROJECT_REPORTS[SUCCESS]:
      case FETCH_PROJECT_REPORTS[REQUEST]:
        return false
      case FETCH_PROJECT_REPORTS[FAILURE]:
        return true
      default:
        return state
    }
  }

  const isGettingReportsList = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_REPORTS[SUCCESS]:
        return false
      case FETCH_REPORTS[FAILURE]:
        return false
      case FETCH_REPORTS[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isGetReportsListFailed = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_REPORTS[SUCCESS]:
      case FETCH_REPORTS[REQUEST]:
        return false
      case FETCH_REPORTS[FAILURE]:
        return true
      default:
        return state
    }
  }

  const isLoadingStateTrue = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_ALL_PROJECT_RESOURCE_REPORT[SUCCESS]:
        return false
      case FETCH_ALL_PROJECT_RESOURCE_REPORT[REQUEST]:
        return true
      case FETCH_ALL_PROJECT_RESOURCE_REPORT[FAILURE]:
        return false
      default:
        return state
    }
  }
  const isGettingDesignationState = (state = false, action: Actions) => {
    switch (action.type) {
      case ORGANIZATION_DESIGNATION[SUCCESS]:
        return true
      case ORGANIZATION_DESIGNATION[FAILURE]:
        return true
      case ORGANIZATION_DESIGNATION[REQUEST]:
        return false
      default:
        return state
    }
  }

  const isGettingDesignationDataState = (state = false, action: Actions) => {
    switch (action.type) {
      case ORGANISATION_DESIGNATION_DATA[SUCCESS]:
        return true
      case ORGANISATION_DESIGNATION_DATA[FAILURE]:
        return true
      case ORGANISATION_DESIGNATION_DATA[REQUEST]:
        return false
      default:
        return state
    }
  }

  const isGettingProjectManagementReportsList = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_PROJECT_MANAGEMENT_REPORT[SUCCESS]:
        return false
      case FETCH_PROJECT_MANAGEMENT_REPORT[FAILURE]:
        return false
      case FETCH_PROJECT_MANAGEMENT_REPORT[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isGettingProjectQAReport = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_PROJECT_QA_REPORT[SUCCESS]:
        return false
      case FETCH_PROJECT_QA_REPORT[FAILURE]:
        return false
      case FETCH_PROJECT_QA_REPORT[REQUEST]:
        return true
      case FETCH_PROJECT_QA_REPORT[RESET]:
        return false
      default:
        return state
    }
  }
  const isGetNonBillableResources = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_NON_BILLABLE_RESOURCES[SUCCESS]:
        return false
      case FETCH_NON_BILLABLE_RESOURCES[FAILURE]:
        return false
      case FETCH_NON_BILLABLE_RESOURCES[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isUpdatedNonBillableResources = (state = false, action:Actions) =>{
    switch (action.type) {
      case UPDATE_NON_BILLABLE_RESOURCES[REQUEST]:
        return false
      case UPDATE_NON_BILLABLE_RESOURCES[SUCCESS]:
        return true
      case UPDATE_NON_BILLABLE_RESOURCES[FAILURE]:
        return false
      case UPDATE_NON_BILLABLE_RESOURCES[RESET]:
        return false
      default:
        return state
    }
  }
  const isGettingProjectCustomers = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_PROJECT_CUSTOMERS_LIST[SUCCESS]:
        return false
      case FETCH_PROJECT_CUSTOMERS_LIST[FAILURE]:
        return false
      case FETCH_PROJECT_CUSTOMERS_LIST[REQUEST]:
        return true
      case CREATE_PROJECT_CUSTOMERS[RESET]:
        return false
      default:
        return state
    }
  }

  const isProjectCustomersCreated = (state = false, action: Actions) => {
    switch (action.type) {
      case CREATE_PROJECT_CUSTOMERS[SUCCESS]:
        toast.success('Client created successfully')
        return true
      case CREATE_PROJECT_CUSTOMERS[FAILURE]:
        return false
      case CREATE_PROJECT_CUSTOMERS[RESET]:
        return false
      case CREATE_PROJECT_CUSTOMERS[REQUEST]:
        return false
      default:
        return state
    }
  }

  const isGettingMandateType = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_MANDATE_TYPE[SUCCESS]:
        return false
      case FETCH_MANDATE_TYPE[FAILURE]:
        return false
      case FETCH_MANDATE_TYPE[REQUEST]:
        return true
      case CREATE_MANDATE_TYPE[RESET]:
        return false
      default:
        return state
    }
  }

  const isMandateTypeCreated = (state = false, action: Actions) => {
    switch (action.type) {
      case CREATE_MANDATE_TYPE[SUCCESS]:
        toast.success('Mandate type created successfully')
        return true
      case CREATE_MANDATE_TYPE[FAILURE]:
        return false
      case CREATE_MANDATE_TYPE[RESET]:
        return false
      case CREATE_MANDATE_TYPE[REQUEST]:
        return false
      default:
        return state
    }
  }

  const isDomainTypeCreated = (state = false, action: Actions) => {
    switch (action.type) {
      case CREATE_DOMAIN_TYPE[SUCCESS]:
        toast.success('Domain name created successfully')
        return true
      case CREATE_DOMAIN_TYPE[FAILURE]:
        return false
      case CREATE_DOMAIN_TYPE[RESET]:
        return false
      case CREATE_DOMAIN_TYPE[REQUEST]:
        return false
      default:
        return state
    }
  }

  const isDomainTypeUpdated = (state = false, action:Actions) =>{
    switch (action.type) {
      case UPDATE_DOMAIN_TYPE[SUCCESS]:
        toast.success('Domain name updated successfully')
        return true
      case UPDATE_DOMAIN_TYPE[FAILURE]:
        return false
      case UPDATE_DOMAIN_TYPE[RESET]:
        return false
      case UPDATE_DOMAIN_TYPE[REQUEST]:
        return false
      default:
        return state
    }
  }
  
  const isMandateTypeDeleted = (state = false, action:Actions) =>{
    switch (action.type) {
      case DELETE_MANDATE_TYPE[SUCCESS]:
        toast.success('Mandate deleted successfully')
        return true
      case DELETE_MANDATE_TYPE[FAILURE]:
        return false
      case DELETE_MANDATE_TYPE[RESET]:
        return false
      case DELETE_MANDATE_TYPE[REQUEST]:
        return false
      default:
        return state
    }
  }

  const isMandateTypeUpdated = (state = false, action:Actions) =>{
    switch (action.type) {
      case UPDATE_MANDATE_TYPE[SUCCESS]:
        toast.success('Mandate type updated successfully')
        return true
      case UPDATE_MANDATE_TYPE[FAILURE]:
        return false
      case UPDATE_MANDATE_TYPE[RESET]:
        return false
      case UPDATE_MANDATE_TYPE[REQUEST]:
        return false
      default:
        return state
    }
  }

  const isProjectCustomerDeleted = (state = false, action:Actions) =>{
    switch (action.type) {
      case DELETE_PROJECT_CUSTOMER[SUCCESS]:
        toast.success('Client deleted successfully')
        return true
      case DELETE_PROJECT_CUSTOMER[FAILURE]:
        return false
      case DELETE_PROJECT_CUSTOMER[RESET]:
        return false
      case DELETE_PROJECT_CUSTOMER[REQUEST]:
        return false
      default:
        return state
    }
  }

  const isProjectCustomerUpdated = (state = false, action:Actions) =>{
    switch (action.type) {
      case UPDATE_PROJECT_CUSTOMER[SUCCESS]:
        toast.success('Client updated successfully')
        return true
      case UPDATE_PROJECT_CUSTOMER[FAILURE]:
        return false
      case UPDATE_PROJECT_CUSTOMER[RESET]:
        return false
      case UPDATE_PROJECT_CUSTOMER[REQUEST]:
        return false
      default:
        return state
    }
  }

  const isGettingProjectDetailsData = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_PROJECT_DETAILS[SUCCESS]:
        return false
        case FETCH_PROJECT_DETAILS[FAILURE]:
      case FETCH_PROJECT_DETAILS[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isGettingWorkingEmpData = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_WORKING_EMP[SUCCESS]:
        return false
        case FETCH_PROJECT_DETAILS[FAILURE]:
      case FETCH_WORKING_EMP[REQUEST]:
        return true
      default:
        return state
    }
  }


  const isGettingGraphData = (state = false, action: Actions) => {
    switch (action.type) {
      case PROJECT_GRAPH_DATA[SUCCESS]:
        return false
      case PROJECT_GRAPH_DATA[REQUEST]:
        return true
      default:
        return state
    }
  }



    return combineReducers({
        isGettingDesignationGraphData,
        isGettingProjectsData,
        isGettingProjectTypes,
        isDeletingProject,
        isRestartingProject,
        isCreatingproject,
        isAddingEmp,
        resetAddDeveloper,
        resetCreateproject,
        isProjectUpdated,
        isRecordsdeleted,
        isEditingProject,
        isGettingProjectReportsList,
        isGetProjectReportsListFailed,
        isLoadingStateTrue,
        isGettingDesignationState,
        isGettingProjectManagementReportsList,
        isGettingProjectQAReport,
        isGetNonBillableResources,
        isUpdatedNonBillableResources,
        isGettingProjectCustomers,
        isProjectCustomersCreated,
        isProjectCustomerDeleted,
        isProjectCustomerUpdated,
        isGettingDesignationDataState,
        isGettingProjectDetailsData,
        isGettingGraphData,
        isGettingWorkingEmpData,
        isGettingMandateType,
        isMandateTypeCreated,
        isMandateTypeUpdated,
        isMandateTypeDeleted,
        isProjectUpdating,
        isProjectEditing,
        isProjectCreating,
        isGettingReportsList,
        isGetReportsListFailed,
        isDomainTypeCreated,
        isDomainTypeUpdated
    })  
}
export default ui

export const getProjectManagemet = (state: RootState) => state.ui.projectmanagement