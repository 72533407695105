import React, { useEffect, useRef } from 'react'
import { Navigate } from 'react-router-dom'
import { isLoggedIn } from './Auth'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { GetAccessToken } from '../components/Types'
import { getUserData, renewAccessToken } from '../actions'
import { dashboardEntity, loaderStateUI, userAuthenticationEntity } from '../reducers'
import { ProtectedStack } from '../components/Common/CommonStyles'
import Dashboard from '../components/Dashboard/Dashboard'
import style from '../utils/styles.json'

export type ProtectedRouteProps = {
  component: React.JSX.Element
  getAccessToken: (data: GetAccessToken) => void
  loginUserData: any
  getUserData: () => void
  userData: { id: number }
  loaderState: boolean
}
const ProtectedRoute: React.FC<ProtectedRouteProps> = (props: ProtectedRouteProps) => {
  const { getAccessToken, getUserData, userData } = props

  const authenticated = isLoggedIn()
  const eventListenerRegisteredRef = useRef(false)

  useEffect(() => {
    if (!userData.id) {
      getUserData()
    }
  }, [])

  //This useeffect will register an event listner that calls renew action token
  useEffect(() => {
    if (!eventListenerRegisteredRef.current) {
      document.addEventListener('Access_Token_Event', getNewAccessToken)
      eventListenerRegisteredRef.current = true
    }
    return () => {
      document.removeEventListener('Access_Token_Event', () => {})
    }
  }, [])

  //This method is useful to call the action that renews access token api
  const getNewAccessToken = () => {
    let refresh = JSON.stringify(localStorage.getItem('refresh_token'))
    getAccessToken({ grant_type: 'refresh_token', refresh_token: refresh.replace(/"/g, '') })
  }

  //This Block of code is used to get new refresh token
  useEffect(() => {
    let tokenExpireTime = localStorage.getItem('token_expire_time')
    tokenExpireTime = tokenExpireTime && JSON.parse(tokenExpireTime)
    let timeStamp = Date.now()
    let differenceTime = (tokenExpireTime ? timeStamp - parseInt(tokenExpireTime[0]) : 0) / 1000
    let remainingTime = tokenExpireTime ? parseInt(tokenExpireTime[1]) - differenceTime : -1
    if (remainingTime < 0) {
      getNewAccessToken()
    } else {
      setTimeout(() => {
        getNewAccessToken()
      }, remainingTime * 1000)
    }
  }, [])

  if (authenticated) {
    return (
      <ProtectedStack bgcolor={style.BACKGROUND_COLOR}>
        <Dashboard />
      </ProtectedStack>
    )
  } else {
    return <Navigate to='/' />
  }
}

ProtectedRoute.propTypes = {
  getAccessToken: PropTypes.func.isRequired,
}

//Here we are mapping states to our component
const mapStateToProps = (state: any) => {
  return {
    loginUserData: userAuthenticationEntity.getUserAuthentication(state).getUserAuthentication,
    userData: dashboardEntity.getDashboard(state).getUserData,
    loaderState: loaderStateUI.getLoaderState(state).isLoaderTrue,
  }
}

//Here we are mapping actions to our component
const mapDispatchToProps = (dispatch: any) => {
  return {
    getAccessToken: (data: GetAccessToken) => dispatch(renewAccessToken.request(data)),
    getUserData: () => dispatch(getUserData.request()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute)
