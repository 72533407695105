import React, { useEffect } from 'react'
import { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { loaderProps } from '../Common/CommonStyles'
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material'
import {
  CommonButton,
  InputField,
  SelectField,
  StyledMenuItem,
  useStyles,
} from './UserDetailsStyle'
import { validationSchema } from './ValidationSchema'
import { FormValues } from './IFormValue'
import { RootState } from '../../configureStore'
import {
  employeePortalEntity,
  fetchUserDetailsEntities,
  fetchUserDetailsUI,
  projectManagementEntity,
} from '../../reducers'
import {
  fetchAllRoles,
  fetchAllStates,
  fetchAllCountries,
  fetchAllFloors,
  fetchAllWorkstation,
  fetchAllLocation,
  uploadUserImg,
  fetchAllClientLocation,
  fetchDesignation,
  fetchDesignationBand,
  fetchWorkingEmp,
  addNewJoiners,
  updateNewJoiners,
  getExpectedJoinersImage,
} from '../../actions'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import { ArrowBack } from '@mui/icons-material'
import { HeaderHeading } from '../Common/CommonStyles'
import styles from '../../utils/styles.json'

const style = {
  selectStyle: {
    '& .MuiSelect-select': {
      height: '21px',
    },
  },
  gridStyle: {
    '& .MuiFormControl-root': {
      margin: '0 0 15px',
    },
  },
  changedGridStyle: {
    '& .MuiFormControl-root': {
      margin: '-10px 0 5px',
    },
  },
  gridStylewfo: {
    '& .MuiFormControl-root': {
      margin: '-10px 0 5px',
    },
  },
}

const steps = ['Personal Information', 'Address Information']

const daysOfWeek = [
  { id: 1, value: 'Sunday' },
  { id: 2, value: 'Monday' },
  { id: 3, value: 'Tuesday' },
  { id: 4, value: 'Wednesday' },
  { id: 5, value: 'Thursday' },
  { id: 6, value: 'Friday' },
  { id: 7, value: 'Saturday' },
]

const StepperForm = (props: any) => {
  const { isNewJoinerCreating, isNewJoinerUpdating } = props

  const rowdata: any = useLocation()?.state
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const [imagePreview, setImagePreview] = useState<string | null>(null)
  const [age, setAge] = useState(rowdata?.age ?? 0)

  const navigate = useNavigate()
  const location = useLocation()
  // const userData = location?.state?.data?.rowData || {}

  useEffect(() => {
    props.fetchAllRole()
    props.fetchAllState()
    props.fetchAllCountry()
    props.fetchAllFloor()
    props.fetchAllWorkstation()
    props.fetchAllLocation()
    props.fetchAllClientLocation()

    if (rowdata?.userid) {
      props.getExpectedJoinersImage(rowdata)
    }

    return () => {
      props.resetaddNewJoiners()
      props.resetUpdateNewJoiners()
      props.resetUserImage()
    }
  }, [rowdata?.userid])

  useEffect(() => {
    props.fetchDesignationData()
    props.fetchAllEmpAPI()
    props.fetchDesignationBandData()
  }, [])

  useEffect(() => {
    if (props.isAddNewJoinersDone) {
      toast.success('Added Expected Joiners successfully')
      navigate('/home/admin/expected-joiners')
    }

    // if (props.isUserCreateFailed) {
    //   toast.error('Failed to create user')
    // }

    if (props.isUpdateNewJoinersDone) {
      toast.success('Updated expected joiners successfully')
      navigate('/home/admin/expected-joiners')
    }

    // if (props.isUserUpdateFailed) {
    //   toast.error('Failed to update user')
    // }
  }, [
    props.isAddNewJoinersDone,
    props.isUpdateNewJoinersDone /* props.isUserCreateFailed,  props.isUserUpdateFailed */,
  ])

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      if (
        file.type === 'video/mp4' ||
        file.type === 'video/mpeg' ||
        file.type === 'video/quicktime' ||
        file.type === 'video/x-matroska'
      ) {
        toast.error('Video files are not allowed')
        formik.setFieldValue('image_path', null)
      } else {
        const reader = new FileReader()

        reader.onloadend = () => {
          const base64String = reader.result as string
          setImagePreview(base64String)
          formik.setFieldValue('image_path', file)
        }

        reader.readAsDataURL(file)
      }
    }
  }

  const uniqueRole =
    props.allRoles.length > 0
      ? props.allRoles.filter(
          (item: { name: any }, index: any, self: any[]) =>
            index === self.findIndex((t) => t.name === item.name),
        )
      : []

  const roleIdArr: any = []
  const dependentsArr: any = []

  rowdata?.roles?.map((value: any) => roleIdArr.push(value.id))

  rowdata?.dependents &&
    rowdata?.dependents?.length > 0 &&
    rowdata?.dependents?.map((value: any) => {
      return dependentsArr.push({
        id: value.id,
        name: value.name,
        id_relationship: value.id_relationship,
        birth_date: value.birth_date ? dayjs(value.birth_date).format('YYYY-MM-DD') : '',
        phone_number: value.phone_number,
        age: value.age,
        is_emergency_contact: value.is_emergency_contact,
      })
    })

  const formattedOfficialDate = rowdata?.official_birth_date
    ? dayjs(rowdata.official_birth_date).format('YYYY-MM-DD')
    : ''
  const formattedActualDate = rowdata?.actual_birth_date
    ? dayjs(rowdata.actual_birth_date).format('YYYY-MM-DD')
    : ''

  const formattedAnniverseryDate = rowdata?.anniversary_date
    ? dayjs(rowdata?.anniversary_date).format('YYYY-MM-DD')
    : ''
  const formattedLocationStartDate = rowdata?.location_start_date
    ? dayjs(rowdata.location_start_date).format('YYYY-MM-DD')
    : ''
  const formattedLocationEndDate = rowdata?.location_end_date
    ? dayjs(rowdata.location_end_date).format('YYYY-MM-DD')
    : ''
  const formattedCompLocationStartDate = rowdata?.comp_location_start_date
    ? dayjs(rowdata.comp_location_start_date).format('YYYY-MM-DD')
    : ''
  const formattedCompLocationEndDate = rowdata?.comp_location_end_date
    ? dayjs(rowdata.comp_location_end_date).format('YYYY-MM-DD')
    : ''    

  const formik = useFormik({
    initialValues: {
      first_name: rowdata?.first_name ?? '',
      last_name: rowdata?.last_name ?? '',
      middle_name: rowdata?.middle_name ?? '',
      father_name: rowdata?.father_name ?? '',
      mother_name: rowdata?.mother_name ?? '',
      id_tenant: "",
      image_path: '',
      employee_id: rowdata?.employeeId ?? '',
      email: rowdata?.email ?? '',
      track_attendance: true,
      // Workstation: rowdata?.workstation_id ?? null,
      loginId: rowdata?.email ?? '',
      address_type: rowdata && 'address' in rowdata ? rowdata?.address[0]?.address_type : 0,
      present_address: rowdata && 'address' in rowdata ? rowdata?.address[0]?.address : '',
      present_city: rowdata && 'address' in rowdata ? rowdata?.address[0]?.city : '',
      present_postal_code:
        rowdata && 'address' in rowdata ? rowdata?.address[0]?.postal_code : '',
      present_state:
        rowdata && 'address' in rowdata ? String(rowdata?.address[0]?.state) : String(15),
      deleted_by: 'Murthy',
      is_deleted: '0',
      id_country: 5,
      // floor_id: rowdata?.floor_id ?? null,
      citizenship: rowdata?.citizenship ?? '',
      mobile_no: rowdata?.mobile_no ?? '',
      // id_country_permanent: rowdata && 'address' in rowdata ? rowdata?.address[0]?.id_country : 5,
      official_birth_date: formattedOfficialDate ? formattedOfficialDate : '', // '1992-10-28 14:22:50Z',
      birth_date: formattedActualDate ? formattedActualDate : '', // '1992-10-28 14:22:50Z',
      gender: rowdata?.gender ?? 1,
      contract_employee: rowdata?.is_contract_employees ?? 1,
      // blood_group: rowdata?.blood_group ?? '',
      id_roles: roleIdArr?.length > 0 ? [...roleIdArr] : [457],
      marital_status: rowdata?.matital_status ?? 1,
      probation_period: rowdata?.probation_period ?? 4,
      isEmployeeSelected: false,
      // employment_type: rowdata?.employeeinfo?.[0]?.employee_type ?? 1,
      dependents:
        dependentsArr.length > 0
          ? dependentsArr
          : [
              {
                name: rowdata?.father_name ? rowdata?.father_name : '',
                id_relationship: 'father',
                birth_date: '',
                phone_number: '',
                age: null,
                is_emergency_contact: 0,
              },
              {
                name: rowdata?.mother_name ? rowdata?.mother_name : '',
                id_relationship: 'mother',
                birth_date: '',
                phone_number: '',
                age: null,
                is_emergency_contact: 0,
              },
            ],
    },
    validationSchema,
    onSubmit: (values) => {},
  })  

  const stepSchemas = [
    // Step 1 Schema
    Yup.object({
      first_name: Yup.string().required('Required'),
      last_name: Yup.string().required('Required'),
      father_name: Yup.string(),
      mother_name: Yup.string(),
      mobile_no: Yup.string().required('Required'),
      official_birth_date: Yup.date(),
      birth_date: Yup.date(),
      age: Yup.number(), 
      gender: Yup.string(),
      contract_employee: Yup.string().required(),
      marital_status: Yup.number(),
      email: Yup.string().email('Invalid email format').required('Required'),
      probation_period: Yup.number(),
    }),

    // Step 3 Schema
    Yup.object({
      present_address: Yup.string().required('Required'),
      present_city: Yup.string().required('Required'),
      present_postal_code: Yup.string().required('Required'),
      present_state: Yup.string().required('Required'),
      id_country: Yup.number().required('Required'),
      citizenship: Yup.string(),
    }),
  ]

  const handleNext = async () => {
    const stepSchema = stepSchemas[activeStep]
    try {
      await stepSchema.validate(formik.values, { abortEarly: false })
      if (activeStep === stepSchemas.length - 1) {
        const entity = {
          id: Boolean(rowdata?.email) && rowdata?.userid,
          first_name: formik.values.first_name,
          middle_name: formik.values.middle_name,
          last_name: formik.values.last_name,
          father_name :formik.values.father_name,
          mother_name :formik.values.mother_name,
          id_tenant: formik.values.id_tenant,
          profile_pic : '',
          email: formik.values.email,
          track_attendance: true,
          address_type: formik.values.address_type,
          address: formik.values.present_address,
          city: formik.values.present_city,
          postal_code: formik.values.present_postal_code,
          state: formik.values.present_state,
          is_deleted: '0',
          id_country: formik.values.id_country,
          mobile_no: formik.values.mobile_no,
          // id_country_permanent: formik.values.id_country_permanent,
          official_birth_date: formik.values.official_birth_date,
          actual_birth_date: formik.values.birth_date,
          gender: String(formik.values.gender),
          is_contract_employees: Number(formik.values.contract_employee),
          age: age,
          marital_status: formik.values.marital_status,
          // contact_person: formik.values.contact_person,
          probation_period: Number(formik.values.probation_period),
        }

        const isUpdate = Boolean(rowdata?.email)
        // userid
        const apiCall = isUpdate ? props.updateNewJoiners : props.addNewJoiners

        if (formik.values.image_path) {
          entity.profile_pic = formik.values.image_path
          await apiCall(entity)
        } else {
          apiCall(entity)
        }
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
      }
    } catch (error) {

      if (error instanceof Yup.ValidationError) {
        const stepErrors = error.inner.reduce((acc, curr) => {
          if (curr.path) {
            acc[curr.path as keyof FormValues] = curr.message
          }
          return acc
        }, {} as { [K in keyof FormValues]?: string })

        if (
          formik.values.dependents.some(
            (dependent: any) => !dependent?.name || !dependent?.id_relationship,
          )
        ) {
          const touched = { ...formik.touched } as any // Casting to match FormikTouched<FormValues> type

          formik.values.dependents.forEach((dependent: any, index: any) => {
            if (!dependent?.name || !dependent?.id_relationship) {
              if (!touched.dependents) {
                touched.dependents = []
              }
              if (!touched.dependents[index]) {
                touched.dependents[index] = {}
              }
              touched.dependents[index] = {
                ...touched.dependents[index],
                name: !dependent?.name ? true : touched.dependents[index].name,
                id_relationship: !dependent?.id_relationship
                  ? true
                  : touched.dependents[index].id_relationship, // Mark id_relationship as touched
              }
            }
          })

          // Set the updated touched state with type safety
          const getBeforeFileds = Object.keys(stepErrors).reduce((acc, key) => {
            acc[key as keyof FormValues] = true
            return acc
          }, {} as { [K in keyof FormValues]: any })
          formik.setTouched({ ...touched, ...getBeforeFileds })
        }
        formik.setErrors(stepErrors)
      } else {
        console.error('Unexpected error:', error)
      }
    }
  }

  let existEmpId = formik.values.id_roles.filter((emp) => emp === 281)

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target
    const currentRoles = formik.values.id_roles

    if (checked) {
      formik.setFieldValue('id_roles', [...currentRoles, parseInt(value)])
      if (parseInt(value) === 281) {
        formik.setFieldValue('isEmployeeSelected', true)
      }
    } else {
      formik.setFieldValue(
        'id_roles',
        currentRoles?.filter((roleId: number) => roleId !== parseInt(value)),
      )
      if (parseInt(value) === 281) {
        formik.setFieldValue('isEmployeeSelected', false)
      }
    }

    setTimeout(() => {
      formik.validateField('id_roles')
    }, 0)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  useEffect(() => {
    if (formik.values.marital_status === 1) formik.setFieldValue('anniversary_date', '')
  }, [formik.values.marital_status])

  const calculateAge = (birthDate: any) => {
    const today = new Date()
    const birthDateObj = new Date(birthDate)
    let age = today?.getFullYear() - birthDateObj?.getFullYear()
    const monthDiff = today?.getMonth() - birthDateObj?.getMonth()
    if (monthDiff < 0 || (monthDiff === 0 && today?.getDate() < birthDateObj?.getDate())) {
      age--
    }
    return age
  }

  const employmentTypeLabels: { [key: number]: string } = {
    1: 'Employee ID',
    2: 'Professional ID',
    3: 'Contractor ID',
  }

  // const label = employmentTypeLabels[Number(formik.values.employment_type)] || 'Employee ID'


  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <Grid
                  item
                  sx={{ height: '65px', display: 'flex', alignContent: 'center', gap: '10px' }}
                >
                  <InputField
                    size='small'
                    fullWidth
                    id='first_name'
                    name='first_name'
                    label='First Name'
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                    inputProps={{ tabIndex: 1 }}
                  />

                  <InputField
                    size='small'
                    id='middle_name'
                    name='middle_name'
                    label={`Middle Name`}
                    value={formik.values.middle_name}
                    onChange={formik.handleChange}
                    inputProps={{ tabIndex: 2 }}
                    required={false}
                  />

                  <InputField
                    size='small'
                    fullWidth
                    id='last_name'
                    name='last_name'
                    label='Last Name'
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                    inputProps={{ tabIndex: 3 }}
                  />
                </Grid>

                <Grid
                  item
                  sx={{ display: 'flex', alignContent: 'center', gap: '10px', height: '75px' }}
                >
                  <InputField
                    size='small'
                    fullWidth
                    id='official_birth_date'
                    name='official_birth_date'
                    label='Official Birth Date'
                    required={true}
                    type='date'
                    value={formik.values.official_birth_date || ''}
                    onChange={(e: any) => {
                      const age = dayjs().diff(dayjs(e?.target?.value, 'YYYY-MM-DD'), 'year')
                      if (age > 0) setAge(age)
                      formik.setFieldValue('birth_date', e?.target?.value)
                      return formik.handleChange(e)
                    }}
                    error={
                      formik.touched.official_birth_date &&
                      Boolean(formik.errors.official_birth_date)
                    }
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ tabIndex: 4, max: dayjs().format('YYYY-MM-DD') }} // Set max to today's date
                  />

                  <InputField
                    size='small'
                    fullWidth
                    id='birth_date'
                    name='birth_date'
                    label='Actual Birth Date'
                    type='date'
                    required={true}
                    value={formik.values.birth_date || ''}
                    onChange={formik.handleChange}
                    error={formik.touched.birth_date && Boolean(formik.errors.birth_date)}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ tabIndex: 5, max: dayjs().format('YYYY-MM-DD') }} // Set max to today's date
                  />
                  <InputField
                    size='small'
                    fullWidth
                    id='age'
                    name='age'
                    label='Age'
                    required={false}
                    value={age ?? ''}
                    disabled
                  />
                </Grid>

                <Grid
                  item
                  container
                  sx={{
                    display: 'flex',
                    alignContent: 'center',
                    flexDirection: 'row',
                    height: '70px'
                  }}
                  spacing={1.1}
                >
                  <Grid item xs={4}>
                    <SelectField
                      size='small'
                      id='gender'
                      name='gender'
                      label='Gender'
                      select
                      value={formik.values.gender}
                      onChange={formik.handleChange}
                      error={formik.touched.gender && Boolean(formik.errors.gender)}
                      inputProps={{ tabIndex: 6 }}
                      required={false}
                    >
                      <StyledMenuItem value={1}>Male</StyledMenuItem>
                      <StyledMenuItem value={2}>Female</StyledMenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item xs={4}>
                    <SelectField
                      size='small'
                      id='marital_status'
                      name='marital_status'
                      label='Gender'
                      select
                      required={false}
                      value={formik.values.marital_status}
                      onChange={formik.handleChange}
                      error={formik.touched.marital_status && Boolean(formik.errors.marital_status)}
                      inputProps={{ tabIndex: 7 }}
                    >
                      <StyledMenuItem value={1}>Single</StyledMenuItem>
                      <StyledMenuItem value={2}>Married</StyledMenuItem>
                      <StyledMenuItem value={3}>Widow</StyledMenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item xs={4}>
                <InputField
                  size='small'
                  fullWidth
                  id='mobile_no'
                  name='mobile_no'
                  label='Mobile Number'
                  value={formik.values.mobile_no}
                  inputProps={{ tabIndex: 12 }}
                  onChange={(e) => {
                    let onlyNums = e.target.value.replace(/[^0-9]/g, '')
                    if (onlyNums.length > 10) {
                      onlyNums = onlyNums.slice(0, 10) // Limit to 10 digits
                    }
                    formik.setFieldValue('mobile_no', onlyNums)
                  }}
                  error={formik.touched.mobile_no && Boolean(formik.errors.mobile_no)}
                  required={true}
                />
              </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Grid item style={{ textAlign: 'center', marginBottom: '0px' }}>
                  <Avatar
                    src={imagePreview ? (imagePreview as string) : props.userImage}
                    alt='User Preview'
                    sx={{
                      width: 120,
                      height: 120,
                      margin: '0 auto',
                      border: '2px solid #ddd',
                      boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                    }}
                  />
                </Grid>
                <Grid
                  item
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '8px',
                  }}
                >
                  <Button
                    variant='contained'
                    component='label'
                    sx={{
                      fontSize: '15px !important',
                      fontFamily: `${styles.FONT_BOLD}!important`,
                      width: '100%',
                      borderRadius: '20px !important',
                    }}
                  >
                    UPLOAD PHOTO
                    <input type='file' hidden accept='image/*' onChange={handleImageChange} />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              sx={{ display: 'flex', alignContent: 'center', gap: '10px', height: '65px' }}
            >
              <Grid item xs={2.9}>
                <InputField
                  size='small'
                  fullWidth
                  id='father_name'
                  name='father_name'
                  label='Father Name'
                  value={formik.values.father_name}
                  onChange={formik.handleChange}
                  error={formik.touched.father_name && Boolean(formik.errors.father_name)}
                  required={false}
                  inputProps={{ tabIndex: 9 }}
                />
              </Grid>
              <Grid item xs={2.9}>
                <InputField
                  size='small'
                  fullWidth
                  id='mother_name'
                  name='mother_name'
                  label='Mother Name'
                  value={formik.values.mother_name}
                  onChange={formik.handleChange}
                  error={formik.touched.mother_name && Boolean(formik.errors.mother_name)}
                  required={false}
                  inputProps={{ tabIndex: 10 }}
                />
              </Grid>

              <Grid item xs={2.9}>
                <InputField
                  size='small'
                  fullWidth
                  id='email'
                  name='email'
                  label='Email'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  required={true}
                  inputProps={{ tabIndex: 11 }}
                  disabled={Boolean(rowdata?.email)}
                />
              </Grid>
              <Grid item xs={2.9}>
                    <SelectField
                      size='small'
                      id='contract_employee'
                      name='contract_employee'
                      label='Is Contract Employee'
                      select
                      value={formik.values.contract_employee}
                      onChange={formik.handleChange}
                      error={formik.touched.contract_employee && Boolean(formik.errors.contract_employee)}
                      inputProps={{ tabIndex: 13 }}
                    >
                      <StyledMenuItem value={0}>Yes</StyledMenuItem>
                      <StyledMenuItem value={1}>No</StyledMenuItem>
                    </SelectField>
                </Grid>
            </Grid>
            <Grid
              item
              container
              sx={{ display: 'flex', alignContent: 'center', gap: '10px', height: '65px' }}
            >
                <Grid item xs={2.9}>
                    <SelectField
                      size='small'
                      id='probation_period'
                      name='probation_period'
                      label='Probation Period'
                      select
                      required={true}
                      value={formik.values.probation_period}
                      onChange={formik.handleChange}
                      error={formik.touched.probation_period && Boolean(formik.errors.probation_period)}
                      inputProps={{ tabIndex: 14 }}
                    >
                      <StyledMenuItem value={0}>3 months</StyledMenuItem>
                      <StyledMenuItem value={1}>6 months</StyledMenuItem>
                      <StyledMenuItem value={2}>9 months</StyledMenuItem>
                      <StyledMenuItem value={3}>1 Year</StyledMenuItem>
                    </SelectField>
                  </Grid>
            </Grid>  
          </>
        )
      case 1:
        return (
          <Box>
            {/* <Box mb={2}>
              <Typography fontFamily='Montserrat-SemiBold'>Present Address</Typography>
            </Box> */}
            <Grid item xs={12}>
                <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center',marginBottom:'10px' }}>
                  <FormLabel
                    id='demo-row-radio-buttons-group-label'
                    sx={{
                      mr: 2,
                      fontSize: '15px', // Change label font size here
                      fontFamily: styles.FONT_MEDIUM, // Change font family here
                      lineHeight: '1.8em', // Line height adjustment
                    }}
                  >
                    Address
                  </FormLabel>
                  <RadioGroup
                    row
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        // Style for RadioGroup labels
                        fontSize: '13px', // Change label font size here
                        fontFamily: styles.FONT_MEDIUM, // Change label font family here
                      },
                    }}
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='address_type'
                    value={formik.values.address_type}
                    onChange={formik.handleChange}
                  >
                    <FormControlLabel value={0} control={<Radio />} label='Present' />
                    <FormControlLabel value={1} control={<Radio />} label='Permanent' />
                  </RadioGroup>
                {formik.touched.address_type && formik.errors.address_type && (
                  <FormHelperText error>
                    {Array.isArray(formik.errors.address_type)
                      ? formik.errors.address_type.join(', ')
                      : String(formik.errors.address_type)}
                  </FormHelperText>
                )}
                </FormControl>
              </Grid>
              
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Grid item sx={style.gridStyle}>
                  {props.allCountries && props.allCountries.length > 0 && (
                    <SelectField
                      select
                      size='small'
                      fullWidth
                      id='id_country'
                      name='id_country'
                      label='Country'
                      value={formik.values.id_country}
                      onChange={formik.handleChange}
                      error={formik.touched.id_country && Boolean(formik.errors.id_country)}
                      inputProps={{ tabIndex: 1 }}
                    >
                      {props.allCountries.map((country: any) => (
                        <StyledMenuItem key={country.id} value={country.id}>
                          {country.country_name}
                        </StyledMenuItem>
                      ))}
                    </SelectField>
                  )}
                </Grid>
                <Grid item sx={style.gridStyle}>
                  <InputField
                    size='small'
                    fullWidth
                    id='present_address'
                    name='present_address'
                    label='Address'
                    value={formik.values.present_address}
                    onChange={formik.handleChange}
                    error={formik.touched.present_address && Boolean(formik.errors.present_address)}
                    inputProps={{ tabIndex: 4 }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid item sx={style.gridStyle}>
                  {/* {props.allStates && props.allStates.length > 0 && ( */}
                  <SelectField
                    select
                    size='small'
                    fullWidth
                    id='present_state'
                    name='present_state'
                    label='State'
                    value={formik.values.present_state}
                    onChange={formik.handleChange}
                    error={formik.touched.present_state && Boolean(formik.errors.present_state)}
                    // helperText={formik.touched.present_state && formik.errors.present_state}
                    inputProps={{ tabIndex: 2 }}
                  >
                    {props.allStates.map((state: any) => (
                      <StyledMenuItem key={state.id} value={state.id}>
                        {state.state_name}
                      </StyledMenuItem>
                    ))}
                  </SelectField>
                  {/* )} */}
                </Grid>
                <Grid item sx={style.gridStyle}>
                  <InputField
                    size='small'
                    fullWidth
                    id='present_postal_code'
                    name='present_postal_code'
                    label='Postal Code'
                    value={formik.values.present_postal_code}
                    // onChange={formik.handleChange}
                    onChange={(e) => {
                      let allowedCharacters = e.target.value.replace(/[^a-zA-Z0-9]/g, '')
                      if (allowedCharacters.length > 6) {
                        allowedCharacters = allowedCharacters.slice(0, 6)
                      }
                      formik.setFieldValue('present_postal_code', allowedCharacters)
                    }}
                    error={
                      formik.touched.present_postal_code &&
                      Boolean(formik.errors.present_postal_code)
                    }
                    inputProps={{ tabIndex: 5 }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid item sx={style.gridStyle}>
                  <InputField
                    size='small'
                    fullWidth
                    id='present_city'
                    name='present_city'
                    label='City'
                    value={formik.values.present_city}
                    onChange={formik.handleChange}
                    error={formik.touched.present_city && Boolean(formik.errors.present_city)}
                    inputProps={{ tabIndex: 3 }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )
      default:
        return 'Unknown stepIndex'
    }
  }

  return (
    <div className={classes.root}>
      {(isNewJoinerCreating || isNewJoinerUpdating) && (
        <Dialog open={isNewJoinerCreating || isNewJoinerUpdating} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      )}
      <form onSubmit={formik.handleSubmit}>
        {/* <Box mb={2}>
          <Typography
            sx={{ fontSize: '28px', color: '#193C6D', fontFamily: 'Montserrat-Bold' }}
            textAlign={'center'}
          >
            {Boolean(rowdata?.email) ? 'Update Employee' : 'Add Employee'}
          </Typography>
        </Box> */}
        <Box sx={{ textAlign: 'left' }}>
          <Box
            onClick={() => navigate(-1)}
            sx={{ float: 'right', pr: '30px', mt: '0px', cursor: 'pointer' }}
          >
            <ArrowBack />
          </Box>
        </Box>

        <HeaderHeading sx={{ mb: 2, mt: -1.5 }}>
          {Boolean(rowdata?.email)
            ? 'Update Expected Joiner'
            : 'Add Expected Joiner'}
        </HeaderHeading>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ mx: 4, my: 1, mt: 3 }}>
          <div>
            <div>{getStepContent(activeStep)}</div>
            <div
              style={{
                marginTop: '24px',
                display: 'flex',
                justifyContent: activeStep === 0 ? 'flex-end' : 'space-between',
              }}
            >
              <CommonButton
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
                sx={{ display: activeStep === 0 ? 'none' : 'flex' }}
              >
                PREVIOUS
              </CommonButton>
              <CommonButton variant='contained' color='primary' onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'FINISH' : 'NEXT'}
              </CommonButton>
            </div>
          </div>
        </Box>
      </form>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    designationData: employeePortalEntity.getEmployeePortal(state).getDesignationData,
    designationBandData: employeePortalEntity.getEmployeePortal(state).getDesignationBandData,
    AllEmpList: projectManagementEntity.getAllProjectManagement(state).getAllEmpList,

    getUserDetails: fetchUserDetailsEntities.fetchUserData(state).fetchUser,
    allRoles: fetchUserDetailsEntities.fetchUserData(state).fetchAllRole,
    allStates: fetchUserDetailsEntities.fetchUserData(state).fetchAllState,
    allCountries: fetchUserDetailsEntities.fetchUserData(state).fetchAllCountry,
    allFloors: fetchUserDetailsEntities.fetchUserData(state).fetchAllFloor,
    allWorkstations: fetchUserDetailsEntities.fetchUserData(state).fetchAllWorkstation,
    allLocation: fetchUserDetailsEntities.fetchUserData(state).fetchAllLocation,
    allClientLocation: fetchUserDetailsEntities.fetchUserData(state).fetchAllClientLocation,
    isAddNewJoinersDone: fetchUserDetailsUI.fetchUserData(state).isAddNewJoinersDone,
    isNewJoinerCreating: fetchUserDetailsUI.fetchUserData(state).isNewJoinerCreating,
    isUserCreateFailed: fetchUserDetailsUI.fetchUserData(state).isUserCreateFailed,
    isUpdateNewJoinersDone: fetchUserDetailsUI.fetchUserData(state).isUpdateNewJoinersDone,
    isNewJoinerUpdating: fetchUserDetailsUI.fetchUserData(state).isNewJoinerUpdating,
    isUserUpdateFailed: fetchUserDetailsUI.fetchUserData(state).isUserUpdateFailed,
    userImage: fetchUserDetailsEntities.fetchUserData(state).getExpectedJoinersImage,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchDesignationData: () => dispatch(fetchDesignation.request()),
    fetchDesignationBandData: () => dispatch(fetchDesignationBand.request()),
    fetchAllEmpAPI: () => dispatch(fetchWorkingEmp.request()),
    getExpectedJoinersImage: (data: any) => dispatch(getExpectedJoinersImage.request(data)),
    resetUserImage: () => {
      dispatch(getExpectedJoinersImage.reset())
    },
    uploadUserImg: (data: any) => dispatch(uploadUserImg.request({ data })),
    addNewJoiners: (data: any) => dispatch(addNewJoiners.request({ data })),
    resetaddNewJoiners: () => {
      dispatch(addNewJoiners.reset())
    },
    fetchAllRole: () => dispatch(fetchAllRoles.request()),
    fetchAllState: () => dispatch(fetchAllStates.request()),
    fetchAllCountry: () => dispatch(fetchAllCountries.request()),
    fetchAllFloor: () => dispatch(fetchAllFloors.request()),
    fetchAllWorkstation: () => dispatch(fetchAllWorkstation.request()),
    fetchAllLocation: () => dispatch(fetchAllLocation.request()),
    updateNewJoiners: (data: any) => dispatch(updateNewJoiners.request({ data })),
    resetUpdateNewJoiners: () => {
      dispatch(updateNewJoiners.reset())
    },
    fetchAllClientLocation: () => dispatch(fetchAllClientLocation.request()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(StepperForm)
