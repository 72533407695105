import React, { useEffect, useState } from 'react'
import ApexCharts from 'apexcharts'

// Predefined color palette for the first 10 colors
const predefinedColors = [
  '#FF5733',
  '#33FF57',
  '#3357FF',
  '#FF33A8',
  '#FF8F33',
  '#33FFF3',
  '#33A8FF',
  '#D433FF',
  '#FF5733',
  '#57FF33',
]

// Function to generate distinct colors using RGB
const generateColors = (count: number) => {
  const colors = [...predefinedColors] // Start with predefined colors

  // Generate more colors if needed
  if (count > predefinedColors.length) {
    const step = Math.floor(255 / (count - predefinedColors.length)) // Calculate step size for RGB
    for (let i = predefinedColors.length; i < count; i++) {
      const r = (i * step) % 256 // Cycle through red values
      const g = (128 + i * step) % 256 // Cycle through green values
      const b = (64 + i * step) % 256 // Cycle through blue values
      colors.push(`rgb(${r}, ${g}, ${b})`) // Add new RGB color
    }
  }

  return colors.slice(0, count) // Return only the required number of colors
}

const AnalyticsPieChart = (props: any) => {
  const { myChartData } = props

  const [chartData, setChartData] = useState<{ name: string; count: number; percentage: string }[]>(
    [],
  )

  useEffect(() => {
    if (myChartData?.technologies && myChartData.technologies.length > 0) {
      
      const newData = myChartData?.technologies.map((user: any) => ({
        name: user.technology, // Use `technology` for the chart label
        count: user.count || 0, // Ensure we have the count field
        percentage: user.percentage || '0%', // Use the provided percentage
      }))
      setChartData(newData)
    }
  }, [myChartData])

  useEffect(() => {
    if (chartData.length > 0) {
      const names = chartData.map((item) => item.name)
      const counts = chartData.map((item) => parseFloat(item.percentage))
      const colors = generateColors(chartData.length) // Dynamically generate colors based on data length

      const options = {
        series: counts,
        chart: {

          height: 550,
          width: 550,
          type: 'donut',
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
            dataLabels: {
              enabled: false, // Disable data labels
            },
          },
        },
        fill: {
          type: 'gradient',
        },
        labels: names,
        colors: colors, // Use the generated colors
        tooltip: {
          enabled: true, // Disable the default tooltip
          custom: function (prop: any) {
            const technology = chartData[prop?.seriesIndex]?.name || 'Unknown'
            const count = chartData[prop?.seriesIndex]?.count || 0
            const percentage = chartData[prop?.seriesIndex]?.percentage || '0%'

            // Create custom tooltip content

            return `
              <div style="
                padding: 12px 16px;
                background-color: #2c3e50;  /* Darker blue-gray background */
                color: #ecf0f1;  /* Light font color */
                border-radius: 10px;
                box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
                font-family: 'Roboto', sans-serif;
                max-width: 220px;
                font-size: 14px;
              ">
                  <strong>Technology:</strong> ${technology} </br>
                  <strong>Count:</strong> ${Math.round(count)} </br>
                  <strong>Percentage:</strong> ${percentage} </br>
              </div>
            `
          },
        },
        legend: {
          position: 'bottom',
          fontSize: '14px', // Adjust legend font size
          maxHeight: 100, // Limit legend height for large datasets
          scrollable: true, // Enable scroll for large legends
        },
      }

      const chart = new ApexCharts(document.querySelector('#piechart'), options)
      chart.render()

      return () => {
        chart.destroy()
      }
    }
  }, [chartData])

  return (
    <div
      id='piechart'
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center', // Center the chart vertically
        width: '100%',
        height: '600px', // Match the increased chart height
      }}
    />
  )
}

export default AnalyticsPieChart
