import { useEffect, useState } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import { ClickAwayListener, Collapse } from '@mui/material'
import {
  ChildList,
  DrawerHeaderStack,
  StyledDrawer,
  StyledListItemButton,
  StyledListItemText,
} from './FinancialStyles'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as AdministrationLogo } from '../../assets/images/home.svg'
import { ReactComponent as FileUploadLogo } from '../../assets/images/file-upload-icon.svg'
import { ReactComponent as EmployeeInfoIcon } from '../../assets/images/monitor-data.svg'
import { ReactComponent as FinancalIcon } from '../../assets/images/db.svg'
import { ReactComponent as EmployeeList } from '../../assets/images/Users-04.svg'
import AccessibilityIcon from '@mui/icons-material/Accessibility'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded'
import EscalatorIcon from '@mui/icons-material/Escalator'
import { SmallArrow } from '../Common/CommonStyles'
import { SideDrawerMenu as menuObject } from '../../utils/StaticData'
import MenuIcon from '@mui/icons-material/Menu'
import SourceIcon from '@mui/icons-material/Source'
import { useSelector } from 'react-redux'
import InsertChartIcon from '@mui/icons-material/InsertChart'
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone'
import Groups2Icon from '@mui/icons-material/Groups2'
// import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import style from '../../utils/styles.json'

const SideDrawer = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const listArray = Object.keys(menuObject)
  const [openedIndex, setOpenedIndex] = useState<null | number>(null)
  const [openDrawer, setOpenDrawer] = useState(false)
  const location = useLocation()
  const pathName = location?.pathname?.split('/')
  const userRole = useSelector(
    (state: { entities: { dashboard: { getUserDetails: { roles: string[] } } } }) =>
      state?.entities?.dashboard?.getUserDetails?.roles,
  )

  const handleOpenMenu = (index: number, value: string) => {
    setOpenedIndex(index === openedIndex ? null : index)
    navigate(value)
  }

  const handleOpenDrawer = () => {
    setOpenDrawer(!openDrawer)
  }

  const handleClickMenu = (value: string) => {
    if (value !== 'projectmanagement') localStorage.setItem('selectedTab', '0')
    if (value === 'benefits') {
      window.open(
        'https://sites.google.com/tudip.com/tudipinternal/tudip-benefits?authuser=0',
        '_blank',
        'noreferrer',
      )
    } else {
      navigate(value)
    }
    handleOpenDrawer()
    if (menuObject[value]?.length <= 0) setOpenedIndex(null)
  }

  const getListItemIcon = (value: string) => {
    switch (value) {
      case 'dashboard':
        return <AdministrationLogo />
      case 'admin':
        return <ManageAccountsIcon />
      case 'employees':
        return <PeopleAltTwoToneIcon />
      case 'employeeportal':
        return <EmployeeInfoIcon />
      case 'financeinfo':
        return <FinancalIcon />
      case 'workinfo':
        return <EscalatorIcon />
      case 'employeelist':
        return <EmployeeList />
      case 'leaveinfo':
        return <AccessibilityIcon />
      case 'projectmanagement':
        return <SourceIcon />
      case 'organizationalchart':
        return <InsertChartIcon />
      case 'myTeam':
        return <Groups2Icon />
      default:
        return <FileUploadLogo />
    }
  }

  const drsCount = useSelector(
    (state: { entities: { dashboard: { getUserDetails: { drsCount: { count: number } } } } }) =>
      state?.entities?.dashboard?.getUserDetails?.drsCount?.count,
  )

  return (
    <ClickAwayListener
      onClickAway={(event: any) => {
        setOpenDrawer(false)
      }}
    >
      <StyledDrawer
        variant='permanent'
        anchor='left'
        open={openDrawer}
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: `${style.PRIMARY_COLOR} !important`,
          },
        }}
      >
        <DrawerHeaderStack>
          <MenuIcon
            sx={{ cursor: 'pointer', color: 'white', fontSize: '28px' }}
            onClick={handleOpenDrawer}
          />
        </DrawerHeaderStack>

        <List>
          {listArray.map(
            (link: string, index: number) =>
              (userRole?.includes('Project Manager') || link !== 'projectmanagement') &&
              (userRole?.includes('Org Admin') || link !== 'organizationalchart') &&
              (userRole?.includes('Admin') || drsCount > 0 || link !== 'myTeam') &&
              (userRole?.includes('Admin') ||
                userRole?.includes('Accountant') ||
                link !== 'admin') && (
                <>
                  <ListItem
                    key={`index-${link}`}
                    onClick={() =>
                      !openDrawer
                        ? handleOpenDrawer()
                        : menuObject[link].length > 0
                        ? handleOpenMenu(index, link)
                        : handleClickMenu(link)
                    }
                  >
                    <StyledListItemButton selected={pathName?.includes(link)}>
                      <ListItemIcon sx={{ minWidth: '35px' }}>{getListItemIcon(link)}</ListItemIcon>
                      {openDrawer && (
                        <StyledListItemText
                          primary={t(
                            link === 'admin' &&
                              !userRole?.includes('Admin') &&
                              userRole?.includes('Accountant')
                              ? 'accounts'
                              : link,
                          )}
                        />
                      )}
                      {openDrawer &&
                        menuObject[link].length > 0 &&
                        (openedIndex === index ? (
                          <ArrowDropUpRoundedIcon sx={SmallArrow} />
                        ) : (
                          <ArrowDropDownRoundedIcon sx={SmallArrow} />
                        ))}
                    </StyledListItemButton>
                  </ListItem>
                  {openDrawer && menuObject[link].length > 0 && (
                    <Collapse in={openedIndex === index} timeout='auto' unmountOnExit>
                      <ChildList>
                        {menuObject[link].map(
                          (subLink: string, j: number) =>
                            (userRole?.includes('Admin') ||
                              userRole?.includes('Accountant') ||
                              subLink !== 'Form16Info') &&
                            (userRole?.includes('Admin') || subLink !== 'employees') && (
                              <>
                                <ListItem key={`e-${subLink}`}>
                                  <StyledListItemButton
                                    onClick={() => handleClickMenu(`${link}/${subLink}`)}
                                    selected={pathName.includes(subLink)}
                                  >
                                    <StyledListItemText primary={t(subLink)} />
                                  </StyledListItemButton>
                                </ListItem>
                              </>
                            ),
                        )}
                      </ChildList>
                    </Collapse>
                  )}
                </>
              ),
          )}
        </List>
      </StyledDrawer>
    </ClickAwayListener>
  )
}

export default SideDrawer
