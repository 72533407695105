import { call, takeLatest } from 'redux-saga/effects'
import { actionTypes } from '../actions'
import * as ApiService from '../services'
import { sendPayload, sendPayloadFailure } from './helper'
import {
  ADD_EMP_BASED_ON_ROLE,
  ADD_NEW_COMMENT,
  ADD_NEW_PROJECT,
  CREATE_PROJECT_CUSTOMERS,
  DELETE_EMP_BASED_ON_ROLE,
  DELETE_PROJECT,
  DELETE_PROJECT_CUSTOMER,
  DESIGNATION_GRAPH_DATA,
  DESIGNATION_LIST,
  EDIT_PROJECT,
  FETCH_ALL_PROJECT_RESOURCE_REPORT,
  FETCH_NON_BILLABLE_RESOURCES,
  FETCH_MANDATE_TYPE,
  CREATE_MANDATE_TYPE,
  DELETE_MANDATE_TYPE,
  FETCH_DOMAIN_TYPE,
  FETCH_PROJECT_CUSTOMERS,
  FETCH_PROJECT_CUSTOMERS_LIST,
  FETCH_PROJECT_DETAILS,
  FETCH_PROJECT_DOMAIN,
  FETCH_PROJECT_LOCATION,
  FETCH_PROJECT_MANAGEMENT_REPORT,
  FETCH_PROJECT_QA_REPORT,
  FETCH_PROJECT_REPORTS,
  FETCH_REPORTS,
  FETCH_PROJECT_SOURCE,
  FETCH_PROJECT_TYPES,
  FETCH_TECHNOLOGIES,
  FETCH_WORKING_EMP,
  ORGANISATION_DESIGNATION_DATA,
  ORGANIZATION_DESIGNATION,
  PROJECT_GRAPH_DATA,
  PROJECT_RESOURCE_REPORT_DROPDOWN,
  REQUEST,
  RESET,
  RESTART_PROJECT,
  UPDATE_NON_BILLABLE_RESOURCES,
  UPDATE_PROJECT,
  UPDATE_PROJECT_CUSTOMER,
  UPDATE_MANDATE_TYPE,
  DOWNLOAD_CSV_FOR_MY_TEAM,
  CREATE_DOMAIN_TYPE,
  UPDATE_DOMAIN_TYPE,
} from '../actions/actiontypes'
import { DEFAULT_DESKTOP_MODE_MEDIA_QUERY } from '@mui/x-date-pickers'

export interface FetchProjectReportsRequest {
  type: string
  start_date: string
  end_date: string
  page: string
  limit: string
  search: string
}

export interface ProjectCustomersType {
  type: string
  data: {
    id: number
    customer_name: string
    page: string
    limit: string
    search: string
  }
}

function* getGraphData(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getAllGraphData,
      data.data,
    )
    yield sendPayload(apiResponse, PROJECT_GRAPH_DATA)
  } catch (e) {
    yield sendPayloadFailure(e, PROJECT_GRAPH_DATA)
  }
}

function* getDesignationGraphList() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getDesignationGraphList
    )
    yield sendPayload(apiResponse, DESIGNATION_LIST)
  } catch (e) {
    yield sendPayloadFailure(e, DESIGNATION_LIST)
  }
}

function* getProjectDomain() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getProjectDomain
    )
    yield sendPayload(apiResponse, FETCH_PROJECT_DOMAIN)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_PROJECT_DOMAIN)
  }
}

function* getProjectSource() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getProjectSource
    )
    yield sendPayload(apiResponse, FETCH_PROJECT_SOURCE)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_PROJECT_SOURCE)
  }
}

function* getDesignationGraphData(data:any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getDesignationGraphData,
      data.data
    )
    yield sendPayload(apiResponse, DESIGNATION_GRAPH_DATA)
  } catch (e) {    
    yield sendPayloadFailure(e, DESIGNATION_GRAPH_DATA)
  }
}

function* getProjectTypes(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getAllProjectTypes,
      data,
    )
    yield sendPayload(apiResponse, FETCH_PROJECT_TYPES)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_PROJECT_TYPES)
  }
}
function* handleAllTechnologies() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(ApiService.getAllTechnologies)
    yield sendPayload(apiResponse, FETCH_TECHNOLOGIES)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_TECHNOLOGIES)
  }
}
function* handleProjectLocation() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getAllProjectLocation,
    )
    yield sendPayload(apiResponse, FETCH_PROJECT_LOCATION)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_PROJECT_LOCATION)
  }
}

function* handleAddNewProject(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getNewProjectInfo,
      data,
    )
    yield sendPayload(apiResponse, ADD_NEW_PROJECT)
  } catch (e) {
    yield sendPayloadFailure(e, ADD_NEW_PROJECT)
  }
}

function* handleDeletingProject(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getDeletingProject,
      data,
    )
    yield sendPayload(apiResponse, DELETE_PROJECT)
  } catch (e) {
    yield sendPayloadFailure(e, DELETE_PROJECT)
  }
}

function* handleProjectCustomers() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getProjectCustomers,
    )
    yield sendPayload(apiResponse, FETCH_PROJECT_CUSTOMERS)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_PROJECT_CUSTOMERS)
  }
}

function* handleMandateType(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getMandateType,
      data.data,
    )
    yield sendPayload(apiResponse, FETCH_MANDATE_TYPE)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_MANDATE_TYPE)
  }
}

function* handleDomainType(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getDomainType,
      data.data,
    )
    yield sendPayload(apiResponse, FETCH_DOMAIN_TYPE)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_DOMAIN_TYPE)
  }
}

function* handleRestartedProject(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getRestartedProject,
      data,
    )
    yield sendPayload(apiResponse, RESTART_PROJECT)
  } catch (e) {
    yield sendPayloadFailure(e, RESTART_PROJECT)
  }
}

function* handleProjectDetails(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getprojectDetails,
      data,
    )
    yield sendPayload(apiResponse, FETCH_PROJECT_DETAILS)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_PROJECT_DETAILS)
  }
}

function* handleAllWorkingEmployees() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getAllEmployeesList,
    )
    yield sendPayload(apiResponse, FETCH_WORKING_EMP)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_WORKING_EMP)
  }
}

function* handleEmpBasedOnRoles(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.AddEmpBasedOnRoles,
      data,
    )
    yield sendPayload(apiResponse, ADD_EMP_BASED_ON_ROLE)
  } catch (e) {
    yield sendPayloadFailure(e, ADD_EMP_BASED_ON_ROLE)
  }
}

function* handleUpdatedProject(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.UpdateEmpBasedOnRoles,
      data,
    )
    yield sendPayload(apiResponse, UPDATE_PROJECT)
  } catch (e) {
    yield sendPayloadFailure(e, UPDATE_PROJECT)
  }
}

function* handleDeletedRecords(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.DeleteEmpBasedOnRoles,
      data,
    )
    yield sendPayload(apiResponse, DELETE_EMP_BASED_ON_ROLE)
  } catch (e) {
    yield sendPayloadFailure(e, DELETE_EMP_BASED_ON_ROLE)
  }
}

function* handleEditedProject(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.GetAllEditedProjects,
      data,
    )
    yield sendPayload(apiResponse, EDIT_PROJECT)
  } catch (e) {
    yield sendPayloadFailure(e, EDIT_PROJECT)
  }
}

function* getProjectReports(data: FetchProjectReportsRequest) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getProjectReportsSheetURL,
      data,
    )
    yield sendPayload(apiResponse, FETCH_PROJECT_REPORTS)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_PROJECT_REPORTS)
  }
}

function* getReports(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getReportsSheetURL,
      data,
    )
    yield sendPayload(apiResponse, FETCH_REPORTS)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_REPORTS)
  }
}

function* getAllReports(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getAllResourceReportData,
      data.data,
    )
    yield sendPayload(apiResponse, FETCH_ALL_PROJECT_RESOURCE_REPORT)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_ALL_PROJECT_RESOURCE_REPORT)
  }
}

function* getDesignationList() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(ApiService.getDesignationList)
    yield sendPayload(apiResponse, ORGANIZATION_DESIGNATION)
  } catch (e) {
    yield sendPayloadFailure(e, ORGANIZATION_DESIGNATION)
  }
}

function* getDesignationDataList(data: { hierarchyName: string; type: string }) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getDesignationDataList,
      data,
    )
    yield sendPayload(apiResponse, ORGANISATION_DESIGNATION_DATA)
  } catch (e) {
    yield sendPayloadFailure(e, ORGANISATION_DESIGNATION_DATA)
  }
}

function* getResourceReportDropdownData(data: FetchProjectReportsRequest) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getProjectResourceDropdownData,
    )
    yield sendPayload(apiResponse, PROJECT_RESOURCE_REPORT_DROPDOWN)
  } catch (e) {
    yield sendPayloadFailure(e, PROJECT_RESOURCE_REPORT_DROPDOWN)
  }
}

function* getProjectManagementReports(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getProjectManagementReportData,
      data.data,
    )
    yield sendPayload(apiResponse, FETCH_PROJECT_MANAGEMENT_REPORT)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_PROJECT_MANAGEMENT_REPORT)
  }
}

function* fetchProjectQAReport(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getAllProjectQAReport,
      data.data,
    )
    yield sendPayload(apiResponse, FETCH_PROJECT_QA_REPORT)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_PROJECT_QA_REPORT)
  }
}

function* getNonBillableResources() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getNonBillableResources,
    )
    yield sendPayload(apiResponse, FETCH_NON_BILLABLE_RESOURCES)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_NON_BILLABLE_RESOURCES)
  }
}
export interface NonBillableUpdateType {
  type: string
  data: {
    Employee_id: number[]
  }
}

function* updateNonBillable(data: NonBillableUpdateType) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.updateNonBillable,
      data.data,
    )
    yield sendPayload(apiResponse, UPDATE_NON_BILLABLE_RESOURCES)
  } catch (e) {
    yield sendPayloadFailure(e, UPDATE_NON_BILLABLE_RESOURCES)
  }
}

function* getProjectCustomerList(data: ProjectCustomersType) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getAllProjectCustomers,
      data.data,
    )
    yield sendPayload(apiResponse, FETCH_PROJECT_CUSTOMERS_LIST)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_PROJECT_CUSTOMERS_LIST)
  }
}

function* createProjectCustomers(data: ProjectCustomersType) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.createProjectCustomers,
      data,
    )
    yield sendPayload(apiResponse, CREATE_PROJECT_CUSTOMERS)
  } catch (e) {
    yield sendPayloadFailure(e, CREATE_PROJECT_CUSTOMERS)
  }
}
function* createMandateType(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.createMandateTypes,
      data,
    )
    yield sendPayload(apiResponse, CREATE_MANDATE_TYPE)
  } catch (e) {
    yield sendPayloadFailure(e, CREATE_MANDATE_TYPE)
  }
}

function* createDomainType(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.createDomainTypes,
      data,
    )
    yield sendPayload(apiResponse, CREATE_DOMAIN_TYPE)
  } catch (e) {
    yield sendPayloadFailure(e, CREATE_DOMAIN_TYPE)
  }
}

function* deleteMandateTypeSaga(data: any) {
  try {
    const apiResponse: Generator<String, number, string> = yield call(
      ApiService.deleteOneMandateType,
      data.data.id,
    )
    yield sendPayload(apiResponse, DELETE_MANDATE_TYPE)
  } catch (e) {
    yield sendPayloadFailure(e, DELETE_MANDATE_TYPE)
  }
}


function* deleteProjectCustomerSaga(data: ProjectCustomersType) {
  try {
    const apiResponse: Generator<String, number, string> = yield call(
      ApiService.deleteOneProjectCustomers,
      data.data.id,
    )
    yield sendPayload(apiResponse, DELETE_PROJECT_CUSTOMER)
  } catch (e) {
    yield sendPayloadFailure(e, DELETE_PROJECT_CUSTOMER)
  }
}

function* updateProjectCustomer(data: any) {
  try {
    const apiResponse: Generator<String, number, string> = yield call(
      ApiService.updateProjectCustomer,
      data.data,
    )
    yield sendPayload(apiResponse, UPDATE_PROJECT_CUSTOMER)
  } catch (e) {
    yield sendPayloadFailure(e, UPDATE_PROJECT_CUSTOMER)
  }
}

function* updateMandateType(data: any) {
  try {
    const apiResponse: Generator<String, number, string> = yield call(
      ApiService.updateMandateType,
      data.data,
    )
    yield sendPayload(apiResponse, UPDATE_MANDATE_TYPE)
  } catch (e) {
    yield sendPayloadFailure(e, UPDATE_MANDATE_TYPE)
  }
}

function* updateDomainType(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.updateDomainType,
      data,
    )
    yield sendPayload(apiResponse, UPDATE_DOMAIN_TYPE)
  } catch (e) {
    yield sendPayloadFailure(e, UPDATE_DOMAIN_TYPE)
  }
}
function* downloadCsvForMyTeam(data: any) {
  try {
    const apiResponse: Generator<String, number, string> = yield call(
      ApiService.downloadCsvForMyTeamReport,
      data,
    )
    yield sendPayload(apiResponse, DOWNLOAD_CSV_FOR_MY_TEAM)
  } catch (e) {
    yield sendPayloadFailure(e, DOWNLOAD_CSV_FOR_MY_TEAM)
  }
}


export const sagas = {
  watchDesignationGraphList: takeLatest(DESIGNATION_LIST[REQUEST],getDesignationGraphList),
  watchDesignationGraphData: takeLatest(DESIGNATION_GRAPH_DATA[REQUEST],getDesignationGraphData),
  watchGetGraphData: takeLatest(actionTypes.PROJECT_GRAPH_DATA[REQUEST], getGraphData),
  watchGetProjectTypes: takeLatest(actionTypes.FETCH_PROJECT_TYPES[REQUEST], getProjectTypes),
  watchGetProjectDomain: takeLatest(FETCH_PROJECT_DOMAIN[REQUEST],getProjectDomain),
  watchGetProjectSource: takeLatest(FETCH_PROJECT_SOURCE[REQUEST],getProjectSource),
  watchHandleAllTechnologies: takeLatest(
    actionTypes.FETCH_TECHNOLOGIES[REQUEST],
    handleAllTechnologies,
  ),
  watchHandleProjectLocation: takeLatest(
    actionTypes.FETCH_PROJECT_LOCATION[REQUEST],
    handleProjectLocation,
  ),
  watchHandleAddNewProject: takeLatest(actionTypes.ADD_NEW_PROJECT[REQUEST], handleAddNewProject),
  watchHandleDeletingProject: takeLatest(
    actionTypes.DELETE_PROJECT[REQUEST],
    handleDeletingProject,
  ),
  watchHandleMandateType: takeLatest(
    actionTypes.FETCH_MANDATE_TYPE[REQUEST],
    handleMandateType,
  ),
  watchHandleDomainType: takeLatest(
    actionTypes.FETCH_DOMAIN_TYPE[REQUEST],
    handleDomainType,
  ),
  watchHandleProjectCustomers: takeLatest(
    actionTypes.FETCH_PROJECT_CUSTOMERS[REQUEST],
    handleProjectCustomers,
  ),
  watchHandleRestartedProject: takeLatest(
    actionTypes.RESTART_PROJECT[REQUEST],
    handleRestartedProject,
  ),
  watchHandleProjectDetails: takeLatest(
    actionTypes.FETCH_PROJECT_DETAILS[REQUEST],
    handleProjectDetails,
  ),
  watchHandleAllWorkingEmployees: takeLatest(
    actionTypes.FETCH_WORKING_EMP[REQUEST],
    handleAllWorkingEmployees,
  ),
  watchHandleEmpBasedOnRoles: takeLatest(
    actionTypes.ADD_EMP_BASED_ON_ROLE[REQUEST],
    handleEmpBasedOnRoles,
  ),
  watchHandleUpdatedProject: takeLatest(actionTypes.UPDATE_PROJECT[REQUEST], handleUpdatedProject),
  watchHandleDeletedRecords: takeLatest(
    actionTypes.DELETE_EMP_BASED_ON_ROLE[REQUEST],
    handleDeletedRecords,
  ),
  watchHandleEditedproject: takeLatest(actionTypes.EDIT_PROJECT[REQUEST], handleEditedProject),
  watchGetProjectReports: takeLatest(actionTypes.FETCH_PROJECT_REPORTS[REQUEST], getProjectReports),
  watchGetReports: takeLatest(actionTypes.FETCH_REPORTS[REQUEST], getReports),
  watchGetAllReports: takeLatest(
    actionTypes.FETCH_ALL_PROJECT_RESOURCE_REPORT[REQUEST],
    getAllReports,
  ),
  watchDeisgntion: takeLatest(actionTypes.ORGANIZATION_DESIGNATION[REQUEST], getDesignationList),
  watchDeisgntionData: takeLatest(
    actionTypes.ORGANISATION_DESIGNATION_DATA[REQUEST],
    getDesignationDataList,
  ),
  watchGetResourceReportDropdownData: takeLatest(
    actionTypes.PROJECT_RESOURCE_REPORT_DROPDOWN[REQUEST],
    getResourceReportDropdownData,
  ),
  watchProjectQAReport: takeLatest(
    actionTypes.FETCH_PROJECT_QA_REPORT[REQUEST],
    fetchProjectQAReport,
  ),
  watchGetNonBillable: takeLatest(
    actionTypes.FETCH_NON_BILLABLE_RESOURCES[REQUEST],
    getNonBillableResources,
  ),
  watchUpdateNonBillable: takeLatest(
    actionTypes.UPDATE_NON_BILLABLE_RESOURCES[REQUEST],
    updateNonBillable,
  ),
  watchProjectManagementReports: takeLatest(
    actionTypes.FETCH_PROJECT_MANAGEMENT_REPORT[REQUEST],
    getProjectManagementReports,
  ),
  watchProjectCustomerList: takeLatest(
    actionTypes.FETCH_PROJECT_CUSTOMERS_LIST[REQUEST],
    getProjectCustomerList,
  ),
  watchCreateProjectCustomer: takeLatest(
    actionTypes.CREATE_PROJECT_CUSTOMERS[REQUEST],
    createProjectCustomers,
  ),
  watchCreateMandateType: takeLatest(
    actionTypes.CREATE_MANDATE_TYPE[REQUEST],
    createMandateType,
  ),
  watchCreateDomainType: takeLatest(
    actionTypes.CREATE_DOMAIN_TYPE[REQUEST],
    createDomainType,
  ),

  watchUpdateMandateType: takeLatest(
    actionTypes.UPDATE_MANDATE_TYPE[REQUEST],
    updateMandateType,
  ),

  watchUpdateDomainType: takeLatest(
    actionTypes.UPDATE_DOMAIN_TYPE[REQUEST],
    updateDomainType,
  ),

  watchDeleteMandateType: takeLatest(
    actionTypes.DELETE_MANDATE_TYPE[REQUEST],
    deleteMandateTypeSaga,
  ),

  watchDeleteProjectCustomer: takeLatest(
    actionTypes.DELETE_PROJECT_CUSTOMER[REQUEST],
    deleteProjectCustomerSaga,
  ),
  watchUpdateProjectCustomer: takeLatest(
    actionTypes.UPDATE_PROJECT_CUSTOMER[REQUEST],
    updateProjectCustomer,
  ),
  watchDownloadCsvForMyTeam: takeLatest(
    actionTypes.DOWNLOAD_CSV_FOR_MY_TEAM[REQUEST],
    downloadCsvForMyTeam,
  ),
}
