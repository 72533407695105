import { useEffect } from 'react'
import { Box, Dialog, DialogTitle, IconButton, Backdrop, Grid, styled, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { connect } from 'react-redux'
import { createMandateType, updateMandateType, updateProjectCustomer } from '../../../actions'
import { style } from './projectCustomersStyle'
import FormikForm from './FormikForm'
import { FormValues, DiaglogFormPropsType } from './ProjectCustomersTypes'
import { Dispatch } from 'redux'
import { FormikHelpers } from 'formik';
import styles from '../../../utils/styles.json'


const Heading = styled(Typography)(({ theme }) => ({
  fontSize: '22px',
  textAlign: 'center',
  fontFamily: styles.FONT_BOLD,
  letterSpacing: '0px',
}))

const DiaglogForm = ({
  openCustomerForm,
  setOpenCustomerForm,
  openCustomerEditForm,
  setopenCustomerEditForm,
  createProjectCustomers,
  editCustomerData,
  updateProjectCustomer,
}: DiaglogFormPropsType) => {
  useEffect(() => {}, [editCustomerData])

  const handleClickCloseDiaglogForm = () => {
    setOpenCustomerForm(false)
    setopenCustomerEditForm(false)
  }

  const initialValues = {
    id: openCustomerEditForm ? editCustomerData.id : 0,
    mandate_name: openCustomerEditForm ? editCustomerData.mandate_name : '',
  }
  

  const handleSubmit = (values: any, actions: FormikHelpers<FormValues>) => {
    
    if (!values.mandate_name) {
      actions.setErrors({
        mandate_name: !values.mandate_name ? 'Required' : '',
      })
    } else {
      if (openCustomerEditForm) {
        const updateValues = {
          id: values.id,
          mandateName: values.mandate_name
        };
        updateProjectCustomer(updateValues)
        actions.setSubmitting(false)
        handleClickCloseDiaglogForm()
      } else {
        createProjectCustomers(values)
        actions.setSubmitting(false)
        handleClickCloseDiaglogForm()
      }
    }
  }

  return (
        <Box>
          <Dialog
            open={openCustomerForm || openCustomerEditForm}
            onClose={handleClickCloseDiaglogForm}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            BackdropComponent={Backdrop}
            sx={style.BackdropDiaglogStyle}
          >
            <Box>
              <Grid item xs={12} sm={12} md={12}/>
              <DialogTitle id='alert-dialog-title'>
                <Heading>{openCustomerEditForm ? ('Edit '):('Create ')}Mandate</Heading>
                <IconButton
                  aria-label='close'
                  onClick={handleClickCloseDiaglogForm}
                  sx={{ ...style.iconButtonStyle }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <FormikForm
                initialValues={initialValues}
                onSubmit={handleSubmit}
                handleClickCloseDiaglogForm={handleClickCloseDiaglogForm}
                openCustomerEditForm={openCustomerEditForm}
              />
            </Box>
          </Dialog>
        </Box>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createProjectCustomers: (data: FormValues) => dispatch(createMandateType.request({ data })),
  updateProjectCustomer: (data: any) => dispatch(updateMandateType.request({ data })),
})

export default connect(null, mapDispatchToProps)(DiaglogForm)
