import styles from '../../../utils/styles.json'

export const style = {
  container: {
    overflow: 'hidden',
    fontFamily: 'Montserrat-Bold',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    background: '#FFFFFF',
    opacity: '1',
    textAlign: 'left',
    margin: '20px',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '25px',
    width: '93%',
    border: '1px solid #DDDDDD',
    '@media print': {
      '@page': {
        size: 'landscape',
      },
    },
  },
  pageHead: {
    padding: '0 32px 0',
    paddingLeft: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    '@media only screen and (max-width:860px)': {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  headings: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  primaryHeading: {
    fontSize: '1.3rem',
    fontFamily: 'Montserrat-Bold',
    color: '#353665',
    textAlign: 'center',
  },
  headerHeading: {
    fontSize: '0.9rem',
    textAlign: 'left',
    fontFamily: 'Montserrat-Regular',
    color: '#353665',
    '@media only screen and (max-width:780px)': {
      fontSize: '0.7rem',
      textAlign: 'center',
    },
  },
  tableContainer: {
    '*::-webkit-scrollbar ': { display: 'none' },
    '@media only screen and (max-width:860px)': {
      marginLeft: '40px',
    },
  },
  border: {
    borderLeft: '1px solid #E9E9E9',
    borderRight: '1px solid #E9E9E9',
  },
  cellWidth: {
    width:"150px"
  },
  tableHead: {
    '& th:first-of-type': {
      borderRadius: '50px 0 0 50px',
      border: 'none',
    },
    '& th:last-of-type': {
      borderRadius: '0 50px 50px 0',
      border: 'none',
    },
    '& th': {
      fontFamily: styles.FONT_MEDIUM,
      fontSize: '1rem',
      padding: '15px 0 15px 0',
      border: 'none',
    },
    backgroundColor: styles.PRIMARY_COLOR,
  },
  tableHeadCell: {
    color: 'white',
    minWidth: '230px',
  },
  rowBorder: {
    borderTop: '1px solid #EEEEEE',
    borderBottom: '1px solid #EEEEEE',
    minHeight: '60px',
    margin: '16px 0 0',
  },
  rowInnerItem: {
    fontFamily: 'Montserrat-Regular',
    fontSize: '0.8rem',
    padding: '0',
  },
  serachBoxContainer: {
    '@media only screen and (max-width:1240px)': {
      marginRight: '1rem',
    },
  },
  actionBarConatiner: {
    width: '97%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionBar: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingLeft: '1rem',
    width: '100%',
    '@media only screen and (max-width:1240px)': {
      width: '70%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  rightGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'max(390px, 30%)',
    '@media only screen and (max-width:1245px)': {
      marginRight: '1.5rem',
    },
    '@media only screen and (max-width:480px)': {
      flexDirection: 'column',
      marginBottom: '1rem',
    },
  },
  datePickerContainer: {
    height: '40px',
  },
  expandButton: {
    backgroundColor: 'transparent',
    position: 'absolute',
    right: '0',
    top: '0.5rem',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  cardSection: {
    width: '260px',
    padding: '0 10px',
    height: '45px',
    display: 'flex',
    flexwrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'primary',
    backgroundColor: '#c1c1c1',
    textTransform: 'uppercase',
    borderRadius: '20px',
  },
  cardText: {
    color: '#353665',
    fontFamily: styles.FONT_MEDIUM,
    fontSize: '0.77rem',
    '@media only screen and (max-width:480px)': {
      fontSize: '0.65rem',
    },
  },
  loader: {
    backgroundColor: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '2rem',
  },
}
