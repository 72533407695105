import { combineReducers } from 'redux'
import { Actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import {
  DOWNLOAD_CERTIFICATE,
  EMPLOYEE_HISTORY,
  FAILURE,
  MANAGER_HISTORY,
  REQUEST,
  RESET,
  SUCCESS,
  UPLOAD_CERTIFICATE,
} from '../../actions/actiontypes'

const ui = () => {
  const isGettingEmpHistoryData = (state = false, action: Actions) => {
    switch (action.type) {
      case EMPLOYEE_HISTORY[SUCCESS]:
        return false
      case EMPLOYEE_HISTORY[REQUEST]:
        return true
      default:
        return state
    }
  }
  const isGettingmManagerHistoryData = (state = false, action: Actions) => {
    switch (action.type) {
      case MANAGER_HISTORY[SUCCESS]:
        return false
      case MANAGER_HISTORY[REQUEST]:
        return true
      default:
        return state
    }
  }
  const isDownloadingCertificate = (state = false, action: Actions) => {
    switch (action.type) {
      case DOWNLOAD_CERTIFICATE[SUCCESS]:
      case DOWNLOAD_CERTIFICATE[FAILURE]:
      case DOWNLOAD_CERTIFICATE[RESET]:
        return false
      case DOWNLOAD_CERTIFICATE[REQUEST]:
        return true
      default:
        return state
    }
  }
  const isUploadingCertificate = (state = false, action: Actions) => {
    switch (action.type) {
      case UPLOAD_CERTIFICATE[SUCCESS]:
      case UPLOAD_CERTIFICATE[FAILURE]:
      case UPLOAD_CERTIFICATE[RESET]:
        return false
      case UPLOAD_CERTIFICATE[REQUEST]:
        return true
      default:
        return state
    }
  }

  return combineReducers({
    isGettingEmpHistoryData,
    isGettingmManagerHistoryData,
    isDownloadingCertificate,
    isUploadingCertificate,
  })
}
export default ui

export const getAllEmpPortalUI = (state: RootState) => state.ui.employeePortal
