import { combineReducers } from 'redux'
import { Actions as actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import {
  ADD_EMP_BASED_ON_ROLE,
  ADD_NEW_PROJECT,
  DELETE_EMP_BASED_ON_ROLE,
  DELETE_PROJECT,
  DESIGNATION_GRAPH_DATA,
  DESIGNATION_LIST,
  EDIT_PROJECT,
  FETCH_ALL_PROJECT_RESOURCE_REPORT,
  FETCH_NON_BILLABLE_RESOURCES,
  FETCH_PROJECT_CUSTOMERS,
  FETCH_MANDATE_TYPE,
  FETCH_DOMAIN_TYPE,
  FETCH_PROJECT_CUSTOMERS_LIST,
  FETCH_PROJECT_DETAILS,
  FETCH_PROJECT_DOMAIN,
  FETCH_PROJECT_LOCATION,
  FETCH_PROJECT_MANAGEMENT_REPORT,
  FETCH_PROJECT_QA_REPORT,
  FETCH_PROJECT_REPORTS,
  FETCH_PROJECT_SOURCE,
  FETCH_PROJECT_TYPES,
  FETCH_TECHNOLOGIES,
  FETCH_WORKING_EMP,
  ORGANISATION_DESIGNATION_DATA,
  ORGANIZATION_DESIGNATION,
  PROJECT_GRAPH_DATA,
  PROJECT_RESOURCE_REPORT_DROPDOWN,
  REQUEST,
  RESTART_PROJECT,
  SUCCESS,
  UPDATE_PROJECT,
  FETCH_REPORTS,
} from '../../actions/actiontypes'

const entity = () => {
  const fetchProjectGraphData = (state = [], action: actions) => {
    switch (action.type) {
      case PROJECT_GRAPH_DATA[SUCCESS]:
        return action.payload
      case PROJECT_GRAPH_DATA[REQUEST]:
        return []

      default:
        return state
    }
  }

  const fetchDesignationGraphData = (state = [], action: actions) => {
    switch (action.type) {
      case DESIGNATION_GRAPH_DATA[SUCCESS]:
        return action.payload
      case DESIGNATION_GRAPH_DATA[REQUEST]:
        return []

      default:
        return state
    }
  }

  const fetchDesignationList = (state = [], action: actions) => {
    switch (action.type) {
      case DESIGNATION_LIST[SUCCESS]:
        return action.payload
      case DESIGNATION_LIST[REQUEST]:
        return []

      default:
        return state
    }
  }
  const fetchProjectDomain = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_PROJECT_DOMAIN[SUCCESS]:
        return action.payload
      case FETCH_PROJECT_DOMAIN[REQUEST]:
        return []

      default:
        return state
    }
  }

  const fetchProjectSource = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_PROJECT_SOURCE[SUCCESS]:
        return action.payload
      case FETCH_PROJECT_SOURCE[REQUEST]:
        return []

      default:
        return state
    }
  }

  const fetchProjectTypes = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_PROJECT_TYPES[SUCCESS]:
        return action.payload
      case FETCH_PROJECT_TYPES[REQUEST]:
        return []

      default:
        return state
    }
  }
  const fetchAllTechnologies = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_TECHNOLOGIES[SUCCESS]:
        return action.payload
      case FETCH_TECHNOLOGIES[REQUEST]:
        return []

      default:
        return state
    }
  }
  const fetchAllProjectLocation = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_PROJECT_LOCATION[SUCCESS]:
        return action.payload
      case FETCH_PROJECT_LOCATION[REQUEST]:
        return []

      default:
        return state
    }
  }
  const getNewProjectDetails = (state = [], action: actions) => {
    switch (action.type) {
      case ADD_NEW_PROJECT[SUCCESS]:
        return action.payload
      case ADD_NEW_PROJECT[REQUEST]:
        return []

      default:
        return state
    }
  }

  const getAllDeletedProject = (state = [], action: actions) => {
    switch (action.type) {
      case DELETE_PROJECT[SUCCESS]:
        return action.payload
      case DELETE_PROJECT[REQUEST]:
        return []

      default:
        return state
    }
  }

  const getAllProjectCustomers = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_PROJECT_CUSTOMERS[SUCCESS]:
        return action.payload
      case FETCH_PROJECT_CUSTOMERS[REQUEST]:
        return []

      default:
        return state
    }
  }

  const getAllMandate = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_MANDATE_TYPE[SUCCESS]:
        return action.payload
      case FETCH_MANDATE_TYPE[REQUEST]:
        return []

      default:
        return state
    }
  }

  const getAllDomain = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_DOMAIN_TYPE[SUCCESS]:
        return action.payload
      case FETCH_DOMAIN_TYPE[REQUEST]:
        return []

      default:
        return state
    }
  }

  const getAllRestartedProject = (state = [], action: actions) => {
    switch (action.type) {
      case RESTART_PROJECT[SUCCESS]:
        return action.payload
      case RESTART_PROJECT[REQUEST]:
        return []

      default:
        return state
    }
  }

  const getAllprojectDetails = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_PROJECT_DETAILS[SUCCESS]:
        return action.payload
      case FETCH_PROJECT_DETAILS[REQUEST]:
        return []

      default:
        return state
    }
  }

  const getAllEmpList = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_WORKING_EMP[SUCCESS]:
        return action.payload
      case FETCH_WORKING_EMP[REQUEST]:
        return []

      default:
        return state
    }
  }

  const getEmpBasedOnRoles = (state = [], action: actions) => {
    switch (action.type) {
      case ADD_EMP_BASED_ON_ROLE[SUCCESS]:
        return action.payload
      case ADD_EMP_BASED_ON_ROLE[REQUEST]:
        return []

      default:
        return state
    }
  }

  const getUpdatedProject = (state = [], action: actions) => {
    switch (action.type) {
      case UPDATE_PROJECT[SUCCESS]:
        return action.payload
      case UPDATE_PROJECT[REQUEST]:
        return []

      default:
        return state
    }
  }

  const getDeletedRecords = (state = [], action: actions) => {
    switch (action.type) {
      case DELETE_EMP_BASED_ON_ROLE[SUCCESS]:
        return action.payload
      case DELETE_EMP_BASED_ON_ROLE[REQUEST]:
        return []

      default:
        return state
    }
  }

  const getEditedProjects = (state = [], action: actions) => {
    switch (action.type) {
      case EDIT_PROJECT[SUCCESS]:
        return action.payload
      case EDIT_PROJECT[REQUEST]:
        return []

      default:
        return state
    }
  }

  const getProjectReports = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_PROJECT_REPORTS[SUCCESS]:
        return action.payload
      case FETCH_PROJECT_REPORTS[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getReports = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_REPORTS[SUCCESS]:
        return action.payload
      case FETCH_REPORTS[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getResourceReportData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_ALL_PROJECT_RESOURCE_REPORT[SUCCESS]:
        return action.payload
      case FETCH_ALL_PROJECT_RESOURCE_REPORT[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getOrganizationDesignations = (state = [], action: actions) => {
    switch (action.type) {
      case ORGANIZATION_DESIGNATION[SUCCESS]:
        return action.payload
      case ORGANIZATION_DESIGNATION[REQUEST]:
        return []

      default:
        return state
    }
  }

  const getOrganizationDesignationsData = (state = [], action: actions) => {
    switch (action.type) {
      case ORGANISATION_DESIGNATION_DATA[SUCCESS]:
        return action.payload
      case ORGANISATION_DESIGNATION_DATA[REQUEST]:
        return []

      default:
        return state
    }
  }

  const getResourceReportDropdowndata = (state = [], action: actions) => {
    switch (action.type) {
      case PROJECT_RESOURCE_REPORT_DROPDOWN[SUCCESS]:
        return action.payload
      case PROJECT_RESOURCE_REPORT_DROPDOWN[REQUEST]:
        return []

      default:
        return state
    }
  }

  const getProjectManagementReports = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_PROJECT_MANAGEMENT_REPORT[SUCCESS]:
        return action.payload
      case FETCH_PROJECT_MANAGEMENT_REPORT[REQUEST]:
        return []
      default:
        return state
    }
  }
  const getProjectQAReportDetails = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_PROJECT_QA_REPORT[SUCCESS]:
        return action.payload
      case FETCH_PROJECT_QA_REPORT[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getNonBillableResourcesData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_NON_BILLABLE_RESOURCES[REQUEST]:
        return []
      case FETCH_NON_BILLABLE_RESOURCES[SUCCESS]:
        return action.payload
      default:
        return state
    }
  }
  const getProjectCustomersDetails = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_PROJECT_CUSTOMERS_LIST[SUCCESS]:
        return action.payload
      case FETCH_PROJECT_CUSTOMERS_LIST[REQUEST]:
        return []
      default:
        return state
    }
  }

  return combineReducers({
    fetchProjectSource,
    fetchDesignationList,
    fetchDesignationGraphData,
    fetchProjectDomain,
    fetchProjectGraphData,
    fetchProjectTypes,
    fetchAllTechnologies,
    fetchAllProjectLocation,
    getNewProjectDetails,
    getAllDeletedProject,
    getAllProjectCustomers,
    getAllMandate,
    getAllRestartedProject,
    getAllprojectDetails,
    getAllEmpList,
    getEmpBasedOnRoles,
    getUpdatedProject,
    getDeletedRecords,
    getEditedProjects,
    getProjectReports,
    getResourceReportData,
    getOrganizationDesignations,
    getOrganizationDesignationsData,
    getResourceReportDropdowndata,
    getProjectManagementReports,
    getProjectQAReportDetails,
    getNonBillableResourcesData,
    getProjectCustomersDetails,
    getAllDomain,
    getReports
  })
}

export default entity

export const getAllProjectManagement = (state: RootState) => state.entities.projectManagement
