import {
  Avatar,
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  Typography,
  styled,
  Grid,
  CircularProgress,
  Dialog,
  Box,
} from '@mui/material'
import React, { useState } from 'react'
import html2pdf from 'html2pdf.js'
import PdfIcon from '../../assets/images/pdf_image.png'
import { connect, useSelector } from 'react-redux'
import { RootState } from '../../configureStore'
import { Dispatch } from 'redux'
import { fetchEmpSalarySlip, getPaySlip, getTimesheet } from '../../actions'
import {
  fetchUserDetailsEntities,
  fetchUserDetailsUI,
  financeEntity,
  financeUI,
} from '../../reducers'
import moment from 'moment'
import { loaderProps } from '../Common/CommonStyles'
import style from '../../utils/styles.json'
import { useLocation, useNavigate } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'
const CardStyleKey = {
  width: '230px',
}

const CardStyleValue = {
  width: '200px',
}

const CardStyleKeyNew = {
  width: '200px',
}

const cardStyles = {
  border: 'none',
}
const NotEligibleEmployee = styled(Paper)(({ theme }) => ({
  width: '70%',
  padding: '30px 25px',
  background: '#FFFFFF',
  opacity: '1',
  marginTop: '25px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '20px',
  border: '1px solid #DDDDDD',
}))

const EligibleEmployee = styled(Paper)(({ theme }) => ({
  width: '80%',
  padding: '30px 25px',
  background: '#FFFFFF',
  opacity: '1',
  marginTop: '25px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '20px',
  border: '1px solid #DDDDDD',
}))

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontFamily: style.FONT_MEDIUM,
  textAlign: 'center',
  letterSpacing: '0px',
}))

const SubHeading = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontFamily: style.FONT_MEDIUM,
  textAlign: 'center',
  letterSpacing: '0px',
  color: '#6c6b6b',
}))

const ExportButton = { display: 'flex', justifyContent: 'flex-end' }

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '13px',
  fontFamily: style.FONT_MEDIUM,
}))

const Paragraph = styled(Typography)(({ theme }) => ({
  fontSize: '13px',
  fontFamily: style.FONT_MEDIUM,
  textAlign: 'center',
  letterSpacing: '0px',
  lineHeight: 1.8,
  marginTop: '15px',
}))

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  // marginTop: '4.5px',
}))

const MainContainer = {
  background: 'rgb(231, 235, 240)',
  width: '100%',
}

const TextStyle = {
  fontFamily: style.FONT_MEDIUM,
  fontSize: '13px',
  marginBottom: '5px',
}
const SelectField = styled(Select)(({ theme }) => ({
  width: '240px',
  borderRadius: '20px',
  '& .MuiSelect-select': {
    padding: '11px 14px',
    fontSize: '13px',
    fontFamily: style.FONT_MEDIUM,
    borderRadius: '20px',
  },
}))

const TextStylenew = {
  fontFamily: style.FONT_MEDIUM,
  fontSize: '13px',
  height: 0,
}

const handleExportPDF = (emname: string, month: string, year: number) => {
  const element = document.getElementById('pdf-export')
  const PaySlipname = `${emname}_${month}_${year}`

  if (element) {
    const options = {
      margin: 10,
      filename: PaySlipname,
      image: { type: 'png', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a3', orientation: 'portrait' },
    }

    html2pdf().set(options).from(element).save()
  }
}

const SalarySlip = (props: any) => {
  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const [payslipMonth, setPayslipMonth] = useState('')
  const [paySlipYear, setPaySlipYear] = useState(0)
  const {
    fetchTimesheet,
    timesheetData,
    isPayslipRequest,
    fetchPaySlips,
    payslipData,
    loaderStateoftimeSheet,
    fetchEmpSalarySlip,
    salarySlipData,
    isSalarySlipData,
  } = props

  const rowData: any = useLocation()?.state
  const navigate = useNavigate();

  const [selectedPaySlipDateOption, setPaySlipDateOption] = useState('')

  const handlePaySlipDateChange = (event: any, timesheets: any[]) => {
    const selectedId = event.target.value
    const selectedTimesheet = timesheets.find((timesheet) => timesheet.id === selectedId)
    if (selectedTimesheet) {
      let dateIndex: number = new Date(selectedTimesheet.start_date).getMonth()
      setPayslipMonth(month[dateIndex])
      setPaySlipYear(new Date(selectedTimesheet.start_date).getFullYear())
      setPaySlipDateOption(selectedTimesheet.id)
      //   fetchPaySlips(selectedTimesheet.id)
      fetchEmpSalarySlip({ tId: selectedTimesheet.id, userId: rowData?.userid })
    }
  }
  React.useEffect(() => {
    fetchTimesheet()
  }, [])

  React.useEffect(() => {
    if (loaderStateoftimeSheet && timesheetData.length) {
      setPaySlipDateOption(timesheetData[0].id)
      let dateIndex: number = new Date(timesheetData[0].start_date).getMonth()
      setPayslipMonth(month[dateIndex])
      setPaySlipYear(new Date(timesheetData[0].start_date).getFullYear())

      fetchEmpSalarySlip({ tId: timesheetData[0].id, userId: rowData?.userid })
    }
  }, [loaderStateoftimeSheet])

  const CompanyName = useSelector(
    (state: { entities: { dashboard: { getUserDetails: { company_name: string } } } }) =>
      state?.entities?.dashboard?.getUserDetails?.company_name,
  )

  return (
    <div
      style={{
        ...MainContainer,
        paddingBottom: loaderStateoftimeSheet && !timesheetData.length ? '250px' : '20px',
      }}
    >
      <Box sx={{ textAlign: 'left' }}>
        <Box
          onClick={() => navigate(-1)}
          sx={{ float: 'right', pr: '60px', mt: '5px', cursor: 'pointer' }}
        >
          <ArrowBack />
        </Box>
      </Box>
      <Dialog open={isSalarySlipData} PaperProps={{ style: loaderProps }}>
        <CircularProgress color='secondary' />
      </Dialog>
      {loaderStateoftimeSheet && !timesheetData.length ? (
        <NotEligibleEmployee elevation={3}>
          <Heading>{CompanyName} Salary Slip Portal</Heading>

          <Paragraph>
            You are not able to access the salary slips because you are under the probation period.
            For more information please drop mail to hr@tudip.com.
          </Paragraph>
        </NotEligibleEmployee>
      ) : null}
      {loaderStateoftimeSheet && timesheetData.length ? (
        <EligibleEmployee>
          <Grid item xs={12} sm={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <StyledFormControl>
                <InputLabel id='demo-simple-select-readonly-label'>Select Payslip</InputLabel>
                <SelectField
                  variant='outlined'
                  value={selectedPaySlipDateOption}
                  label='Select Payslip:'
                  onChange={(event) => handlePaySlipDateChange(event, timesheetData)}
                >
                  {timesheetData?.map((option: any) => (
                    <StyledMenuItem key={`${option?.id}`} value={option?.id}>
                      ({moment(option?.start_date).format('DD/MM/YYYY')}) to (
                      {moment(option?.end_date).format('DD/MM/YYYY')})
                    </StyledMenuItem>
                  ))}
                </SelectField>
              </StyledFormControl>
            </div>

            <div style={ExportButton}>
              <Button
                variant='outlined'
                sx={{
                  fontSize: '14px',
                  height: '42px',
                  float: 'right',
                  borderRadius: '20px',
                }}
                startIcon={
                  <Avatar sx={{ width: 24, height: 24 }}>
                    <img src={PdfIcon} alt='PDF' />
                  </Avatar>
                }
                onClick={(e) =>
                  handleExportPDF(
                    payslipData?.employeeDetails?.full_name,
                    payslipMonth,
                    paySlipYear,
                  )
                }
              >
                EXPORT AS PDF
              </Button>
            </div>
          </Grid>

          <div id='pdf-export'>
            <Heading>{CompanyName}, Pune</Heading>
            <SubHeading>
              Salary Slip for the Month of {payslipMonth} {paySlipYear}
            </SubHeading>
            <TableContainer
              component={Paper}
              sx={{ border: '1px solid #DDDDDD', width: '99.8%', mt: '40px' }}
            >
              <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography sx={{ paddingTop: '5px' }} style={TextStyle}>
                        Name: {payslipData?.employeeDetails?.full_name}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <TableRow>
                    <Card sx={cardStyles} variant='outlined'>
                      <CardContent sx={CardStyleKeyNew}>
                        <Typography style={TextStyle}>Employee No:</Typography>

                        <Typography style={TextStyle}>Designation:</Typography>

                        <Typography style={TextStyle}>PF No:</Typography>

                        <Typography style={TextStyle}>UAN No:</Typography>
                      </CardContent>
                    </Card>
                  </TableRow>
                  <TableRow>
                    <Card
                      sx={cardStyles}
                      variant='outlined'
                      style={{ textAlign: 'end', position: 'relative', left: '15px' }}
                    >
                      <CardContent sx={CardStyleValue}>
                        <Typography style={TextStyle}>
                          <span>
                            {payslipData?.employeeDetails?.employee_id
                              ? payslipData?.employeeDetails?.employee_id
                              : 'NA'}
                          </span>
                        </Typography>

                        <Typography style={TextStyle}>
                          <span>
                            {' '}
                            {payslipData?.employeeDetails?.job_level
                              ? payslipData?.employeeDetails?.job_level
                              : 'NA'}
                          </span>
                        </Typography>

                        <Typography sx={{ fontSize: '13px', marginTop: '4px' }}>
                          <span>
                            {' '}
                            {payslipData?.employeeDetails?.pf_no
                              ? payslipData?.employeeDetails?.pf_no
                              : 'NA'}
                          </span>
                        </Typography>

                        <Typography sx={{ fontSize: '13px', marginTop: '6px' }}>
                          <span>
                            {payslipData?.employeeDetails?.uan_no
                              ? payslipData?.employeeDetails?.uan_no
                              : 'NA'}
                          </span>
                        </Typography>
                      </CardContent>
                    </Card>
                  </TableRow>
                  <Divider orientation='vertical' flexItem />
                  <TableRow>
                    <Card
                      sx={cardStyles}
                      style={{ position: 'relative', right: '20px' }}
                      variant='outlined'
                    >
                      <CardContent sx={CardStyleKeyNew}>
                        <Typography style={TextStyle}>Bank Name:</Typography>

                        <Typography style={TextStyle}>Bank Account No:</Typography>

                        <Typography style={TextStyle}>Bank Branch:</Typography>

                        <Typography style={TextStyle}>PAN Card No:</Typography>

                        <Typography style={TextStyle}>Aadhaar No:</Typography>
                      </CardContent>
                    </Card>
                  </TableRow>

                  <TableRow>
                    <Card sx={cardStyles} variant='outlined' style={{ textAlign: 'end' }}>
                      <CardContent sx={CardStyleValue}>
                        <Typography style={TextStyle}>
                          <span>{payslipData?.employeeDetails?.bank_name || 'NA'}</span>
                        </Typography>

                        <Typography style={TextStyle}>
                          <span> {payslipData?.employeeDetails?.bank_account_no || 'NA'}</span>
                        </Typography>

                        <Typography style={TextStyle}>
                          <span> {payslipData?.employeeDetails?.bank_branch || 'NA'}</span>
                        </Typography>

                        <Typography style={TextStyle}>
                          <span>{payslipData?.employeeDetails?.pan_card_no || 'NA'}</span>
                        </Typography>

                        <Typography style={TextStyle}>
                          <span>{payslipData?.employeeDetails?.aadhaarCard || 'NA'}</span>
                        </Typography>
                      </CardContent>
                    </Card>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer
              component={Paper}
              sx={{ border: '1px solid #DDDDDD', marginTop: '20px', width: '99.8%' }}
            >
              <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
                <TableHead>
                  <TableBody sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <TableRow>
                      <Card sx={cardStyles} variant='outlined'>
                        <CardContent sx={CardStyleKeyNew}>
                          <Typography
                            style={TextStylenew}
                            sx={{ width: '220px', paddingTop: '0px', paddingBottom: '0px' }}
                          >
                            Earnings and Reimbursements
                          </Typography>
                        </CardContent>
                      </Card>
                    </TableRow>

                    <TableRow>
                      <Card
                        sx={cardStyles}
                        variant='outlined'
                        style={{ textAlign: 'right', position: 'relative', left: '15px' }}
                      >
                        <CardContent sx={CardStyleValue}>
                          <Typography
                            style={TextStylenew}
                            sx={{ paddingTop: '0px', paddingBottom: '0px', marginLeft: '70px' }}
                          >
                            <span>In Rs</span>
                          </Typography>
                        </CardContent>
                      </Card>
                    </TableRow>

                    <Divider orientation='vertical' flexItem />
                    <TableRow>
                      <Card
                        sx={cardStyles}
                        style={{ position: 'relative', right: '15px' }}
                        variant='outlined'
                      >
                        <CardContent sx={CardStyleKeyNew}>
                          <Typography
                            style={TextStylenew}
                            sx={{ paddingTop: 'px', paddingBottom: '0px', marginLeft: '-7px' }}
                          >
                            Deductions and Recoveries
                          </Typography>
                        </CardContent>
                      </Card>
                    </TableRow>

                    <TableRow>
                      <Card sx={cardStyles} variant='outlined' style={{ textAlign: 'end' }}>
                        <CardContent sx={CardStyleValue}>
                          <Typography
                            style={TextStylenew}
                            sx={{
                              paddingTop: '0px',
                              paddingBottom: '7px',
                              marginLeft: '77px',
                              marginRight: '0px',
                              paddingRight: '0px',
                            }}
                          >
                            <span>In Rs</span>
                          </Typography>
                        </CardContent>
                      </Card>
                    </TableRow>
                  </TableBody>
                </TableHead>
                <Divider orientation='horizontal' />
                <TableBody sx={{ display: 'flex', justifyContent: 'space-around' }}>
                  <TableRow>
                    <Card sx={cardStyles} variant='outlined'>
                      <CardContent sx={CardStyleKey}>
                        {payslipData?.allowances?.enrichedAllowances?.map(
                          (item: any, index: any) => (
                            <>
                              <Typography key={index} style={TextStyle}>
                                {item?.name}
                              </Typography>
                            </>
                          ),
                        )}
                      </CardContent>
                    </Card>
                  </TableRow>

                  <TableRow>
                    <Card sx={cardStyles} variant='outlined'>
                      <CardContent sx={CardStyleValue} style={{ textAlign: 'right' }}>
                        {payslipData?.allowances?.enrichedAllowances?.map(
                          (item: any, index: any) => (
                            <>
                              <Typography style={TextStyle}>
                                <span style={{ marginLeft: '61px' }}>
                                  {item?.pay_period_amount}
                                </span>
                              </Typography>
                            </>
                          ),
                        )}
                      </CardContent>
                    </Card>
                  </TableRow>

                  <Divider orientation='vertical' sx={{ marginRight: '31px' }} flexItem />
                  <TableRow>
                    <Card
                      sx={{ border: 'none', position: 'relative', right: '20px' }}
                      variant='outlined'
                    >
                      <CardContent sx={CardStyleKeyNew}>
                        {payslipData?.deductions?.enrichedDeductions?.map(
                          (item: any, index: any) => (
                            <>
                              <Typography
                                key={index}
                                sx={{ marginLeft: '-16px' }}
                                style={TextStyle}
                              >
                                <span>{item?.name}</span>
                              </Typography>
                            </>
                          ),
                        )}
                      </CardContent>
                    </Card>
                  </TableRow>

                  <TableRow>
                    <Card sx={cardStyles} variant='outlined' style={{ textAlign: 'right' }}>
                      <CardContent sx={CardStyleValue}>
                        {payslipData?.deductions?.enrichedDeductions?.map(
                          (item: any, index: any) => (
                            <>
                              <Typography key={index} style={TextStyle}>
                                <span style={{ marginLeft: '80px' }}>
                                  {item?.pay_period_amount}
                                </span>
                              </Typography>
                            </>
                          ),
                        )}
                      </CardContent>
                    </Card>
                  </TableRow>
                </TableBody>
              </Table>
              <Divider orientation='horizontal' flexItem />
              <TableBody sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <TableRow>
                  <Card sx={cardStyles} variant='outlined'>
                    <CardContent sx={CardStyleKeyNew}>
                      <Typography style={TextStyle} sx={{ color: '#000' }}>
                        Total Earnings:
                      </Typography>
                    </CardContent>
                  </Card>
                </TableRow>

                <TableRow>
                  <Card
                    sx={cardStyles}
                    variant='outlined'
                    style={{ position: 'relative', left: '20px', textAlign: 'end' }}
                  >
                    <CardContent sx={CardStyleValue}>
                      <Typography style={TextStyle}>
                        <span style={{ marginLeft: '73px' }}>
                          {payslipData?.allowances?.totalAllowances}
                        </span>
                      </Typography>
                    </CardContent>
                  </Card>
                </TableRow>
                <Divider orientation='vertical' flexItem />
                <TableRow>
                  <Card
                    sx={cardStyles}
                    style={{ position: 'relative', right: '17px' }}
                    variant='outlined'
                  >
                    <CardContent sx={CardStyleKeyNew}>
                      <Typography sx={{ marginLeft: '-7px', color: '#000' }} style={TextStyle}>
                        Total Deduction:
                      </Typography>
                      <Typography sx={{ marginLeft: '-7px', color: '#000' }} style={TextStyle}>
                        Net Pay:
                      </Typography>
                    </CardContent>
                  </Card>
                </TableRow>

                <TableRow>
                  <Card sx={cardStyles} variant='outlined' style={{ textAlign: 'right' }}>
                    <CardContent sx={CardStyleValue}>
                      <Typography style={TextStyle}>
                        <span style={{ marginLeft: '77px' }}>
                          {payslipData?.deductions?.totalDeduction}
                        </span>
                      </Typography>
                      <Typography style={TextStyle}>
                        <span style={{ marginLeft: '77px' }}>
                          {payslipData?.net_pay ? payslipData?.net_pay : 'NA'}
                        </span>
                      </Typography>
                    </CardContent>
                  </Card>
                </TableRow>
              </TableBody>
              <Divider orientation='horizontal' flexItem />
            </TableContainer>
          </div>
        </EligibleEmployee>
      ) : null}
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    isSalarySlipData: fetchUserDetailsUI.fetchUserData(state).isEmpSalarySlip,
    payslipData: fetchUserDetailsEntities.fetchUserData(state).getSalarySlipInfo,
    timesheetData: financeEntity.getFinance(state).getTimesheetData,
    // payslipData: financeEntity.getFinance(state).getPaySlipData,
    loaderStateoftimeSheet: financeUI.getFinance(state).isTimesheetData,
    isPayslipRequest: financeUI.getFinance(state).isPayslipData,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchTimesheet: () => dispatch(getTimesheet.request()),
    fetchPaySlips: (data: any) => dispatch(getPaySlip.request(data)),
    fetchEmpSalarySlip: (data: any) => dispatch(fetchEmpSalarySlip.request(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SalarySlip)
