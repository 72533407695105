import { makeStyles } from '@mui/styles'
import React, { useState, useEffect } from 'react'
import {
  Card,
  CardContent,
  Paper,
  Typography,
  Dialog,
  CircularProgress,
  Stack,
  Divider,
  Box,
  Collapse,
} from '@mui/material'
import { dashboardEntity, dashboardUI } from '../../reducers'
import { useNavigate } from 'react-router-dom'
import { CurrentLoginUserProfile, OpenTabLogo } from '../../utils/StaticData'
import { connect } from 'react-redux'
import { getDrsData, getSubDrsData } from '../../actions'
import { HeaderHeading, loaderProps, StyledEditableTableCell } from '../Common/CommonStyles'
import { RootState } from '../../configureStore'
import { Dispatch } from 'redux'
import { ArrowBack } from '@mui/icons-material'
import style from '../../utils/styles.json'

function DRs({
  fetchDrsData,
  DrsData,
  loaderState,
  fetchSubDrsData,
  SubDrsData,
  SubDRsLoader,
}: any) {
  useEffect(() => {
    fetchDrsData()
  }, [])

  const navigate = useNavigate()

  const managerViewScreen = (userId: number) => {
    if (userId) {
      navigate(`/home/dashboard/drs/manager-view?id=${userId}`)
    }
  }

  const useStyles = makeStyles({
    image: {
      width: '80px',
      borderRadius: '50%',
      height: '80px',
    },
    container: {
      width: '100%',
      color: 'azure',
    },
    subContainer: {
      padding: '12px',
    },
    paperCard: {
      width: '100%',
      height: '100%',
      padding: '10px 0px 0px 0px',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 3px 6px #00000029',
      opacity: '1',
      marginTop: '10px',
      overflow: 'hidden',
    },
    drsHeader: {
      marginLeft: '90px !important',
      marginTop: '10px !important',
      marginBottom: '30px !important',
    },
    stack: {
      justifyContent: 'center',
      height: '40px',
    },
    divider: {
      position: 'relative',
      margin: '0% 5%',
    },
    cardContainer: {},
    subCardContainer: {},
    CommonCardStyled: {
      cursor: 'pointer',
      display: 'flex',
    },
    backArrow: {
      float: 'right',
      cursor: 'pointer',
    },
    cardEmpIdMidText: {
      fontSize: '15px',
      textAlign: 'left',
      marginTop: '8px',
      fontFamily: style.FONT_MEDIUM,
      fontWeight: '100',
      letterSpacing: '0px',
      color: style.PRIMARY_COLOR,
    },
    empIdHeading: {
      fontStyle: 'italic !important',
      color: `${style.PRIMARY_COLOR} !important`,
      margin: '0px !important',
      fontSize: '13px !important',
    },
    empDesignationHeading: {
      fontStyle: 'italic !important',
      color: `${style.PRIMARY_COLOR} !important`,
      bottom: '5px',
      fontSize: '13px !important',
    },
    boxContainer: {
      textAlign: 'left',
    },
    boxSubContainer: {
      float: 'right',
      marginRight: '30px',
      marginTop: '20px',
      cursor: 'pointer',
    },
    popoverNameText: {
      fontSize: 14,
      position: 'relative',
      fontFamily: style.FONT_BOLD,
      color: '#000',
    },
    popoverEmailPhoneHeading: {
      fontStyle: 'italic !important',
      color: '#000 !important',
      margin: '0px !important',
      fontSize: '13px !important',
    },
    popoverWorksationHeading: {
      fontStyle: 'italic !important',
      color: '#000 !important',
      bottom: '5px',
      fontSize: '13px !important',
    },
    root: {
      alignItems: 'center',
    },
    image1: {
      width: '70px',
      height: '70px',
      marginRight: '10px',
    },
    subroot: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '15px',
    },
    icon: {
      marginRight: '10px',
      color: style.PRIMARY_COLOR,
    },
    info: {
      color: 'gray',
      fontSize: 14,
      position: 'relative',
      fontFamily: style.FONT_BOLD,
    },
    subCardStyle: {
      width: '300px',
    },
  })
  const classes = useStyles()

  const cardStyles = {
    marginLeft: '10px',
    width: '25%',
    position: 'relative',
    left: '37.5%',
    paddingBottom: '0px',
  }

  const cardStyled = {
    justifyContent: 'normal',
    border: 'none',
    marginTop: '15px',
    marginBottom: '15px',
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    gap: '10px',
    textAlign: 'justify',
  }

  const CommonCardStyle: React.CSSProperties = {
    border: '1px solid #DDDDDD',
    borderRadius: 5,
    margin: '0% 15%',
    maxWidth: '70%',
    position: 'relative',
  }

  const CardHeadingText: React.CSSProperties = {
    fontSize: 14,
    position: 'relative',
    textAlign: 'left',
    fontFamily: style.FONT_BOLD,
    color: style.PRIMARY_COLOR,
    width: 'calc(100% - 30px)',
  }

  const CardMidText = {
    fontStyle: 'italic',
    lineHeight: '1.8',
    fontSize: '11px',
    fontFamily: style.FONT_MEDIUM,
    letterSpacing: '0px',
    color: '#00000080',
    opacity: '1',
  }

  const CardStyle = {
    padding: '16px 13px 16px 16px',
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [subDrsId, setSubDrsId] = useState<number>(0)
  const [rowClicked, setRowClicked] = useState(1)

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handleSubDrsClick = (index: number, itemId: number) => {
    setRowClicked(index)
    setSubDrsId(itemId)
  }

  useEffect(() => {
    if (subDrsId) {
      fetchSubDrsData({ id: subDrsId })
    }
  }, [subDrsId])

  const calcMargin = () => {
    switch (rowClicked % 3) {
      case 0:
        return 15
      case 1:
        return 50
      case 2:
        return 85
    }
  }

  const open = Boolean(anchorEl)

  const ManagerCard = (
    <React.Fragment>
      <div>
        <CardContent style={{ padding: '10px' }} sx={CardStyle}>
          <Typography gutterBottom>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                className={classes.image}
                src={
                  DrsData?.managerDetails?.imagePath
                    ? DrsData?.managerDetails?.imagePath
                    : CurrentLoginUserProfile
                }
                alt=''
              />
              <div style={{ marginLeft: '10px' }}>
                <span style={CardHeadingText}>{DrsData?.managerDetails?.employeeName}</span>
                <Typography className={classes.empIdHeading} color='text.secondary' gutterBottom>
                  Emp Id: <span>{DrsData?.managerDetails?.employeeId}</span>
                </Typography>
                <Typography
                  className={classes.empDesignationHeading}
                  color='text.secondary'
                  gutterBottom
                >
                  {DrsData?.managerDetails?.designation}
                </Typography>
                <Typography fontSize='12px' fontFamily={style.FONT_MEDIUM} color='#C11828'>
                  DRs: {DrsData?.managerDetails?.count}
                </Typography>
              </div>
            </div>
          </Typography>
          <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            <span> {DrsData?.managerDetails?.email} | </span>
            <span> {DrsData?.managerDetails?.phone}</span>
          </Typography>
        </CardContent>
      </div>
    </React.Fragment>
  )

  const UserCard = (
    <React.Fragment>
      <div>
        <CardContent style={{ padding: '10px' }} sx={CardStyle}>
          <Typography gutterBottom>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                className={classes.image}
                src={
                  DrsData?.userDetails?.imagePath
                    ? DrsData?.userDetails?.imagePath
                    : CurrentLoginUserProfile
                }
                alt=''
              />
              <div style={{ marginLeft: '10px' }}>
                <span style={CardHeadingText}>{DrsData?.userDetails?.employeeName}</span>
                <Typography className={classes.empIdHeading} color='text.secondary' gutterBottom>
                  Emp Id: <span>{DrsData?.userDetails?.employeeId}</span>
                </Typography>
                <Typography
                  className={classes.empDesignationHeading}
                  color='text.secondary'
                  textAlign={'left'}
                  gutterBottom
                >
                  {DrsData?.userDetails?.designation}
                </Typography>
                <Typography fontSize='12px' fontFamily={style.FONT_MEDIUM} color='#C11828'>
                  DRs: {DrsData?.userDetails?.count}
                </Typography>
              </div>
            </div>
          </Typography>
          <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            <span> {DrsData?.userDetails?.email} | </span>
            <span> {DrsData?.userDetails?.phone} </span>
          </Typography>
        </CardContent>
      </div>
    </React.Fragment>
  )

  const addSubDrs = (
    <>
      {SubDrsData?.drs?.map((item: any) => (
        <div key={item?.EmployeeName}>
          <div
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup='true'
            onMouseLeave={handlePopoverClose}
            style={{ ...CommonCardStyle, height: '90px', width: '250px' }}
          >
            <CardContent style={{ padding: '5px' }} sx={CardStyle}>
              <Typography gutterBottom>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Box className={classes.image}>
                    <img
                      className={classes.image}
                      src={item?.Image ? `${item?.Image}` : CurrentLoginUserProfile}
                      alt=''
                    />
                  </Box>
                  <div style={{ marginLeft: '10px' }}>
                  <Box
                                display='flex'
                                alignItems='center'
                                justifyContent='center'
                                position='absolute'
                                top='0.2rem'
                                right='0.2rem'
                                width='fit-content'
                              >
                                <img
                                  src={OpenTabLogo}
                                  style={{ cursor: 'alias', zIndex: '999' }}
                                  alt=''
                                  onClick={() => {
                                    managerViewScreen(item.userId)
                                  }}
                                />
                              </Box>
                    <Typography style={CardHeadingText}>{item?.EmployeeName}</Typography>
                    <Typography
                      style={{ textAlign: 'left' }}
                      className={classes.empDesignationHeading}
                      color='text.secondary'
                      gutterBottom
                    >
                      {item?.designation}
                    </Typography>
                  </div>
                </div>
              </Typography>
            </CardContent>
          </div>
        </div>
      ))}
    </>
  )

  const Drs = (
    <React.Fragment>
      {DrsData?.DRs?.length ? (
        DrsData?.DRs?.map((item: any, index: any) => {
          if (index !== 0 && index % 3 === 0) {
            return (
              <>
                <Box sx={{ gridColumnStart: 1, gridColumnEnd: 4, margin: '-10px 0 30px' }}>
                  {subDrsId !== 0 && Math.floor(rowClicked / 3) + 1 === Math.floor(index / 3) && (
                    <Box>
                      <Box>
                        <Stack sx={{ height: '30px', marginLeft: `${calcMargin()}%` }}>
                          <Divider
                            orientation='vertical'
                            sx={{ bgcolor: '#BEBEBE', width: '1px' }}
                          />
                        </Stack>
                      </Box>
                      <Collapse
                        orientation='vertical'
                        in={subDrsId > 0}
                        sx={{
                          border: '2px solid #BEBEBE',
                          width: '90%',
                          marginLeft: '5%',
                          borderRadius: '5px',
                        }}
                      >
                        {SubDRsLoader ? (
                          <Box
                            height='60px'
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                          >
                            <CircularProgress color='secondary' />
                          </Box>
                        ) : (
                          <Card
                            sx={cardStyled}
                            variant='outlined'
                            className={classes.cardContainer}
                          >
                            {addSubDrs}
                          </Card>
                        )}
                      </Collapse>
                    </Box>
                  )}
                </Box>
                <Box>
                  <div>
                    <div
                      aria-owns={open ? 'mouse-over-popover' : undefined}
                      aria-haspopup='true'
                      onMouseLeave={handlePopoverClose}
                      style={CommonCardStyle}
                      className={classes.CommonCardStyled}
                      onClick={() => {
                        if (subDrsId === item.userId || item.count === 0) {
                          setSubDrsId(0)
                          return
                        }
                        handleSubDrsClick(index, item.userId)
                      }}
                    >
                      <CardContent style={{ padding: '10px' }} sx={CardStyle}>
                        <Typography gutterBottom>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img
                              className={classes.image}
                              src={item?.imagePath ? item?.imagePath : CurrentLoginUserProfile}
                              alt=''
                            />
                            <div style={{ marginLeft: '10px' }}>
                              <Box
                                display='flex'
                                alignItems='center'
                                justifyContent='center'
                                position='absolute'
                                top='0.2rem'
                                right='0.2rem'
                                width='fit-content'
                              >
                                <img
                                  src={OpenTabLogo}
                                  style={{ cursor: 'alias', zIndex: '999' }}
                                  alt=''
                                  onClick={() => {
                                    managerViewScreen(item.userId)
                                  }}
                                />
                              </Box>
                              <Typography style={CardHeadingText}>{item?.employeeName}</Typography>
                              <Typography
                                style={{ textAlign: 'left' }}
                                className={classes.empDesignationHeading}
                                color='text.secondary'
                                gutterBottom
                              >
                                {item?.designation}
                              </Typography>
                              <Typography
                                fontSize='12px'
                                fontFamily={style.FONT_MEDIUM}
                                color='#C11828'
                              >
                                DRs: {item.count}
                              </Typography>
                            </div>
                          </div>
                        </Typography>
                      </CardContent>
                    </div>
                  </div>
                </Box>
              </>
            )
          }
          return (
            <Box key={`${item.userId} + ${item.employeeName}`}>
              <div>
                <div
                  aria-owns={open ? 'mouse-over-popover' : undefined}
                  aria-haspopup='true'
                  onMouseLeave={handlePopoverClose}
                  style={CommonCardStyle}
                  className={classes.CommonCardStyled}
                  onClick={() => {
                    if (subDrsId === item.userId || item.count === 0) {
                      setSubDrsId(0)
                      return
                    }
                    handleSubDrsClick(index, item.userId)
                  }}
                >
                  <CardContent style={{ padding: '10px' }} sx={CardStyle}>
                    <Typography gutterBottom>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          className={classes.image}
                          src={item?.imagePath ? item?.imagePath : CurrentLoginUserProfile}
                          alt=''
                        />
                        <div style={{ marginLeft: '10px' }}>
                          <Box
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                            position='absolute'
                            top='0.2rem'
                            right='0.2rem'
                            width='fit-content'
                          >
                            <img
                              src={OpenTabLogo}
                              style={{ cursor: 'alias', zIndex: '999' }}
                              alt=''
                              onClick={() => {
                                managerViewScreen(item.userId)
                              }}
                            />
                          </Box>

                          <Typography style={CardHeadingText}>{item?.employeeName}</Typography>
                          <Typography
                            style={{ textAlign: 'left' }}
                            className={classes.empDesignationHeading}
                            color='text.secondary'
                            gutterBottom
                          >
                            {item?.designation}
                          </Typography>
                          <Typography
                            fontSize='12px'
                            fontFamily={style.FONT_MEDIUM}
                            color='#C11828'
                          >
                            DRs: {item.count}
                          </Typography>
                        </div>
                      </div>
                    </Typography>
                  </CardContent>
                </div>
              </div>
            </Box>
          )
        })
      ) : (
        <p style={{ fontSize: '15px', marginTop: '1px' }}></p>
      )}
    </React.Fragment>
  )

  return (
    <>
      {loaderState ? (
        <Dialog open={loaderState} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      ) : (
        <div className={classes.container}>
          <div className={classes.subContainer}>
            <Paper
              className={classes.paperCard}
              elevation={3}
            >
              <Box className={classes.boxContainer}>
                <Box className={classes.boxSubContainer} onClick={() => navigate(-1)}>
                  <ArrowBack />
                </Box>
              </Box>
              <div style={{}}>
                <HeaderHeading
                  className={classes.drsHeader}
                  sx={{ marginTop: '10px', marginBottom: '20px' }}
                >
                  Direct Reportees
                </HeaderHeading>
                <Card sx={cardStyles} variant='outlined'>
                  {ManagerCard}
                </Card>
                <Stack className={classes.stack}>
                  <Divider orientation='vertical' />
                </Stack>
                <Card sx={cardStyles} variant='outlined'>
                  {UserCard}
                </Card>
                <Stack className={classes.stack}>
                  <Divider orientation='vertical' />
                </Stack>
              </div>
              <Divider
                sx={{ marginRight: '1%', marginLeft: '1%' }}
                className={classes.divider}
                orientation='horizontal'
                flexItem
              ></Divider>
              <div className={classes.cardContainer} style={{ position: 'relative' }}>
                <div className={classes.subCardContainer}>
                  <Card sx={cardStyled} variant='outlined' className={classes.cardContainer}>
                    {Drs}
                    <Box sx={{ gridColumnStart: 1, gridColumnEnd: 4, margin: '-10px 0 30px' }}>
                      {subDrsId !== 0 && rowClicked >= Math.floor(DrsData?.DRs?.length / 3) * 3 && (
                        <Box>
                          <Box>
                            <Stack sx={{ height: '30px', marginLeft: `${calcMargin()}%` }}>
                              <Divider
                                orientation='vertical'
                                sx={{ bgcolor: '#BEBEBE', width: '1px' }}
                              />
                            </Stack>
                          </Box>
                          <Collapse
                            orientation='vertical'
                            in={subDrsId > 0}
                            sx={{
                              border: '2px solid #BEBEBE',
                              width: '90%',
                              marginLeft: '5%',
                              borderRadius: '5px',
                            }}
                          >
                            {SubDRsLoader ? (
                              <Box
                                height='60px'
                                display='flex'
                                alignItems='center'
                                justifyContent='center'
                              >
                                <CircularProgress color='secondary' />
                              </Box>
                            ) : (
                              <Card
                                sx={cardStyled}
                                variant='outlined'
                                className={classes.cardContainer}
                              >
                                {addSubDrs}
                              </Card>
                            )}
                          </Collapse>
                        </Box>
                      )}
                    </Box>
                  </Card>
                </div>
              </div>
            </Paper>
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    DrsData: dashboardEntity.getDashboard(state).getDrsData,
    loaderState: dashboardUI.getDashboard(state).isDrsData,
    SubDrsData: dashboardEntity.getDashboard(state).getSubDrsData,
    SubDRsLoader: dashboardUI.getDashboard(state).isSubDrsData,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchDrsData: () => dispatch(getDrsData.request()),
    fetchSubDrsData: (data: { id: number }) => dispatch(getSubDrsData.request(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DRs)
