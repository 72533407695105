import styles from '../../../utils/styles.json'

export const style = {
    headerHeading: {
      fontSize: '1rem',
      textAlign: 'left',
      fontFamily: 'Poppins-Bold',
      letterSpacing: '0px',
      color: styles.PRIMARY_COLOR,
      fontWeight: '700',
      lineHeight: '27px',
      marginTop: '1rem',
    },
    styledPaper: {
      fontFamily: 'Poppins-Bold',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      background: '#FFFFFF',
      opacity: '1',
      textAlign: 'left',
      padding: '0 3vw',
    },
  }
  
  export const formLabel = {
    fontFamily: styles.FONT_MEDIUM,
    color: '#555555',
    fontSize: '0.975rem',
    padding: '0 10px 0 0',
    backgroundColor: '#ffffff'
  }
  
  export const textFileds = {
    '& .MuiInputBase-root': {
      height: '40px',
      width: '250px',
      fontSize: '0.950rem',
      fontFamily: styles.FONT_MEDIUM,
      '@media (max-width: 1240px)': {
        width: '210px'
      },
      '@media (max-width: 1067px)': {
        width: '220px'
      },
    },
  
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      borderRadius: '20px',
      marginBottom: '0px'
    },
  }
  export const projectReportCards = {
    
    '@media (max-width: 1067px)': {
      justifyContent: 'space-around',
      width: '50%',
      marginLeft: 0
    },
    '@media (max-width: 893px)': {
      width: '70%',
    },
    '@media (max-width: 770px)': {
      width: '80%',
    },
    '@media (max-width: 710px)': {
      width: '95%',
    },
    tabs: {
      display:'flex',
      padding: '0 10px',
      alignItems: 'center',
      bgcolor:'#C9C9C9', 
      height:'40px', 
      width:'234px',
      marginLeft: '2px',
      borderRadius:'20px',
      marginTop: '4px',
      fontFamily: styles.FONT_MEDIUM, 
      fontSize:'0.950rem',
    }
  }
  
  export const dateSubmitButtonForReport =
    { height: '40px', borderRadius: '20px', fontFamily: styles.FONT_MEDIUM, fontSize: '0.950rem',
      '@media (max-width: 770px)' : {
        height:'35px',
        fontSize: '0.850rem',
        marginBottom: '10px'
      },
      '@media (max-width: 700px)' : {
        marginTop: '-5px',
        marginBottom: '5px'
      }
    }