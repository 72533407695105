import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
  Dialog,
  CircularProgress,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { loaderProps } from '../Common/CommonStyles'
import { Dispatch } from 'redux'
import { RootState } from '../../configureStore'
import { connect } from 'react-redux'
import React, { useEffect } from 'react'
import { fetchLeaveDetails } from '../../actions'
import { dashboardEntity, dashboardUI } from '../../reducers'
import { useLocation, useNavigate } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'
import { ILeavesAccrualReport } from '../../models/leaves-accrual-report.model'
import { ILeaveDetails } from '../../models/leave-detail.model'
import style from '../../utils/styles.json'

export interface ILeaveDetailsProps {
  leaveDetails: ILeavesAccrualReport
  loaderState: boolean
  fetchLeaveDetails: (data: any) => void
  showBackArrow?: boolean
}

const LeaveDetails = (props: ILeaveDetailsProps) => {
  const { fetchLeaveDetails, leaveDetails, loaderState, showBackArrow = true } = props
  const [expanded, setExpanded] = React.useState<string | false>(false)
  const location = useLocation()
  const userId = new URLSearchParams(location.search).get('id')
  const navigate = useNavigate()

  useEffect(() => {
    fetchLeaveDetails({ userId: userId })
  }, [])

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }
  const MainContainer = {
    background: 'rgb(231, 235, 240)',
    width: '100%',
    paddingTop: '20px',
    paddingBottom: '1px',
    height: '100%',
  }

  const MultiAccordion: React.CSSProperties = {
    margin: '10px 0 10px 60px',
  }

  const AccordionStyle = {
    width: '95%',
    marginBottom: '15px',
  }

  const AccordingHeading = {
    flexShrink: 0,
    fontFamily: style.FONT_MEDIUM,
    letterSpacing: '0',
    fontSize: '20px',
  }

  const StyledPaper = styled(Paper)(({ theme }) => ({
    width: '86%',
    padding: '30px 25px',
    background: '#FFFFFF',
    opacity: '1',
    marginTop: '25px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '20px',
  }))

  const PaperBlock = styled(Paper)(({ theme }) => ({
    width: '30%',
    padding: '6px',
    fontFamily: style.FONT_MEDIUM,
    fontSize: '13px',
  }))

  const PaperMiniBlock = styled(Paper)(({ theme }) => ({
    width: '5%',
    padding: '6px',
    fontFamily: style.FONT_MEDIUM,
    fontSize: '13px',
    textAlign: 'center',
  }))

  const PaperSepBlock = styled(Paper)(({ theme }) => ({
    width: '68%',
    padding: '6px',
    fontFamily: style.FONT_MEDIUM,
    fontSize: '13px',
    textAlign: 'center',
  }))

  const SplitDiv: React.CSSProperties = {
    display: 'flex',
  }

  const Heading: React.CSSProperties = {
    margin: 0,
    marginBottom: '12px',
    fontSize: '25px',
    textAlign: 'center',
    fontFamily: style.FONT_MEDIUM,
  }

  const LeavesDetailsHeading = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    textAlign: 'center',
    fontFamily: style.FONT_BOLD,
    color: 'black',
    padding: '20px',
  }))

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: style.PRIMARY_COLOR,
      color: 'White',
      fontFamily: style.FONT_MEDIUM,
      textAlign: 'center',
      border: '1px solid #d1cbcbf2',
      fontSize: '11px',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      fontFamily: style.FONT_MEDIUM,
      textAlign: 'center',
      border: '1px solid #d1cbcbf2',
      color: '#483f3f',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {},
    '&:last-child td, &:last-child th': {
      fontSize: 12,
      fontFamily: style.FONT_MEDIUM,
      textAlign: 'center',
      border: '1px solid #d1cbcbf2',
      borderBottom: 'none',
    },
  }))

  const LastCellStyle = { color: '#555', fontFamily: style.FONT_MEDIUM }

  const AllocatedLeaves = (props: {
    totalLeaveCount: number
    allocatedLeaves: ILeaveDetails[]
  }) => {
    const { totalLeaveCount, allocatedLeaves } = props
    return (
      <>
        <LeavesDetailsHeading>ALLOCATED LEAVES ({totalLeaveCount})</LeavesDetailsHeading>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label='customized table'>
            <TableHead>
              <TableRow>
                <StyledTableCell>LEAVE TYPE</StyledTableCell>
                <StyledTableCell>LEAVE START DATE</StyledTableCell>
                <StyledTableCell>LEAVE END DATE</StyledTableCell>
                <StyledTableCell>COUNT</StyledTableCell>
                <StyledTableCell style={{ width: '32%' }}>DESC</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allocatedLeaves?.map((leave, i) => (
                <>
                  <StyledTableRow key={`${leave?.leaveStartDate}${leave?.count}`}>
                    <StyledTableCell component='th' scope='row'>
                      {leave?.leaveType}
                    </StyledTableCell>
                    <StyledTableCell align='right'>
                      {new Date(leave?.leaveStartDate).toLocaleDateString('en-GB')}
                    </StyledTableCell>
                    <StyledTableCell align='right'>
                      {new Date(leave?.leaveEndDate).toLocaleDateString('en-GB')}
                    </StyledTableCell>
                    <StyledTableCell align='right'>{leave?.count}</StyledTableCell>
                    <StyledTableCell align='right'>{leave?.desc}</StyledTableCell>
                  </StyledTableRow>
                </>
              ))}
              <StyledTableRow key={888}>
                <StyledTableCell component='th' scope='row' colSpan={3} style={LastCellStyle}>
                  Total Leaves Allocated:
                </StyledTableCell>
                <StyledTableCell align='right' style={LastCellStyle}>
                  {totalLeaveCount}
                </StyledTableCell>
                <StyledTableCell align='right'></StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }

  const DeductibleLeavesAvailed = (props: {
    totalDeductibleLeaves: number
    deductibleLeavesAvailed: ILeaveDetails[]
  }) => {
    const { totalDeductibleLeaves, deductibleLeavesAvailed } = props
    return (
      <>
        <LeavesDetailsHeading>
          DEDUCTIBLE LEAVES AVAILED ({totalDeductibleLeaves})
        </LeavesDetailsHeading>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label='customized table'>
            <TableHead>
              <TableRow>
                <StyledTableCell>LEAVE TYPE</StyledTableCell>
                <StyledTableCell>LEAVE START DATE</StyledTableCell>
                <StyledTableCell>LEAVE END DATE</StyledTableCell>
                <StyledTableCell>COUNT</StyledTableCell>
                <StyledTableCell style={{ width: '32%' }}>DESC</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {deductibleLeavesAvailed?.map((leave, i) => (
                <>
                  <StyledTableRow key={`${leave?.leaveStartDate}${leave?.count}`}>
                    <StyledTableCell component='th' scope='row'>
                      {leave?.leaveType}
                    </StyledTableCell>
                    <StyledTableCell align='right'>
                      {new Date(leave?.leaveStartDate).toLocaleDateString('en-GB')}
                    </StyledTableCell>
                    <StyledTableCell align='right'>
                      {new Date(leave?.leaveEndDate).toLocaleDateString('en-GB')}
                    </StyledTableCell>
                    <StyledTableCell align='right'>{leave?.count}</StyledTableCell>
                    <StyledTableCell align='right'>{leave?.desc}</StyledTableCell>
                  </StyledTableRow>
                </>
              ))}
              <StyledTableRow>
                <StyledTableCell component='th' scope='row' colSpan={3} style={LastCellStyle}>
                  Total Deductible Leaves:
                </StyledTableCell>
                <StyledTableCell align='right' style={LastCellStyle}>
                  {totalDeductibleLeaves}
                </StyledTableCell>
                <StyledTableCell align='right'></StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }

  const NonDeductibleLeavesAvailed = (props: {
    totalNonDeductibleLeavesAvailed: number
    nonDeductibleLeavesAvailed: ILeaveDetails[]
  }) => {
    const { totalNonDeductibleLeavesAvailed, nonDeductibleLeavesAvailed } = props
    return (
      <>
        <LeavesDetailsHeading>
          NON DEDUCTIBLE LEAVES AVAILED ({totalNonDeductibleLeavesAvailed})
        </LeavesDetailsHeading>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label='customized table'>
            <TableHead>
              <TableRow>
                <StyledTableCell>LEAVE TYPE</StyledTableCell>
                <StyledTableCell>LEAVE START DATE</StyledTableCell>
                <StyledTableCell>LEAVE END DATE</StyledTableCell>
                <StyledTableCell>COUNT</StyledTableCell>
                <StyledTableCell style={{ width: '32%' }}>DESC</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nonDeductibleLeavesAvailed?.map((leave, i) => (
                <>
                  <StyledTableRow key={`${leave?.leaveType}${leave?.leaveStartDate}`}>
                    <StyledTableCell component='th' scope='row'>
                      {leave?.leaveType}
                    </StyledTableCell>
                    <StyledTableCell align='right'>
                      {new Date(leave?.leaveStartDate).toLocaleDateString('en-GB')}
                    </StyledTableCell>
                    <StyledTableCell align='right'>
                      {new Date(leave?.leaveEndDate).toLocaleDateString('en-GB')}
                    </StyledTableCell>
                    <StyledTableCell align='right'>{leave?.count}</StyledTableCell>
                    <StyledTableCell align='right'>{leave?.desc}</StyledTableCell>
                  </StyledTableRow>
                </>
              ))}
              <StyledTableRow>
                <StyledTableCell component='th' scope='row' colSpan={3} style={LastCellStyle}>
                  Total Non Deductible Leaves:
                </StyledTableCell>
                <StyledTableCell align='right' style={LastCellStyle}>
                  {totalNonDeductibleLeavesAvailed}
                </StyledTableCell>
                <StyledTableCell align='right'></StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }

  return (
    <div style={MainContainer}>
      {loaderState && (
        <Dialog open={loaderState} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      )}
      {leaveDetails && Object.values(leaveDetails) && (
        <>
        {showBackArrow && (
          <Box sx={{ textAlign: 'left' }}>
            <Box
              onClick={() => navigate(-1)}
              sx={{ float: 'right', pr: '60px', mt: '-10px', cursor: 'pointer' }}
            >
              <ArrowBack />
            </Box>
          </Box>
        )}
          <StyledPaper elevation={3}>
            <p style={Heading}>Leave Accrual Report </p>

            <div style={SplitDiv}>
              <PaperBlock variant='outlined' square>
                Employee Id:
              </PaperBlock>
              <PaperBlock variant='outlined' square>
                {leaveDetails?.employeeId}
              </PaperBlock>
              <PaperBlock variant='outlined' square>
                Total Leaves Allocated:
              </PaperBlock>
              <PaperMiniBlock variant='outlined' square>
                {leaveDetails?.totalLeaves}
              </PaperMiniBlock>
            </div>
            <div style={SplitDiv}>
              <PaperBlock variant='outlined' square>
                Employee Name:
              </PaperBlock>

              <PaperBlock variant='outlined' square>
                {leaveDetails?.employeeName}
              </PaperBlock>
              <PaperBlock variant='outlined' square>
                Leaves Availed:
              </PaperBlock>
              <PaperMiniBlock variant='outlined' square>
                {leaveDetails?.availedLeaves}
              </PaperMiniBlock>
            </div>
            <div style={SplitDiv}>
              <PaperBlock variant='outlined' square>
                Designation:
              </PaperBlock>

              <PaperBlock variant='outlined' square>
                {leaveDetails?.designation}
              </PaperBlock>
              <PaperBlock variant='outlined' square>
                Leave Balance:
              </PaperBlock>
              <PaperMiniBlock variant='outlined' square>
                {leaveDetails?.balanceLeaves}
              </PaperMiniBlock>
            </div>
            <div style={SplitDiv}>
              <PaperBlock variant='outlined' square>
                Accrual Type:
              </PaperBlock>

              <PaperBlock variant='outlined' square>
                {leaveDetails?.accrualType}
              </PaperBlock>
              <PaperBlock variant='outlined' square>
                Leaves Allocated in previous FY:
              </PaperBlock>
              <PaperMiniBlock variant='outlined' square>
                {leaveDetails?.previousYearLeaves}
              </PaperMiniBlock>
            </div>
            {leaveDetails && leaveDetails?.encashedLeaves >= 0 ? (
              <div style={SplitDiv}>
                <PaperBlock variant='outlined' square>
                  Previous FY Leave encashment:
                </PaperBlock>
                <PaperSepBlock variant='outlined' square>
                  {leaveDetails?.encashedLeaves}
                </PaperSepBlock>
              </div>
            ) : (
              <div style={SplitDiv}>
                <PaperBlock variant='outlined' square>
                  Extra Leaves availed in previous FY year:
                </PaperBlock>

                <PaperSepBlock variant='outlined' square>
                  -{leaveDetails?.encashedLeaves}
                </PaperSepBlock>
              </div>
            )}
          </StyledPaper>
          {leaveDetails &&
            leaveDetails?.leaveAccrualList &&
            leaveDetails?.leaveAccrualList?.length > 0 &&
            leaveDetails?.leaveAccrualList?.map((leaveAccrual, i) => (
              <div key={`${leaveAccrual?.totalLeaveCount}${leaveAccrual?.allocatedLeaves}`} style={MultiAccordion}>
                <Accordion
                  expanded={expanded === `panel${i}`}
                  onChange={handleChange(`panel${i}`)}
                  sx={AccordionStyle}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1bh-content'
                    id='panel1bh-header'
                    sx={{ backgroundColor: '#F2F2F2' }}
                  >
                    <Typography sx={AccordingHeading}>
                      {`Leave Details for the Financial Year ${leaveAccrual?.financialYear}`}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <AllocatedLeaves
                      totalLeaveCount={leaveAccrual?.totalLeaveCount}
                      allocatedLeaves={leaveAccrual?.allocatedLeaves}
                    />
                    <DeductibleLeavesAvailed
                      totalDeductibleLeaves={leaveAccrual?.totalDeductibleLeaves}
                      deductibleLeavesAvailed={leaveAccrual?.deductibleLeavesAvailed}
                    />
                    <NonDeductibleLeavesAvailed
                      totalNonDeductibleLeavesAvailed={
                        leaveAccrual?.totalNonDeductibleLeavesAvailed
                      }
                      nonDeductibleLeavesAvailed={leaveAccrual?.nonDeductibleLeavesAvailed}
                    />
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
        </>
      )}
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    leaveDetails: dashboardEntity.getDashboard(state).getLeaveDetails,
    loaderState: dashboardUI.getDashboard(state).isLeaveData,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchLeaveDetails: (data: any) => dispatch(fetchLeaveDetails.request(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeaveDetails)
