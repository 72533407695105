import * as React from 'react'
import { styled, alpha } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Menu, { MenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Box, Typography } from '@mui/material'
import { useState } from 'react'
import styles from '../../../utils/styles.json'

type EnteriesPropType = {
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>
  setPage: React.Dispatch<React.SetStateAction<number>>
  rowsPerPage: any

}

const style = {
  selectEntriesBtn: {
    padding: '0',
    fontFamily: 'Montserrat-Regular',
    width: '80px',
    height: '32px',
    fontSize: '0.9rem',
    backgroundColor: '#FFFFFF',
    color: '#353665',
    border: '1px solid #353665',
    borderRadius: '50px',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: '#353665',
    },
  },
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    minWidth: 70,
    display: 'flex',
    justifyContent: 'center',
    alignItem: 'center',
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}))

const ShowEnteries = (props: EnteriesPropType) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [value, setValue] = useState(props.rowsPerPage)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    // props.setPage(1)
    window.addEventListener('scroll', () => {
      setAnchorEl(null)
    })
  }

  const handleClose = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const htmlEle = e.target as HTMLElement;
    setAnchorEl(null);
    if (htmlEle?.innerText !== '' && htmlEle !== undefined && htmlEle !== null) {
      setValue(htmlEle.innerText);
      props.setPage(1)
      props.setRowsPerPage(parseInt(htmlEle.innerText));
    }
  };
  return (
    <Box display='flex' alignItems='center' justifyContent='center' width='200px' marginLeft='1rem'>
      <Typography fontFamily={styles.FONT_MEDIUM} width='100px' fontSize='0.8rem' color='#353665'>
        Show Entries:
      </Typography>
      <Button
        id='demo-customized-button'
        aria-controls={open ? 'entries' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        variant='contained'
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={style.selectEntriesBtn}
      >
        {value}
      </Button>
      <StyledMenu id='entries' anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleClose} disableRipple>
          100
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          200
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          300
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          400
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          500
        </MenuItem>
      </StyledMenu>
    </Box>
  )
}

export default ShowEnteries
