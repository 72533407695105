import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import {
  Box,
  CircularProgress,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { HeaderHeading, loaderProps } from '../Common/CommonStyles'
import { Dispatch } from 'redux'
import { RootState } from '../../configureStore'
import { connect } from 'react-redux'
import { useEffect } from 'react'
import { fetchAccountType, fetchBankInfo, fetchCountry, fetchState } from '../../actions'
import { financeEntity, financeUI } from '../../reducers'
import style from '../../utils/styles.json'

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '70%',
  height: '82%',
  padding: '40px 70px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: '#FFFFFF',
  opacity: '1',
  marginTop: '25px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '20px',
  border: '1px solid #DDDDDD',
}))

const InputField = styled(TextField)(({ theme }) => ({
  marginTop: '5px',
  marginBottom: '5px',
  '& .MuiOutlinedInput-input': {
    cursor: 'not-allowed',
    padding: '11.5px 14px',
    fontSize: '13px',
    fontFamily: style.FONT_MEDIUM,
    borderRadius: '20px',
  },
  '& .MuiFormLabel-asterisk': {
    color: 'red',
  },
  '& .MuiFormLabel-root-MuiInputLabel-root': {
    fontSize: '14px',
    fontFamily: style.FONT_MEDIUM,
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
    fontFamily: style.FONT_MEDIUM,
  },
  '& .MuiInputLabel-root': {
    fontFamily: style.FONT_MEDIUM,
  },
}))

const SelectField = styled(Select)(({ theme }) => ({
  borderRadius: '20px',
  '& .MuiSelect-select': {
    cursor: 'not-allowed',
    padding: '11px 14px',
    fontSize: '13px',
    fontFamily: style.FONT_MEDIUM,
    borderRadius: '20px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
}))

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: '4.5px',
}))

const TextLabel = styled(InputLabel)(({ theme }) => ({
  fontFamily: style.FONT_MEDIUM,
}))

const EmployeeBankInfo = (props: any) => {
  const bankData = props.data
  const stateName = props.stateData.find((user: any) => user.id === parseInt(bankData.id_state))
  const sname = stateName ? stateName.state_name : 'NA'
  const countryName = props.countryData.find((country: any) => country.id === bankData.id_country)
  const cname = countryName ? countryName.country_name : 'NA'
  // const accountType = 'salary'

  useEffect(() => {}, [props])

  return (
    <StyledPaper elevation={0}>
      <HeaderHeading>Employee Bank Information</HeaderHeading>
      <Grid
        sx={{ marginTop: '10px' }}
        container
        rowSpacing={3}
        columnSpacing={{ xs: 1, sm: 2, md: 6 }}
        spacing={3}
      >
        <Grid item xs={6} sm={6}>
          <InputField
            required={false}
            label='Employee Bank Name'
            value={bankData.bank_name || 'NA'}
            size='small'
          />
        </Grid>

        <Grid item xs={6} sm={6}>
          <InputField
            required={false}
            label={'IFSC Code'}
            value={bankData.id_bank || 'NA'}
            size='small'
          />
        </Grid>

        <Grid item xs={6} sm={6}>
          <InputField
            required={false}
            label={'Bank Branch Address No'}
            value={bankData.bank_branch_address_no1 || 'NA'}
            size='small'
          />
        </Grid>

        <Grid item xs={6} sm={6}>
          <InputField
            required={false}
            label='Bank Branch Address No2'
            value={bankData.bank_branch_address_no2 || 'NA'}
            size='small'
          />
        </Grid>

        <Grid item xs={4} sm={4}>
          <InputField
            required={false}
            label={'Branch Name'}
            value={bankData.branch_name ?? 'NA'}
            size='small'
          />
        </Grid>

        <Grid item xs={4} sm={4}>
          <InputField
            required={false}
            label={'Employee Account Number'}
            value={bankData.account_no || 'NA'}
            size='small'
          />
        </Grid>

        <Grid item xs={4} sm={4}>
          <InputField
            required={false}
            label={<>City</>}
            value={bankData.city || 'NA'}
            size='small'
          />
        </Grid>

        <Grid item xs={4} sm={4}>
          <StyledFormControl>
            <TextLabel id='demo-simple-select-readonly-label'>State</TextLabel>
            <SelectField
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              value={sname}
              label={<>State</>}
              required
              inputProps={{ readOnly: true }}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              <MenuItem value={sname}>{sname}</MenuItem>
            </SelectField>
          </StyledFormControl>
        </Grid>

        <Grid item xs={4} sm={4}>
          <StyledFormControl>
            <TextLabel id='demo-simple-select-readonly-label'>Country</TextLabel>
            <SelectField
              required
              inputProps={{ readOnly: true }}
              value={cname}
              label={<>Country</>}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              <MenuItem value={cname}>{cname}</MenuItem>
            </SelectField>
          </StyledFormControl>
        </Grid>

        <Grid item xs={4} sm={4}>
          <StyledFormControl>
            <TextLabel id='demo-simple-select-readonly-label'>Account Type</TextLabel>
            <SelectField
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              value={bankData.account_type}
              label={<>Account Type</>}
              required
              inputProps={{ readOnly: true }}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {/* <MenuItem value={'salary'}>Salary Account</MenuItem> */}
              <MenuItem value={0}>Saving Account</MenuItem>
              <MenuItem value={1}>Current Account</MenuItem>
            </SelectField>
          </StyledFormControl>
        </Grid>
      </Grid>
    </StyledPaper>
  )
}

const BankInfo = (props: any) => {
  const {
    fetchBankInfoData,
    BankInfoData,
    fetchCountryData,
    CountryData,
    fetchStateData,
    StateData,
    fetchAccountTypeData,
    AccountTypeData,
    isGettingBankInfo
  } = props
  useEffect(() => {
    fetchBankInfoData({userId:""})
    fetchCountryData()
    fetchStateData()
    fetchAccountTypeData()
  }, [])
  return (
    <div
      style={{
        backgroundColor: 'rgb(231, 235, 240)',
        width: '100%',
      }}
    >
        <Dialog open={isGettingBankInfo} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      <Grid container spacing={3} style={{ marginBottom: '40px' }}>
        <Grid item xs={12}>
          {BankInfoData && Object.keys(BankInfoData).length ? (
            <EmployeeBankInfo
              data={BankInfoData}
              countryData={CountryData}
              stateData={StateData}
              accountTypeData={AccountTypeData}
            />
          ) : null } 
          {!isGettingBankInfo && !Object.keys(BankInfoData).length ?
            <Box
              style={{
                backgroundColor: '#ffffff',
                height: '12em',
                padding: 24,
                margin: 24,
                textAlign: 'center',
              }}
            >
              <Typography style={{ position: 'relative', top: '2em' }} variant='h4'>
                No bank details found
              </Typography>
            </Box>
          : null}
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    BankInfoData: financeEntity.getFinance(state).getBankInfoData,
    StateData: financeEntity.getFinance(state).getStateData,
    CountryData: financeEntity.getFinance(state).getCountryData,
    AccountTypeData: financeEntity.getFinance(state).getAccountTypeData,
    isGettingBankInfo: financeUI.getFinance(state).isGettingBankInfo
  }
}

// Define mapDispatchToProps function
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchBankInfoData: (data:any) => dispatch(fetchBankInfo.request(data)),
    fetchStateData: () => dispatch(fetchState.request()),
    fetchCountryData: () => dispatch(fetchCountry.request()),
    fetchAccountTypeData: () => dispatch(fetchAccountType.request()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BankInfo)
