import Button from '@mui/material/Button'
import { ReactComponent as ExportToExcelIcon } from '../../../assets/images/exportToExcel.svg'
import { Box, Tooltip } from '@mui/material'
import { RootState } from '../../../configureStore'
import { Dispatch } from 'redux'
import { getDownLoadCsvForMyTeam } from '../../../actions'
import { connect } from 'react-redux'
import dayjs from 'dayjs'

const style = {
  buttonStyle: {
    fontFamily: 'Montserrat-Regular',
    fontSize: '1rem',
    padding: '0',
    width: '40px',
    height: '40px',
    minWidth: 'fit-content',
    borderRadius: '50%',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
    '@media only screen and (max-width:780px)': {
      fontSize: '0.9rem',
    },
  },
  container: {
    '@media only screen and (max-width:1230px)': {
      width: '200px',
    },
  },
}

const ExportButton = (props: any) => {
  const { getCsvDownload, startDate, endDate, selectedTab, downLoadCsvData, searchQuery } = props

  const handlerCsvDownload = () => {
    getCsvDownload({
      startDate: dayjs(startDate).format('YYYY-MM-DD').toString(),
      endDate: dayjs(endDate).format('YYYY-MM-DD').toString(),
      reportType: selectedTab,
      search: searchQuery,
    })
  }

  return (
    <Box display='flex' justifyContent='end' alignItems='center' width='160px' sx={style.container}>
      <Tooltip title={'Download CSV'} arrow>
        <Button
          onClick={() => {
            handlerCsvDownload()
          }}
          sx={style.buttonStyle}
          variant='outlined'
        >
          <ExportToExcelIcon />
        </Button>
      </Tooltip>
    </Box>
  )
}

const mapStateToProps = (state: RootState) => {
  return {}
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getCsvDownload: (data: any) => dispatch(getDownLoadCsvForMyTeam.request(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportButton)
