import { useEffect, useState } from 'react'
import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  Backdrop,
  Grid,
  styled,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { connect } from 'react-redux'
import { createProjectCustomers, updateProjectCustomer } from '../../../actions'
import { style } from './projectCustomersStyle'
import FormikForm from './FormikForm'
import { FormValues, DiaglogFormPropsType } from './ProjectCustomersTypes'
import { Dispatch } from 'redux'
import { FormikHelpers } from 'formik'
import styles from '../../../utils/styles.json'
import { projectManagementUI } from '../../../reducers'
import { RootState } from '../../../configureStore'

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: '22px',
  textAlign: 'center',
  fontFamily: styles.FONT_BOLD,
  letterSpacing: '0px',
}))

const DiaglogForm = ({
  openCustomerForm,
  setOpenCustomerForm,
  openCustomerEditForm,
  setopenCustomerEditForm,
  createProjectCustomers,
  editCustomerData,
  updateProjectCustomer,
  isProjectCustomerUpdated
}: any) => {
  useEffect(() => {}, [editCustomerData])

  const [value, setValue] = useState({})

  const handleClickCloseDiaglogForm = () => {
    setOpenCustomerForm(false)
    setopenCustomerEditForm(false)
  }

  const initialValues = {
    id: openCustomerEditForm ? editCustomerData.id : 0,
    customer_name: openCustomerEditForm ? editCustomerData.customer_name : '',
    country_code: openCustomerEditForm ? editCustomerData.country_code : '',
  }

  const handleSubmit = (values: any, actions: FormikHelpers<FormValues>) => {
    if (!values.customer_name) {
      actions.setErrors({
        customer_name: !values.customer_name ? 'Required' : '',
      })
    } else {
      if (openCustomerEditForm) {
        const updateValues = {
          id: values.id,
          CustomerName: values.customer_name,
          country_code: values.country_code,
        }
        updateProjectCustomer(updateValues)
        actions.setSubmitting(false)
        handleClickCloseDiaglogForm()
      } else {
        createProjectCustomers(values)
        actions.setSubmitting(false)
        handleClickCloseDiaglogForm()
      }
    }
  }

  return (
    <Box>
      <Dialog
        open={openCustomerForm || openCustomerEditForm}
        onClose={handleClickCloseDiaglogForm}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        BackdropComponent={Backdrop}
        sx={style.BackdropDiaglogStyleNewClient}
      >
        <Box>
          <Grid item xs={12} sm={12} md={12} />
          <DialogTitle id='alert-dialog-title'>
            <Heading>{openCustomerEditForm ? 'Edit ' : 'Create '}Client</Heading>
            <IconButton
              aria-label='close'
              onClick={handleClickCloseDiaglogForm}
              sx={{ ...style.iconButtonStyle }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <FormikForm
            initialValues={initialValues}
            onSubmit={handleSubmit}
            handleClickCloseDiaglogForm={handleClickCloseDiaglogForm}
            openCustomerEditForm={openCustomerEditForm}
            value={value}
            setValue={setValue}
          />
        </Box>
      </Dialog>
    </Box>
  )
}

const mapStateToProps = (state: RootState) => ({
  isProjectCustomerUpdated: projectManagementUI.getProjectManagemet(state).isProjectCustomerUpdated
});



const mapDispatchToProps = (dispatch: Dispatch) => ({
  createProjectCustomers: (data: FormValues) => dispatch(createProjectCustomers.request({ data })),
  updateProjectCustomer: (data: FormValues) => dispatch(updateProjectCustomer.request({ data })),
})

export default connect(mapStateToProps, mapDispatchToProps)(DiaglogForm)
