import React, { useEffect } from 'react'
import { useState } from 'react'
import { FieldArray, Form, FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { FamilyDetailsHeading, loaderProps } from '../Common/CommonStyles'
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  CommonButton,
  InputField,
  SelectField,
  StyledMenuItem,
  useStyles,
} from './UserDetailsStyle'
import RemoveCircleOutlineSharpIcon from '@mui/icons-material/RemoveCircleOutlineSharp'
import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone'
import { validationSchema } from './ValidationSchema'
import { FormValues } from './IFormValue'
import { RootState } from '../../configureStore'
import {
  employeePortalEntity,
  fetchUserDetailsEntities,
  fetchUserDetailsUI,
  projectManagementEntity,
} from '../../reducers'
import {
  createUser,
  fetchAllRoles,
  fetchAllStates,
  fetchAllCountries,
  fetchAllFloors,
  fetchAllWorkstation,
  fetchAllLocation,
  uploadUserImg,
  updateUser,
  fetchAllClientLocation,
  getUserImage,
  fetchDesignation,
  fetchDesignationBand,
  fetchWorkingEmp,
  fetchEmployee,
} from '../../actions'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import { ArrowBack } from '@mui/icons-material'
import { HeaderHeading } from '../Common/CommonStyles'
import styles from '../../utils/styles.json'
import { all } from 'redux-saga/effects'

const style = {
  selectStyle: {
    '& .MuiSelect-select': {
      height: '21px',
    },
  },
  gridStyle: {
    '& .MuiFormControl-root': {
      margin: '0 0 15px',
    },
  },
  gridStyleLogin: {
    '& .MuiFormControl-root': {
      margin: '-10px 0 15px',
      marginLeft: '-5px',
    },
  },
  changedGridStyle: {
    '& .MuiFormControl-root': {
      margin: '-10px 0 5px',
    },
  },
  gridStylewfo: {
    '& .MuiFormControl-root': {
      margin: '-10px 0 5px',
    },
  },
}

const steps = ['Personal Information', 'Employment Details', 'Address Information']

const daysOfWeek = [
  { id: 1, value: 'Sunday' },
  { id: 2, value: 'Monday' },
  { id: 3, value: 'Tuesday' },
  { id: 4, value: 'Wednesday' },
  { id: 5, value: 'Thursday' },
  { id: 6, value: 'Friday' },
  { id: 7, value: 'Saturday' },
]

const StepperForm = (props: any) => {
  const {
    isUserCreating,
    isUserUpdating,
    fetchEmployee,
    getEmployeeData,
    isEmployeeFetched,
    resetEmployee,
    allStates,
  } = props
  const rowdata: any = useLocation()?.state
  const classes = useStyles()
  // const [rowdata, setRowData] = useState<any>({})
  const [activeStep, setActiveStep] = useState(0)
  const [imagePreview, setImagePreview] = useState<string | null>(null)
  const [age, setAge] = useState(rowdata?.age ?? 0)
  const [hasSameAddress, setHasSameAddress] = useState(false)
  const [states, setStates] = useState([])
  const [statesPermanent, setStatesPermanent] = useState([])

  const navigate = useNavigate()
  const location = useLocation()
  const relationshipOrder = ['father', 'mother', 'spouse', 'children'] // Define the desired order

  useEffect(() => {
    if (allStates?.length > 0) {
      setStates(allStates)
      setStatesPermanent(allStates)
    }
  }, [allStates])

  useEffect(() => {
    props.fetchAllRole()
    props.fetchAllState()
    props.fetchAllCountry()
    props.fetchAllFloor()
    props.fetchAllWorkstation()
    props.fetchAllLocation()
    props.fetchAllClientLocation()

    if (rowdata?.userid) {
      props.getUserImage({ userid: rowdata?.userid })
    }

    return () => {
      props.resetCreateUser()
      props.resetUpdateUser()
      props.resetUserImage()
    }
  }, [rowdata?.userid])

  useEffect(() => {
    props.fetchDesignationData()
    props.fetchAllEmpAPI()
    props.fetchDesignationBandData()
  }, [])

  useEffect(() => {
    if (props.isUserCreated) {
      toast.success('User added successfully')
      navigate('/home/admin/employees')
    }

    if (props.isUserUpdated) {
      toast.success('User updated successfully')
      navigate('/home/admin/employees')
    }
  }, [
    props.isUserCreated,
    props.isUserUpdated /* props.isUserCreateFailed,  props.isUserUpdateFailed */,
  ])

//  useEffect(()=>{
//   if(rowdata?.userid) fetchEmployee({ id: rowData?.userid })
//  },[rowdata?.userid])

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      if (
        file.type === 'video/mp4' ||
        file.type === 'video/mpeg' ||
        file.type === 'video/quicktime' ||
        file.type === 'video/x-matroska'
      ) {
        toast.error('Video files are not allowed')
        formik.setFieldValue('image_path', null)
      } else {
        const reader = new FileReader()

        reader.onloadend = () => {
          const base64String = reader.result as string
          setImagePreview(base64String)
          formik.setFieldValue('image_path', file)
        }

        reader.readAsDataURL(file)
      }
    }
  }

  const uniqueRole =
    props.allRoles.length > 0
      ? props.allRoles.filter(
          (item: { name: any }, index: any, self: any[]) =>
            index === self.findIndex((t) => t.name === item.name),
        )
      : []

  const roleIdArr: any = []
  const dependentsArr: any = []

  rowdata?.roles?.map((value: any) => roleIdArr.push(value.id))

  rowdata?.dependents &&
    rowdata?.dependents?.length > 0 &&
    rowdata?.dependents?.map((value: any) => {
      return dependentsArr.push({
        id: value.id,
        name: value.name,
        id_relationship: value.id_relationship,
        birth_date: value.birth_date ? dayjs(value.birth_date).format('YYYY-MM-DD') : '',
        phone_number: value.phone_number,
        age: value.age,
        is_emergency_contact: value.is_emergency_contact,
      })
    })

  const formattedOfficialDate = rowdata?.official_birth_date
    ? dayjs(rowdata.official_birth_date).format('YYYY-MM-DD')
    : ''
  const formattedActualDate = rowdata?.birth_date
    ? dayjs(rowdata.birth_date).format('YYYY-MM-DD')
    : ''

  const formattedAnniverseryDate = rowdata?.anniversary_date
    ? dayjs(rowdata?.anniversary_date).format('YYYY-MM-DD')
    : ''
  const formattedLocationStartDate = rowdata?.location_start_date
    ? dayjs(rowdata.location_start_date).format('YYYY-MM-DD')
    : ''
  const formattedLocationEndDate = rowdata?.location_end_date
    ? dayjs(rowdata.location_end_date).format('YYYY-MM-DD')
    : ''
  const formattedCompLocationStartDate = rowdata?.comp_location_start_date
    ? dayjs(rowdata.comp_location_start_date).format('YYYY-MM-DD')
    : ''
  const formattedCompLocationEndDate = rowdata?.comp_location_end_date
    ? dayjs(rowdata.comp_location_end_date).format('YYYY-MM-DD')
    : ''

  const formik = useFormik({
    initialValues: {
      hasSameAddress: rowdata?.address?.same_as_present ? true : false,
      first_name: rowdata?.first_name ?? '',
      last_name: rowdata?.last_name ?? '',
      middle_name: rowdata?.middle_name ?? '',
      id_tenant: '',
      image_path: '',
      employee_id: rowdata?.employeeId ?? '',
      work_mode: rowdata?.work_mode ? Number(rowdata?.work_mode) : 2,
      client_location: rowdata?.client_location ?? null,
      location_start_date: formattedLocationStartDate ? formattedLocationStartDate : '',
      location_end_date: formattedLocationEndDate ? formattedLocationEndDate : '',
      comp_location_start_date: formattedCompLocationStartDate
        ? formattedCompLocationStartDate
        : '',
      comp_location_end_date: formattedCompLocationEndDate ? formattedCompLocationEndDate : '',
      day_to_visit: rowdata?.day_to_visit ?? '',
      time_to_visit: rowdata?.time_to_visit ?? '',
      project_assigned: rowdata?.project_assigned ?? '',
      location: rowdata?.location ?? null,
      key_assigned: rowdata?.key_assigned === 1 ? 1 : 0,
      key_number: '',
      // under_probation: rowdata?.under_probation === 0 ? 0 : 1,
      email: rowdata?.email ?? '',
      track_attendance: true,
      Workstation: rowdata?.workstation_id ?? null,
      loginId: rowdata?.username ?? '',
      password: '',
      confirm_password: '',
      designation: rowdata?.DesginationName?.id ?? '',
      desig_band: rowdata?.employeeinfo?.designation_band ?? '',
      manager: rowdata?.managerName?.id ?? '',
      status: Number(rowdata?.status) === 0 ? Number(rowdata?.status) : 1,
      present_address: rowdata && 'address' in rowdata ? rowdata?.address?.present_address : '',
      present_city: rowdata && 'address' in rowdata ? rowdata?.address?.present_city : '',
      present_postal_code:
        rowdata && 'address' in rowdata ? rowdata?.address?.present_postal_code : '',
      present_state:
        rowdata && 'address' in rowdata ? String(rowdata?.address?.present_state) : String(15),
      deleted_by: 'Murthy',
      is_deleted: '0',
      id_country: rowdata && 'address' in rowdata ? rowdata?.address?.id_country : 5,
      floor_id: rowdata?.floor_id ?? null,
      citizenship: rowdata?.citizenship ?? '',
      permanent_address: rowdata && 'address' in rowdata ? rowdata?.address?.permanent_address : '',
      permanent_city: rowdata && 'address' in rowdata ? rowdata?.address?.permanent_city : '',
      permanent_postal_code:
        rowdata && 'address' in rowdata ? rowdata?.address?.permanent_postal_code : '',
      permanent_state:
        rowdata && 'address' in rowdata ? String(rowdata?.address?.permanent_state) : String(15),
      mobile_no: rowdata?.mobile_no ?? '',
      id_country_permanent:
        rowdata && 'address' in rowdata ? rowdata?.address?.id_country_permanent : 5,
      official_birth_date: formattedOfficialDate ? formattedOfficialDate : '', // '1992-10-28 14:22:50Z',
      birth_date: formattedActualDate ? formattedActualDate : '', // '1992-10-28 14:22:50Z',
      gender: rowdata?.gender ?? 1,
      blood_group: rowdata?.blood_group ?? '',
      id_roles: roleIdArr?.length > 0 ? [...roleIdArr] : [457],
      anniversary_date: formattedAnniverseryDate ? formattedAnniverseryDate : '',
      contact_person: rowdata?.contact_person ?? '',
      marital_status: rowdata?.matital_status ?? 1,
      // probation_period: rowdata?.probation_period ?? 12,
      isEmployeeSelected: false,
      employment_type: rowdata?.employeeinfo?.employee_type ?? 1,
      dependents:
        dependentsArr.length > 0
          ? dependentsArr?.sort((a: any, b: any) => {
              const indexA = relationshipOrder.indexOf(a.id_relationship)
              const indexB = relationshipOrder.indexOf(b.id_relationship)
              return indexA - indexB
            })
          : [
              {
                name: rowdata.father_name ? rowdata.father_name : '',
                id_relationship: 'father',
                birth_date: '',
                phone_number: '',
                age: null,
                is_emergency_contact: 0,
              },
              {
                name: rowdata.mother_name ? rowdata.mother_name : '',
                id_relationship: 'mother',
                birth_date: '',
                phone_number: '',
                age: null,
                is_emergency_contact: 0,
              },
              rowdata?.spouse_name && {
                name: rowdata.spouse_name,
                id_relationship: 'spouse',
                birth_date: '',
                phone_number: '',
                age: null,
                is_emergency_contact: 0,
              },
              rowdata?.child_name && {
                name: rowdata.child_name,
                id_relationship: 'child',
                birth_date: '',
                phone_number: '',
                age: null,
                is_emergency_contact: 0,
              },
            ].filter(Boolean),
    },
    validationSchema,
    onSubmit: (values) => {},
  })
  const stepSchemas = [
    // Step 1 Schema
    Yup.object({
      first_name: Yup.string().required('Required'),
      last_name: Yup.string().required('Required'),
      mobile_no: Yup.string(),
      official_birth_date: Yup.date().required('Required'),
      birth_date: Yup.date().required('Required'),
      age: Yup.number(), // not required but must be a number if entered
      gender: Yup.string(),
      marital_status: Yup.number(),
      email: Yup.string().email('Invalid email format').required('Required'),
      employee_id: Yup.string().required('Required'),
      employment_type: Yup.string().required(),
      id_roles: Yup.array()
        .of(Yup.number().required())
        .min(1, 'At least one role must be selected')
        .required('Roles are required')
        .test(
          'employee-role',
          'Employee ID is required when the Employee role is selected',
          function (value) {
            const { employee_id } = this.parent
            if (value?.includes(281) && !employee_id) {
              return this.createError({
                path: 'employee_id',
                message: 'Employee ID is required when the Employee role is selected',
              })
            }
            return true
          },
        ),
      dependents: Yup.array()
        .of(
          Yup.object().shape({
            name: Yup.string().required(),
            id_relationship: Yup.string().required(),
            birth_date: Yup.string().notRequired(),
            phone_number: Yup.string().notRequired(),
            age: Yup.number().notRequired(),
            is_emergency_contact: Yup.number().notRequired(),
          }),
        )
        .required('Dependents are required'),
    }),

    // Step 2 Schema
    Yup.object({
      designation: Yup.string().required('Required'),
      desig_band: Yup.string().required('Required'),
      manager: Yup.string().required('Required'),
      work_mode: Yup.number().required('Required'),
      location: Yup.number().when('work_mode', (value: any) => {
        if (value[0]) return Yup.number().notRequired()
        return Yup.number().required('Location is required')
      }),

      client_location: Yup.number().when('work_mode', (value: any) => {
        if (value[0] === 1) return Yup.number().required()
        return Yup.number().notRequired()
      }),

      status: Yup.number().required('Required'),
      loginId: Yup.string().required('Required'),
      password: Yup.string(),
      confirm_password: Yup.string(),
    }),

    // Step 3 Schema
    Yup.object({
      present_address: Yup.string().required('Required'),
      present_city: Yup.string().required('Required'),
      present_postal_code: Yup.string().required('Required'),
      present_state: Yup.string().required('Required'),
      id_country: Yup.number().required('Required'),
      citizenship: Yup.string(),
      permanent_address: Yup.string().required('Required'),
      permanent_city: Yup.string().required('Required'),
      permanent_postal_code: Yup.string().required('Required'),
      permanent_state: Yup.string().required('Required'),
      id_country_permanent: Yup.number().required('Required'),
    }),
  ]

  const handleNext = async () => {
    const stepSchema = stepSchemas[activeStep]
    try {
      await stepSchema.validate(formik.values, { abortEarly: false })
      if (activeStep === stepSchemas.length - 1) {
        const entity = {
          id: Boolean(rowdata?.username) && rowdata?.userid,
          first_name: formik.values.first_name,
          middle_name: formik.values.middle_name,
          last_name: formik.values.last_name,
          id_tenant: formik.values.id_tenant,
          image: '',
          employee_id: String(formik.values.employee_id),
          work_mode: Number(formik.values.work_mode),
          location: formik.values.location,
          // under_probation: Number(formik.values.under_probation),
          email: formik.values.email,
          track_attendance: true,
          Workstation: formik.values.Workstation,
          username: formik.values.loginId,
          password: formik.values.password,
          status: Number(formik.values.status),
          present_address: formik.values.present_address,
          present_city: formik.values.present_city,
          present_postal_code: formik.values.present_postal_code,
          present_state: formik.values.present_state,
          deleted_by: formik.values.deleted_by,
          dependents: formik.values.dependents,
          is_deleted: '0',
          id_country: formik.values.id_country,
          floor_id: formik.values.floor_id,
          citizenship: formik.values.citizenship,
          permanent_address: formik.values.permanent_address,
          permanent_city: formik.values.permanent_city,
          permanent_postal_code: formik.values.permanent_postal_code,
          permanent_state: formik.values.permanent_state,
          mobile_no: formik.values.mobile_no,
          id_country_permanent: formik.values.id_country_permanent,
          official_birth_date: formik.values.official_birth_date,
          birth_date: formik.values.birth_date,
          gender: String(formik.values.gender),
          age: age,
          blood_group: formik.values.blood_group,
          id_roles: formik.values.id_roles.map((roleId: number) => roleId),
          anniversary_date: formik.values.anniversary_date,
          marital_status: formik.values.marital_status,
          contact_person: formik.values.contact_person,
          // probation_period: Number(formik.values.probation_period),
          day_to_visit: String(formik.values.day_to_visit),
          key_assigned: Number(formik.values.key_assigned),
          location_start_date: formik.values.location_start_date,
          location_end_date: formik.values.location_end_date,
          comp_location_start_date: formik.values.comp_location_start_date,
          comp_location_end_date: formik.values.comp_location_end_date,
          time_to_visit: formik.values.time_to_visit,
          project_assigned: formik.values.project_assigned,
          client_location: formik.values.client_location,

          id_manager: formik.values.manager, //manger
          id_grade: formik.values?.designation, //designation
          designation_band: formik.values.desig_band, //designation
          secondary_employment: 1,
          company_identification_no: '1',
          id_payroll_frequency: 1,
          job_code: 0,
          employee_type: parseInt(formik.values.employment_type, 10),
          non_resident: 0,
          // for employment info
          pf_no: 'NA',
          mediclaim_no: 'NA',
          uan_no: 'NA',
          pan_card_no: 'NA',
          employment_type: 1,
          same_as_present: hasSameAddress ? 1 : 0,
        }

        const isUpdate = Boolean(rowdata?.username)
        // userid
        const apiCall = isUpdate ? props.updateUser : props.createUser

        if (formik.values.image_path) {
          entity.image = formik.values.image_path
          await apiCall(entity)
        } else {
          apiCall(entity)
        }
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const stepErrors = error.inner.reduce((acc, curr) => {
          if (curr.path) {
            acc[curr.path as keyof FormValues] = curr.message
          }
          return acc
        }, {} as { [K in keyof FormValues]?: string })

        if (
          formik.values.dependents.some(
            (dependent: any) => !dependent?.name || !dependent?.id_relationship,
          )
        ) {
          const touched = { ...formik.touched } as any // Casting to match FormikTouched<FormValues> type

          formik.values.dependents.forEach((dependent: any, index: any) => {
            if (!dependent?.name || !dependent?.id_relationship) {
              if (!touched.dependents) {
                touched.dependents = []
              }
              if (!touched.dependents[index]) {
                touched.dependents[index] = {}
              }
              touched.dependents[index] = {
                ...touched.dependents[index],
                name: !dependent?.name ? true : touched.dependents[index].name,
                id_relationship: !dependent?.id_relationship
                  ? true
                  : touched.dependents[index].id_relationship, // Mark id_relationship as touched
              }
            }
          })

          // Set the updated touched state with type safety
          const getBeforeFileds = Object.keys(stepErrors).reduce((acc, key) => {
            acc[key as keyof FormValues] = true
            return acc
          }, {} as { [K in keyof FormValues]: any })
          formik.setTouched({ ...touched, ...getBeforeFileds })
        }
        formik.setErrors(stepErrors)
      } else {
        console.error('Unexpected error:', error)
      }
    }
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target
    const currentRoles = formik.values.id_roles

    if (checked) {
      formik.setFieldValue('id_roles', [...currentRoles, parseInt(value)])
      if (parseInt(value) === 281) {
        formik.setFieldValue('isEmployeeSelected', true)
      }
    } else {
      formik.setFieldValue(
        'id_roles',
        currentRoles?.filter((roleId: number) => roleId !== parseInt(value)),
      )
      if (parseInt(value) === 281) {
        formik.setFieldValue('isEmployeeSelected', false)
      }
    }

    setTimeout(() => {
      formik.validateField('id_roles')
    }, 0)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  useEffect(() => {
    if (formik.values.marital_status === 1) formik.setFieldValue('anniversary_date', '')
  }, [formik.values.marital_status])

  useEffect(() => {
    if (hasSameAddress) {
      formik.setFieldValue('id_country_permanent', formik.values.id_country)
      formik.setFieldValue('permanent_address', formik.values.present_address)
      formik.setFieldValue('permanent_city', formik.values.present_city)
      formik.setFieldValue('permanent_postal_code', formik.values.present_postal_code)
      formik.setFieldValue('permanent_state', formik.values.present_state)
    }
  }, [hasSameAddress])

  const handleCountry = (event: any) => {
    const countryID = event.target.value
    formik.setFieldValue('id_country', countryID)

    const selectedCountry = props.allCountries.find(
      (country: any) => country.id === countryID,
    )?.id_country

    const filtertedStates = allStates?.filter((state: any) => state?.id_country === selectedCountry)

    setStates(() => {
      return filtertedStates.length > 0 ? filtertedStates : allStates
    })
  }

  const handlePermanentCountry = (event: any) => {
    const countryID = event.target.value
    formik.setFieldValue('id_country_permanent', countryID)

    const selectedCountry = props.allCountries.find(
      (country: any) => country.id === countryID,
    )?.id_country

    const filtertedStates = allStates?.filter((state: any) => state?.id_country === selectedCountry)

    setStatesPermanent(() => {
      return filtertedStates.length > 0 ? filtertedStates : allStates
    })
  }

  const renderFieldsByWorkMode = (workMode: any) => {
    switch (Number(workMode)) {
      case 1:
        return (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Grid item sx={style.gridStyle}>
                <SelectField
                  size='small'
                  id='client_location'
                  name='client_location'
                  label='Client Location'
                  select
                  value={formik.values.client_location}
                  onChange={formik.handleChange}
                  error={formik.touched.client_location && Boolean(formik.errors.client_location)}
                  sx={style.selectStyle}
                >
                  {props?.allClientLocation.map((location: any) => (
                    <StyledMenuItem key={location.id} value={location.id}>
                      {location.location_name} - {location.city}
                    </StyledMenuItem>
                  ))}
                </SelectField>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid item sx={style.gridStyle}>
                <InputField
                  size='small'
                  fullWidth
                  id='location_start_date'
                  name='location_start_date'
                  label='Location Start Date'
                  type='date'
                  value={formik.values.location_start_date || ''}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.location_start_date && Boolean(formik.errors.location_start_date)
                  }
                  InputLabelProps={{ shrink: true }}
                  required={false}
                />
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid item sx={style.gridStyle}>
                <InputField
                  size='small'
                  fullWidth
                  id='location_end_date'
                  name='location_end_date'
                  label='Location End Date'
                  type='date'
                  value={formik.values.location_end_date || ''}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.location_end_date && Boolean(formik.errors.location_end_date)
                  }
                  InputLabelProps={{ shrink: true }}
                  required={false}
                  inputProps={{
                    min: formik.values.location_start_date || '',
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid item sx={style.changedGridStyle}>
                <SelectField
                  size='small'
                  id='day_to_visit'
                  name='day_to_visit'
                  label='Day To Visit'
                  select
                  value={formik.values.day_to_visit}
                  onChange={formik.handleChange}
                  error={formik.touched.day_to_visit && Boolean(formik.errors.day_to_visit)}
                  sx={style.selectStyle}
                  required={false}
                >
                  {daysOfWeek?.map((day) => (
                    <StyledMenuItem key={day.id} value={day.value}>
                      {day.value}
                    </StyledMenuItem>
                  ))}
                </SelectField>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid item sx={style.changedGridStyle}>
                <InputField
                  size='small'
                  fullWidth
                  id='time_to_visit'
                  name='time_to_visit'
                  label='Time To Visit'
                  type='time'
                  value={formik.values.time_to_visit || ''}
                  onChange={formik.handleChange}
                  error={formik.touched.time_to_visit && Boolean(formik.errors.time_to_visit)}
                  InputLabelProps={{ shrink: true }}
                  required={false}
                />
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid item sx={style.changedGridStyle}>
                <InputField
                  size='small'
                  fullWidth
                  id='project_assigned'
                  name='project_assigned'
                  label='Project Assigned'
                  value={formik.values.project_assigned}
                  onChange={formik.handleChange}
                  error={formik.touched.project_assigned && Boolean(formik.errors.project_assigned)}
                  required={false}
                />
              </Grid>
            </Grid>
          </Grid>
        )

      case 2:
        return (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Grid item sx={style.gridStyle}>
                <SelectField
                  size='small'
                  id='location'
                  name='location'
                  label='Location'
                  select
                  value={formik.values.location}
                  onChange={formik.handleChange}
                  error={formik.touched.location && Boolean(formik.errors.location)}
                  sx={style.selectStyle}
                  required={false}
                >
                  <StyledMenuItem key={0} value={0}>
                    NA
                  </StyledMenuItem>
                  {props?.allLocation.map((location: any) => (
                    <StyledMenuItem key={location.id} value={location.id}>
                      {location.location}
                    </StyledMenuItem>
                  ))}
                </SelectField>
              </Grid>
            </Grid>

            <Grid item sx={style.gridStyle} xs={4}>
              <InputField
                size='small'
                fullWidth
                id='comp_location_start_date'
                name='comp_location_start_date'
                label='Location Start Date'
                type='date'
                value={formik.values.comp_location_start_date || ''}
                onChange={formik.handleChange}
                error={
                  formik.touched.comp_location_start_date &&
                  Boolean(formik.errors.comp_location_start_date)
                }
                InputLabelProps={{ shrink: true }}
                required={false}
                disabled={!formik?.values?.location}
              />
            </Grid>
            <Grid item sx={style.gridStyle} xs={4}>
              <InputField
                size='small'
                fullWidth
                id='comp_location_end_date'
                name='comp_location_end_date'
                label='Location End Date'
                type='date'
                value={formik.values.comp_location_end_date || ''}
                onChange={formik.handleChange}
                error={
                  formik.touched.comp_location_end_date &&
                  Boolean(formik.errors.comp_location_end_date)
                }
                InputLabelProps={{ shrink: true }}
                required={false}
                disabled={!formik?.values?.location}
                inputProps={{
                  min: formik.values.comp_location_start_date || '',
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <Grid item sx={style.gridStylewfo}>
                <SelectField
                  size='small'
                  id='floor_id'
                  name='floor_id'
                  label='Floor'
                  select
                  value={formik.values.floor_id}
                  onChange={formik.handleChange}
                  error={formik.touched.floor_id && Boolean(formik.errors.floor_id)}
                  sx={style.selectStyle}
                  required={false}
                >
                  {props?.allFloors?.map((floor: any) => (
                    <StyledMenuItem key={floor.id} value={floor?.id}>
                      {floor?.floor_name}
                    </StyledMenuItem>
                  ))}
                </SelectField>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid item sx={style.gridStylewfo}>
                <SelectField
                  size='small'
                  id='Workstation'
                  name='Workstation'
                  label='Workstation'
                  select
                  value={formik.values.Workstation}
                  onChange={formik.handleChange}
                  error={formik.touched.Workstation && Boolean(formik.errors.Workstation)}
                  sx={style.selectStyle}
                  required={false}
                >
                  <StyledMenuItem key={0} value={0}>
                    NA
                  </StyledMenuItem>
                  {props?.allWorkstations?.map((workstation: any) => (
                    <StyledMenuItem key={workstation.id} value={workstation.id}>
                      {workstation.workstation_name}
                    </StyledMenuItem>
                  ))}
                </SelectField>
              </Grid>
            </Grid>
          </Grid>
        )

      case 3:
        return (
          <Grid container spacing={2}>
            <Grid item sx={style.gridStyle} xs={6}>
              <InputField
                size='small'
                fullWidth
                id='comp_location_start_date'
                name='comp_location_start_date'
                label='Start Date'
                type='date'
                value={formik.values.comp_location_start_date || ''}
                onChange={formik.handleChange}
                error={
                  formik.touched.comp_location_start_date &&
                  Boolean(formik.errors.comp_location_start_date)
                }
                InputLabelProps={{ shrink: true }}
                required={false}
              />
            </Grid>
            <Grid item sx={style.gridStyle} xs={5.9}>
              <InputField
                size='small'
                fullWidth
                id='comp_location_end_date'
                name='comp_location_end_date'
                label='End Date'
                type='date'
                value={formik.values.comp_location_end_date || ''}
                onChange={formik.handleChange}
                error={
                  formik.touched.comp_location_end_date &&
                  Boolean(formik.errors.comp_location_end_date)
                }
                InputLabelProps={{ shrink: true }}
                required={false}
                inputProps={{
                  min: formik.values.comp_location_start_date || '',
                }}
              />
            </Grid>
          </Grid>
        )

      default:
        return null // Return null or a default UI if work_mode is not 1 or 2
    }
  }

  const calculateAge = (birthDate: any) => {
    const today = new Date()
    const birthDateObj = new Date(birthDate)
    let age = today?.getFullYear() - birthDateObj?.getFullYear()
    const monthDiff = today?.getMonth() - birthDateObj?.getMonth()
    if (monthDiff < 0 || (monthDiff === 0 && today?.getDate() < birthDateObj?.getDate())) {
      age--
    }
    return age
  }

  const employmentTypeLabels: { [key: number]: string } = {
    1: 'Employee ID',
    2: 'Professional ID',
    3: 'Contractor ID',
  }

  const label = employmentTypeLabels[Number(formik.values.employment_type)] || 'Employee ID'

  const handleOnHoldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    formik.setFieldValue('hasSameAddress', checked)
    setHasSameAddress(checked)
  }

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <Grid
                  item
                  sx={{ height: '65px', display: 'flex', alignContent: 'center', gap: '10px' }}
                >
                  <InputField
                    size='small'
                    fullWidth
                    id='first_name'
                    name='first_name'
                    label='First Name'
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                    inputProps={{ tabIndex: 1 }}
                  />

                  <InputField
                    size='small'
                    id='middle_name'
                    name='middle_name'
                    label={`Middle Name`}
                    value={formik.values.middle_name}
                    onChange={formik.handleChange}
                    inputProps={{ tabIndex: 2 }}
                    required={false}
                  />

                  <InputField
                    size='small'
                    fullWidth
                    id='last_name'
                    name='last_name'
                    label='Last Name'
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                    inputProps={{ tabIndex: 3 }}
                  />
                </Grid>

                <Grid
                  item
                  sx={{ display: 'flex', alignContent: 'center', gap: '10px', height: '75px' }}
                >
                  <InputField
                    size='small'
                    fullWidth
                    id='official_birth_date'
                    name='official_birth_date'
                    label='Official Birth Date'
                    type='date'
                    value={formik.values.official_birth_date || ''}
                    onChange={(e: any) => {
                      const age = dayjs().diff(dayjs(e?.target?.value, 'YYYY-MM-DD'), 'year')
                      if (age > 0) setAge(age)
                      formik.setFieldValue('birth_date', e?.target?.value)
                      return formik.handleChange(e)
                    }}
                    error={
                      formik.touched.official_birth_date &&
                      Boolean(formik.errors.official_birth_date)
                    }
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ tabIndex: 4, max: dayjs().format('YYYY-MM-DD') }} // Set max to today's date
                  />

                  <InputField
                    size='small'
                    fullWidth
                    id='birth_date'
                    name='birth_date'
                    label='Actual Birth Date'
                    type='date'
                    value={formik.values.birth_date || ''}
                    onChange={formik.handleChange}
                    error={formik.touched.birth_date && Boolean(formik.errors.birth_date)}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ tabIndex: 5, max: dayjs().format('YYYY-MM-DD') }} // Set max to today's date
                  />
                  <InputField
                    size='small'
                    fullWidth
                    id='age'
                    name='age'
                    label='Age'
                    value={age ?? ''}
                    disabled
                  />
                </Grid>

                <Grid
                  item
                  container
                  sx={{
                    display: 'flex',
                    alignContent: 'center',
                    flexDirection: 'row',
                    height: '70px',
                  }}
                  spacing={1.1}
                >
                  <Grid item xs={4}>
                    <SelectField
                      size='small'
                      id='gender'
                      name='gender'
                      label='Gender'
                      select
                      value={formik.values.gender}
                      onChange={formik.handleChange}
                      error={formik.touched.gender && Boolean(formik.errors.gender)}
                      inputProps={{ tabIndex: 6 }}
                    >
                      <StyledMenuItem value={1}>Male</StyledMenuItem>
                      <StyledMenuItem value={2}>Female</StyledMenuItem>
                    </SelectField>
                  </Grid>
                  <Grid
                    item
                    xs={
                      Number(formik.values.marital_status) !== 1 &&
                      Number(formik.values.marital_status) !== 3
                        ? 1.8
                        : 4
                    }
                  >
                    <SelectField
                      size='small'
                      id='marital_status'
                      name='marital_status'
                      label='Marital Status'
                      select
                      value={formik.values.marital_status}
                      onChange={formik.handleChange}
                      error={formik.touched.marital_status && Boolean(formik.errors.marital_status)}
                      inputProps={{ tabIndex: 7 }}
                    >
                      <StyledMenuItem value={1}>Single</StyledMenuItem>
                      <StyledMenuItem value={2}>Married</StyledMenuItem>
                      <StyledMenuItem value={3}>Widow</StyledMenuItem>
                    </SelectField>
                  </Grid>
                  {Number(formik.values.marital_status) !== 1 &&
                    Number(formik.values.marital_status) !== 3 && (
                      <Grid item xs={2.2}>
                        <InputField
                          size='small'
                          fullWidth
                          id={`anniversary_date`}
                          label='Anniversary Date'
                          name='anniversary_date'
                          type='date'
                          value={formik.values.anniversary_date || ''}
                          onChange={formik.handleChange}
                          InputLabelProps={{ shrink: true }}
                          required={false}
                          inputProps={{ max: dayjs().format('YYYY-MM-DD') }} // Set max to today's date
                        />
                      </Grid>
                    )}
                  <Grid item xs={4}>
                    <SelectField
                      select
                      size='small'
                      fullWidth
                      id='blood_group'
                      name='blood_group'
                      label='Blood Group'
                      value={formik.values.blood_group}
                      onChange={formik.handleChange}
                      error={formik.touched.blood_group && Boolean(formik.errors.blood_group)}
                      required={false}
                      inputProps={{ tabIndex: 8 }}
                      // helperText={formik.touched.present_state && formik.errors.present_state}
                    >
                      <StyledMenuItem value='' disabled>
                        Select Blood Group
                      </StyledMenuItem>
                      <StyledMenuItem value='A+'>A Positive</StyledMenuItem>
                      <StyledMenuItem value='A-'>A Negative</StyledMenuItem>
                      <StyledMenuItem value='B+'>B Positive</StyledMenuItem>
                      <StyledMenuItem value='B-'>B Negative</StyledMenuItem>
                      <StyledMenuItem value='O+'>O Positive</StyledMenuItem>
                      <StyledMenuItem value='O-'>O Negative</StyledMenuItem>
                      <StyledMenuItem value='AB+'>AB Positive</StyledMenuItem>
                      <StyledMenuItem value='AB-'>AB Negative</StyledMenuItem>
                    </SelectField>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Grid item style={{ textAlign: 'center', marginBottom: '0px' }}>
                  <Avatar
                    src={imagePreview ? (imagePreview as string) : props.userImage}
                    alt='User Preview'
                    sx={{
                      width: 120,
                      height: 120,
                      margin: '0 auto',
                      border: '2px solid #ddd',
                      boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                    }}
                  />
                </Grid>
                <Grid
                  item
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '8px',
                  }}
                >
                  <Button
                    variant='contained'
                    component='label'
                    sx={{
                      fontSize: '15px !important',
                      fontFamily: `${styles.FONT_BOLD}!important`,
                      width: '100%',
                      borderRadius: '20px !important',
                    }}
                  >
                    UPLOAD PHOTO
                    <input type='file' hidden accept='image/*' onChange={handleImageChange} />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              sx={{ display: 'flex', alignContent: 'center', gap: '10px', height: '65px' }}
            >
              <Grid item xs={2.93}>
                <SelectField
                  select
                  size='small'
                  fullWidth
                  id='employment_type'
                  name='employment_type'
                  label='Employment Type'
                  value={formik.values.employment_type}
                  onChange={formik.handleChange}
                  error={formik.touched.employment_type && Boolean(formik.errors.employment_type)}
                  required={true}
                  inputProps={{ tabIndex: 9 }}
                >
                  <StyledMenuItem value={1}>Employee</StyledMenuItem>
                  <StyledMenuItem value={2}>Professional</StyledMenuItem>
                  <StyledMenuItem value={3}>Contractor</StyledMenuItem>
                </SelectField>
              </Grid>

              <Grid item xs={2.93}>
                <InputField
                  size='small'
                  fullWidth
                  id='employee_id'
                  name='employee_id'
                  label={label}
                  value={formik.values.employee_id}
                  onChange={formik.handleChange}
                  error={formik.touched.employee_id && Boolean(formik.errors.employee_id)}
                  inputProps={{ tabIndex: 9 }}
                />
              </Grid>

              <Grid item xs={2.9}>
                <InputField
                  size='small'
                  fullWidth
                  id='email'
                  name='email'
                  label='Alternative Email'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  required={true}
                  inputProps={{ tabIndex: 11 }}
                  // disabled={Boolean(rowdata?.username)}
                />
              </Grid>
              <Grid item xs={2.9}>
                <InputField
                  size='small'
                  fullWidth
                  id='mobile_no'
                  name='mobile_no'
                  label='Mobile Number'
                  value={formik.values.mobile_no}
                  inputProps={{ tabIndex: 12 }}
                  onChange={(e) => {
                    let onlyNums = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                    if (onlyNums.length > 20) {
                      onlyNums = onlyNums.slice(0, 20); // Limit to 20 digits
                    }
                    formik.setFieldValue('mobile_no', onlyNums); // Update the formik field with only numbers
                  }}
                  error={formik.touched.mobile_no && Boolean(formik.errors.mobile_no)}
                  required={false}
                />
              </Grid>
            </Grid>
            <Divider sx={{ mb: 1, mt: 1 }} textAlign='center'>
              <FamilyDetailsHeading>Family Details</FamilyDetailsHeading>
            </Divider>

            <Grid item xs={12} mt={-1}>
              <FormikProvider value={formik}>
                <Form>
                  <FieldArray name='dependents'>
                    {({ push, remove }) => (
                      <>
                        {formik.values.dependents.map((dependent: any, index: number) => {
                          const baseTabIndex = 13 + index * 5
                          return (
                            <Grid
                              container
                              spacing={2}
                              key={index}
                              alignItems='center'
                              style={{ height: '80px' }}
                            >
                              <Grid item xs={2.6}>
                                <InputField
                                  name={`dependents[${index}].name`}
                                  size='small'
                                  fullWidth
                                  label={
                                    dependent.id_relationship === 'children'
                                      ? "Child's Name"
                                      : dependent.id_relationship === 'father'
                                      ? "Father's Name"
                                      : dependent.id_relationship === 'mother'
                                      ? "Mother's Name"
                                      : dependent.id_relationship === 'spouse'
                                      ? "Spouse's Name"
                                      : 'Name of the dependent'
                                  }
                                  variant='outlined'
                                  margin='normal'
                                  value={dependent.name}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  required={true}
                                  inputProps={{ tabIndex: baseTabIndex }}
                                  error={
                                    (formik.touched.dependents as any[])?.[index]?.name &&
                                    Boolean((formik.errors.dependents as any[])?.[index]?.name)
                                  }
                                />
                              </Grid>

                              <Grid item xs={1.8}>
                                <SelectField
                                  name={`dependents[${index}].id_relationship`}
                                  size='small'
                                  fullWidth
                                  select
                                  label='Relationship'
                                  variant='outlined'
                                  margin='normal'
                                  value={dependent.id_relationship.toLowerCase()}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  required={true}
                                  error={
                                    (formik.touched.dependents as any[])?.[index]
                                      ?.id_relationship &&
                                    !formik.values.dependents[index].id_relationship &&
                                    Boolean(!formik.values.dependents[index].id_relationship)
                                  }
                                  inputProps={{ tabIndex: baseTabIndex + 1 }}
                                >
                                  <StyledMenuItem value='mother' disabled>
                                    Mother
                                  </StyledMenuItem>
                                  <StyledMenuItem value='father' disabled>
                                    Father
                                  </StyledMenuItem>
                                  {Number(formik.values.marital_status) !== 1 &&
                                    Number(formik.values.marital_status) !== 3 && (
                                      <StyledMenuItem
                                        value='spouse'
                                        disabled={index === 0 || index === 1}
                                      >
                                        Spouse
                                      </StyledMenuItem>
                                    )}
                                  {Number(formik.values.marital_status) !== 1 && (
                                    <StyledMenuItem
                                      value='children'
                                      disabled={index === 0 || index === 1}
                                    >
                                      Children
                                    </StyledMenuItem>
                                  )}
                                </SelectField>
                              </Grid>
                              <Grid item xs={1.9}>
                                <InputField
                                  size='small'
                                  fullWidth
                                  id={`dependents[${index}].birth_date`}
                                  label='Birth Date'
                                  name={`dependents[${index}].birth_date`}
                                  type='date'
                                  value={dependent.birth_date || ''}
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const selectedDate = event.target.value
                                    formik.setFieldValue(
                                      `dependents[${index}].birth_date`,
                                      selectedDate,
                                    )

                                    const age = calculateAge(selectedDate)
                                    formik.setFieldValue(`dependents[${index}].age`, age)
                                  }}
                                  InputLabelProps={{ shrink: true }}
                                  required={false}
                                  inputProps={{
                                    tabIndex: baseTabIndex + 2,
                                    max: dayjs().format('YYYY-MM-DD'),
                                  }} // Set max to today's date
                                />
                              </Grid>

                              <Grid item xs={1.9}>
                                <InputField
                                  size='small'
                                  fullWidth
                                  id='phone_number'
                                  name={`dependents[${index}].phone_number`}
                                  label='Phone Number'
                                  value={dependent.phone_number}
                                  onChange={(e) => {
                                    let onlyNums = e.target.value.replace(/[^0-9]/g, '')
                                    if (onlyNums.length > 20) {
                                      onlyNums = onlyNums.slice(0, 10)
                                    }
                                    formik.setFieldValue(
                                      `dependents[${index}].phone_number`,
                                      onlyNums,
                                    )
                                  }}
                                  required={false}
                                  inputProps={{ tabIndex: baseTabIndex + 3 }}
                                />
                              </Grid>

                              <Grid item xs={1.1}>
                                <InputField
                                  name={`dependents[${index}].age`}
                                  size='small'
                                  fullWidth
                                  label='Age'
                                  variant='outlined'
                                  margin='normal'
                                  value={dependent.age ?? ''}
                                  required={false}
                                  inputProps={{ tabIndex: baseTabIndex + 4 }}
                                  disabled={true}
                                />
                              </Grid>

                              <Grid
                                item
                                xs={2.6}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <FormGroup row>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        disabled={!dependent.phone_number}
                                        name={`dependents[${index}].is_emergency_contact`}
                                        checked={
                                          Number(dependent.is_emergency_contact) === 1
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          formik.setFieldValue(
                                            `dependents[${index}].is_emergency_contact`,
                                            Number(dependent.is_emergency_contact) === 1 ? 0 : 1,
                                          )
                                        }
                                        inputProps={{ tabIndex: baseTabIndex + 5 }}
                                      />
                                    }
                                    label='Emergency Contact'
                                    sx={{
                                      '& .MuiFormControlLabel-label': {
                                        fontSize: '13.7px',
                                        fontFamily: styles.FONT_MEDIUM,
                                      },
                                    }}
                                  />
                                </FormGroup>
                                {dependent.id_relationship !== 'father' &&
                                  dependent.id_relationship !== 'mother' && (
                                    <Tooltip title='Remove Dependent' arrow>
                                      <IconButton
                                        onClick={() => remove(index)}
                                        tabIndex={baseTabIndex + 3}
                                        sx={{ margin: '0px', padding: '0px' }}
                                      >
                                        <RemoveCircleOutlineSharpIcon
                                          sx={{ margin: '0px', padding: '0px' }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                              </Grid>
                            </Grid>
                          )
                        })}

                        <CommonButton
                          type='button'
                          onClick={() =>
                            push({
                              name: '',
                              id_relationship: '',
                              birth_date: '',
                              phone_number: '',
                              age: null,
                              is_emergency_contact: 0,
                            })
                          }
                          startIcon={<AddCircleOutlineTwoToneIcon />}
                          variant='contained'
                          color='primary'
                          sx={{ mt: 2, width: '250px !important' }}
                          tabIndex={formik.values.dependents.length * 3 + 19}
                          disabled={
                            Number(formik.values.marital_status) === 1
                              ? true
                              : formik.values.dependents?.[0].name === ''
                          }
                        >
                          Add Family Member
                        </CommonButton>
                      </>
                    )}
                  </FieldArray>
                </Form>
              </FormikProvider>
            </Grid>

            <Divider sx={{ my: 2 }} />

            <Grid container spacing={1}>
              <Grid item>
                <FormControl component='fieldset' sx={{ marginTop: '-10px' }}>
                  <FormLabel
                    component='legend'
                    sx={{
                      fontSize: '16px', // Change label font size here
                      fontFamily: styles.FONT_MEDIUM, // Change font family here
                      lineHeight: '1.8em', // Line height adjustment
                    }}
                  >
                    Assign Role
                  </FormLabel>
                  <FormGroup row>
                    {uniqueRole &&
                      uniqueRole.length > 0 &&
                      uniqueRole.map((role: { id: number; name: string }, roleIndex: any) => (
                        <FormControlLabel
                          key={role.id}
                          control={
                            <Checkbox
                              name='id_roles'
                              value={role.id.toString()}
                              checked={(formik.values.id_roles as number[]).includes(role.id)}
                              onChange={handleCheckboxChange}
                              tabIndex={formik.values.dependents.length * 3 + 20 + roleIndex}
                            />
                          }
                          label={role.name}
                          sx={{
                            '& .MuiFormControlLabel-label': {
                              fontSize: '15px', // Apply font size to the label
                              fontFamily: styles.FONT_MEDIUM, // Apply font family to the label
                            },
                          }}
                        />
                      ))}
                  </FormGroup>

                  <FormHelperText sx={{ color: 'red', ml: 0 }}>
                    {Array.isArray(formik.errors.id_roles)
                      ? formik.errors.id_roles.join(', ')
                      : formik.errors.id_roles}{' '}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </>
        )
      case 1:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <FormLabel
                  id='demo-row-radio-buttons-group-label'
                  sx={{
                    marginTop: '-2px',
                    paddingRight: '10px',
                    fontSize: '15px', // Change label font size here
                    fontFamily: styles.FONT_MEDIUM, // Change font family here
                    lineHeight: '1.8em', // Line height adjustment
                  }}
                >
                  Work Mode
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby='demo-row-radio-buttons-group-label'
                  name='work_mode'
                  value={formik.values.work_mode}
                  onChange={(event) => {
                    formik.handleChange(event) // Update the work_mode value

                    // Reset all the fields based on the selected work mode
                    formik.setFieldValue('client_location', null)
                    formik.setFieldValue('location_start_date', '')
                    formik.setFieldValue('location_end_date', '')
                    formik.setFieldValue('day_to_visit', '')
                    formik.setFieldValue('time_to_visit', '')
                    formik.setFieldValue('project_assigned', '')
                    formik.setFieldValue('location', null)
                    formik.setFieldValue('floor_id', null)
                    formik.setFieldValue('Workstation', null)
                    formik.setFieldValue('comp_location_start_date', '')
                    formik.setFieldValue('comp_location_end_date', '')
                  }}
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      // Style for RadioGroup labels
                      fontSize: '13px', // Change label font size here
                      fontFamily: styles.FONT_MEDIUM, // Change label font family here
                    },
                  }}
                >
                  <FormControlLabel value={1} control={<Radio />} label='Client Location' />
                  <FormControlLabel value={2} control={<Radio />} label='Work From Office' />
                  <FormControlLabel value={3} control={<Radio />} label='Work From Home' />
                </RadioGroup>
                {formik.touched.work_mode && formik.errors.work_mode && (
                  <FormHelperText error>{formik.errors.work_mode}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sx={{ position: 'relative', left: '10px', top: '11px' }}>
              <Grid container spacing={2}>
                {renderFieldsByWorkMode(formik?.values?.work_mode)}
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12}>
                <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <FormLabel
                    id='demo-row-radio-buttons-group-label'
                    sx={{
                      mr: 2,
                      fontSize: '15px', // Change label font size here
                      fontFamily: styles.FONT_MEDIUM, // Change font family here
                      lineHeight: '1.8em', // Line height adjustment
                    }}
                  >
                    Status
                  </FormLabel>
                  <RadioGroup
                    row
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        // Style for RadioGroup labels
                        fontSize: '13px', // Change label font size here
                        fontFamily: styles.FONT_MEDIUM, // Change label font family here
                      },
                    }}
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='status'
                    value={formik.values.status}
                    onChange={formik.handleChange}
                  >
                    <FormControlLabel value={1} control={<Radio />} label='Active' />
                    <FormControlLabel value={0} control={<Radio />} label='InActive' />
                  </RadioGroup>
                  {formik.touched.status && formik.errors.status && (
                    <FormHelperText error>{formik.errors.status}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item sx={style.gridStyleLogin} xs={3}>
                <InputField
                  size='small'
                  fullWidth
                  id='loginId'
                  name='loginId'
                  label='Login Id'
                  value={formik.values.loginId}
                  onChange={formik.handleChange}
                  error={formik.touched.loginId && Boolean(formik.errors.loginId)}
                />
              </Grid>
              {/* <Grid item sx={style.gridStyle} xs={4}>
                <InputField
                  size='small'
                  fullWidth
                  type='password'
                  id='password'
                  name='password'
                  label='Password'
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  required={false}
                  // helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>
              <Grid item sx={style.gridStyle} xs={4}>
                <InputField
                  size='small'
                  fullWidth
                  type='password'
                  id='confirm_password'
                  name='confirm_password'
                  label='Confirm Password'
                  value={formik.values.confirm_password}
                  onChange={formik.handleChange}
                  required={false}
                  error={formik.touched.confirm_password && Boolean(formik.errors.confirm_password)}
                />
                <Typography></Typography>
              </Grid> */}

              {/* Designation */}
              <Grid item xs={3}>
                <Grid item sx={style.changedGridStyle}>
                  <SelectField
                    size='small'
                    id='designation'
                    name='designation'
                    label='Designation'
                    select
                    value={formik.values.designation}
                    onChange={formik.handleChange}
                    error={formik.touched.designation && Boolean(formik.errors.designation)}
                    sx={style.selectStyle}
                    required={true}
                  >
                    {props.designationData?.map((data: any, index: number) => (
                      <StyledMenuItem key={index} value={data.id}>
                        {data.grade_name} - {data.desc}
                      </StyledMenuItem>
                    ))}
                  </SelectField>
                </Grid>
              </Grid>

              {/* Designation */}
              <Grid item xs={3}>
                <Grid item sx={style.changedGridStyle}>
                  <SelectField
                    size='small'
                    id='desig_band'
                    name='desig_band'
                    label='Designation Band'
                    select
                    value={formik.values.desig_band}
                    onChange={formik.handleChange}
                    error={formik.touched.desig_band && Boolean(formik.errors.desig_band)}
                    sx={style.selectStyle}
                    required={true}
                  >
                    {props.designationBandData?.map((data: any, index: number) => (
                      <StyledMenuItem key={index} value={data.id}>
                        {data.band_title}
                      </StyledMenuItem>
                    ))}
                  </SelectField>
                </Grid>
              </Grid>

              {/* Manager */}
              <Grid item xs={3}>
                <Grid item sx={style.changedGridStyle}>
                  <SelectField
                    size='small'
                    id='manager'
                    name='manager'
                    label='Manager'
                    select
                    value={formik.values.manager}
                    onChange={formik.handleChange}
                    error={formik.touched.manager && Boolean(formik.errors.manager)}
                    sx={style.selectStyle}
                    required={true}
                  >
                    {props.AllEmpList?.map((data: any, index: number) => (
                      <StyledMenuItem key={index} value={data.userId}>
                        {data.name}
                      </StyledMenuItem>
                    ))}
                  </SelectField>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      case 2:
        return (
          <Box>
            <Box mb={2}>
              <Typography fontFamily={styles.FONT_BOLD}>Present Address</Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Grid item sx={style.gridStyle}>
                  {props.allCountries && props.allCountries.length > 0 && (
                    <SelectField
                      select
                      size='small'
                      fullWidth
                      id='id_country'
                      name='id_country'
                      label='Country'
                      value={formik.values.id_country}
                      onChange={(event) => handleCountry(event)}
                      error={formik.touched.id_country && Boolean(formik.errors.id_country)}
                      inputProps={{ tabIndex: 1 }}
                    >
                      {props.allCountries.map((country: any) => (
                        <StyledMenuItem key={country.id} value={country.id}>
                          {country.country_name}
                        </StyledMenuItem>
                      ))}
                    </SelectField>
                  )}
                </Grid>
                <Grid item sx={style.gridStyle}>
                  <InputField
                    size='small'
                    fullWidth
                    id='present_address'
                    name='present_address'
                    label='Address'
                    value={formik.values.present_address}
                    onChange={formik.handleChange}
                    error={formik.touched.present_address && Boolean(formik.errors.present_address)}
                    inputProps={{ tabIndex: 4 }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid item sx={style.gridStyle}>
                  {/* {props.allStates && props.allStates.length > 0 && ( */}
                  <SelectField
                    select
                    size='small'
                    fullWidth
                    id='present_state'
                    name='present_state'
                    label='State'
                    value={formik.values.present_state}
                    onChange={formik.handleChange}
                    error={formik.touched.present_state && Boolean(formik.errors.present_state)}
                    // helperText={formik.touched.present_state && formik.errors.present_state}
                    inputProps={{ tabIndex: 2 }}
                  >
                    {states.map((state: any) => (
                      <StyledMenuItem key={state.id} value={state.id}>
                        {state.state_name}
                      </StyledMenuItem>
                    ))}
                  </SelectField>
                  {/* )} */}
                </Grid>
                <Grid item sx={style.gridStyle}>
                  <InputField
                    size='small'
                    fullWidth
                    id='present_postal_code'
                    name='present_postal_code'
                    label='Postal Code'
                    value={formik.values.present_postal_code}
                    // onChange={formik.handleChange}
                    onChange={(e) => {
                      let allowedCharacters = e.target.value.replace(/[^a-zA-Z0-9]/g, '')
                      if (allowedCharacters.length > 10) {
                        allowedCharacters = allowedCharacters.slice(0, 10)
                      }
                      formik.setFieldValue('present_postal_code', allowedCharacters)
                    }}
                    error={
                      formik.touched.present_postal_code &&
                      Boolean(formik.errors.present_postal_code)
                    }
                    inputProps={{ tabIndex: 5 }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid item sx={style.gridStyle}>
                  <InputField
                    size='small'
                    fullWidth
                    id='present_city'
                    name='present_city'
                    label='City'
                    value={formik.values.present_city}
                    onChange={formik.handleChange}
                    error={formik.touched.present_city && Boolean(formik.errors.present_city)}
                    inputProps={{ tabIndex: 3 }}
                  />
                </Grid>
                <Grid item sx={style.gridStyle}>
                  <InputField
                    size='small'
                    fullWidth
                    id='citizenship'
                    name='citizenship'
                    label='Citizenship'
                    value={formik.values.citizenship}
                    onChange={formik.handleChange}
                    error={formik.touched.citizenship && Boolean(formik.errors.citizenship)}
                    required={false}
                    inputProps={{ tabIndex: 6 }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Box mb={2} sx={{ display: 'flex', alignItems: 'center', marginLeft: '-17px' }}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.hasSameAddress}
                      onChange={(event) => handleOnHoldChange(event)}
                    />
                  }
                  label=''
                  sx={{
                    marginLeft: '5px',
                    '& .MuiFormControlLabel-label': {
                      fontSize: '13.7px',
                      fontFamily: 'Montserrat-Medium',
                    },
                  }}
                />
              </FormGroup>
              <Typography fontFamily={styles.FONT_BOLD} sx={{ marginLeft: '-20px' }}>
                Permanent address is same as present <address></address>
              </Typography>
            </Box>
            {!formik.values.hasSameAddress && (
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Grid item sx={style.gridStyle}>
                    {props.allCountries && props.allCountries.length > 0 && (
                      <SelectField
                        select
                        size='small'
                        fullWidth
                        id='id_country_permanent'
                        name='id_country_permanent'
                        label='Country'
                        value={formik.values.id_country_permanent}
                        onChange={(event) => handlePermanentCountry(event)}
                        error={
                          formik.touched.id_country_permanent &&
                          Boolean(formik.errors.id_country_permanent)
                        }
                        inputProps={{ tabIndex: 8 }}
                      >
                        {props.allCountries.map((country: any) => (
                          <StyledMenuItem key={country.id} value={country.id}>
                            {country.country_name}
                          </StyledMenuItem>
                        ))}
                      </SelectField>
                    )}
                  </Grid>
                  <Grid item sx={style.gridStyle}>
                    <InputField
                      size='small'
                      fullWidth
                      id='permanent_address'
                      name='permanent_address'
                      label='Address'
                      value={formik.values.permanent_address}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.permanent_address && Boolean(formik.errors.permanent_address)
                      }
                      inputProps={{ tabIndex: 11 }}
                      // helperText={formik.touched.permanent_address && formik.errors.permanent_address}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid item sx={style.gridStyle}>
                    {/* {props.allStates && props.allStates.length > 0 && ( */}
                    <SelectField
                      select
                      size='small'
                      fullWidth
                      id='permanent_state'
                      name='permanent_state'
                      label='State'
                      value={formik.values.permanent_state}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.permanent_state && Boolean(formik.errors.permanent_state)
                      }
                      inputProps={{ tabIndex: 9 }}
                    >
                      {statesPermanent?.map((state: any) => (
                        <StyledMenuItem key={state.id} value={state.id}>
                          {state.state_name}
                        </StyledMenuItem>
                      ))}
                    </SelectField>
                    {/* )} */}
                  </Grid>
                  <Grid item sx={style.gridStyle}>
                    <InputField
                      size='small'
                      fullWidth
                      id='permanent_postal_code'
                      name='permanent_postal_code'
                      label='Postal Code'
                      value={formik.values.permanent_postal_code}
                      // onChange={formik.handleChange}
                      onChange={(e) => {
                        let allowedCharacters = e.target.value.replace(/[^a-zA-Z0-9]/g, '')
                        if (allowedCharacters.length > 10) {
                          allowedCharacters = allowedCharacters.slice(0, 10)
                        }
                        formik.setFieldValue('permanent_postal_code', allowedCharacters)
                      }}
                      error={
                        formik.touched.permanent_postal_code &&
                        Boolean(formik.errors.permanent_postal_code)
                      }
                      inputProps={{ tabIndex: 12 }}
                      // helperText={
                      //   formik.touched.permanent_postal_code && formik.errors.permanent_postal_code
                      // }
                    />
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid item sx={style.gridStyle}>
                    <InputField
                      size='small'
                      fullWidth
                      id='permanent_city'
                      name='permanent_city'
                      label='City'
                      value={formik.values.permanent_city}
                      onChange={formik.handleChange}
                      error={formik.touched.permanent_city && Boolean(formik.errors.permanent_city)}
                      inputProps={{ tabIndex: 10 }}
                      // helperText={formik.touched.permanent_city && formik.errors.permanent_city}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Box>
        )
      default:
        return 'Unknown stepIndex'
    }
  }

  return (
    <div className={classes.root}>
      {(isUserCreating || isUserUpdating) && (
        <Dialog open={isUserCreating || isUserUpdating} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      )}
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ textAlign: 'left' }}>
          <Box
            onClick={() => navigate(-1)}
            sx={{ float: 'right', pr: '30px', mt: '0px', cursor: 'pointer' }}
          >
            <ArrowBack />
          </Box>
        </Box>

        <HeaderHeading sx={{ mb: 2, mt: -1.5 }}>
          {Boolean(rowdata?.username)
            ? `Update ${rowdata?.first_name}'s Details`
            : `Add Employee/Professional/Contractor`}
        </HeaderHeading>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ mx: 4, my: 1, mt: 3 }}>
          <div>
            <div>{getStepContent(activeStep)}</div>
            <div
              style={{
                marginTop: '24px',
                display: 'flex',
                justifyContent: activeStep === 0 ? 'flex-end' : 'space-between',
              }}
            >
              <CommonButton
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
                sx={{ display: activeStep === 0 ? 'none' : 'flex' }}
              >
                PREVIOUS
              </CommonButton>
              <CommonButton variant='contained' color='primary' onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'FINISH' : 'NEXT'}
              </CommonButton>
            </div>
          </div>
        </Box>
      </form>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    designationData: employeePortalEntity.getEmployeePortal(state).getDesignationData,
    designationBandData: employeePortalEntity.getEmployeePortal(state).getDesignationBandData,
    AllEmpList: projectManagementEntity.getAllProjectManagement(state).getAllEmpList,
    isEmployeeFetched: fetchUserDetailsUI.fetchUserData(state).isGetEmpoyee,

    getUserDetails: fetchUserDetailsEntities.fetchUserData(state).fetchUser,
    allRoles: fetchUserDetailsEntities.fetchUserData(state).fetchAllRole,
    allStates: fetchUserDetailsEntities.fetchUserData(state).fetchAllState,
    allCountries: fetchUserDetailsEntities.fetchUserData(state).fetchAllCountry,
    allFloors: fetchUserDetailsEntities.fetchUserData(state).fetchAllFloor,
    allWorkstations: fetchUserDetailsEntities.fetchUserData(state).fetchAllWorkstation,
    allLocation: fetchUserDetailsEntities.fetchUserData(state).fetchAllLocation,
    allClientLocation: fetchUserDetailsEntities.fetchUserData(state).fetchAllClientLocation,
    isUserCreated: fetchUserDetailsUI.fetchUserData(state).isUserCreatedSuccess,
    isUserCreating: fetchUserDetailsUI.fetchUserData(state).isUserCreating,
    isUserCreateFailed: fetchUserDetailsUI.fetchUserData(state).isUserCreateFailed,
    isUserUpdated: fetchUserDetailsUI.fetchUserData(state).isUserUpdated,
    isUserUpdating: fetchUserDetailsUI.fetchUserData(state).isUserUpdating,
    isUserUpdateFailed: fetchUserDetailsUI.fetchUserData(state).isUserUpdateFailed,
    userImage: fetchUserDetailsEntities.fetchUserData(state).getUserImage,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchDesignationData: () => dispatch(fetchDesignation.request()),
    fetchDesignationBandData: () => dispatch(fetchDesignationBand.request()),
    fetchAllEmpAPI: () => dispatch(fetchWorkingEmp.request()),
    getUserImage: (data: any) => dispatch(getUserImage.request(data)),
    resetUserImage: () => {
      dispatch(getUserImage.reset())
    },
    uploadUserImg: (data: any) => dispatch(uploadUserImg.request({ data })),
    createUser: (data: any) => dispatch(createUser.request({ data })),
    resetCreateUser: () => {
      dispatch(createUser.reset())
    },
    fetchEmployee: (data: any) => dispatch(fetchEmployee.request(data)),
    resetEmployee: () => dispatch(fetchEmployee.reset()),
    fetchAllRole: () => dispatch(fetchAllRoles.request()),
    fetchAllState: () => dispatch(fetchAllStates.request()),
    fetchAllCountry: () => dispatch(fetchAllCountries.request()),
    fetchAllFloor: () => dispatch(fetchAllFloors.request()),
    fetchAllWorkstation: () => dispatch(fetchAllWorkstation.request()),
    fetchAllLocation: () => dispatch(fetchAllLocation.request()),
    updateUser: (data: any) => dispatch(updateUser.request({ data })),
    resetUpdateUser: () => {
      dispatch(updateUser.reset())
    },
    fetchAllClientLocation: () => dispatch(fetchAllClientLocation.request()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(StepperForm)
