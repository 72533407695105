import { Paper } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import StepperForm from './StepperForm'

const MainContainer = {
  backgroundColor: 'rgb(231, 235, 240)',
  width: '100%',
}
const StyledPaper = styled(Paper)(() => ({
  width: '93%',
  padding: '25px 25px',
  background: '#FFFFFF',
  opacity: '1',
  margin: '20px',
  border: '1px solid #DDDDDD',
}))
const NewJoiners = () => {
  return (
    <div style={MainContainer}>
      <StyledPaper>
        <StepperForm />
      </StyledPaper>
    </div>
  )
}

export default NewJoiners
