import * as React from 'react'
import MenuItem from '@mui/material/MenuItem'
import { Box, FormControl, InputLabel, Select } from '@mui/material'
import { SelectReportTypePropType } from './projectQAReportTypes'
import { style } from './ projectQAReportStyles'

const SelectReportType = ({selectType,setSelectType}:SelectReportTypePropType) => {

  const handleSelectChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setSelectType(event.target.value)
  }

  return (
    <Box sx={style.SelectReportTypeBoxStyle}>
      <FormControl fullWidth>
        <InputLabel id='demo-simple-select-label'>Report Type</InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          displayEmpty
          value={selectType}
          label='Report Type'
          onChange={handleSelectChange}
          sx={style.selectReportTypeStyle}
        >
          <MenuItem value='project-wise'>Project Wise</MenuItem>
          <MenuItem value='employee-wise'>Employee Wise</MenuItem>
        </Select>
      </FormControl>
    </Box>
  )
}

export default SelectReportType
