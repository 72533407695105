export const CardData = (tableData: any) => {
  return [
    {
      title: 'Project Details',
      items: [`Total Projects - ${tableData?.overall?.count ? tableData?.overall?.count  : 0}`],
    },
    {
      title: 'Total Billable Dev',
      items: [
        `Fixed - ${tableData?.overall?.stats?.all_dev_fixed  ? tableData?.overall?.stats?.all_dev_fixed : 0}`,
        `Monthly - ${tableData?.overall?.stats?.all_dev_monthly  ? tableData?.overall?.stats?.all_dev_monthly  : 0}`,
        `Hours - ${tableData?.overall?.stats?.all_qa_hourly ? tableData?.overall?.stats?.all_dev_hourly  : 0}`,
      ],
    },
    {
      title: 'Total Billable QA',
      items: [
        `Fixed - ${tableData?.overall?.stats?.all_qa_fixed  ? tableData?.overall?.stats?.all_qa_fixed : 0}`,
        `Monthly - ${tableData?.overall?.stats?.all_qa_monthly  ? tableData?.overall?.stats?.all_qa_monthly  : 0}`,
        `Hours - ${tableData?.overall?.stats?.all_dev_hourly ? tableData?.overall?.stats?.all_qa_hourly  : 0}`,
      ],
    },
    {
      title: 'Total Billable BA',
      items: [
        `Fixed - ${tableData?.overall?.stats?.all_BA_fixed  ? tableData?.overall?.stats?.all_BA_fixed : 0}`,
        `Monthly - ${tableData?.overall?.stats?.all_BA_monthly  ? tableData?.overall?.stats?.all_BA_monthly  : 0}`,
        `Hours - ${tableData?.overall?.stats?.all_dev_hourly ? tableData?.overall?.stats?.all_BA_hourly  : 0}`,
      ],
    },
    {
      title: 'Total Billable Designer',
      items: [
        `Fixed - ${tableData?.overall?.stats?.all_designer_fixed  ? tableData?.overall?.stats?.all_designer_fixed : 0}`,
        `Monthly - ${tableData?.overall?.stats?.all_designer_monthly  ? tableData?.overall?.stats?.all_designer_monthly  : 0}`,
        `Hours - ${tableData?.overall?.stats?.all_dev_hourly ? tableData?.overall?.stats?.all_designer_hourly  : 0}`,
      ],
    },
    {
      title: 'Total Billable Lead',
      items: [
        `Fixed - ${tableData?.overall?.stats?.all_lead_fixed  ? tableData?.overall?.stats?.all_lead_fixed : 0}`,
        `Monthly - ${tableData?.overall?.stats?.all_lead_monthly  ? tableData?.overall?.stats?.all_lead_monthly  : 0}`,
        `Hours - ${tableData?.overall?.stats?.all_lead_hourly ? tableData?.overall?.stats?.all_lead_hourly  : 0}`,
      ],
    },
    {
      title: 'Total Billable Reviewer',
      items: [
        `Fixed - ${tableData?.overall?.stats?.all_reviewer_fixed  ? tableData?.overall?.stats?.all_reviewer_fixed : 0}`,
        `Monthly - ${tableData?.overall?.stats?.all_reviewer_monthly  ? tableData?.overall?.stats?.all_reviewer_monthly  : 0}`,
        `Hours - ${tableData?.overall?.stats?.all_reviewer_hourly ? tableData?.overall?.stats?.all_reviewer_hourly  : 0}`,
      ],
    },
  ]
}
