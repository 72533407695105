import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import CloseIcon from '@mui/icons-material/Close'
import { ReactComponent as EditIcon } from '../../assets/images/editIconTimesheet.svg'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Pagination,
  Tooltip,
  Typography,
} from '@mui/material'
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone'
import {
  HeaderHeading,
  StatusBoxCustom,
  StyledEditableTableCell,
  loaderProps,
} from '../Common/CommonStyles'
import { Dispatch } from 'redux'
import { RootState } from '../../configureStore'
import { connect } from 'react-redux'
import {
  deleteUser,
  fetchAllUserList,
  fetchEmployee,
  fetchEmployeeInfo,
  fetchSR,
} from '../../actions'
import {
  SRUI,
  dashboardEntity,
  dashboardUI,
  fetchUserDetailsEntities,
  fetchUserDetailsUI,
} from '../../reducers'
// import getHardCodedData from './ServiceRequestData.json'
import { useNavigate } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import { toast } from 'react-toastify'
import { StyledMenuItem } from './UserDetailsStyle'
import { style } from '../ProjectManagement/mandate/ConfirmDeleteDialogStyles'
import DebouncedSearchedBox from '../Common/DebouncedSearchBox'
import styles from '../../utils/styles.json'

const StyledPaper = styled(Paper)(() => ({
  width: '93%',
  padding: '25px 25px',
  background: '#FFFFFF',
  opacity: '1',
  margin: '20px',
  border: '1px solid #DDDDDD',
}))

const HeadingNew = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  textAlign: 'center',
  fontFamily: styles.FONT_BOLD,
  letterSpacing: '0px',
  '@media (max-width: 500px)': {
    fontSize: '5vw',
  },
}))

const ActionButtonNew = styled(Button)(({ theme }) => ({
  fontSize: '13px',
  height: '35px',
  fontFamily: styles.FONT_BOLD,
  width: '25%',
  borderRadius: '20px',
}))

const CancelButton = styled(Button)(({ theme }) => ({
  background: '#E2E2E2',
  color: '#000000',
  fontSize: '13px',
  height: '35px',
  fontFamily: styles.FONT_BOLD,
  width: '25%',
  borderRadius: '20px',
  '&:hover': {
    background: '#E2E2E2',
    color: '#000000',
  },
}))

const MainContainer = {
  backgroundColor: 'rgb(231, 235, 240)',
  width: '100%',
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: styles.PRIMARY_COLOR,
    color: 'White',
    fontFamily: styles.FONT_MEDIUM,
    textAlign: 'center',
    fontSize: '13px',
    letterSpacing: '0px',
    padding: '11px 0px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    fontFamily: styles.FONT_MEDIUM,
    textAlign: 'center',
    color: '#483f3f',
    letterSpacing: '0px',
  },
}))

const ActionButton = styled(Button)(() => ({
  fontSize: '13px',
  height: '42px',
  float: 'right',
  marginTop: '15px',
  borderRadius: '20px',
  padding: '5px 20px',
  fontFamily: styles.FONT_MEDIUM,
}))

const StyledTableRow = styled(TableRow)(() => ({
  left: '160px',
  width: '1719px',
  height: '60px',
  boxShadow: '0px 10px 3px #6c6c6c10',
  opacity: '1',
}))

const ServiceRequestTableData = ({
  userRequestData,
  total,
  page,
  handleChangePage,
  setSearchQuery,
  setPage,
  isGettingUserData,
  empStatus,
  setEmpStatus,
  handleDeleteOpenDialog,
  fetchEmployee,
  rowData,
  restFetchEmployee,
  fetchEmployeementInfo,
  employeementInfoData,
  resetEmployeementInfo,
}: any) => {
  const rowsPerPage = 100
  const navigate = useNavigate()

  const [getUserId, setUserId] = useState()

  // useEffect(() => {
  //   if (employeementInfoData?.employee_id) {
  //     navigate('/home/admin/employees/employees-info', {
  //       state: { userid: getUserId ? getUserId : '', employmentData: employeementInfoData },
  //     })
  //     resetEmployeementInfo()
  //   }
  // }, [employeementInfoData?.employee_id])

  const handleClickOpen = (rowData: any) => {
    // if (rowData?.userid) fetchEmployee({ id: rowData?.userid })
    if (rowData?.userid)
      navigate('/home/employees/employeeInfoTiles', { state: rowData?.userid ? rowData : {} })
    else
      navigate('/home/employees/new-employees', {
        state: { userid: rowData?.userid ? rowData : '' },
      })
  }
  const handleEditEmpInfo = (event: any, rowData: any) => {
    event.stopPropagation() // Prevent row click
    setUserId(rowData?.userid)
    if (rowData?.userid) {
      fetchEmployeementInfo({ userId: rowData?.userid })
    }
  }

  const handleBankInfo = (event: any, rowData: any) => {
    event.stopPropagation()
    if (rowData?.userid) {
      navigate('/home/admin/employees/bank-info')
    }
  }

  // useEffect(() => {
  //   if (rowData?.userid) {
  //     navigate('/home/employees/employeeInfoTiles', { state: rowData?.userid ? rowData : {} })
  //     // navigate('/home/employees/new-employees', { state: rowData?.userid ? rowData : {} })
  //     restFetchEmployee()
  //   }
  // }, [rowData])

  return (
    <>
      <Box sx={{ textAlign: 'left' }}>
        <Box
          onClick={() => navigate(-1)}
          sx={{ float: 'right', pr: '30px', mt: '0px', cursor: 'pointer' }}
        >
          <ArrowBack />
        </Box>
      </Box>

      <HeaderHeading>Employee List</HeaderHeading>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Box
          sx={{
            display: 'flex',
            width: '50%',
            height: 'auto',
            gap: '20px',
          }}
        >
          <DebouncedSearchedBox
            placeHolder={'Search'}
            setSearchQuery={setSearchQuery}
            setPage={setPage}
          />
          <Box>
            <StatusBoxCustom
              size='small'
              label='Emp Status'
              value={empStatus}
              onChange={(e: any) => {
                setEmpStatus(e.target.value)
                setPage(1)
              }}
              select
              required={false}
            >
              <StyledMenuItem value='All'>All{/* - {userList.count} */}</StyledMenuItem>
              <StyledMenuItem value='active'>
                Active {/* - {userList?.activeCount}*/}
              </StyledMenuItem>
              <StyledMenuItem value='disabled'>
                Disabled {/* - {userList?.disabledCount}*/}
              </StyledMenuItem>
            </StatusBoxCustom>
          </Box>
        </Box>

        <ActionButton
          variant='outlined'
          startIcon={<AddTwoToneIcon sx={{ width: 24, height: 24 }} />}
          onClick={handleClickOpen}
          sx={{ width: '200px', mt: 1 }}
        >
          NEW EMPLOYEE
        </ActionButton>
      </Box>

      <TableContainer component={Paper} sx={{ cursor: 'pointer' }}>
        <Table sx={{ minWidth: 700 }} aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledEditableTableCell width='50px'>EMP ID</StyledEditableTableCell>
              <StyledTableCell>NAME</StyledTableCell>
              <StyledTableCell>EMAIL</StyledTableCell>
              <StyledTableCell width='400px'>ROLE TYPE</StyledTableCell>
              <StyledTableCell>MANAGER</StyledTableCell>
              <StyledTableCell>DESIGNATION</StyledTableCell>
              <StyledTableCell>MOBILE NO</StyledTableCell>
              <StyledTableCell>STATUS</StyledTableCell>
              <StyledTableCell>ACTION</StyledTableCell>
            </TableRow>
          </TableHead>
          {userRequestData && userRequestData?.length > 0 && (
            <TableBody>
              {userRequestData?.map((request: any) => (
                <StyledTableRow key={request?.employeeId} onClick={() => handleClickOpen(request)}>
                  <StyledTableCell component='th' scope='row'>
                    {request?.employeeId}
                  </StyledTableCell>
                  <StyledTableCell>{request?.name}</StyledTableCell>
                  <StyledTableCell>{request?.loginId}</StyledTableCell>
                  <StyledTableCell>
                    {request?.roles.map(
                      (value: any, index: number) =>
                        `${value?.name}${index < request.roles.length - 1 ? ', ' : ''}`,
                    )}
                  </StyledTableCell>
                  <StyledTableCell>{request?.managerName?.name}</StyledTableCell>
                  <StyledTableCell>{request?.DesginationName?.desc}</StyledTableCell>
                  <StyledTableCell>{request.mobile_no}</StyledTableCell>
                  <StyledTableCell>{request.status ? 'Active' : 'Disabled'}</StyledTableCell>
                  <StyledTableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      {/* <Tooltip title={`Edit ${request?.first_name}'s Employment info`} arrow>
                        <EditIcon
                          onClick={(event: any) => handleEditEmpInfo(event, request)}
                          // style={{ marginLeft: '10px', fontSize: '15px' }}
                          width={'25px'}
                          height={'25px'}
                        />
                      </Tooltip> */}
                      {/* <Tooltip title={`Bank info`} arrow>
                        <EditIcon
                          onClick={(event: any) => handleBankInfo(event, request)}
                          // style={{ marginLeft: '10px', fontSize: '15px' }}
                          width={'25px'}
                          height={'25px'}
                        />
                      </Tooltip> */}
                      {request.status !== 0 && (
                        <Tooltip title={'Delete'} arrow>
                          <DeleteIcon
                            onClick={(event) => {
                              event.stopPropagation()
                              handleDeleteOpenDialog(request.name, request)
                            }}
                          />
                        </Tooltip>
                      )}
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          )}{' '}
          {!isGettingUserData && (!userRequestData || userRequestData?.length === 0) && (
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align='center' colSpan={10}>
                  <Typography variant='subtitle1' sx={{ color: '#483f3f' }}>
                    No matching records found.
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {userRequestData && userRequestData?.length > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <Pagination
            count={Math.ceil(total / rowsPerPage)}
            color='primary'
            page={page}
            onChange={handleChangePage}
          />
        </Box>
      )}
    </>
  )
}

const UserDetails = (props: any) => {
  const {
    fetchAllUserList,
    DepartmentList,
    isCreateServiceRequest,
    userList,
    isGettingUserData,
    userDeleting,
    userDeleteSuccess,
    resetDeleteUser,
    fetchEmployee,
    rowdata,
    restFetchEmployee,
    fetchEmployeementInfo,
    employeementInfoData,
    resetEmployeementInfo,
  } = props
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(100)
  const [searchQuery, setSearchQuery] = useState('')
  let [empStatus, setEmpStatus] = useState('active')
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)
  let [empName, setEmpName] = useState('')
  let [dataRow, setDataRow] = useState()

  useEffect(() => {
    fetchAllUserList({ page, pageSize, search: searchQuery, type: empStatus })
  }, [page, searchQuery, empStatus])

  useEffect(() => {
    if (userDeleteSuccess) {
      toast.success('User deleted successfully')
      fetchAllUserList({ page, pageSize, search: searchQuery, type: empStatus })
    }

    // if (userDeleteFail) {
    //   toast.error('Failed to delete user')
    // }

    return () => {
      resetDeleteUser()
    }
  }, [userDeleteSuccess, page, searchQuery /*, userDeleteFail */])

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: any) => {
    setPage(page)
  }

  const fetchSRData1 = () => {
    fetchAllUserList({
      page,
      pageSize,
      search: searchQuery,
    })
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPageSize(event.target.value)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteConfirmationOpen(false)
  }

  const handleDeleteOpenDialog = (name: string, data: any) => {
    setEmpName(name)
    setDeleteConfirmationOpen(true)
    setDataRow(data)
  }

  const deleteUserFunction = () => {
    props.deleteUser(dataRow)
  }

  return (
    <>
      {isGettingUserData && (
        <Dialog open={isGettingUserData} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      )}
      <Dialog
        open={deleteConfirmationOpen}
        onClose={handleCloseDeleteDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <Box>
          <DialogTitle sx={style.dialogTitleStyle}>
            <HeadingNew>Delete User</HeadingNew>
            <Box marginLeft='5px'>
              <IconButton
                aria-label='close'
                onClick={() => setDeleteConfirmationOpen(false)}
                sx={{
                  ...style.iconButtonStyle,
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <Box sx={style.dialogContentBoxStyle}>
              <Box sx={style.dialogContentMsgBox}>
                <Typography sx={style.secondTypographyInContent}>
                  <>
                    Are you sure you want to Delete the{' '}
                    <Typography sx={{ fontWeight: 500, marginLeft: '4px' }}>{empName}</Typography> ?
                  </>
                </Typography>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions sx={style.dialogActions}>
            <CancelButton onClick={() => setDeleteConfirmationOpen(false)}>CANCEL</CancelButton>
            <ActionButtonNew
              onClick={() => {
                deleteUserFunction()
                setDeleteConfirmationOpen(false)
              }}
            >
              DELETE
            </ActionButtonNew>
          </DialogActions>
        </Box>
      </Dialog>

      {(isGettingUserData || isCreateServiceRequest) && userDeleting && (
        <Dialog
          open={isGettingUserData || isCreateServiceRequest || userDeleting}
          PaperProps={{ style: loaderProps }}
        >
          <CircularProgress color='secondary' />
        </Dialog>
      )}
      {/* {!isGettingUserData ? ( */}
      <div style={MainContainer}>
        <StyledPaper>
          <ServiceRequestTableData
            userRequestData={userList?.users}
            isCreateServiceRequest={isCreateServiceRequest}
            fetchSRData1={fetchSRData1}
            page={page}
            DepartmentList={DepartmentList}
            total={userList?.count}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            setPage={setPage}
            isGettingUserData={isGettingUserData}
            deleteUser={props.deleteUser}
            userDeleteSuccess={props.userDeleteSuccess}
            empStatus={empStatus}
            setEmpStatus={setEmpStatus}
            userList={userList}
            handleDeleteOpenDialog={handleDeleteOpenDialog}
            fetchEmployee={fetchEmployee}
            rowData={rowdata}
            restFetchEmployee={restFetchEmployee}
            fetchEmployeementInfo={fetchEmployeementInfo}
            employeementInfoData={employeementInfoData}
            resetEmployeementInfo={resetEmployeementInfo}
          />
        </StyledPaper>
      </div>
      {/* ) : null} */}
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    employeementInfoData: fetchUserDetailsEntities.fetchUserData(state).getEmployeementInfo,
    SRData: dashboardEntity.getDashboard(state).getSRData,
    loaderState: dashboardUI.getDashboard(state).isServiceRequestData,
    userList: fetchUserDetailsEntities.fetchUserData(state).fetchAllUserList,
    isCreateServiceRequest: SRUI.getServiceRequestCreate(state).isCreateServiceRequest,
    isGettingUserData: fetchUserDetailsUI.fetchUserData(state).isUserDataFetched,
    userDeleteSuccess: fetchUserDetailsUI.fetchUserData(state).isUserDeleteSuccess,
    userDeleting: fetchUserDetailsUI.fetchUserData(state).isUserDeleting,
    userDeleteFail: fetchUserDetailsUI.fetchUserData(state).isUserDeleteFailed,
    rowdata: fetchUserDetailsEntities.fetchUserData(state).getEmployee,
    isEmployeementInfoUpdated: fetchUserDetailsUI.fetchUserData(state).isGetEmpoyeementInfo,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchEmployee: (data: any) => dispatch(fetchEmployee.request(data)),
    restFetchEmployee: (data: any) => dispatch(fetchEmployee.reset()),
    fetchSRData: (data: any) => dispatch(fetchSR.request(data)),
    fetchAllUserList: (data: any) => dispatch(fetchAllUserList.request({ data })),
    deleteUser: (data: any) => dispatch(deleteUser.request(data)),
    resetDeleteUser: (data: any) => dispatch(deleteUser.reset()),
    fetchEmployeementInfo: (data: any) => dispatch(fetchEmployeeInfo.request({ data })),
    resetEmployeementInfo: () => dispatch(fetchEmployeeInfo.reset()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails)
