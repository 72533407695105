import { Box, Grid, Paper, Typography, styled } from '@mui/material'
import { Link } from 'react-router-dom'
import { FC, ReactElement } from 'react'
import { style } from './ProjectSheetStyle'
import { OpenTabLogo } from '../../../utils/StaticData'
import { useSelector } from 'react-redux'
import styles from '../../../utils/styles.json'

const StyledLink = styled(Link)(() => ({
  fontSize: '16px',
  borderRadius: '8px',
  color: styles.PRIMARY_COLOR,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  height: '3.2rem',
  textDecoration: 'none',
  fontFamily: styles.FONT_MEDIUM,
  flexWrap: 'wrap',
  border: '2px solid',
  padding: '0 20px',
  position: 'relative',
  transition: 'all 0.3s ease-in-out', 
  '&:hover': {
    backgroundColor: styles.PRIMARY_COLOR,
    color: '#fff',
    transform: 'scale(1.05)', 
    '& img': {
      filter: 'brightness(0) invert(1)',
    },
  },
  
}));

const Icon = styled('img')(() => ({
  cursor: 'pointer',
  height: '21px',
  position: 'absolute',
  top: '4px',   
  right: '6px', 
}));


const ProjectReports: FC = (): ReactElement => {

  const CompanyName = useSelector((state:{entities:{dashboard:{getUserDetails:{company_name:string}}}})=> state?.entities?.dashboard?.getUserDetails?.company_name)
  
  let Name = CompanyName?.toUpperCase()
  
  return (
    <Paper sx={style.styledPaper}>
      <Typography sx={style.headerHeading}>
        {Name} PROJECT REPORTS
      </Typography>
      <Box
        padding='3vw'
        border='1px solid #EEEEEE'
        paddingBottom='11vh'
        borderRadius='5px'
        marginTop='1rem'
      >
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={6} md={4}>
            <StyledLink to='/home/projectmanagement/projectreport'>
              <span>PROJECT SHEET</span>
              <Icon src={OpenTabLogo} alt='Open Tab Logo' />
            </StyledLink>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StyledLink to='/home/projectmanagement/ProjectResourceReport'>
              <span>PROJECT RESOURCE REPORT</span>
              <Icon src={OpenTabLogo} alt='Open Tab Logo' />
            </StyledLink>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StyledLink to='/home/projectmanagement/ProjectManagementReport'>
              <span>PROJECT MANAGEMENT REPORT</span>
              <Icon src={OpenTabLogo} alt='Open Tab Logo' />
            </StyledLink>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StyledLink to='/home/projectmanagement/ProjectQAReports'>
              <span>PROJECT QA REPORT</span>
              <Icon src={OpenTabLogo} alt='Open Tab Logo' />
            </StyledLink>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={4}>
            <StyledLink to='/project-reviewer-report'>
              <span>PROJECT REVIEWER REPORT</span>
              <Icon src={OpenTabLogo} alt='Open Tab Logo' />
            </StyledLink>
          </Grid> */}
          
        </Grid>
      </Box>
    </Paper>
  );
};

export default ProjectReports;
