import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { loaderProps } from '../Common/CommonStyles'
import { Box, Button, CircularProgress, Dialog, Grid, Typography } from '@mui/material'
import {
  CommonButton,
  InputField,
  SelectField,
  StyledMenuItem,
  useStyles,
} from './EmpoloyeementInfoStyle'
import { FormValues } from './IFormValue'
import { RootState } from '../../configureStore'
import {
  employeePortalEntity,
  fetchUserDetailsEntities,
  fetchUserDetailsUI,
  projectManagementEntity,
} from '../../reducers'
import {
  createUser,
  fetchAllRoles,
  fetchAllStates,
  fetchAllCountries,
  fetchAllFloors,
  fetchAllWorkstation,
  fetchAllLocation,
  uploadUserImg,
  fetchAllClientLocation,
  getUserImage,
  fetchDesignation,
  fetchDesignationBand,
  fetchWorkingEmp,
  fetchTiming,
  updateNewJoiners,
} from '../../actions'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import { ArrowBack } from '@mui/icons-material'
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import styles from '../../utils/styles.json'

const style = {
  selectStyle: {
    '& .MuiSelect-select': {
      height: '15px',
    },
    '& .MuiFormLabel-asterisk': {
      color: 'red',
    },
  },
  gridStyle: {
    '& .MuiFormControl-root': {
      margin: '0 0 15px',
    },
  },
}


const EmpoloyeementInfo = (props: any) => {
  const { isUserUpdating, updateNewJoiners } = props

  const rowdata: any = useLocation()?.state
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  // const userData = location?.state?.data?.rowData || {}


  useEffect(() => {
    props.fetchAllRole()
    props.fetchAllState()
    props.fetchAllCountry()
    props.fetchAllFloor()
    props.fetchAllWorkstation()
    props.fetchAllLocation()
    props.fetchAllClientLocation()

    if (rowdata?.userid) {
      props.getUserImage(rowdata)
    }

    return () => {
      props.resetCreateUser()
      props.resetUpdateNewJoiners()
      props.resetUserImage()
    }
  }, [rowdata?.userid])

  useEffect(() => {
    props.fetchDesignationData()
    props.fetchAllEmpAPI()
    props.fetchDesignationBandData()
    props.fetchTimingData()
  }, [])

  useEffect(() => {
    if (props.isUpdateNewJoinersDone) {
      toast.success('Updated expected joiners successfully')
      navigate('/home/admin/expected-joiners')
    }
  }, [props.isUpdateNewJoinersDone])

  const roleIdArr: any = []
  const dependentsArr: any = []

  rowdata?.roles?.map((value: any) => roleIdArr.push(value.id))

  rowdata?.dependents &&
    rowdata?.dependents?.length > 0 &&
    rowdata?.dependents?.map((value: any) =>
      dependentsArr.push({
        id: value.id,
        name: value.name,
        id_relationship: value.id_relationship,
        age: value.age,
      }),
    )

  const formattedHireDate = rowdata?.hire_date
    ? dayjs(rowdata?.hire_date).format('YYYY-MM-DD')
    : ''

  const formattedAcceptanceDate = rowdata?.employeeinfo?.[0]?.acceptance_date
    ? dayjs(rowdata?.employeeinfo?.[0]?.acceptance_date).format('YYYY-MM-DD')
    : ''

  const formattedEffectiveDate = rowdata?.employeeofficeinfo?.[0]?.start_date
    ? dayjs(rowdata?.employeeofficeinfo?.[0]?.start_date).format('YYYY-MM-DD')
    : ''

  const validationSchema = Yup.object({
    empDesignation: Yup.string().required('Required'),
    hire_date: Yup.string().required('Required'),
    // manager: Yup.string().required('Required'),
    acceptance_date: Yup.string().required('Required'),
    acceptance_time: Yup.string().required('Required'),
    week_start: Yup.string().required('Required'),
    week_end: Yup.string().required('Required'),
    // start_date: Yup.string().required('Required'),
    })

  const formik = useFormik({
    initialValues: {
      employee_id: rowdata?.employeeId ?? '',
      id_joiners: rowdata?.id_joiners ?? '',
      under_probation: rowdata?.under_probation === 0 ? 0 : 1,
      // designation: rowdata?.DesginationName?.id ?? '',
      desig_band: rowdata?.employeeinfo?.[0]?.designation_band ?? '',
      manager: rowdata?.managerName?.id ?? '',
      status: rowdata?.status ? Number(rowdata?.status) : 1,
      probation_period: rowdata?.probation_period ?? 12,
      pf_applicable: rowdata?.employeeinfo?.[0]?.pf_applicable ?? 1,
      pf_no: rowdata?.employeeinfo?.[0]?.pf_no ?? '',
      uan_no: rowdata?.employeeinfo?.[0]?.uan_no ?? '',
      pan_card_no: rowdata?.employeeinfo?.[0]?.pan_card_no ?? '',
      emp_type: rowdata?.employeeinfo?.[0]?.employment_type
        ? Number(rowdata?.employeeinfo?.[0]?.employment_type)
        : 1,
      aadhaar_card_no: rowdata?.employeeinfo?.[0]?.aadhaar_card_no ?? '',
      referred_by: !rowdata?.employeeinfo?.[0]?.referred_by ? '' : rowdata?.employeeinfo?.[0]?.referred_by,
      service_agreement: rowdata?.employeeinfo?.[0]?.id_service_agreement ?? '',
      service_agreement_amount:
        rowdata?.employeeinfo?.[0]?.service_agreement_amount === '0'
          ? '75000'
          : rowdata?.employeeinfo?.[0]?.service_agreement_amount ?? '75000',
      experience: rowdata?.employeeinfo?.[0]?.working_experience ?? '',
      isIDCard: rowdata?.employeeinfo?.[0]?.id_card_issued ?? '0',
      under_maternity: rowdata?.employeeinfo?.[0]?.under_maternity ?? '0',
      salary_on_hold: rowdata?.employeeinfo?.[0]?.salary_on_hold ?? '0',
      poor_performance_review: rowdata?.employeeinfo?.[0]?.under_performance_review ?? '0',
      isMediclaim: rowdata?.employeeinfo?.[0]?.mediclaim_issued ?? '0',
      mediclaim_no: rowdata?.employeeinfo?.[0]?.mediclaim_no ?? '',
      passport_no: rowdata?.employeeinfo?.[0]?.passport_no ?? '',
      tshirt_size: rowdata?.employeeinfo?.[0]?.tshirt_size ?? 0,
      under_notice: rowdata?.under_notice ?? 0,
      notice_period: rowdata?.notice_period ?? 3,
      week_start: rowdata?.employeeinfo?.[0]?.week_start ?? '',
      week_end: rowdata?.employeeinfo?.[0]?.week_end ?? '',
      relieving_type: rowdata?.employeeinfo?.[0]?.termination_type ?? 0,
      first_performance_review: rowdata?.employeeinfo?.[0]?.first_performance_review ?? '0',
      first_weekend: rowdata?.employeeinfo?.[0]?.first_weekend ?? 'Saturday',
      second_weekend: rowdata?.employeeinfo?.[0]?.second_weekend ?? 'Sunday',
      hire_date: formattedHireDate ?? '',
      acceptance_date: formattedAcceptanceDate ?? '',
      start_date: formattedEffectiveDate ?? '',
      acceptance_time: rowdata?.employeeinfo?.[0]?.acceptance_time ?? '',

      empDesignation: rowdata?.DesginationName?.id ?? '',
      shift_timing: rowdata?.employeeinfo?.[0]?.timing_id ?? '',
    },
    validationSchema,
    onSubmit: (values) => {},
  })


  const handleSubmit = async () => {
    try {
      await validationSchema.validate(formik.values, { abortEarly: false })
      const entity = {
        id: Boolean(rowdata?.email) && rowdata?.id_joiners,
        employee_id: String(formik.values.employee_id),
        id_grade: formik.values?.empDesignation, //designation
        designation_band: formik.values.desig_band, //designation
        hire_date: formik.values.hire_date,
        acceptance_date: formik.values.acceptance_date,
        // under_notice: Number(formik.values.under_notice),
        notice_period: Number(formik.values.notice_period),
        referred_by: formik.values.referred_by,
        // employment_type: formik.values.emp_type,
        pan_card_no: formik.values.pan_card_no,
        aadhaar_card_no: formik.values.aadhaar_card_no,
        id_service_agreement: formik.values.service_agreement,
        service_agreement_amount: formik.values.service_agreement_amount,
        working_experience: formik.values.experience,
        first_weekend: formik.values.first_weekend,
        second_weekend: formik.values.second_weekend,
        // tshirt_size: formik.values.tshirt_size,
        acceptance_time: formik.values.acceptance_time,
        week_start: String(formik.values.week_start),
        week_end: String(formik.values.week_end),
        updateEmpInfoFlag: 1,
        // start_date: formik.values.start_date,
        timing_id: Number(formik.values.shift_timing),
        update: 1,
      }
      updateNewJoiners(entity)
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const stepErrors = error.inner.reduce((acc, curr) => {
          if (curr.path) {
            acc[curr.path as keyof FormValues] = curr.message
          }
          return acc
        }, {} as { [K in keyof FormValues]?: string })

        formik.setTouched(
          Object.keys(stepErrors).reduce((acc, key) => {
            acc[key as keyof FormValues] = true
            return acc
          }, {} as { [K in keyof FormValues]: any }),
        )

        formik.setErrors(stepErrors)
      } else {
        console.error('Unexpected error:', error)
      }
    }
  }

  const daysOfWeek = [
    { id: 1, value: 'Sunday' },
    { id: 2, value: 'Monday' },
    { id: 3, value: 'Tuesday' },
    { id: 4, value: 'Wednesday' },
    { id: 5, value: 'Thursday' },
    { id: 6, value: 'Friday' },
    { id: 7, value: 'Saturday' },
  ]
  
  return (
    <div className={classes.root}>
      {isUserUpdating && (
        <Dialog open={isUserUpdating} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      )}
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center', // Center items vertically
            justifyContent: 'space-between', // Space items apart (button left, heading center)
            width: '89.5%', // Set width as per your requirement
            height: '70px', // Set height as per your requirement
            borderRadius: '8px', // Round corners of the box
            marginLeft: '35px', // Center the box horizontally
            padding: '0 20px', // Optional: Add horizontal padding
            opacity: 1, // Adjust opacity as needed
            backgroundColor: 'white',
            marginTop: '20px',
          }}
        >
          {/* Centered heading */}
          <Typography
            sx={{
              fontFamily: styles.FONT_BOLD,
              fontSize: '32px', // Set font size
              fontWeight: 700, // Set font weight
              lineHeight: '38.73px', // Set line height
              textAlign: 'center', // Center align the text
              flexGrow: 1, // Allow the heading to grow and take available space
              margin: '0', // Optional: Remove default margin
              color: '#193C6C',
            }}
          >
            Expected Joiners Info
          </Typography>
          <Box onClick={() => navigate(-1)} sx={{ float: 'right', mt: '0px', cursor: 'pointer' }}>
            <ArrowBack />
          </Box>
        </Box>
        <Box sx={{ mx: 4, my: 1, mt: 3, backgroundColor: '' }}>
          <div>
            <Grid container sx={{ display: 'flex', gap: '20px' }}>
              {/* First Section */}
              <Grid item container xs={11.7} sx={{ display: 'flex', gap: '20px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '20px', width: '100%' }}>
                  <Grid item xs={6} sx={{ backgroundColor: 'white', borderRadius: '8px' }}>
                    <Box
                      sx={{
                        borderBottom: '1px solid #193C6C', // Move border to the container
                        width: '100% !important', // Ensure the container takes up the full width
                      }}
                    >
                      <Typography
                        padding={'10px 10px'} // Retain padding inside the Typography
                        sx={{
                          fontFamily: styles.FONT_BOLD,
                          fontSize: '16px', // Use quotes around the size
                          fontWeight: 700, // Use camelCase for font-weight
                          lineHeight: '19.36px', // Use quotes around the line height
                          textAlign: 'left', // Use camelCase for text-align
                          color: '#193C6C',
                        }}
                      >
                        Finance Info
                      </Typography>
                    </Box>

                    <Box>
                      <Grid item alignItems='center'>
                        <InputField
                          size='small'
                          fullWidth
                          id='pan_card_no'
                          name='pan_card_no'
                          label='Pan Number'
                          required={false}
                          value={formik.values.pan_card_no}
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid item>
                        <Box
                          display='flex'
                          justifyContent='center'
                          alignItems='center'
                          width='100%' // Adjust height as needed
                        >
                          <InputField
                            size='small'
                            fullWidth
                            id='aadhaar_card_no'
                            name='aadhaar_card_no'
                            label='Aadhaar Number'
                            onChange={(e: any) => {
                              let onlyNums = e.target.value.replace(/[^0-9]/g, '')
                              if (onlyNums.length > 12) {
                                onlyNums = onlyNums.slice(0, 12)
                              }
                              formik.setFieldValue('aadhaar_card_no', onlyNums)
                            }}
                            value={formik.values.aadhaar_card_no}
                            disabled={formik.values.pf_applicable === 1 ? false : true}
                            required={false}
                          />
                        </Box>
                      </Grid>
                    </Box>
                  </Grid>

                  <Grid item xs={6} sx={{ backgroundColor: 'white', borderRadius: '8px' }}>
                    <Typography
                      padding={'10px 10px'} // Retain padding inside the Typography
                      sx={{
                        fontFamily: styles.FONT_BOLD,
                        fontSize: '16px', // Use quotes around the size
                        fontWeight: 700, // Use camelCase for font-weight
                        lineHeight: '19.36px', // Use quotes around the line height
                        textAlign: 'left', // Use camelCase for text-align
                        borderBottom: '1px solid #193C6C',
                        color: '#193C6C',
                      }}
                    >
                      Designation Info
                    </Typography>
                    <Box>
                      <Grid item>
                        <SelectField
                          size='small'
                          id='empDesignation'
                          name='empDesignation'
                          label='Designation'
                          select
                          required={true}

                          value={formik.values.empDesignation}
                          error={formik.touched.empDesignation && Boolean(formik.errors.empDesignation)}

                          onChange={formik.handleChange}
                          sx={style.selectStyle}
                        >
                          {props.designationData?.map((data: any, index: number) => (
                            <StyledMenuItem key={index} value={data.id}>
                              {data.grade_name} - {data.desc}
                            </StyledMenuItem>
                          ))}
                        </SelectField>
                      </Grid>

                      <Grid item>
                        <SelectField
                          size='small'
                          id='desig_band'
                          name='desig_band'
                          label='Designation Band'
                          select
                          value={formik.values.desig_band}
                          onChange={formik.handleChange}
                          sx={style.selectStyle}
                          required={false}

                        >
                          <StyledMenuItem value={0}>Select</StyledMenuItem>
                          {props.designationBandData?.map((data: any, index: number) => (
                            <StyledMenuItem key={index} value={data.id}>
                              {data.band_title}
                            </StyledMenuItem>
                          ))}
                        </SelectField>
                      </Grid>

                      <Grid item>
                      <SelectField
                      size='small'
                      id='shift_timing'
                      name='shift_timing'
                      label='Timing'
                      value={formik.values.shift_timing}
                      select
                      onChange={formik.handleChange}
                      sx={style.selectStyle}
                      required={false}
                    >
                      {props?.timingData?.map((data: any) => {
                        return (
                          <StyledMenuItem value={data?.id}>
                            {' '}
                            {dayjs(data?.timing).format('h:mm A')}{' '}
                          </StyledMenuItem>
                        )
                      })}
                    </SelectField>
                      </Grid>
                      <Grid item>
                        <SelectField
                          size='small'
                          id='referred_by'
                          name='referred_by'
                          label='Referred By'
                          select
                          value={formik.values.referred_by}
                          onChange={formik.handleChange}
                          required={false}
                          sx={style.selectStyle}
                        >
                          {props.AllEmpList?.map((data: any, index: number) => {
                            return (
                              <StyledMenuItem key={index} value={data.userId}>
                                {data.name}
                              </StyledMenuItem>
                            )
                          })}
                        </SelectField>
                      </Grid>

                      <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['DatePicker']}>
                            <Box sx={{ width: '92%' }}>
                              <DatePicker
                                sx={{
                                  marginTop: '10px',
                                  '& .MuiOutlinedInput-root': {
                                    marginLeft: '15px',
                                    borderRadius: '20px',
                                    height: '40px',
                                    fontSize: '13px', // Adjust the font size here
                                    fontFamily: styles.FONT_MEDIUM,
                                  },
                                  '& .MuiInputLabel-root': {
                                    marginLeft: '15px',
                                    marginTop: '-5px',
                                    fontSize: '13px', // Adjust the font size here
                                    fontFamily: styles.FONT_MEDIUM,
                                    '& .MuiFormLabel-asterisk': {
                                      color: 'red',
                                    },
                                  },
                                }}
                                label='Hire Date'
                                value={
                                  formik.values.hire_date ? dayjs(formik.values.hire_date) : null
                                }
                                onChange={(date: any) => {
                                  formik.setFieldValue('hire_date', date?.format('YYYY-MM-DD'))
                                }}
                                slotProps={{
                                  textField: {
                                    error: formik.touched.hire_date && Boolean(formik.errors.hire_date)
                                  },
                                }}       
                                
                              />
                            </Box>
                          </DemoContainer>
                        </LocalizationProvider>
                      </Grid>
                    </Box>
                  </Grid>
                </Box>
                <Grid
                  item
                  xs={12}
                  sx={{ backgroundColor: 'white', borderRadius: '8px', paddingBottom: '15px' }}
                >
                  <Typography
                    padding={'10px 10px'} // Retain padding inside the Typography
                    sx={{
                      fontFamily: styles.FONT_BOLD,
                      fontSize: '16px', // Use quotes around the size
                      fontWeight: 700, // Use camelCase for font-weight
                      lineHeight: '19.36px', // Use quotes around the line height
                      textAlign: 'left', // Use camelCase for text-align
                      borderBottom: '1px solid #193C6C',
                      color: '#193C6C',
                    }}
                  >
                    Service Info
                  </Typography>

                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Grid item xs={5.7}>
                      <SelectField
                        size='small'
                        id='first_weekend'
                        name='first_weekend'
                        label='First Weekend'
                        select
                        required={false}
                        value={formik.values.first_weekend}
                        onChange={formik.handleChange}
                        sx={style.selectStyle}
                        error={
                          formik.touched.first_weekend && Boolean(formik.errors.first_weekend)
                        }
                      >
                        <StyledMenuItem value={'Sunday'}> Sunday </StyledMenuItem>
                        <StyledMenuItem value={'Monday'}> Monday </StyledMenuItem>
                        <StyledMenuItem value={'Tuesday'}> Tuesday </StyledMenuItem>
                        <StyledMenuItem value={'Wednesday'}> Wednesday </StyledMenuItem>
                        <StyledMenuItem value={'Thursday'}> Thursday </StyledMenuItem>
                        <StyledMenuItem value={'Friday'}> Friday </StyledMenuItem>
                        <StyledMenuItem value={'Saturday'}> Saturday </StyledMenuItem>
                      </SelectField>
                      <SelectField
                        size='small'
                        id='second_weekend'
                        name='second_weekend'
                        label='Second Weekend'
                        select
                        required={false}
                        value={formik.values.second_weekend}
                        onChange={formik.handleChange}
                        sx={style.selectStyle}
                      >
                        <StyledMenuItem value={'Sunday'}> Sunday </StyledMenuItem>
                        <StyledMenuItem value={'Monday'}> Monday </StyledMenuItem>
                        <StyledMenuItem value={'Tuesday'}> Tuesday </StyledMenuItem>
                        <StyledMenuItem value={'Wednesday'}> Wednesday </StyledMenuItem>
                        <StyledMenuItem value={'Thursday'}> Thursday </StyledMenuItem>
                        <StyledMenuItem value={'Friday'}> Friday </StyledMenuItem>
                        <StyledMenuItem value={'Saturday'}> Saturday </StyledMenuItem>
                      </SelectField>

                      <SelectField
                        size='small'
                        id='service_agreement'
                        name='service_agreement'
                        label='Service Agreement Period'
                        required={false}
                        select
                        value={formik.values.service_agreement}
                        error={formik.touched.service_agreement && Boolean(formik.errors.service_agreement)}
                        onChange={formik.handleChange}
                        sx={style.selectStyle}
                      >
                        <StyledMenuItem value={1}> 1 year </StyledMenuItem>
                        <StyledMenuItem value={2}> 1.5 year </StyledMenuItem>
                        <StyledMenuItem value={3}> 2 year </StyledMenuItem>
                      </SelectField>

                      <SelectField
                        size='small'
                        id='service_agreement_amount'
                        name='service_agreement_amount'
                        label='Service Agreement Amount (In Rs.)'
                        required={false}
                        select
                        value={formik.values.service_agreement_amount}
                        onChange={formik.handleChange}
                        sx={style.selectStyle}
                      >
                        <StyledMenuItem value={'200000'}> 2,00,000 </StyledMenuItem>
                        <StyledMenuItem value={'10000'}> 1,00,000 </StyledMenuItem>
                        <StyledMenuItem value={'75000'}> 75,000 </StyledMenuItem>
                        <StyledMenuItem value={'50000'}> 50,000 </StyledMenuItem>
                        <StyledMenuItem value={'25000'}> 25,000 </StyledMenuItem>
                      </SelectField>

                      <SelectField
                        size='small'
                        id='experience'
                        name='experience'
                        label='Experience while joinning'
                        select
                        value={formik.values.experience}
                        onChange={formik.handleChange}
                        sx={style.selectStyle}
                        required={false}
                      >
                        <StyledMenuItem value={'0'}> 0 - 0.5 Year </StyledMenuItem>
                        <StyledMenuItem value={'1'}> 0.5 - 1 Year </StyledMenuItem>
                        <StyledMenuItem value={'2'}> 1 - 2 Year </StyledMenuItem>
                        <StyledMenuItem value={'3'}> 2 - 5 Year </StyledMenuItem>
                        <StyledMenuItem value={'4'}> 5 - 10 Year </StyledMenuItem>
                        <StyledMenuItem value={'5'}> 10+ Year </StyledMenuItem>
                      </SelectField>
                    </Grid>

                    <Grid item xs={5.8}>
                    <SelectField
                          size='small'
                          id='week_start'
                          name='week_start'
                          label='Week Start'
                          select
                          value={formik.values.week_start}
                          onChange={formik.handleChange}
                          error={formik.touched.week_start && Boolean(formik.errors.week_start)}
                          sx={style.selectStyle}
                          required={true}
                        >
                          {daysOfWeek?.map((day: any) => (
                            <StyledMenuItem key={day.id} value={day.value}>
                              {day.value}
                            </StyledMenuItem>
                          ))}
                        </SelectField>
                        <SelectField
                          size='small'
                          id='week_end'
                          name='week_end'
                          label='Week End'
                          select
                          value={formik.values.week_end}
                          onChange={formik.handleChange}
                          error={formik.touched.week_end && Boolean(formik.errors.week_end)}
                          sx={style.selectStyle}
                          required={true}
                        >
                          {daysOfWeek?.map((day: any) => (
                            <StyledMenuItem key={day.id} value={day.value}>
                              {day.value}
                            </StyledMenuItem>
                          ))}
                        </SelectField>
                        <SelectField
                        size='small'
                        id='notice_period'
                        name='notice_period'
                        label='Notice Period'
                        select
                        value={formik.values.notice_period}
                        onChange={formik.handleChange}
                        disabled={formik.values.under_notice <= 0}
                        required={false}
                      >
                        {formik.values.under_notice <= 0 && (
                          <StyledMenuItem value={0}>0 Months</StyledMenuItem>
                        )}
                        <StyledMenuItem value={3}>3 Months</StyledMenuItem>
                        <StyledMenuItem value={6}>6 Months</StyledMenuItem>
                        <StyledMenuItem value={9}>9 Months</StyledMenuItem>
                        <StyledMenuItem value={12}>1 Year</StyledMenuItem>
                      </SelectField>
                      <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['DatePicker']}>
                            <Box sx={{ width: '92%' }}>
                              <DatePicker
                                sx={{
                                  marginTop: '10px',
                                  '& .MuiOutlinedInput-root': {
                                    marginLeft: '15px',
                                    borderRadius: '20px',
                                    height: '40px',
                                    fontSize: '13px', // Adjust the font size here
                                    fontFamily: styles.FONT_MEDIUM,
                                  },
                                  '& .MuiInputLabel-root': {
                                    marginLeft: '15px',
                                    marginTop: '-5px',
                                    fontSize: '13px', // Adjust the font size here
                                    fontFamily: styles.FONT_MEDIUM,
                                    '& .MuiFormLabel-asterisk': {
                                      color: 'red',
                                    },
                                  },
                                }}
                                label='Acceptance Date'
                                value={
                                  formik.values.acceptance_date ? dayjs(formik.values.acceptance_date) : null
                                }
                                onChange={(date: any) => {
                                  formik.setFieldValue('acceptance_date', date?.format('YYYY-MM-DD'))
                                }}
                                slotProps={{
                                  textField: {
                                    error: formik.touched.acceptance_date && Boolean(formik.errors.acceptance_date)
                                  },
                                }}       
                                
                              />
                            </Box>
                          </DemoContainer>
                        </LocalizationProvider>
                      </Grid>
                      <Grid item>
                        <InputField
                          size='small'
                          fullWidth
                          id='acceptance_time'
                          name='acceptance_time'
                          label='Acceptance Time'
                          type='time'
                          sx={{
                            marginTop:'0px',
                            '& .MuiFormLabel-asterisk': {
                            color: 'red',
                          },}}
                          value={formik.values.acceptance_time || ''}
                          onChange={(e) => {
                            // Add ':00' to ensure the format is 'hh:mm:ss'
                            const value = e.target.value ? `${e.target.value}:00` : '';
                            formik.setFieldValue('acceptance_time', value);
                          }}
                          InputLabelProps={{ shrink: true }}
                          required={true}
                        />
                      </Grid> 
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <div
              style={{
                marginTop: '24px',
                marginRight: '15px',
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <Button
                sx={{
                  fontSize: '16px !important',
                  fontFamily: `${styles.FONT_BOLD}!important`,
                  width: '20%',
                  borderRadius: '20px !important',
                  cursor: 'pointer',
                  height: '40px', // Set height as per your requirement
                  padding: '14px 28px',
                  opacity: 1, // Adjust opacity as needed
                  background: '#E2E2E2',
                  color: '#193C6C',
                  '&:hover': {
                    background: '#E2E2E2',
                    color: '#000000',
                  },
                }}
                variant='contained'
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              <CommonButton variant='contained' color='primary' onClick={handleSubmit}>
                FINISH
              </CommonButton>
            </div>
          </div>
        </Box>
      </form>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    designationData: employeePortalEntity.getEmployeePortal(state).getDesignationData,
    designationBandData: employeePortalEntity.getEmployeePortal(state).getDesignationBandData,
    AllEmpList: projectManagementEntity.getAllProjectManagement(state).getAllEmpList,

    getUserDetails: fetchUserDetailsEntities.fetchUserData(state).fetchUser,
    allRoles: fetchUserDetailsEntities.fetchUserData(state).fetchAllRole,
    allStates: fetchUserDetailsEntities.fetchUserData(state).fetchAllState,
    allCountries: fetchUserDetailsEntities.fetchUserData(state).fetchAllCountry,
    allFloors: fetchUserDetailsEntities.fetchUserData(state).fetchAllFloor,
    allWorkstations: fetchUserDetailsEntities.fetchUserData(state).fetchAllWorkstation,
    allLocation: fetchUserDetailsEntities.fetchUserData(state).fetchAllLocation,
    allClientLocation: fetchUserDetailsEntities.fetchUserData(state).fetchAllClientLocation,
    isUserCreateFailed: fetchUserDetailsUI.fetchUserData(state).isUserCreateFailed,
    isUpdateNewJoinersDone: fetchUserDetailsUI.fetchUserData(state).isUpdateNewJoinersDone,
    isUserUpdating: fetchUserDetailsUI.fetchUserData(state).isUserUpdating,
    isUserUpdateFailed: fetchUserDetailsUI.fetchUserData(state).isUserUpdateFailed,
    userImage: fetchUserDetailsEntities.fetchUserData(state).getUserImage,
    timingData: employeePortalEntity.getEmployeePortal(state).getTimingData,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchDesignationData: () => dispatch(fetchDesignation.request()),
    fetchDesignationBandData: () => dispatch(fetchDesignationBand.request()),
    fetchAllEmpAPI: () => dispatch(fetchWorkingEmp.request()),
    getUserImage: (data: any) => dispatch(getUserImage.request(data)),
    resetUserImage: () => {
      dispatch(getUserImage.reset())
    },
    uploadUserImg: (data: any) => dispatch(uploadUserImg.request({ data })),
    createUser: (data: any) => dispatch(createUser.request({ data })),
    resetCreateUser: () => {
      dispatch(createUser.reset())
    },
    fetchAllRole: () => dispatch(fetchAllRoles.request()),
    fetchAllState: () => dispatch(fetchAllStates.request()),
    fetchAllCountry: () => dispatch(fetchAllCountries.request()),
    fetchAllFloor: () => dispatch(fetchAllFloors.request()),
    fetchAllWorkstation: () => dispatch(fetchAllWorkstation.request()),
    fetchAllLocation: () => dispatch(fetchAllLocation.request()),
    updateNewJoiners: (data: any) => dispatch(updateNewJoiners.request({ data })),
    resetUpdateNewJoiners: () => {
      dispatch(updateNewJoiners.reset())
    },
    fetchAllClientLocation: () => dispatch(fetchAllClientLocation.request()),
    fetchTimingData: () => dispatch(fetchTiming.request()),


  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EmpoloyeementInfo)
