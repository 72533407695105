import {
    Box,
    IconButton,
    InputAdornment,
    TextField,
    styled,
  } from "@mui/material";
  import { useState } from "react";
  import ClearIcon from "@mui/icons-material/Clear";
  import SearchIcon from "@mui/icons-material/Search";
import { SearchBoxCustom } from "./CommonStyles";
  
  type SearchBoxType = {
    placeHolder: string;
    setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
    setPage?: React.Dispatch<React.SetStateAction<number>>;
  };
  

  
  function debounce<T extends (...args: any[]) => void>(func: T, delay: number): T {
    let timeout: ReturnType<typeof setTimeout>;
    return function (this: unknown, ...args: Parameters<T>) {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    } as T;
  }
  
  const DebouncedSearchedBox = (props: SearchBoxType) => {
    const { placeHolder, setSearchQuery, setPage } = props;
    const [value, setValue] = useState<string>("");
  
    const handleClear = () => {
      setValue("");
      if (setPage) setPage(1);
      setSearchQuery("");
    };
  
    const functionDebounce = debounce(
      (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (setPage) setPage(1);
        setSearchQuery(e.target.value);
      },
      1200
    );
  
    return (
      <Box width="240px">
        <SearchBoxCustom
          id="outlined-basic"
          placeholder={placeHolder}
          variant="outlined"
          size="small"
          value={value}
          onChange={(e) => {
            functionDebounce(e);
            setValue(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear-icon"
                  onClick={handleClear}
                  edge="end"
                >
                  {value && <ClearIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    );
  };
  
  export default DebouncedSearchedBox;
  