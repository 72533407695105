import styled from '@emotion/styled'
import {
  Box,
  Card,
  CardContent,
  Paper,
  Typography,
  CircularProgress,
  Dialog,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { dashboardEntity, dashboardUI } from '../../reducers'
import { fetchHighlights } from '../../actions'
import { CurrentLoginUserProfile } from '../../utils/StaticData'
import CakeIcon from '@mui/icons-material/Cake'
import WorkIcon from '@mui/icons-material/Work'
import { ArrowBack } from '@mui/icons-material'
import { Link, useNavigate } from 'react-router-dom'
import { HeaderHeading, loaderProps } from '../Common/CommonStyles'
import Confetti from '../Common/Confetti'
import '../../../src/style.css'
import style from '../../utils/styles.json'
const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '90%',
  background: '#FFFFFF',
  opacity: '1',
  marginTop: '25px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '20px',
  padding: '25px 25px',
}))

const MainContainer = {
  backgroundColor: 'rgb(231, 235, 240)',
  width: '100%',
}

const cardStyles = {
  border: 'none',
  display: 'flex',
}

const CommonCardStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  width: '80  %',
  border: '1px solid #DDDDDD',
  marginLeft: '20px',
  marginBottom: '18px',
  marginTop: '8px',
  boxShadow: '0px 2px 7px -4px',
  borderRadius: '10px',
}

const CardStyle = {
  padding: '16px 13px 16px 16px',
}

const SubHeading = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontFamily: style.FONT_MEDIUM,
  textAlign: 'center',
  letterSpacing: '0px',
}))

function TodaysHighligts({ HighlightsData, fetchHighlightsData, loaderState }: any) {
  useEffect(() => {
    fetchHighlightsData()
  }, [])

  const useStyles = makeStyles({
    image: {
      width: '60px',
      borderRadius: '50%',
      height: '60px',
      border: '1px red solid',
    },

    header: {},
    subHeadingContainer: {
      position: 'relative',
      top: 20,
      left: 18,
      margin: '20px 0px !important',
    },
    SubHeading: {
      background: style.PRIMARY_COLOR,
      color: '#fff',
      right: '20px',
      padding: '6px',
      margin: '10px',
      position: 'relative',
      bottom: '8px',
    },

    imageContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    nameAnniversaryContainer: {
      width: '160px',
      fontSize: 14,
      color: style.PRIMARY_COLOR,
      position: 'relative',
      top: 12,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: '500',
    },
    anniversaryContainer: {
      position: 'relative',
      top: 12,
      display: 'flex',
      fontSize: 12,
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: '500',
    },
    AnniversaryIcon: {
      color: 'cornflowerblue',
      margin: '-14px 0px 5px -8px',
    },
    cakeIcon: {
      color: 'coral',
      margin: '-14px 0px 5px -8px',
    },
    nameBirthdayContainer: {
      width: '160px',
      fontSize: 14,
      color: 'darkslategray',
      position: 'relative',
      top: 12,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: '500',
    },
    imageLunch: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundSize: 'cover',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: '0px 2px 7px -4px',
    },
    imageDinner: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundSize: 'cover',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    lunchContainer: {
      border: '1px solid #DDDDDD',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      width: '30%',
      boxShadow: '0px 2px 7px -4px',
      left: 20,
    },
    dinnerContainer: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      width: '30%',
      left: 80,
    },
    cardText: {
      color: '#fff !important',
      fontWeight: 'bold !important',
      fontSize: '16 !important',
    },
    dateContainer: {
      position: 'relative',
      left: 100,
      bottom: 0,
    },
    backArrow: {
      float: 'right',
      cursor: 'pointer',
      position: 'relative',
      right: '45px',
      top: '5px',
    },
    MenuItem: {
      width: '100%',
      height: '100%',
    },
    LunchAndDinner: {
      width: '100%',
      height: '100%',
      display: 'flex !important',
      justifyContent: 'space-around !important',
    },
    Lunch: {
      backgroundImage: `url("/images/Lunch.png")`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      width: '45%',
      borderRadius: 10,
    },
    Dinner: {
      borderRadius: 10,
      backgroundImage: `url("/images/Dinner.jpg")`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      width: '45%',
    },
    subContainer: {
      display: 'grid !important',
      gridTemplateColumns: 'auto',
      gap: '10px',
      width: '100%',
    },
    subContainerForBirthday: {
      display: 'grid !important',
      gridTemplateColumns: 'auto auto auto auto auto',
      gap: '20px',
      width: '100%',
      position: 'relative',
      right: 10,
    },
    subContainerForAnniversary: {
      display: 'grid !important',
      gridTemplateColumns: 'auto auto auto auto auto',
      gap: '20px',
      width: '100%',
      position: 'relative',
      right: 10,
    },

    headerContainer: {
      backgroundColor: style.PRIMARY_COLOR,
      width: '100%',
      height: '30px',
      borderRadius: '10px',
    },
    cardTextLunch: {
      padding: '2px 10px !important',
      fontSize: '18px !important',
      fontWeight: 'bold !important',
      color: '#fff !important',
      marginLeft: '20px !important',
    },

    typo: {
      padding: '2px 10px !important',
      fontSize: '18px !important',
      fontWeight: 'bold !important',
      color: '#fff !important',
      marginBottom: '-10px !important',
      marginLeft: '20px !important',
    },
    cardDate: {
      float: 'right',
      background: 'lightblue !important',
      padding: '5px 10px',
      margin: '-18px -13px 2px 1px',
    },
    list: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      listStyle: 'none',
    },
  })
  const classes = useStyles()
  const getAnniversarySuffix = (number: any) => {
    const lastDigit = number % 10
    const lastTwoDigits = number % 100

    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
      return 'th'
    }

    switch (lastDigit) {
      case 1:
        return 'st'
      case 2:
        return 'nd'
      case 3:
        return 'rd'
      default:
        return 'th'
    }
  }

  const AnniversaryCard = (
    <React.Fragment>
      {HighlightsData?.anniversaries?.length ? (
        HighlightsData?.anniversaries?.map((item: any) => (
          <Link to={`/home/basic-info-on-search?id=${item.id}`} key={item.id} style={{ textDecoration: 'none' }}>
            <div style={CommonCardStyle}>
              <CardContent sx={CardStyle}>
                <WorkIcon className={classes.AnniversaryIcon} />

                <Card className={classes.cardDate}>
                  {new Date(item.hireDate).toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'short',
                  })}
                </Card>

                <Typography color='text.secondary' gutterBottom>
                  <div className={classes.imageContainer}>
                    <img
                      className={classes.image}
                      src={item.imageURL ? item.imageURL : CurrentLoginUserProfile}
                      alt=''
                    />
                  </div>
                  <div className={classes.nameAnniversaryContainer}>
                    <span>{item?.name}</span>
                  </div>
                  <div className={classes.anniversaryContainer}>
                    <span>
                      {item?.anniversary}
                      {getAnniversarySuffix(item?.anniversary)} Tudipversary!
                    </span>
                  </div>
                </Typography>
              </CardContent>
            </div>
          </Link>
        ))
      ) : (
        <p style={{ fontSize: '15px', marginTop: '1px' }}></p>
      )}
    </React.Fragment>
  )

  const BirthdayCard = (
    <React.Fragment>
      {HighlightsData?.birthdays?.length ? (
        HighlightsData?.birthdays?.map((item: any) => (
          <Link to={`/home/basic-info-on-search?id=${item.id}`} key={item.id} style={{ textDecoration: 'none' }}>
            <div style={CommonCardStyle}>
              <CardContent sx={CardStyle}>
                <CakeIcon className={classes.cakeIcon} />
                <Card className={classes.cardDate}>
                  {new Date(item.birthDate).toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'short',
                  })}
                </Card>
                <Typography color='text.secondary' gutterBottom>
                  <div className={classes.imageContainer}>
                    <img
                      className={classes.image}
                      src={item.imageURL ? item.imageURL : CurrentLoginUserProfile}
                      alt=''
                    />
                  </div>
                  <div className={classes.nameBirthdayContainer}>
                    <span>{item?.name}</span>
                  </div>
                  <div className={classes.anniversaryContainer}>
                    <span>Birthday!</span>
                  </div>
                </Typography>
              </CardContent>
            </div>
          </Link>
        ))
      ) : (
        <p style={{ fontSize: '15px', marginTop: '1px' }}></p>
      )}
    </React.Fragment>
  )
  const navigate = useNavigate()

  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const currentMonth = month[new Date().getMonth()]

  const [showConfetti, setShowConfetti] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfetti(false)
    }, 5000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <>
      {loaderState && (
        <Dialog open={loaderState} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      )}
      {!loaderState && (
        <div style={MainContainer}>
          <StyledPaper>
            <Box sx={{ textAlign: 'left' }}>
              <Box className={classes.backArrow} onClick={() => navigate(-1)}>
                <ArrowBack />
              </Box>
            </Box>
            <HeaderHeading className={classes.header}>{currentMonth} Highlights</HeaderHeading>
            {HighlightsData?.anniversaries?.length > 0 ? (
              <Paper style={{ boxShadow: 'none' }}>
                <Typography className={classes.subHeadingContainer}>
                  <SubHeading className={classes.SubHeading}>Happy Tudipversary!</SubHeading>
                </Typography>
                <div>
                  <Card
                    className={classes.subContainerForAnniversary}
                    sx={cardStyles}
                    variant='outlined'
                  >
                    {AnniversaryCard}
                  </Card>
                </div>
              </Paper>
            ) : null}

            {HighlightsData?.birthdays?.length > 0 ? (
              <Paper style={{ boxShadow: 'none' }}>
                <Typography className={classes.subHeadingContainer}>
                  <SubHeading className={classes.SubHeading}>Happy Birthday Tudipians!</SubHeading>
                </Typography>
                <div>
                  <Card
                    className={classes.subContainerForBirthday}
                    sx={cardStyles}
                    variant='outlined'
                  >
                    {BirthdayCard}
                  </Card>
                </div>
              </Paper>
            ) : null}
            <Confetti recycle={showConfetti} />
          </StyledPaper>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    HighlightsData: dashboardEntity.getDashboard(state).getHighlightsData,
    loaderState: dashboardUI.getDashboard(state).getHighlightsData,
  }
}

const mapDispatchToProps = (dispatch: (arg: { type: any }) => void) => {
  return {
    fetchHighlightsData: () => dispatch(fetchHighlights.request()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TodaysHighligts)
