import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import TableRowContainer from './TableRowContainer'
import { style } from './SheetStyles'
import {
  StyledEditableTableCell,
  StyledLastTableCellEmp,
  StyledTableCell,
  StyledTableCellEmp,
} from '../Common/CommonStyles'
import EditPlanForTheDay from './EditPlanForTheDay'
import { useState } from 'react'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  left: '160px',
  width: '1719px',
  height: '60px',
  boxShadow: '0px 10px 3px #6c6c6c10',
  opacity: '1',
}))

// selectedTab

const TableLayout = (props: any) => {
  const { rowsToDisplay, onRowClick, selectedTab, mandateType } = props
  const [isNewPlanDialogOpen, setNewPlanDialog] = useState(false)
  const [getPfpId, setPfpId] = useState()
  const [getpfpIdForMngEditId, setPfpIdForMngEdit] = useState({ sender: '', cc: '' })

  let statusType = new Map<number, string>()
  mandateType?.data?.forEach((mandate: { id: number; mandate_name: string }) => {
    statusType.set(mandate.id, mandate.mandate_name)
  })

  const handleCloseNewPlanDialog = () => {
    setNewPlanDialog(false)
  }

  const handleGetpfpIdForMngEdit = (data: any) => {
    setPfpIdForMngEdit(data)
  }

  return (
    <Box width='100%' margin='20px  0px' sx={style.table}>
      <TableContainer component={Paper} id='export' sx={style.tableContainer}>
        <Table>
          <TableHead id='head'>
            <StyledTableRow sx={style.border}>
              <StyledTableCellEmp
                sx={{
                  ...style.tableHeadWidth,
                  width: '250px !important',
                  padding: '0px 16px',
                }}
              >
                EMP NAME
              </StyledTableCellEmp>

              <StyledTableCell sx={style.tableHeadWidth}>SUBJECT</StyledTableCell>
              <StyledTableCell sx={style.tableHeadWidth}>PROJECT NAME</StyledTableCell>
              <StyledTableCell sx={style.tableHeadWidth}>TYPE</StyledTableCell>

              {selectedTab === 1 ? (
                <>
                  <StyledTableCell sx={style.tableHeadWidth}>TIME SPENT</StyledTableCell>
                  <StyledEditableTableCell width='100px'>SUBMITTED AT</StyledEditableTableCell>
                  <StyledTableCell sx={style.tableHeadWidth}>COMMENT</StyledTableCell>
                </>
              ) : (
                <>
                  <StyledTableCell sx={style.tableHeadWidth}>EST TIME</StyledTableCell>
                  <StyledLastTableCellEmp sx={style.tableHeadWidth}>STATUS</StyledLastTableCellEmp>
                  <StyledEditableTableCell width='100px'>SUBMITTED AT</StyledEditableTableCell>
                  <StyledLastTableCellEmp sx={style.tableHeadWidth}>COMMENT</StyledLastTableCellEmp>
                  <StyledLastTableCellEmp
                    sx={{
                      ...style.tableHeadWidth,
                      width: '10px !important',
                      padding: '0px 10px',
                    }}
                  >
                    EDIT
                  </StyledLastTableCellEmp>
                </>
              )}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {rowsToDisplay?.map((row: any, idx: any) => (
              <TableRowContainer
                setNewPlanDialog={setNewPlanDialog}
                isNewPlanDialogOpen={isNewPlanDialogOpen}
                getPfpId={getPfpId}
                setPfpId={setPfpId}
                selectedTab={selectedTab}
                statusType={statusType}
                key={idx}
                row={row}
                idx={idx}
                onRowClick={onRowClick}
                handleGetpfpIdForMngEdit={handleGetpfpIdForMngEdit}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EditPlanForTheDay
        getpfpIdForMngEdit={getpfpIdForMngEditId}
        setPfpId={setPfpId}
        getPfpId={getPfpId}
        isNewPlanDialogOpen={isNewPlanDialogOpen}
        setNewPlanDialog={setNewPlanDialog}
        onClose={handleCloseNewPlanDialog}
      />
    </Box>
  )
}

export default TableLayout
