
import styles from '../../../utils/styles.json'
const UserRole = localStorage.getItem('roles')
const isDisabled = !UserRole?.includes('Admin')

export const style = {
  customBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '-15px',
    '@media only screen and (max-width: 900px)': {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '20px',
    },
    '@media only screen and (max-width: 650px )': {
      display: 'flex',
    },
  },

  searchCustomBoxStyle: {
    display:'flex',
    justifyContent:'center',
    // marginLeft: '10px',
    '@media only screen and (max-width:900px)': {
      margin: '15px 0',
    },
    '@media only screen and (max-width:650px)': {
      width: '100%',
    },
  },
  AddTwoToneIconStyle: {
    width: 20,
    height: 24,
  },
  serachBoxContainer: {
    '@media only screen and (max-width:1240px)': {
      marginRight: '1rem',
    },
  },

  selectComponent: {
    display:'flex',
    alignItems:'center',
    width: '5rem',
    fontFamily: styles.FONT_MEDIUM,
    borderRadius: '20px',
    textAlign: 'center',
    margin: '0',
    '@media (max-width: 1180px)': {
      width: '90px'
    }
  },
  showEntriesSelectCustomStyle: {
    borderRadius: '20px',
    height: '40px',
    width: '65%',
    opacity: '0px',
  },
  showEntriesTypographyCustom: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    fontFamily: styles.FONT_MEDIUM, 
    color:'#353665',
    fontSize:'0.975rem'
  },
  showEntriesFormControlStyle: {
    m: 1,
    minWidth: '120px',
    margin:'0 0 0 8px',
  },
  showEntriesInBoxStyle:{
    display:'flex', 
    marginRight:'15px'
  },

  formGridContainerStyle: {
    display:'flex',
    justifyContent:'center',
  },
  iconButtonStyle: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: '#9e9e9e',
  },
  fieldBoxStyle: {
    margin:'12px',
    width: '65%',
  },
  BackdropDiaglogStyle:{
    backdropFilter: 'blur(1px)' ,
    padding:'50px',
  '& .MuiDialogContent-root' :{
    flex: '1 1 auto',
    overflowY: 'auto',
    padding: '0',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    width:'360px',
  },
  '& .MuiDialogTitle-root' :{
    padding:'10px 0 5px 0'
  },
  },
  diaglogActionStyle: {
    padding: '50px 0 0 0',
    '@media only screen and (max-width: 911px)': {
      margin: '25px',
      padding: '0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  typographyStyle: {
    margin: '5px',
  },
  diaglogHeading: {
    fontFamily: 'Poppins-Bold',
    color: '#353665',
  },
  diaglogActionBoxStyle: {
    display: 'flex',
    justifyContent: 'center',
    margin: '15px -20px 15px 15px',
    gap: '10px',
  },
  labelFirstTypography: {
    paddingTop: '5px',
    color: '#555555',
    fontFamily: 'Poppins-Regular',
  },

  boxCircularProgress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30vh',
  },
  cardBox: {
  },
  cardStyle: {
    backgroundColor: 'rgb(231, 235, 240)',
    borderRadius: '10px',
    paddingBottom: '0px',
    '& .MuiCardContent-root': {
      paddingBottom: '10px',
      padding: '8px',
    },
  },
  cardContentStyle: {
    '&:last-child': {
      paddingBottom: '5px',
    },
  },
  cardTitleStyle: {
    fontSize: '14px',
    fontFamily: styles.FONT_MEDIUM,
  },
  cardContentTypoStyle: {
    fontSize: '14px',
    fontFamily: styles.FONT_MEDIUM,
    margin: '5px 20px 5px 0',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  cardContentBoxStyle: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  iconBoxStyle: {
    display: 'flex',
    alignItems: 'center',
    width: '30%',
    justifyContent: 'end',
  },
  
  editIconsStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    width: '40px',
    backgroundColor: 'white',
    borderRadius: '50px',
    cursor: 'pointer',
    margin: '0 10px',
  },
  iconsStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    width: '40px',
    backgroundColor: 'white',
    borderRadius: '50px',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
  },
  selectAndPaginationBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop:'12px'
  },
  noCardStyle: {
    backgroundColor: 'rgb(231, 235, 240)',
    margin: '20px 0px 20px 20px',
    borderRadius: '10px',
    paddingBottom: '0px',
   
  },
  noCardContentBoxStyle: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
  },
  noCardContentTypoStyle: {
    fontSize: '16px',
    fontFamily: styles.FONT_BOLD,
    marginLeft: '10px',
  },
  noCardContentStyle: {
    '&:last-child': {
      padding:'12px',
    },
  },
}
