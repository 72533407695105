import React, { useEffect, useState } from 'react'
import ApexCharts from 'apexcharts'

// Predefined color palette for the first 10 colors
const predefinedColors = [
  '#FF5733',
  '#33FF57',
  '#3357FF',
  '#FF33A8',
  '#FF8F33',
  '#33FFF3',
  '#33A8FF',
  '#D433FF',
  '#FF5733',
  '#57FF33',
]

// Function to generate distinct colors using RGB
const generateColors = (count: number) => {
  const colors = [...predefinedColors]

  if (count > predefinedColors.length) {
    const step = Math.floor(255 / (count - predefinedColors.length))
    for (let i = predefinedColors.length; i < count; i++) {
      const r = (i * step) % 256
      const g = (128 + i * step) % 256
      const b = (64 + i * step) % 256
      colors.push(`rgb(${r}, ${g}, ${b})`)
    }
  }

  return colors.slice(0, count)
}

const CertificateWiseSplitUp = (props: any) => {
  const { myChartData } = props

  const [chartData, setChartData] = useState<{ name: string; count: number; percentage: string }[]>(
    [],
  )

  useEffect(() => {
    if (myChartData && Array.isArray(myChartData) && myChartData.length > 0) {
      const newData = myChartData.map((item) => ({
        name: item.certificateName || 'Unknown',
        count: item.count || 0,
        percentage: item.percentage || '0%',
      }))
      setChartData(newData)
    }
  }, [myChartData])

  useEffect(() => {
    if (chartData.length > 0) {
      const names = chartData.map((item) => item.name)
      const counts = chartData.map((item) => parseFloat(item.percentage.replace('%', '')))
      const colors = generateColors(chartData.length)

      const options = {
        series: counts,
        chart: {
          height: 355,
          width: 355,
          type: 'donut',
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
            dataLabels: {
              enabled: false,
            },
          },
        },
        fill: {
          type: 'gradient',
        },
        labels: names,
        colors: colors,
        tooltip: {
          enabled: true,
          custom: function (prop: any) {
            const certificateName = chartData[prop?.seriesIndex]?.name || 'Unknown'
            const count = chartData[prop?.seriesIndex]?.count || 0
            const percentage = chartData[prop?.seriesIndex]?.percentage || '0%'

            return `
              <div style="
                padding: 12px 16px;
                background-color: #2c3e50;
                color: #ecf0f1;
                border-radius: 10px;
                box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
                font-family: 'Roboto', sans-serif;
                max-width: 220px;
                font-size: 14px;
              ">
                  <strong>Certificate Name:</strong> ${certificateName} </br>
                  <strong>Count:</strong> ${Math.round(count)} </br>
                  <strong>Percentage:</strong> ${percentage}
              </div>
            `
          },
        },
        legend: {
          position: 'bottom',
          fontSize: '14px',
          maxHeight: 100,
          scrollable: true,
        },
      }

      const chart = new ApexCharts(document.querySelector('#piechartCerticateWiseSplitUp'), options)
      chart.render()

      return () => {
        chart.destroy()
      }
    }
  }, [chartData])

  return (
    <div
      id='piechartCerticateWiseSplitUp'
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 'auto',
        marginTop:'25px'
      }}
    />
  )
}

export default CertificateWiseSplitUp
