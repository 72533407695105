import { Button, styled } from '@mui/material'
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone'
import { style } from './projectCustomersStyle'
import { AddButtonPropType } from './ProjectCustomersTypes'
import styles from '../../../utils/styles.json'

const ActionButton = styled(Button)(() => ({
  fontSize: '15px',
  height: '42px',
  float: 'right',
  margin: '0',
  borderRadius: '20px',
  padding: '5px 15px',
  fontFamily: styles.FONT_MEDIUM,
}))

export const AddCustomerButton = ({ setOpenCustomerForm }: AddButtonPropType) => {
  const handleClickOpenDiaglogForm = () => {
    setOpenCustomerForm(true)
  }
  return (
    <>
      <ActionButton
        variant='outlined'
        startIcon={<AddTwoToneIcon sx={style.AddTwoToneIconStyle} />}
        onClick={handleClickOpenDiaglogForm}
      >
        NEW CLIENT
      </ActionButton>
    </>
  )
}

export default AddCustomerButton
