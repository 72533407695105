import styles from '../../../utils/styles.json'

export const style = {
  containerTable: {
    padding: 1,
  },
  formGroup: {
    marginLeft: 3.5,
    marginTop: 1,
  },
  showingResultBox: {
    padding: '0 0 10px 0',
  },
  saveButton: {
    margin: '20px 0 0',
    width: '100px',
    borderRadius: '20px',
    fontFamily: styles.FONT_BOLD,
    '&.Mui-disabled': {
      opacity: 0.5,
      color: '#ffffff',
      backgroundColor: styles.PRIMARY_COLOR,
      cursor: 'not-allowed',
    },
  },
  buttonContainer: {
    justifyContent: 'center',
  },
  buttonMargin: {
    backgroundColor: '#F6F6F6',
    color: '#777777',
    borderRadius: '10px',
    padding: '10px 17px',
    marginRight: '5px',
    height: '35px',
    fontFamily: styles.FONT_BOLD,
    '& .MuiButton-root': {
      minWidth: '0',
    },
  },
  activeButton: {
    backgroundColor: styles.PRIMARY_COLOR,
    color: '#FFFFFF',
  },
  alphabetContainer: {
    overflow: 'auto',
    height: '35px',
    display: 'flex',
    scrollbarWidth: 'none',
    margin: '10px 0',
  },

  alphaText: {
    marginLeft: '13px',
    width: '170px',
    height: '24px',
    fontFamily: styles.FONT_MEDIUM,
    marginTop: '16px',
  },
  checkeboxTable: {
    color: '#1D1B20',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    height: '30vh',
    width: '100%',
  },
  typographyStyle: {
    color: '#5e646f',
    fontFamily: styles.FONT_MEDIUM,
    fontSize: '16px',
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
  },
  labelSelectAll: {
    color: styles.PRIMARY_COLOR,
    fontFamily: styles.FONT_BOLD,
    fontSize: '16px',
    marginLeft: '0px',
    fontWeight: '500',
  },
  mainCheckBoxContainter: {
    padding: '10px',
    border: '1px solid #EEEEEE',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  checkBoxContainter: {
    display: 'flex',
    width: '100%',
    overflowY: 'auto',
  },
  noContentCheckBox: {
    padding: '10px',
    width: '100%',
    display: 'flex',
    justifyContent:'center'
  },
  noContentTypographyCheckBox: {
    color: '#5e646f',
    fontSize: '16px',
    fontFamily: styles.FONT_MEDIUM,
  },
  firstColumnBox: {
    // flex: '1 1 33%',
    margin: '0 0 0 10px',
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    '@media only screen and (max-width:780px)': {
      gridTemplateColumns: 'auto auto',
    },
    '@media only screen and (max-width:480px)': {
      gridTemplateColumns: 'auto',
    },
  },
  selectAllStyle: {
    width: 'fit-content',
    color: styles.PRIMARY_COLOR,
    fontFamily: styles.FONT_BOLD,
    fontSize: '16px',
    marginLeft: '0',
    fontWeight: '500',
  },
  selectSingleStyle: {
    width: 'fit-content',
  },
  formControl: {
    width: '100%',
  },
  alphabetTypoStyle: {
    fontSize: '16px',
    fontFamily: styles.FONT_BOLD,
  },
  checkBoxLetterLabelStyle: {
    fontSize: '14px',
    fontFamily: styles.FONT_MEDIUM,
  },
  checkBoxSelectAllLabelStyle: {
    fontSize: '15px',
    fontFamily: styles.FONT_MEDIUM,
  },
}
