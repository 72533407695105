import React, { useEffect, useState } from 'react'
import ApexCharts from 'apexcharts'
import { countryName } from '../Common/countryName'

// Predefined color palette for the first 10 colors
const predefinedColors = [
  '#FF5733',
  '#33FF57',
  '#3357FF',
  '#FF33A8',
  '#FF8F33',
  '#33FFF3',
  '#33A8FF',
  '#D433FF',
  '#FF5733',
  '#57FF33',
]

// Function to generate distinct colors using RGB
const generateColors = (count: number) => {
  const colors = [...predefinedColors]

  if (count > predefinedColors.length) {
    const step = Math.floor(255 / (count - predefinedColors.length))
    for (let i = predefinedColors.length; i < count; i++) {
      const r = (i * step) % 256
      const g = (128 + i * step) % 256
      const b = (64 + i * step) % 256
      colors.push(`rgb(${r}, ${g}, ${b})`)
    }
  }

  return colors.slice(0, count)
}

const CountryContributionChart = ({ myChartData }: { myChartData: any }) => {
  const [chartData, setChartData] = useState<{ country: string; businessCount: number; percentage: number }[]>([])

  useEffect(() => {
    if (Array.isArray(myChartData) && myChartData.length > 0) {
      const newData = myChartData.map((data: any) => ({
        country: data.country || 'Unknown',
        businessCount: data.businessCount || 0, 
        percentage: data.businessPercentage
      }))
      setChartData(newData)
    }
  }, [myChartData])

  useEffect(() => {
    if (chartData.length > 0) {
      const countries = chartData.map((item) => {
        const match = countryName.find((country) => country.code === item.country);
        return match ? match.name : item.country;
      });
      const businessCounts = chartData.map((item) => item.businessCount)
      const colors = generateColors(chartData.length)

      const options = {
        series: businessCounts,
        chart: {
          width: 355,
          height: 355,
          type: 'donut',
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        fill: {
          type: 'gradient',
        },
        labels: countries,
        colors: colors,
        tooltip: {
          custom: function ({ seriesIndex }: any) {
            const country = countryName.find((c) => c.code === chartData[seriesIndex]?.country)?.name || 'Unknown'
            const businessCount = chartData[seriesIndex]?.businessCount || 0
            const percentage = chartData[seriesIndex]?.percentage

            return `
              <div style="
                padding: 12px 16px;
                background-color: #2c3e50;
                color: #ecf0f1;
                border-radius: 10px;
                box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
                font-family: 'Roboto', sans-serif;
                max-width: 220px;
                font-size: 14px;
              ">
                <strong>Country:</strong> ${country}<br/>
                <strong>Business Count:</strong> ${businessCount}<br/>
                <strong>Business Percentage:</strong> ${percentage}
              </div>
            `
          },
        },
        legend: {
          position: 'bottom',
          fontSize: '14px',
          maxHeight: 100,
          scrollable: true,
        },
      }

      const chart = new ApexCharts(document.querySelector('#piechartCountryContribution'), options)
      chart.render()

      return () => {
        chart.destroy()
      }
    }
  }, [chartData])

  return (
    <div
      id='piechartCountryContribution'
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
        height: 'auto',
        marginTop:'25px'
      }}
    />
  )
}

export default CountryContributionChart
