import { Formik, Form, Field, FieldProps } from 'formik'
import {
  Autocomplete,
  Box,
  Button,
  DialogContent,
  Grid,
  TextField,
  Tooltip,
  Typography,
  styled,
  Popper,
} from '@mui/material'
import { style } from './projectCustomersStyle'
import { InputField } from '../../Common/ComponentCommonStyles'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import { FormikFormPropType } from './ProjectCustomersTypes'
import styles from '../../../utils/styles.json'
import { countryName } from '../../Common/countryName'

export const validationSchema = Yup.object({
  customer_name: Yup.string()
    .required('Customer Name is required')
    .test('is-empty-or-whitespace', 'Customer Name cannot be empty or whitespace', (value: any) => {
      return value?.trim().length > 0
    }),
})

const ActionButton = styled(Button)(({ theme }) => ({
  fontSize: '13px',
  height: '40px',
  fontFamily: styles.FONT_BOLD,
  width: '120px',
  borderRadius: '20px',
  '&.Mui-disabled': {
    opacity: 0.5,
    color: '#ffffff',
  },
}))

const CancelButton = styled(Button)(({ theme }) => ({
  background: '#E2E2E2',
  color: '#000000',
  fontSize: '13px',
  height: '40px',
  fontFamily: styles.FONT_BOLD,
  width: '120px',
  borderRadius: '20px',
  '&:hover': {
    background: '#E2E2E2',
    color: '#000000',
  },
}))

const FormikForm = ({
  initialValues,
  onSubmit,
  handleClickCloseDiaglogForm,
  openCustomerEditForm,
  value,
  setValue,
}: FormikFormPropType) => {
  const [isDisabled, setIsDisabled] = useState(true)

  useEffect(() => {
    if (openCustomerEditForm && initialValues.customer_name?.trim()) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [openCustomerEditForm, initialValues.customer_name])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validate={(values) => {
        const errors = {}
        if (!values.customer_name || !values.customer_name.trim()) {
          setIsDisabled(true)
        } else {
          setIsDisabled(false)
        }
        return errors
      }}
    >
      {({ values, setFieldValue, errors, touched }) => (
        <Form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <DialogContent
            dividers
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            <Grid container sx={style.formGridContainerStyle}>
              <Box sx={style.fieldBoxStyle}>
                <Field name='customer_name'>
                  {({ field }: FieldProps<string>) => (
                    <InputField
                      {...field}
                      label='Client Name'
                      variant='outlined'
                      placeholder='Enter Customer Name'
                      size='small'
                      error={touched.customer_name && !!errors.customer_name}
                      helperText={
                        touched.customer_name && typeof errors.customer_name === 'string'
                          ? errors.customer_name
                          : ''
                      }
                      inputProps={{
                        autoComplete: 'new-password',
                        form: { autoComplete: 'off' },
                      }}
                    />
                  )}
                </Field>
              </Box>
              <Autocomplete
                autoFocus={false}
                size='small'
                disablePortal={false}
                id='select-working-employees'
                options={countryName}
                getOptionLabel={(option: any) => option?.name ?? ''}
                value={countryName.find((option) => option.code === values.country_code) || null}
                sx={{
                  width: '85%',
                  paddingBottom: '15px',
                  '.MuiInputBase-root': {
                    padding: '10px 11px',
                    borderRadius: '20px',
                    fontSize: '13px',
                    fontFamily: 'Montserrat-Medium',
                    height: '45px',
                  },
                  '& .MuiFormControl-root': {
                    margin: '0',
                    marginTop: '5px',
                  },
                  '& .MuiFormLabel-root ': {
                    backgroundColor: 'white',
                    width: '125px',
                  },
                  '.MuiFormLabel-asterisk': {
                    color: 'white',
                  },
                }}
                renderInput={(params: any) => (
                  <TextField {...params} label='Search Country' variant='outlined' />
                )}
                onChange={(event: any, newValue: any) => {
                  if (newValue && newValue?.code) {
                    setFieldValue('country_code', newValue?.code)
                  } else {
                    setFieldValue('country_code', '')
                  }
                }}
                ListboxProps={{
                  style: {
                    maxHeight: '200px',
                  },
                }}
                PopperComponent={(props) => (
                  <Popper
                    {...props}
                    style={{
                      zIndex: 1500,
                      width: props.style?.width,
                      ...props.style,
                    }}
                  />
                )}
              />
            </Grid>
          </DialogContent>
          <Box sx={style.diaglogActionBoxStyle}>
            <CancelButton onClick={handleClickCloseDiaglogForm} autoFocus>
              CANCEL
            </CancelButton>
            <ActionButton autoFocus type='submit' disabled={isDisabled}>
              {openCustomerEditForm ? (
                <Typography>Update</Typography>
              ) : (
                <Typography>Create</Typography>
              )}
            </ActionButton>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default FormikForm
