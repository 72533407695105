import { Box, TableCell, TableRow, Typography, styled } from '@mui/material'
import { StyledTableCell } from '../../../Common/CommonStyles'
import dayjs from 'dayjs'
import styles from '../../../../utils/styles.json'

const style = {
  leftBorder: {
    borderLeft: '1px solid #EEEEEE',
  },
  styledcell: {
    padding: '6px 16px',
  },
  tableCellsStyles: {
    backgroundColor: '#e5e5e6',
    height: '20px',
    padding: '6px 10px',
  },
  typographyStyle: {
    fontSize: '13px',
    fontFamily: styles.FONT_MEDIUM,
  },
  dividerStyle: { padding: '5px 0' },
  resourcesStyle: {
    height: '15px',
    padding: '10px',
  },
  totalBillableStyle: {
    backgroundColor: '#e5e5e6',
    padding: '6px 16px',
  },
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  left: '160px',
  width: '1719px',
  height: '15px',
  boxShadow: '0px 5px 2px #6c6c6c10',
  opacity: '1',
}))

const StyledTypographyDivider = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  padding: '1px 0',
  borderTop: '1px solid #EEEEEE',
  borderBottom: '1px solid #EEEEEE',
  backgroundColor: '#3B3C36',
  borderRadius: '5px',
}))




const label = [
  'project_resource',
  'resource_type',
  'is_billable',
  'start_date',
  'end_date',
  'employee_type',
  'worked_time',
  'balance_time',
]

const TableRowContainer = (props: any) => {
  const { row, idx } = props

  const hasChildData = row?.projectResourcesReport?.length > 2
  const demo = [1, 2, 3, 4, 5, 6, 7, 8]

  return (
    <>
      <StyledTableRow id='body' key={idx} sx={style.tableCellsStyles}>
        <StyledTableCell sx={style.styledcell}>{row.projectName}</StyledTableCell>
        <StyledTableCell sx={style.styledcell}>{row.project_source}</StyledTableCell>
        <StyledTableCell sx={style.styledcell}>{row.domains??"NA"}</StyledTableCell>
        <StyledTableCell sx={style.styledcell}>{row.project_customer}</StyledTableCell>
        <StyledTableCell sx={style.styledcell}>{row.project_status}</StyledTableCell>
        <StyledTableCell sx={style.styledcell}>{row.project_location}</StyledTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
      </StyledTableRow>
      {row.project_members?.map((report: any, index: number) => (
        <StyledTableRow id='body' key={`report-${report?.start_date}-${report?.end_date}`} sx={style.resourcesStyle}>
          <StyledTableCell></StyledTableCell>
          {demo.slice(3, demo.length).map(() => {
            return <StyledTableCell></StyledTableCell>
          })}
          {label.map((labelData: string) => {
            return (
              <StyledTableCell key={labelData} sx={labelData === 'balance_time' ? { borderRight: '' } : {}}>
                {labelData === 'start_date'
                  ? report.start_date
                    ? dayjs(report.start_date).format('DD/MM/YYYY')
                    : 'NA'
                  : labelData === 'end_date'
                  ? report.end_date
                    ? dayjs(report.end_date).format('DD/MM/YYYY')
                    : 'NA'
                  : report[labelData] || 'NA'}
              </StyledTableCell>
            )
          })}
        </StyledTableRow>
      ))}
      {row?.fixed > 0 || row?.monthly > 0 || row?.hourly > 0 ? (
        <StyledTableRow sx={style.totalBillableStyle}>
          <TableCell
            sx={{
              ...style.leftBorder,
              textAlign: 'center',
              fontSize: '13px',
              fontFamily: styles.FONT_MEDIUM,
              padding: '6px 16px',
            }}
            colSpan={5}
          >
            Total Billable Resources
          </TableCell>
          <StyledTableCell colSpan={10} sx={{ padding: '6px 16px' }}>
            <Box display='flex' flexDirection='column'>
              {row?.fixed > 0 && (
                <Typography sx={style.typographyStyle}>{`Fixed - ${row?.fixed}`}</Typography>
              )}
              {row?.monthly > 0 && (
                <Typography sx={style.typographyStyle}>{`Monthly - ${row?.monthly}`}</Typography>
              )}
              {row?.hourly > 0 && (
                <Typography sx={style.typographyStyle}>{`Hours - ${row?.hourly}`}</Typography>
              )}
            </Box>
          </StyledTableCell>
        </StyledTableRow>
      ) : (
        <TableRow>
          <TableCell colSpan={15} align='center'>
            No members available.
          </TableCell>
        </TableRow>
      )}
      {!hasChildData && (
        <TableCell sx={style.dividerStyle} colSpan={14}>
          <StyledTypographyDivider />
        </TableCell>
      )}
    </>
  )
}

export default TableRowContainer
