import * as React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { CircularProgress, Grid, MenuItem, Modal, TextField } from '@mui/material'
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import data from './NewRequestData.json'
import { Dispatch } from 'redux'
import { RootState } from '../../configureStore'
import { connect } from 'react-redux'
import {
  addNewComment,
  fetchDownloadableURL,
  fetchUpdatedServiceRequest,
  getDrsData,
  getIssueTypeList,
} from '../../actions'
import { userAuthenticationUI, SRUI, dashboardEntity, dashboardUI } from '../../reducers'
import { Box } from '@mui/system'
import { ICreateComment } from '../../models/create-comment.model'
import { loaderProps } from '../Common/CommonStyles'
import moment from 'moment'
import { duration } from '../../utils/date-format'
import { toast } from 'react-toastify'
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone'
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded'
import styles from '../../utils/styles.json'

const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiDialogContent-root': {
    padding: '30px 40px',
  },
  '& .MuiDialogActions-root': {
    justifyContent: 'center',
    gap: '15px',
    padding: '20px 0',
  },

  '& .MuiButton-root:hover': {},
  '&.MuiButtonBase-root-MuiButton-root:hover': {},
}))

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

const StyledMenuItem = styled(MenuItem)(() => ({
  fontSize: '13px',
  fontFamily: styles.FONT_MEDIUM,
}))

const Heading = styled(Typography)(() => ({
  fontSize: '26px',
  textAlign: 'center',
  fontFamily: styles.FONT_BOLD,
  letterSpacing: '0px',
}))

const InputField = styled(TextField)(() => ({
  marginTop: '5px',
  marginBottom: '5px',
  '& .MuiOutlinedInput-input': {
    padding: '13.5px 14px',
    fontSize: '13px',
    fontFamily: styles.FONT_MEDIUM,
  },
  '& .MuiFormLabel-asterisk': {
    color: 'red',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
  '& .MuiFormLabel-root.MuiInputLabel-root': {
    fontSize: '15px',
    lineHeight: '1.8em',
  },
}))

const SelectField = styled(TextField)(() => ({
  marginTop: '5px',
  marginBottom: '5px',
  '& .MuiOutlinedInput-input': {
    padding: '12px 14px',
    fontSize: '13px',
    fontFamily: styles.FONT_MEDIUM,
  },
  '& .MuiFormLabel-asterisk': {
    color: 'red',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
}))

const ActionButtonForAddComment = styled(Button)(() => ({
  fontSize: '13px',
  height: '42px',
  fontFamily: styles.FONT_BOLD,
  width: '37%',
  borderRadius: '20px',
}))

const ActionButtonForPostComment = styled(Button)(() => ({
  fontSize: '13px',
  height: '42px',
  fontFamily: styles.FONT_BOLD,
  width: '60%',
  borderRadius: '20px',
}))

const CancelButton = styled(Button)(() => ({
  background: '#E2E2E2',
  color: '#000000',
  fontSize: '13px',
  height: '42px',
  fontFamily: styles.FONT_BOLD,
  width: '20%',
  borderRadius: '20px',
  '&:hover': {
    background: '#E2E2E2',
    color: '#000000',
  },
}))

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #A5C332',
  boxShadow: 24,
  p: 4,
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

const UpdateRequestDialog = ({
  rowData,
  open,
  onClose,
  DepartmentList,
  GetIssueTypeData,
  isCommentPostDone,
  getIssueTypeList,
  userDetails,
  addNewComment,
  loaderState,
  comment,
  fetchSRData1,
  resetUpdatedSR,
  resetAddComment,
  fetchDrsData,
  fetchUpdatedRequests,
  isAssignedRequest,
  fetchDownloadableURLs,
  DownloadableData,
  isDownloadable,
  resetDownloadableURLs,
}: any) => {
  const { AllPriority, AllStatus } = data
  const [getDepartmentId, setDepartmentId] = useState(
    rowData?.id && rowData?.department?.id ? rowData?.department?.id : '2',
  )
  const [getParticularEmployeeUserId, setParticularEmployeeUserId] = useState(
    rowData?.userId ? rowData?.userId : '',
  )

  const [selectedPriorityOption, setPriorityOption] = useState(
    rowData?.priority ? rowData?.priority : '',
  )

  const [TypeError, setTypeError] = useState(false)
  const [TitleError, setTitleError] = useState(false)
  const [DescriptionError, setDescriptionError] = useState(false)
  const [PriorityError, setPriorityError] = useState(false)
  const [employeeNameError, setEmployeeNameError] = useState(false)

  const [selectedStatusOption, setStatusOption] = useState(
    rowData?.id && rowData?.status ? rowData?.status : 1,
  )
  const [titleValue, setTitleValue] = React.useState(rowData?.title ? rowData?.title : '')
  const [descriptionValue, setDescriptionValue] = React.useState(
    rowData?.description ? rowData?.description : '',
  )
  const [getIssueTypeId, setIssueTypeId] = useState(rowData?.issues?.id ? rowData?.issues?.id : '')
  const [isRequestId, setIsRequestId] = React.useState(rowData?.id ? true : false)
  const [openCommentModal, setOpenCommentModal] = React.useState(false)
  const [comments, setComments] = React.useState(
    rowData?.comments && rowData?.comments.length > 0 ? rowData?.comments : [],
  )
  const [employeeName, setEmployeeName] = React.useState(
    rowData?.employeeName?.id ? rowData?.employeeName?.id : '',
  )
 
  const [newComment, setNewComment] = React.useState('')
  const isCommentEmpty = newComment.trim() === ''

  useEffect(() => {
    fetchDrsData()
    setTitleValue(rowData?.title ? rowData?.title : '')
    setDescriptionValue(rowData?.description ? rowData?.description : '')
    setDepartmentId(rowData?.id && rowData?.department?.id ? rowData?.department.id : 2)
    setIssueTypeId(rowData?.issues?.id ? rowData?.issues.id : '')
    setPriorityOption(rowData?.priority ? rowData?.priority : '')
    setEmployeeName(rowData?.employeeName ? rowData?.employeeName : '')
    setStatusOption(rowData?.id && rowData?.status ? rowData?.status : 1)
    setIsRequestId(rowData?.id ? true : false)
    setComments(rowData?.comments && rowData?.comments.length > 0 ? rowData?.comments : [])

    if (rowData?.id && rowData?.department?.id ? rowData?.department.id : 2) {
      getIssueTypeList({
        dept_id: rowData?.id && rowData?.department?.id ? rowData?.department.id : 2,
      })
    }

    if (rowData?.leaveFor) {
      setParticularEmployeeUserId(rowData.userId ? rowData.userId : null)
    }
  }, [rowData, fetchDrsData])

  React.useMemo(() => {
    const commentList = [...comments]
    if (comment?.id) {
      commentList.push(comment)
      setComments(commentList)
    }
  }, [comment])

  const handleClose = () => {
    onClose()
    setTitleValue(rowData?.title ? rowData?.title : '')
    setEmployeeName(rowData?.employeeName ? rowData?.employeeName : '')
    setDescriptionValue(rowData?.description ? rowData?.description : '')
    setDepartmentId(rowData?.id && rowData?.department?.id ? rowData?.department.id : 2)
    setIssueTypeId(rowData?.issues?.id ? rowData?.issues.id : '')
    setPriorityOption(rowData?.priority ? rowData?.priority : '')
    setTypeError(false)
    setTitleError(false)
    setEmployeeNameError(false)
    setDescriptionError(false)
    setPriorityError(false)
    setParticularEmployeeUserId('')
  }

  const inputDateStr = rowData?.created_at
  const dateObject = new Date(inputDateStr)
  const formattedDate = moment(dateObject).format('DD/MM/YYYY')

  const handleDepartmentChange = (event: any) => {
    const selectedDepartmentId = event.target.value
    setDepartmentId(selectedDepartmentId)
    setIssueTypeId(null)
    getIssueTypeList({ dept_id: selectedDepartmentId })
  }

  useEffect(() => {}, [getParticularEmployeeUserId])

  const handleIssueTypeChange = (event: any) => {
    const selectedIssueTypeId = event.target.value
    setIssueTypeId(selectedIssueTypeId)
    setTypeError(typeof event.target.value !== 'number')
  }

  const handlePriorityChange = (event: any) => {
    setPriorityOption(event.target.value)
    setPriorityError(typeof event.target.value !== 'number')
  }

  const handleStatusChange = (event: any) => {
    setStatusOption(event.target.value)
  }

  const handleDescriptionChange = (e: any) => {
    setDescriptionValue(e.target.value)
    setDescriptionError(!e.target.value)
  }

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitleValue(e.target.value)
    setTitleError(!e.target.value)
  }

  const handeEmployeeNameChange = (e: any) => {
    setEmployeeName(e.target.value)
    setEmployeeNameError(!e.target.value)
  }

  const handleOpenCommentModal = () => setOpenCommentModal(true)
  const handleCloseCommentModal = () => {
    setOpenCommentModal(false)
  }
  const handleClosePost = () => {
    onClose()
  }

  useEffect(() => {
    if (isCommentPostDone) {
      toast.success('Comment added successfully')
      fetchSRData1()
      resetAddComment()
    }
  }, [isCommentPostDone])

  const handlePostComment = () => {
    if (newComment.trim() && rowData?.id) {
      const entity: ICreateComment = {
        id_service_request: rowData.id,
        comment: newComment,
        created_by: userDetails.name,
        id_user: userDetails.id,
        request_status: rowData.status,
      }
      addNewComment(entity)
      handleCloseCommentModal()
      handleClosePost()
      setNewComment('')
    }
  }

  const handleUpdate = () => {
    let data = {
      srId: rowData.id,
      status: selectedStatusOption,
      id_department: getDepartmentId,
      id_issue_type: getIssueTypeId,
      priority: selectedPriorityOption,
    }
    try {
      fetchUpdatedRequests(data)

      toast.success('Service request updated successfully.')
      fetchSRData1()
      resetUpdatedSR()
    } catch (error: any) {
      toast.error(`Error updating service request: ${error.message}`)
    }

    handleClose()
  }
  useEffect(() => {
    if (isAssignedRequest) {
      fetchSRData1()
      resetUpdatedSR()
    }
  }, [isAssignedRequest])

  useEffect(() => {
    if (isDownloadable) {
      const link = document.createElement('a')
      link.href = DownloadableData
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      resetDownloadableURLs()
    }
  }, [isDownloadable])

  const handleDownload = () => {
    fetchDownloadableURLs({ srId: rowData.id })
  }

  return (
    <div>
      <>
        {loaderState && (
          <Dialog open={loaderState} PaperProps={{ style: loaderProps }}>
            <CircularProgress color='secondary' />
          </Dialog>
        )}
      </>
      <BootstrapDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
          <Heading>Service Request</Heading>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
            <Grid item xs={4} sm={6}>
              <SelectField
                required
                id='outlined-required'
                label='Department'
                size='small'
                select
                value={getDepartmentId}
                onChange={handleDepartmentChange}
                variant='outlined'
              >
                {DepartmentList?.map((option: any) => (
                  <StyledMenuItem key={option?.id} value={option?.id}>
                    {option.dept_name}
                  </StyledMenuItem>
                ))}
              </SelectField>
            </Grid>
            <Grid item xs={6} sm={6}>
              <SelectField
                error={TypeError}
                required
                id='outlined-required'
                label='Type'
                size='small'
                select
                value={getIssueTypeId}
                onChange={handleIssueTypeChange}
                variant='outlined'
                helperText={TypeError && 'Type required'}
              >
                <StyledMenuItem value='' disabled>
                  Select type
                </StyledMenuItem>
                {GetIssueTypeData.map((option: any) => (
                  <StyledMenuItem key={option?.id} value={option?.id}>
                    {option.title}
                  </StyledMenuItem>
                ))}
              </SelectField>
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputField
                error={TitleError}
                required
                id='outlined-required'
                label='Title'
                size='small'
                value={titleValue}
                onChange={handleTitleChange}
                helperText={TitleError && 'Title required'}
                disabled={isRequestId}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputField
                error={DescriptionError}
                required
                id='outlined-required'
                label='Description'
                size='small'
                value={descriptionValue}
                maxRows={4}
                fullWidth={true}
                helperText={DescriptionError && 'Description required'}
                onChange={handleDescriptionChange}
                multiline={true}
                disabled={isRequestId}
              />
            </Grid>
            {rowData?.id && (
              <Grid item xs={12} sm={12}>
                <InputField
                  required
                  id='outlined-required'
                  label='Created Date'
                  size='small'
                  value={formattedDate}
                  disabled={isRequestId}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <SelectField
                error={PriorityError}
                required
                id='outlined-required'
                label='Priority'
                size='small'
                select
                value={selectedPriorityOption}
                onChange={handlePriorityChange}
                variant='outlined'
                helperText={PriorityError && 'Priority required'}
              >
                {AllPriority.map((option) => (
                  <StyledMenuItem key={option.value} value={option.value}>
                    {option.label}
                  </StyledMenuItem>
                ))}
              </SelectField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectField
                required
                id='outlined-required'
                label='Status'
                size='small'
                select
                value={Number(selectedStatusOption)}
                onChange={handleStatusChange}
                variant='outlined'
              >
                {AllStatus.map((option) => (
                  <StyledMenuItem key={option.value} value={option.value}>
                    {option.label}
                  </StyledMenuItem>
                ))}
              </SelectField>
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputField
                error={employeeNameError}
                required
                id='outlined-required'
                label='Raised By'
                size='small'
                value={employeeName}
                onChange={handeEmployeeNameChange}
                helperText={employeeNameError && 'Raised by is required'}
                disabled={isRequestId}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                sx={{
                  fontSize: '15px',
                  padding: '6px 12px',
                  borderRadius: '24px',
                  textTransform: 'uppercase',
                  fontFamily: styles.FONT_MEDIUM,
                  marginLeft: '0px',
                  marginBottom: '16px',
                  '&.Mui-disabled': {
                    opacity: 0.5,
                    color: '#ffffff',
                    cursor: 'not-allowed',
                  },
                }}
                startIcon={<DownloadForOfflineRoundedIcon />}
                disabled={isDownloadable}
                onClick={handleDownload}
              >
                Download
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <CancelButton
                sx={{
                  fontSize: '14px',
                  fontWeight: '100',
                  width: '80%',
                  textTransform: 'uppercase',
                  marginLeft: '95px',
                }}
                onClick={handleClose}
              >
                CANCEL
              </CancelButton>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                sx={{
                  fontSize: '15px',
                  padding: '6px 22px',
                  borderRadius: '24px',
                  fontFamily: styles.FONT_MEDIUM,
                  marginLeft: '40px',
                  marginBottom: '16px',
                  textTransform: 'uppercase',
                }}
                variant='contained'
                component='span'
                onClick={handleUpdate}
              >
                Update
              </Button>
            </Grid>
            {rowData?.id && (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <ActionButtonForAddComment
                    variant='contained'
                    startIcon={<AddTwoToneIcon sx={{ width: 20, height: 24 }} />}
                    size='small'
                    onClick={handleOpenCommentModal}
                    sx={{ fontSize: '14px', fontWeight: '100', textTransform: 'uppercase' }}
                  >
                    Add Comment
                  </ActionButtonForAddComment>
                </Grid>
                <div>
                  <Modal
                    open={openCommentModal}
                    onClose={handleCloseCommentModal}
                    aria-labelledby='modal-comment'
                    aria-describedby='modal-comment-desc'
                  >
                    <Box sx={style}>
                      <Typography
                        id='modal-comment'
                        variant='h6'
                        component='h6'
                        sx={{ textTransform: 'uppercase' }}
                      >
                        Add Comment
                      </Typography>
                      <TextField
                        type='text'
                        id='comments'
                        name='comments'
                        fullWidth
                        multiline
                        rows={2}
                        maxRows={4}
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                      />
                      <Box textAlign='left'>
                        <ActionButtonForPostComment
                          variant='contained'
                          size='small'
                          sx={{
                            fontSize: '14px',
                            fontWeight: '100',
                            width: '50%',
                            textTransform: 'uppercase',
                          }}
                          onClick={handlePostComment}
                          disabled={isCommentEmpty}
                        >
                          Post Comment
                        </ActionButtonForPostComment>
                      </Box>
                      <Box textAlign='right'>
                        <CancelButton
                          variant='contained'
                          size='small'
                          sx={{
                            fontSize: '14px',
                            fontWeight: '100',
                            width: '32%',
                            textTransform: 'uppercase',
                            bottom: '43px',
                          }}
                          onClick={handleCloseCommentModal}
                        >
                          Cancel
                        </CancelButton>
                      </Box>
                    </Box>
                  </Modal>
                </div>
                {comments && comments.length > 0 && (
                  <Grid item xs={12} sm={12}>
                    <Typography component='h5' variant='h5' align='center'>
                      Comments
                    </Typography>
                    <Box
                      sx={{
                        maxHeight: '400px',
                        overflowY: 'scroll',
                        margin: '0 auto',
                        padding: '5px',
                        border: '2px solid lightgrey',
                      }}
                    >
                      {comments?.map((data: any) => (
                        <>
                          <Typography
                            component='span'
                            sx={{ fontSize: 'smaller', color: '#A82E2F' }}
                          >{`${data?.created_by} @${duration(data?.created_at)} ago`}</Typography>
                          <InputField
                            type='text'
                            fullWidth={true}
                            multiline={true}
                            id='comments'
                            name='comments'
                            InputProps={{ readOnly: true }}
                            value={data.comment}
                          />
                        </>
                      ))}
                    </Box>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </DialogContent>
        {!rowData?.id && (
          <DialogActions>
            <CancelButton autoFocus onClick={handleClose}>
              CANCEL
            </CancelButton>
          </DialogActions>
        )}
      </BootstrapDialog>
    </div>
  )
}

UpdateRequestDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  DepartmentList: PropTypes.array,
  GetIssueTypeData: PropTypes.array,
  getIssueTypeList: PropTypes.func.isRequired,
  rowData: PropTypes.object,
  userDetails: PropTypes.object,
  addNewComment: PropTypes.func.isRequired,
  comment: PropTypes.object,
  loaderState: PropTypes.bool,
  fetchSRData1: PropTypes.func.isRequired,
  resetUpdatedSR: PropTypes.func.isRequired,
  isCommentPostDone: PropTypes.bool,
  isAssignedRequest: PropTypes.bool,
  fetchUpdatedRequests: PropTypes.func.isRequired,
  isDownlodable: PropTypes.bool,
  resetDownloadableURLs: PropTypes.func.isRequired,
}

const mapStateToProps = (state: RootState) => {
  return {
    isAssignedRequest: SRUI.getServicePostState(state).isAssignedRequest,
    isCommentPostDone: SRUI.getServicePostState(state).isCommentPostDone,
    isDownloadable: SRUI.getServicePostState(state).isDownloadable,
    SRStatus: userAuthenticationUI.getUserAuthentication(state).isUserAuthenticationDone,
    GetIssueTypeData: dashboardEntity.getDashboard(state).getIssueTypeList,
    userDetails: dashboardEntity.getDashboard(state).getUserDetails,
    loaderState: dashboardUI.getDashboard(state).isCommentData,
    DrsData: dashboardEntity.getDashboard(state).getDrsData,
    updatedRequestData: dashboardEntity.getDashboard(state).createUpdatedServiceRequestData,
    DownloadableData: dashboardEntity.getDashboard(state).getDownloadableURL,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getIssueTypeList: (data: any) => {
      dispatch(getIssueTypeList.request(data))
    },
    fetchDrsData: () => dispatch(getDrsData.request()),
    addNewComment: (data: ICreateComment) => dispatch(addNewComment.request(data)),
    resetAddComment: () => dispatch(addNewComment.reset()),
    fetchUpdatedRequests: (data: any) => dispatch(fetchUpdatedServiceRequest.request(data)),
    resetUpdatedSR: () => dispatch(fetchUpdatedServiceRequest.reset()),
    fetchDownloadableURLs: (data: any) => dispatch(fetchDownloadableURL.request(data)),
    resetDownloadableURLs: () => dispatch(fetchDownloadableURL.reset()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateRequestDialog)
