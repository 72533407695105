import { StyledTableCell } from '../../Common/CommonStyles'
import { style } from './ projectQAReportStyles'
import { Box, TableCell, TableRow, Typography, styled } from '@mui/material'
import styless from '../../../utils/styles.json'

const styles = {
  BgColor: {
    height: '15px',
    padding: '6px 16px',
    fontFamily: styless.FONT_MEDIUM,
  },
  typographyStyle: {
    fontSize: '13px',
    fontFamily: styless.FONT_MEDIUM,
  },
  dividerStyle: { padding: '5px 0' },
  resourcesStyle: {
    height: '15px',
    padding: '10px',
  },
  noEntriesRow: {
    fontFamily: styless.FONT_MEDIUM,
    Padding: '6px 16px',
    height: '15px',
  },
  tableCellsStyles: {
    backgroundColor: '#e5e5e6',
    height: '20px',
    padding: '6px 10px',
    fontFamily: styless.FONT_MEDIUM,
  },
}

const StyledTypographyDivider = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  padding: '1px 0',
  borderTop: '1px solid #EEEEEE',
  borderBottom: '1px solid #EEEEEE',
  backgroundColor: '#3B3C36',
  borderRadius: '5px',
}))
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  left: '160px',
  width: '1719px',
  height: '15px',
  boxShadow: '0px 5px 2px #6c6c6c10',
  opacity: '1',
}))

const TableBodyRow = ({ row, selectType }: any) => {
  const label = ['emp_name', 'is_billable', 'employement_type']

  const renderProjectDetails = (projectDetails: any[]) => {
    if (projectDetails?.length === 0) {
      return 'NA'
    }
    return projectDetails?.map((project) => (
      <Box key={`${project?.project_id}`}>
        {project.project_name ?? 'NA'} | {project.employement_type? `${project.employement_type ?? 'NA'} (${project.time_count ? project.time_count:'NA'})`:'NA'} 
      </Box>
    ))
  }

  const hasChildData = row?.result > 2

  return (
    <>
      {selectType === 'project-wise' && (
        <>
          <StyledTableRow id='body' sx={styles.tableCellsStyles}>
            <StyledTableCell sx={styles.BgColor}>{row.project_name}</StyledTableCell>
            <StyledTableCell sx={styles.BgColor}>{row.project_status}</StyledTableCell>
            <StyledTableCell sx={styles.BgColor}></StyledTableCell>
            <StyledTableCell sx={styles.BgColor}></StyledTableCell>
            <StyledTableCell sx={styles.BgColor}></StyledTableCell>
          </StyledTableRow>

          {row?.members?.map((report: any, index: any) => (
            <StyledTableRow key={`report-${index}`} sx={style.border}>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              {label.map((labelData: any, idx: any) => (
                <StyledTableCell key={labelData}>
                  {labelData === 'employement_type'
                    ? `${report[labelData] ?? 'NA'}${report.time_count ? ` (${report.time_count})` : ''}`
                    : report[labelData] || 'NA'}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
          {row?.total_qa_count ? (
            <StyledTableRow sx={styles.tableCellsStyles}>
              <StyledTableCell colSpan={2} sx={styles.BgColor}>
                Total QA :
              </StyledTableCell>
              <StyledTableCell colSpan={3} sx={styles.BgColor}>
                <Box display='flex' flexDirection='column'>
                  <Typography sx={styles.typographyStyle}>{row?.total_qa_count}</Typography>
                </Box>
              </StyledTableCell>
            </StyledTableRow>
          ) : (
            <StyledTableRow>
              <TableCell colSpan={5} align='center' sx={styles.noEntriesRow}>
                No QA available.
              </TableCell>
            </StyledTableRow>
          )}
          {!hasChildData && (
            <TableCell sx={styles.dividerStyle} colSpan={5}>
              <StyledTypographyDivider />
            </TableCell>
          )}
        </>
      )}

      {selectType !== 'project-wise' && (
        <StyledTableRow sx={style.border}>
          <StyledTableCell>{row?.emp_id}</StyledTableCell>
          <StyledTableCell>{row?.emp_name}</StyledTableCell>
          <StyledTableCell>{renderProjectDetails(row?.projects)}</StyledTableCell>
          <StyledTableCell>{row?.projects_count}</StyledTableCell>
        </StyledTableRow>
      )}
    </>
  )
}
export default TableBodyRow
