import { Button, Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { isLoggedIn } from '../../auth/Auth'
import {
  CenterContainer,
  LeftContainer,
  Logo,
  MainContainer,
  RightContainer,
} from './Styles'
import { connect } from 'react-redux'
import { getUserLoginUser } from '../../actions'
import PropTypes from 'prop-types'
import {
  loginUserEntity,
  loginUserUI,
} from '../../reducers'
import { toast } from 'react-toastify'
import Footer from '../Common/Footer'
import { GetLoginLeftImage } from '../../utils/StaticData'
import {
  GoogleOAuthProvider,
  useGoogleLogin,
} from '@react-oauth/google'

const rightpartloginpage = {
  height: ' 370px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}

const leftpartloginpage = {
  marginTop: '50px',
}

const footerStyle = {
  marginTop: '200px',
  marginRight: '3px',
}

function Login({ getLoginUser, loginStatus, loginUserData }: any) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [token, setToken] = useState<string>('')

  useEffect(() => {
    if (isLoggedIn()) {
      navigate('/home')
    }
  }, [navigate])

  useEffect(() => {
    if (token) {
      getLoginUser({ token })
    }
  }, [token])

  //This Code block is for redirect to home page after successful login
  useEffect(() => {
    if (loginStatus && loginUserData.access_token) {
      toast.success(t('login_success'))
      navigate('/home')
    }
  }, [loginUserData])

  /**
   * To handle the google Oauth response
   * @param response
   */

  const login = useGoogleLogin({
    onSuccess: (response: any) => {
      setToken(response?.access_token)
    },
  })

  /**
   * To handle the error message
   * @param error
   */

  return (
    <MainContainer>
      <LeftContainer sx={leftpartloginpage}>
        <CenterContainer  src={GetLoginLeftImage}></CenterContainer>
      </LeftContainer>

      <RightContainer>
        <Box sx={rightpartloginpage}>
          <>
            <Logo src='./images/Kuber_Logo.png'></Logo>

            <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
              <Button sx={{ top: '60px' }} variant='contained' size='small' onClick={() => login()}>
                Login with Tudip Gmail
              </Button>
            </GoogleOAuthProvider>
            <div style={footerStyle}>
              <Footer />
            </div>
          </>
        </Box>
      </RightContainer>
    </MainContainer>
  )
}

Login.propTypes = {
  loginStatus: PropTypes.bool,
  loginUserData: PropTypes.object,
  getLoginUser: PropTypes.func.isRequired,
}

const mapStateToProps = (state: any) => {
  return {
    loginStatus: loginUserUI.getLoginUserData(state).isUserLoginDone,
    loginUserData: loginUserEntity.getLoginUserData(state).getLoginUser,
  }
}

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    getLoginUser: (data: any) => {
      dispatch(getUserLoginUser.request(data))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
